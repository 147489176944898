import React, { useState } from 'react';
import './VideoModal.css';
import X from '../../../../../img/X.svg';
import UploadVideo from '../../../../../img/UploadVideo.svg';
import UploadLink from '../../../../../img/UploadByLink.svg';
import { Button, makeStyles } from '@material-ui/core';
import AddByLink from './AddByLink';

const useStyle = makeStyles(() => ({
    rootButton: {
        width: '277px',
        height: '52px',
        borderRadius: '6px',
        backgroundColor: '#F5F5F5'  
    },
    rootButtonClicked: {
        width: '277px',
        height: '51px',
        background: '#FFFFFF',
        border: '1px solid #FEA65A',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
        borderRadius: '6px',  
        '&:hover': {
            background: '#FFFFFF',
        }
    }
}))

const VideoModal = (props) => {
    const classes = useStyle();
    const [uploadBy, setUploadBy] = useState();

    const openUploadByLink = () => setUploadBy(2);

    return(
        <div className='video-modal'>
            <div className="video-modal_content">
                <div className="video-modal_content-header">
                    <span>Legg til video</span>
                    <img loading='lazy' src={X} alt='x' onClick={props.closeModal}/>
                </div>
                <div className="video-modal_content-nav">
                    {/* <Button className={uploadBy === 1 ? classes.rootButtonClicked : classes.rootButton}><img loading='lazy' src={UploadVideo} alt='UploadVideo'/></Button> */}
                    <Button className={uploadBy === 2 ? classes.rootButtonClicked : classes.rootButton} onClick={openUploadByLink}><img loading='lazy' src={UploadLink} alt='UploadLink'/></Button>
                </div>
                {
                    uploadBy === 2 && <AddByLink closeModal={props.closeModal}/>
                }
            </div>
        </div>
    )
}

export default VideoModal;