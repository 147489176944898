import React, { useEffect, useState } from 'react';
import MasterCard from '../../../../img/MasterCard.svg';
import VisaCard from '../../../../img/Visa.svg';
import VippsLogo from '../../../../img/VippsLogo.svg';
import './PaymentMethods.css';

const PaymentMethods = ({
  method,
  showPayment,
  handleShowPayment,
  handleShowCard,
}) => {
  const [cardType, setCardType] = useState(null);
  useEffect(() => {
    if (method.card_type === 'visa') {
      setCardType(VisaCard);
    } else if (method.card_type === 'mastercard') {
      setCardType(MasterCard);
    } else if (method.payment_type === 'vipps') {
      setCardType(VippsLogo);
    }
  }, [method]);

  return (
    <div
      className='user-payment-card'
      onClick={() => {
        if (showPayment) {
          handleShowPayment({});
        } else {
          handleShowPayment(method);
        }
        handleShowCard(false);
      }}
    >
      <div className='payment-wrapper'>
        <div className='select'>
          <input type='checkbox' checked={showPayment} readOnly />
          {method.payment_type === 'card' && (
            <label>
              {method.name} slutter med {method.card_number}
            </label>
          )}
          {method.payment_type === 'vipps' && (
            <label>
              {method.name} +47{method.phone_number}
            </label>
          )}
        </div>
        <div className='images'>
          {cardType && <img src={cardType} alt='VisaCard' />}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
