import Card from './Card'
import React, { useEffect, useState } from 'react';
import {fetch_request} from '../../../../../service/request';
import { Link } from 'react-router-dom';
import NoneGravesImage from '../../../../../img/NoneGravesImage.svg';
import AddGraveImage from '../../../../../img/AddGraveImage.svg';

const Content = (props) => {
  const searchInput = props.searchInputValue || ''
  const [gravesList, setGravesList] = useState([])
  const currentPage = props.currentPage
  const cemeterySlug = props.cemeterySlug
  const gravesCount = props.gravesCount

  useEffect(
    () => {
      fetchGraves()
    },
    [searchInput, currentPage]
  )

  const fetchGraves = () => {
    if ( cemeterySlug ) {
      fetch_request(`/api/search_graves?input=${searchInput}&page=${currentPage}&cemetery_slug=${cemeterySlug}`)
        .then((response) => response.json())
        .then((data) => {
          setGravesList(data.graves);
          props.setTotalPages(data.total_pages)
        })
        .catch((err) => console.log(err));
    }
  }

  return(
    <>
      {

        gravesList && gravesList.length === 0 && gravesCount !==0 ?
        <>
          <div className="municipality-not-found">
            <img src={NoneGravesImage} alt="not-found"/>
            <span className='search-graves_none-text'>Fant ingen resultater for <span>"{searchInput}"</span></span>
          </div>

          <Link to="/ny-grav" className="no-grave-found-link">
          <img loading='lazy' src={AddGraveImage} alt="Opprett ny grav" />
          <span>Opprett ny grav</span>
          </Link>
        </>

        :

        <div className="municipality-search-content">
          {
            gravesList && gravesList.map(item => {
              return(
                <Link to={`/deceased/${item.slug}`}>
                  <Card name={item.name} countryName={item?.graveyard?.name}/>
                </Link>
              )
            })
          }
        </div>
      }
    </>
  )
}

export default Content;