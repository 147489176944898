import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { fetch_request } from '../.././../service/request';
import axios from 'axios';
import { CONSTANTS } from '../../../service/constants';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const Notifications = () => {
  const user = useSelector((state) => state.user.user || {});
  const [check, setCheck] = useState({
    generalSms: false,
    generalEmail: false,
    messagesSms: false,
    messagesEmail: false,
  });
  const [messages, setMessages] = useState({
    id: '',
    category: 'messages',
    email: false,
    sms: false,
    user_id: '',
  });
  const [general, setGeneral] = useState({
    id: '',
    category: 'general',
    email: false,
    sms: false,
    user_id: '',
  });
  const [notificationPreferences, setNotificationPreferences] = useState([]);

  useEffect(() => {
    if (!isEmpty(user)) {
      setMessages({ ...messages, user_id: user.id });
      setGeneral({ ...general, user_id: user.id });
    }
  }, [user]);

  useEffect(() => {
    fetchNotificationPreferences();
  }, [user]);

  const findPreferenceByCategory = (category) => {
    let notificationObject = notificationPreferences.find(
      (notification) => notification.category === category
    );
    return notificationObject;
  };

  useEffect(() => {
    if (notificationPreferences) {
      let generalNotification = findPreferenceByCategory('general');
      if (generalNotification) {
        setGeneral(generalNotification);
      }

      let messagesNotification = findPreferenceByCategory('messages');
      if (messagesNotification) {
        setMessages(messagesNotification);
      }
    }
  }, [notificationPreferences]);

  const fetchNotificationPreferences = () => {
    if (user && user.slug) {
      fetch_request(`/api/users/${user.slug}/user_notification_preferences`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
        .then((res) => res.json())
        .then((data) => {
          let notificationPreferences =
            data.user_notification_preferences || [];
          setNotificationPreferences(notificationPreferences);
        });
    }
  };

  const createNotificationPreference = (user_notification_preference) => {
    axios
      .post(
        `${CONSTANTS.baseUrl}/api/users/${user.slug}/user_notification_preferences.json`,
        { user_notification_preference },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(({ data }) => {
        let notificationPreferences = data.user_notification_preferences || [];
        setNotificationPreferences(notificationPreferences);
      });
  };

  const updateNotificationPreference = (id, user_notification_preference) => {
    axios
      .put(
        `${CONSTANTS.baseUrl}/api/users/${user.slug}/user_notification_preferences/${id}.json`,
        { user_notification_preference },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(({ data }) => {
        let notificationPreferences = data.user_notification_preferences || [];
        setNotificationPreferences(notificationPreferences);
      });
  };

  const createOrUpdatePreferenceCategory = (preference) => {
    if (preference.id) {
      updateNotificationPreference(preference.id, preference);
    } else {
      createNotificationPreference(preference);
    }
  };

  const updateNotificationPreferences = () => {
    createOrUpdatePreferenceCategory(messages);
    createOrUpdatePreferenceCategory(general);
  };

  return (
    <div className='notifications-page'>
      <div className='notifications-page_content'>
        <div className='notifications-page_content-header'>
          <span className='notifications-page_content-header_header'>
            Varslingsinnstillinger
          </span>
          <div className='notifications-page_orange-line' />
          <span className='notifications-page_content-header_subheader'>
            Tilpass dine varslingspreferanser
          </span>
        </div>
        <div className='notifications-page_content-main'>
          <span className='notifications-page_content-main_header'>
            Meldinger
          </span>
          <span className='notifications-page_content-main_subheader'>
            Motta meldinger fra Nettgrav og våre partnere
          </span>
          <div className='notification-checkboxes'>
            <div className='notifications-page_checkbox'>
              <Checkbox
                className='notifications-page_checkbox-checkbox'
                checked={messages.email}
                icon={<span className='checkbox-icon' />}
                checkedIcon={<span className='checkbox-checkedIcon' />}
                onClick={() => {
                  setMessages({ ...messages, email: !messages.email });
                }}
              />
              <span className='notifications-page_checkbox-label'>Email</span>
            </div>
            <div className='notifications-page_checkbox'>
              <Checkbox
                className='notifications-page_checkbox-checkbox'
                icon={<span className='checkbox-icon' />}
                checked={messages.sms}
                checkedIcon={<span className='checkbox-checkedIcon' />}
                onClick={() => {
                  setMessages({ ...messages, sms: !messages.sms });
                }}
              />
              <span className='notifications-page_checkbox-label'>
                Tekstmeldinger
              </span>
            </div>
          </div>
        </div>
        <div className='notifications-page_content-main'>
          <div className='notifications-page_content-general'>
            <span className='notifications-page_content-main_header'>
              Generell informasjon
            </span>
            <span className='notifications-page_content-main_subheader'>
              Motta informasjon og andre meldinger relatert til nettgrav.no
            </span>
            <div className='notification-checkboxes'>
              <div className='notifications-page_checkbox'>
                <Checkbox
                  className='notifications-page_checkbox-checkbox'
                  icon={<span className='checkbox-icon' />}
                  checked={general.email}
                  checkedIcon={<span className='checkbox-checkedIcon' />}
                  onClick={() => {
                    setGeneral({ ...general, email: !general.email });
                  }}
                />
                <span className='notifications-page_checkbox-label'>Email</span>
              </div>
              <div className='notifications-page_checkbox'>
                <Checkbox
                  className='notifications-page_checkbox-checkbox'
                  icon={<span className='checkbox-icon' />}
                  checked={general.sms}
                  checkedIcon={<span className='checkbox-checkedIcon' />}
                  onClick={() => {
                    setGeneral({ ...general, sms: !general.sms });
                  }}
                />
                <span className='notifications-page_checkbox-label'>
                  Tekstmeldinger
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='notification_content-button '>
          <button
            className='notification-button_cancel'
            onClick={fetchNotificationPreferences}
          >
            Avbryt
          </button>
          <button
            className='notification-button_save'
            onClick={updateNotificationPreferences}
          >
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
