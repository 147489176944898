import { Avatar, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveListOfPhotosFromCropper } from '../../../../redux/actions';
import Comment from './Comment';
import './Comments.css';
import { request } from '../../../../service/request';

const Comments = (props) => {
  const photos = useSelector((state) => state.photoReducer.listOfPhotos);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const addComment = async (id) => {
    if (!id) return;
    try {
      const graveComment = {
        grave_image_comment: {
          grave_image_id: id,
          comment: comment,
        },
      };

      const response = await request(
        '/grave_image_comments',
        graveComment,
        'post'
      );
      if (response.data.comment_id) {
        await refreshComments();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshComments = async () => {
    if (!photos[props.currentPhoto].id) return;
    try {
      const response = await request(
        `/grave_images/${photos[props.currentPhoto].id}`
      );
      const newArray = photos.map((photo) =>
        photo.id === photos[props.currentPhoto].id ? response.data : photo
      );
      dispatch(saveListOfPhotosFromCropper(newArray));
      setComment('');
    } catch (err) {
      console.log(err);
    }
  };

  const editComment = async (comment, newValueOfComment) => {
    if (!comment) return;
    try {
      const formdata = new FormData();
      formdata.append('grave_image_comment[comment]', newValueOfComment);
      await request(`/grave_image_comments/${comment.id}`, formdata, 'put');
      await refreshComments();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteComment = async (comment) => {
    if (!comment) return;
    try {
      await request(`/grave_image_comments/${comment.id}`, null, 'delete');
      await refreshComments();
    } catch (error) {
      console.log(error);
    }
  };

  const createReply = async (comment, replyText) => {
    try {
      const graveCommentReply = {
        reply: {
          image_comment_id: comment.id,
          description: replyText,
        },
      };
      const response = await request(
        '/grave_image_replies',
        graveCommentReply,
        'post'
      );
      await refreshComments();
    } catch (error) {
      console.log(error);
    }
  };

  const editReply = async (reply, editedReplyText) => {
    try {
      const graveCommentReply = {
        reply: {
          description: editedReplyText,
        },
      };
      await request(
        `/grave_image_replies/${reply.id}`,
        graveCommentReply,
        'put'
      );
      await refreshComments();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReply = async (reply) => {
    await request(`/grave_image_replies/${reply.id}`, null, 'delete');
    await refreshComments();
  };

  const imagesComments = () => {
    return (
      photos[props.currentPhoto] &&
      photos[props.currentPhoto].image_comments &&
      photos[props.currentPhoto].image_comments.length > 0
    );
  };

  return (
    <div className='grave-picture-gallery_comments'>
      <div className='grave-picture-gallery_comments-content'>
        <div className='grave-picture-gallery_comments-content_post'>
          <Avatar
            className='grave-picture-gallery_comments-content_post-avatar'
            src={user && user?.avatar}
          />
          <input
            type='text'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder='Skriv kommentar her'
          />
          <Button
            className='grave-picture-gallery_comments-content_post-button'
            onClick={() => addComment(photos[props.currentPhoto].id)}
          >
            Send
          </Button>
        </div>
        {imagesComments() && (
          <div className='grave-picture-gallery_comments-content_comments'>
            {photos[props.currentPhoto].image_comments.map((comment) => (
              <Comment
                comment={comment}
                key={comment.id}
                onCommentDelete={deleteComment}
                onCommentEdit={editComment}
                onReplyCreate={createReply}
                onReplyEdit={editReply}
                onReplyDelete={deleteReply}
              />
            ))}
          </div>
        )}
        <div className='grave-picture-gallery_comments-content_hide'>
          <Button
            onClick={props.closeComments}
            className='grave-picture-gallery_comments-content_hide-button'
          >
            Lukk
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Comments;
