import React, { useState } from 'react';
import styles from './UploadImageModal.module.css';
import { Modal, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import X from '../../../../img/X.svg';
import {
  addPhotoForCropping,
  goToChosenGrave,
  saveListOfPhotosFromCropper,
  setListOfTimelinesPhotos,
} from '../../../../redux/actions';
import CropperModal from '../CropperModal/CropperModal';
import SecondModal from '../SecondModal/SecondModal';
import isEmpty from 'lodash.isempty';
import UploadImage from '../UploadImage/UploadImage';
import AllImages from '../AllImages/AllImages';
import {
  makeFileFromURL,
  dataURLtoBlob,
} from '../../../../service/makeFileFromBlob';
import CloseImageModal from '../CloseImageModal/CloseImageModal';
import { request } from '../../../../service/request';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { CONSTANTS } from '../../../../service/constants';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  emptyModalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'auto',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 10,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'auto',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const UploadImageModal = ({
  onOpen,
  onClose,
  category,
  singleImage,
  title,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState();
  const photoForCropping = useSelector(
    (state) => state.photoReducer.photoForCropping
  );
  const photos = useSelector((state) => state.photoReducer.listOfPhotos);
  const grave = useSelector((state) => state.grave.grave);
  const timeLinesPhotos = useSelector(
    (state) => state.photoReducer.timeLinesPhotos
  );

  const handleOpenCropper = (image) => {
    setFirstModalOpen(true);
    setShowImages(false);
    setCroppedImage(image);
  };

  const handelCloseCropper = () => {
    setFirstModalOpen(false);
    setShowImages(true);
  };

  const handleShowImages = () => {
    setShowImages(true);
    setFirstModalOpen(false);
  };

  const handleOpenSecondModal = (croppedImage) => {
    setFirstModalOpen(false);
    setShowImages(false);
    setCroppedImage(croppedImage);
  };

  const handleCloseModal = () => {
    setOpenWarningModal(false);
    onClose();
    setShowImages(false);
    dispatch(addPhotoForCropping([]));
    setFirstModalOpen(false);
  };

  const handelRemoveImage = (id) => {
    const updateCropImage = photoForCropping.filter((image) => image.id !== id);
    dispatch(addPhotoForCropping(updateCropImage));
  };

  const handelOpenWarning = () => {
    if (photoForCropping.length > 0 && !isUploaded) {
      setOpenWarningModal(true);
    } else {
      handleCloseModal();
    }
  };

  const choseCoverPhotoFromList = (photo) => {
    dispatch(addPhotoForCropping(photo));
  };

  const savePhotos = async () => {
    if (!isEmpty(photoForCropping)) {
      const updatedImages = [];
      const updatedTimelinesImages = [];
      for (const photo of photoForCropping) {
        const { privacy, address, comment, ages } = photo;
        if (privacy !== 'default') {
          setIsUploading(true);
          setIsUploaded(false);
          const croppedImage = await makeFileFromURL(
            photo.file_url,
            photo.file.name
          );
          const arrayOfLeftPositions = [],
            arrayOfTopPositions = [],
            arrayOfAges = [];
          if (ages) {
            ages.forEach((age) => {
              arrayOfLeftPositions.push(age.position.left.toString());
              arrayOfTopPositions.push(age.position.top.toString());
              arrayOfAges.push(age.age.toString());
            });
          }
          let textObject;
          if (address && comment)
            textObject = { address: address, comment: comment };
          else if (address && !comment) textObject = { address: address };
          else if (!address && comment) textObject = { comment: comment };
          const formdata = new FormData();
          if (address) {
            formdata.append('grave_image[address]', address);
          }
          if (comment) {
            formdata.append('grave_image[description]', comment);
          }
          formdata.append('grave_image[file]', croppedImage);
          formdata.append('grave_image[grave_id]', `${grave.id}`);
          if (textObject)
            formdata.append(
              'grave_image[image_comments_attributes][][comment]',
              JSON.stringify(textObject)
            );
          if (arrayOfLeftPositions.length > 0)
            formdata.append(
              'grave_image[image_ages_attributes][][position][left]',
              arrayOfLeftPositions
            );
          if (arrayOfTopPositions.length > 0)
            formdata.append(
              'grave_image[image_ages_attributes][][position][top]',
              arrayOfTopPositions
            );
          if (arrayOfAges.length > 0)
            formdata.append(
              'grave_image[image_ages_attributes][][age]',
              arrayOfAges
            );
          formdata.append('grave_image[category]', category);
          formdata.append('grave_image[privacy_attributes][setting]', privacy);

          const headers = {
            'Content-Type': 'multipart/form-data',
          };

          try {
            const res = await request(
              '/grave_images',
              formdata,
              'post',
              headers
            );
            if (res.data) {
              if (category === 'display') {
                updatedImages.push(res.data);
              } else {
                updatedTimelinesImages.push(res.data);
              }
              if (!isEmpty(photo.category)) {
                const formData = new FormData();
                formData.append('grave[cover_image_id]', res.data.id);
                const response = await request(
                  `/graves/${grave.slug}`,
                  formData,
                  'put'
                );
                dispatch(goToChosenGrave(response.data));
              }
            }
          } catch (err) {
            setIsUploading(false);
            console.log(err);
          }
        } else
          showUserNotification(
            'Husk å velge hvem du ønsker å dele bildet på graven med',
            'warning'
          );
      }
      if (updatedImages.length > 0 && category === 'display') {
        dispatch(addPhotoForCropping(updatedImages));
        dispatch(saveListOfPhotosFromCropper([...updatedImages, ...photos]));
      } else if (updatedTimelinesImages.length > 0 && category === 'timeline') {
        dispatch(addPhotoForCropping(updatedTimelinesImages));
        dispatch(
          setListOfTimelinesPhotos([
            ...updatedTimelinesImages,
            ...timeLinesPhotos,
          ])
        );
      }
      setOpenWarningModal(false);
      setIsUploading(false);
      setIsUploaded(true);
      showUserNotification('Bildene er lastet opp', 'success');
    } else
      showUserNotification(
        'Velg et landskapsbilde og legg det til i oversiktsbilde albumet',
        'warning'
      );
  };

  const handelShowUploadImage = () => {
    if (!isUploaded && isUploading) {
      return false;
    } else if (isUploaded && !isUploading) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={onOpen}
        // onClose={handleCloseModal}
        className={classes.modal}
        BackdropProps={{
          className: classes.modalBackground,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div
          className={
            isEmpty(photoForCropping)
              ? classes.emptyModalContent
              : classes.modalContent
          }
        >
          <div>
            <div className={styles['upload-image-header']}>
              <span>{title}</span>
              <img loading='lazy' src={X} alt='x' onClick={handelOpenWarning} />
            </div>
            <div className={styles['upload-image-subheader']}>
              <span>Legg til bilder på graven</span>
            </div>
          </div>
          {!isEmpty(photoForCropping) && showImages && (
            <div className={styles['uploading-images']}>
              {photoForCropping.map((photo, index) => (
                <AllImages
                  key={index}
                  photo={photo}
                  handleOpenCropper={handleOpenCropper}
                  handleOpenSecond={handleOpenSecondModal}
                  handelRemoveImage={handelRemoveImage}
                  isSingleImage={photoForCropping.length === 1}
                  isUploaded={isUploaded}
                  isUploading={isUploading}
                  category={category}
                />
              ))}
            </div>
          )}
          {firstModalOpen && !showImages && (
            <CropperModal
              handelCloseCropper={handelCloseCropper}
              photo={croppedImage}
            />
          )}
          {!firstModalOpen && !showImages && !isEmpty(croppedImage) && (
            <SecondModal
              croppedImage={croppedImage}
              openFirst={handleShowImages}
            />
          )}
          {handelShowUploadImage() && (
            <UploadImage
              handleShowImages={handleShowImages}
              singleImage={singleImage}
            />
          )}
          {showImages && (
            <React.Fragment>
              {!isUploaded ? (
                <React.Fragment>
                  <div className={styles['save-btn-container']}>
                    <button
                      className={styles['save-btn']}
                      onClick={savePhotos}
                      disabled={isUploading}
                    >
                      Lagre
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <div className={styles['save-btn-container']}>
                  <button
                    className={styles['save-btn']}
                    onClick={() => {
                      handleCloseModal();
                      history.push(`/deceased/${grave.slug}/media`);
                    }}
                  >
                    Ferdig
                  </button>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </Modal>
      {openWarningModal && isUploading ? (
        <CloseImageModal
          body={`Modalen kan ikke lukkes akkurat nå da bildene er i ferd med å lastes opp`}
          title='Fjern valgte bilder'
          isUploading={isUploading}
          onOpen={openWarningModal}
          onClose={() => setOpenWarningModal(false)}
          onConfirm={handleCloseModal}
        />
      ) : (
        <CloseImageModal
          body='Er du sikker på å fjerne alle bildene du velger eller redigerer'
          title='Fjern valgte bilder'
          isUploading={isUploading}
          onOpen={openWarningModal}
          onClose={() => setOpenWarningModal(false)}
          onConfirm={handleCloseModal}
        />
      )}
    </React.Fragment>
  );
};

export default UploadImageModal;
