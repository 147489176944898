import React from 'react';
import PhoneInput from 'react-phone-number-input';
import styles from './PhoneNumber.module.css';

const PhoneNumber = ({ disable, onNumberChange, editRelation }) => {
  const handlePhoneNumberChange = (value) => {
    if (value) {
      const phoneNumber = value.replace(/^[\d+ ]+/g, '');
      onNumberChange('phone_number', value);
    } else {
      onNumberChange('phone_number', value);
    }
  };

  return (
    <div>
      <PhoneInput
        disabled={disable}
        placeholder='Enter phone number'
        defaultCountry='NO'
        value={editRelation.phone_number}
        onChange={handlePhoneNumberChange}
        className={styles['custom-phone-input']}
      />
    </div>
  );
};

export default PhoneNumber;
