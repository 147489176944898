import React, { useEffect, useState } from 'react';
import styles from './PhoneNumber.module.css';
import { makeStyles, Button, Popover } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import CountriesPopover from '../../../../Auth/atoms/CountriesPopover';
import CountriesArrowClosed from '../../../../../img/CountriesPopoverClosed.svg';
import CountriesArrowOpen from '../../../../../img/CountriesPopoverOpen.svg';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: 'none',
    minWidth: '350px',
    maxWidth: '350px',
    width: '100%',
    marginTop: '6px',
    borderRadius: '10px',
    maxHeight: '271px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
  },
}));

const PhoneNumber = ({ disable, onNumberChange, relation, countriesList }) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [chosenCountry, setChosenCountry] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setPhoneNumber(relation.phone_number);
    if (relation.country_id) {
      const country = countriesList.find(
        (country) => country.id === relation.country_id
      );
      if (country) {
        setChosenCountry(country);
      }
    } else {
      const country = countriesList.find(
        (country) => country.dial_code === '+47'
      );
      if (country) {
        setChosenCountry(country);
      }
    }
  }, [relation]);

  const handleChoseCountryFromList = (country) => {
    onNumberChange('country_id', country.id);
    setChosenCountry(country);
    setAnchorEl(null);
  };

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePhoneNumberChange = (event) => {
    const str = event.target.value.replace(/[^\d]/g, '');
    if (str.length > 0) {
      setPhoneNumber(event.target.value.replace(/[^\d]/g, ''));
      onNumberChange('phone_number', str);
    } else {
      setPhoneNumber('');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.paper}>
          <CountriesPopover
            handleChoseCountryFromList={handleChoseCountryFromList}
            country={chosenCountry.id}
          />
        </div>
      </Popover>
      <div className={styles['auth-welcome_input']}>
        <div className={styles['create-membership_first-input_block-inputs']}>
          <Button
            disabled={disable}
            onClick={handleOpenPopover}
            className={
              styles['create-membership_first-input_block-inputs_button']
            }
          >
            {chosenCountry.dial_code}
            <img
              loading='lazy'
              src={anchorEl ? CountriesArrowOpen : CountriesArrowClosed}
              className={styles['countries-arrow_popover']}
              alt=''
            />
          </Button>
          <input
            disabled={disable}
            className={styles['mobile']}
            type='tel'
            placeholder='00 00 00 00'
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <input
            disabled={disable}
            className={styles['desktop']}
            type='tel'
            placeholder='00 00 00 00'
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
