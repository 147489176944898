import React, { useState, useRef, useEffect } from 'react';
import X from '../../../../../img/X.svg';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './AddEventModal.module.css';
import LifeEventForm from '../LifeEventForm/LifeEventForm';
import UploadImages from '../uploadImages/UploadImages';
import { useSelector, useDispatch } from 'react-redux';
import SelectedImages from '../SelectedImages/SelectedImages';
import { addLifeEventPhotos } from '../../../../../redux/actions';
import CropperModal from '../CropperModal/CropperModal';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));
const EventModal = ({
  title,
  onOpen,
  onClose,
  grave,
  event,
  handelSaveModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isChanged, setIsChanged] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const lifeEventPhotos = useSelector((state) => state.grave.lifeEventPhotos);
  const [backgroundImage, setBackGroundImage] = useState();
  const currentPhotoIndex = useSelector(
    (state) => state.photoReducer.currentEventPhotoIndex
  );
  const currentEditPhoto = useSelector(
    (state) => state.photoReducer.editEventPhotoIndex
  );
  const [openCropper, setOpenCropper] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  useEffect(() => {
    if (lifeEventPhotos.length > 0) {
      setIsChanged(true);
      setBackGroundImage(lifeEventPhotos.length[currentPhotoIndex]);
    }
  }, [lifeEventPhotos]);

  const handelDiscardModal = (value) => {
    setOpenDiscardModal(value);
    // dispatch(addLifeEventPhotos([]));
    // closeModal();
  };

  const handleShowImages = () => {
    setShowImage(true);
  };

  const handelOpenCropper = () => {
    setOpenCropper(true);
  };

  return (
    <Modal
      open={onOpen}
      // onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['event-content_header']}>
            <span>{title}</span>
            <img
              loading='lazy'
              src={X}
              alt='x'
              onClick={() => {
                if (isChanged && isUploading) {
                  setOpenWarningModal(true);
                } else if (isChanged) {
                  handelDiscardModal(true);
                } else {
                  onClose();
                }
              }}
            />
          </div>
          <div className={styles['event-content_subheader']}>
            <span>Ivareta livslinjen på graven.</span>
          </div>
        </div>
        <LifeEventForm
          closeModal={onClose}
          grave={grave}
          isChanged={isChanged}
          handelIsChanged={(value) => setIsChanged(value)}
          openDiscardModal={openDiscardModal}
          handelDiscardModal={handelDiscardModal}
          event={event}
          handelSaveModal={handelSaveModal}
          handelUploading={(value) => setIsUploading(value)}
          isUploading={isUploading}
          openWarningModal={openWarningModal}
          setOpenWarningModal={() => setOpenWarningModal(false)}
        />
      </div>
    </Modal>
  );
};

export default EventModal;
