import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONSTANTS } from '../../service/constants';
import SubscriptionPlanChosen from './atoms/SubscriptionPlanChosen';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentSubscriptionPlan,
  setProductInfo,
  setSubscriptionCount,
  setSubscriptionTotalPrice,
  setCurrentSubscriptionData,
} from '../../redux/actions';
import './PlansPageRouting.css';

const PlansPageRouting = (props) => {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.products) || [];
  const [currentSubscription, setCurrentSubscription] = useState({});
  const currentSubscriptionPlan = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionPlan || null
  );
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    dispatch(setSubscriptionCount(1));
    dispatch(setSubscriptionTotalPrice(0));
  }, []);

  useEffect(() => {
    if (plans.length === 0) {
      axios
        .get(`${CONSTANTS.baseUrl}/api/products`)
        .then((res) => {
          const products = res.data.filter((product) => product.key !== 'free');
          dispatch(setProductInfo(products));
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch, plans]);

  useEffect(() => {
    if (!isAuth) return;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          dispatch(setCurrentSubscriptionPlan(res.data.product));
          dispatch(setCurrentSubscriptionData(res.data));
          setCurrentSubscription(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const updateSubscription = (value, price) => {
    setCurrentSubscription({
      ...currentSubscription,
      price: price,
      user_count: value.user_count,
    });
    dispatch(setCurrentSubscriptionData(currentSubscription));
  };

  return (
    <div>
      <div className='plans-page_routing'>
        <SubscriptionPlanChosen
          plans={plans}
          currentSubscriptionPlan={currentSubscriptionPlan}
          currentSubscription={currentSubscription}
          updateSubscription={updateSubscription}
        />
      </div>
    </div>
  );
};

export default PlansPageRouting;
