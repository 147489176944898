import React, { useState } from 'react';
import styles from './AccountInformation.module.css';
import CloseIcon from '@material-ui/icons/Close';

const AccountInformation = (props) => {
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const { deleteAccount, pauseAccount } = props;
  const [pauseActive, setPauseActive] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [deleteModelText, setDeleteModelText] = useState('slette kontoen din');
  const [deleteModelHeader, setDeleteModelHeader] = useState('Slett konto');
  const [buttonText, setButtonText] = useState('Slett');
  const [pauseUntil, setPauseUntil] = useState('');
  const [dateActive, setDateActive] = useState('');

  const pasuedDate = (date) => {
    const current = new Date();
    const pasuedDate = current.setDate(current.getDate() + date);
    const formatedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(pasuedDate);
    setPauseUntil(formatedDate);
  };

  const onClickHandler = (value) => {
    setDateActive(value);
  };

  return (
    <>
      {openDeleteModel && (
        <div className={styles['account-information-delete-model']}>
          <div className={styles['account-information-delete-model_wrapper']}>
            <CloseIcon
              className={styles['account-information-delete-model_close_icon']}
              onClick={() => {
                setOpenDeleteModel(false);
                onClickHandler('');
              }}
            />
            <div className={styles['account-information-delete-model-content']}>
              <span
                className={
                  styles['account-information-delete-model-content_header']
                }
              >
                {deleteModelHeader}
              </span>
              <span
                className={
                  styles['account-information-delete-model-content_description']
                }
              >
                {`Bekreft at du ønsker å ${deleteModelText}?`}
              </span>
            </div>
            {pauseActive && (
              <div className={styles['account-information-pause-opions']}>
                <button
                  className={
                    dateActive === '1uke'
                      ? styles['account-information-pause-opions_active']
                      : styles['account-information-pause-opions_button']
                  }
                  onClick={() => {
                    pasuedDate(7);
                    onClickHandler('1uke');
                  }}
                >
                  1 Uke
                </button>
                <button
                  className={
                    dateActive === '1Måned'
                      ? styles['account-information-pause-opions_active']
                      : styles['account-information-pause-opions_button']
                  }
                  onClick={() => {
                    pasuedDate(30);
                    onClickHandler('1Måned');
                  }}
                >
                  1 Måned
                </button>
                <button
                  className={
                    dateActive === '6Måned'
                      ? styles['account-information-pause-opions_active']
                      : styles['account-information-pause-opions_button']
                  }
                  onClick={() => {
                    pasuedDate(182);
                    onClickHandler('6Måned');
                  }}
                >
                  6 Måned
                </button>
              </div>
            )}
            <div
              className={
                styles['account-information-delete-model-buttons_center']
              }
            >
              <button
                className={
                  styles[
                    'account-information-delete-model-buttons_center_cancel'
                  ]
                }
                onClick={() => setOpenDeleteModel(false)}
              >
                Avbryt
              </button>
              <button
                disabled={dateActive ? false : deleteActive ? false : true}
                className={
                  styles[
                    'account-information-delete-model-buttons_center_delete'
                  ]
                }
                onClick={() => {
                  if (deleteModelHeader === 'Slett konto') {
                    deleteAccount();
                  } else {
                    pauseAccount();
                  }
                  setOpenDeleteModel(false);
                  onClickHandler('');
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={styles['account-information']}>
        <div className={styles['account-information-content']}>
          <div className={styles['account-information-content-header']}>
            <span
              className={styles['account-information-content-header_header']}
            >
              Din Nettgrav konto
            </span>
            {/* <div className={styles['account-information_orange-line']} /> */}
          </div>
          <div
            className={
              pauseActive
                ? styles['account-information-content-pause-account_active']
                : styles['account-information-content-pause-account']
            }
            onClick={() => {
              setDeleteActive(false);
              setPauseActive(true);
              setDeleteModelHeader('Sett på pause');
              setDeleteModelText('pause kontoen din');
              setButtonText('Pause');
            }}
          >
            <span
              className={
                pauseActive
                  ? styles[
                      'account-information-content-pause-account_header_active'
                    ]
                  : styles['account-information-content-pause-account_header']
              }
            >
              Sett på pause
            </span>
            <span
              className={
                pauseActive
                  ? styles[
                      'account-information-content-pause-account_description_active'
                    ]
                  : styles[
                      'account-information-content-pause-account_description'
                    ]
              }
            >
              Midlertidig deaktiver kontoen og alle varsler i 1 uke til 6
              måneder. Hvis du har et aktivt abonnement, vil dette også bli satt
              på pause for samme periode. Du kan gjenoppta kontoen og
              abonnementet når som helst i løpet av pauseperioden. Din
              personlige informasjon og data vil forbli lagret sikkert på våre
              servere.
            </span>
          </div>
          <div
            className={
              deleteActive
                ? styles['account-information-content-delete-account_active']
                : styles['account-information-content-delete-account']
            }
            onClick={() => {
              setPauseActive(false);
              setDeleteActive(true);
              setDeleteModelHeader('Slett konto');
              setDeleteModelText('slette kontoen din');
              setButtonText('Slett');
            }}
          >
            <span
              className={
                deleteActive
                  ? styles[
                      'account-information-content-delete-account_header_active'
                    ]
                  : styles['account-information-content-delete-account_header']
              }
            >
              Slett konto
            </span>
            <span
              className={
                deleteActive
                  ? styles[
                      'account-information-content-delete-account_description_active'
                    ]
                  : styles[
                      'account-information-content-delete-account_description'
                    ]
              }
            >
              Slett kontoen din og all tilhørende data permanent fra
              nettgrav.no. Denne handlingen kan ikke angres, så vær sikker på at
              du ønsker å fortsette. Hvis du har spørsmål eller bekymringer,
              vennligst kontakt oss.
            </span>
          </div>
          <div className={styles['account-information-content-buttons']}>
            <div
              className={styles['account-information-content-buttons_center']}
            >
              <button
                className={
                  styles['account-information-content-buttons_center_cancel']
                }
                onClick={() => {
                  setDeleteActive(false);
                  setPauseActive(false);
                }}
              >
                Avbryt
              </button>
              <button
                disabled={deleteActive || pauseActive ? false : true}
                className={
                  styles['account-information-content-buttons_center_delete']
                }
                onClick={() => setOpenDeleteModel(true)}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInformation;
