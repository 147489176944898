import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styles from './LifeEventForm.module.css';
import DatePickerValue from '../../WorkPlaces/DatePicker/DateYears';
import GraveDatePicker from './DatePicker/DatePicker';
import { fetch_request } from '../../../../../service/request';
import isEmpty from 'lodash.isempty';
import DiscardModal from '../../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../../SavedPopupModal/SavedPopupModal';
import { request } from '../../../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  setLifeEvents,
  setEventPhotoIndex,
  addLifeEventVideos,
  addLifeEventPhotos,
} from '../../../../../redux/actions';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import AddEventImages from '../AddEventImages/AddEventImages';
import CircularProgress from '@mui/material/CircularProgress';
import CloseImageModal from '../../CloseImageModal/CloseImageModal';

const LifeEventForm = ({
  grave,
  isChanged,
  handelIsChanged,
  openDiscardModal,
  handelDiscardModal,
  closeModal,
  event,
  handelSaveModal,
  handelUploading,
  isUploading,
  openWarningModal,
  setOpenWarningModal,
}) => {
  const dispatch = useDispatch();
  const [lifeEvent, setLifeEvent] = useState({
    id: '',
    grave_id: '',
    event_title: '',
    description: '',
    creator_id: '',
    event_year: '',
    event_date: '',
    theme_color: '#fff',
    location: '',
    is_hidden: '',
  });
  const [countriesList, setCountriesList] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const user = useSelector((state) => state.user.user);
  const events = useSelector((state) => state.grave.lifeEvents);
  const eventsPhotos = useSelector((state) => state.grave.lifeEventPhotos);
  const eventsVideos = useSelector((state) => state.grave.lifeEventVideos);
  const [isFutureDate, setIsFutureDate] = useState(false);

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCountriesList(data.countries);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!isEmpty(event)) setLifeEvent(event);
  }, [event]);

  useEffect(() => {
    const { description, event_year, event_date, event_title, location } =
      lifeEvent;

    const areRequiredFieldsFilled =
      description !== '' &&
      event_year !== '' &&
      event_date !== '' &&
      event_title !== '' &&
      location !== '';
    if (areRequiredFieldsFilled && !isFutureDate) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [lifeEvent, isFutureDate]);

  const handelEditForm = (field, value) => {
    setLifeEvent((prev) => ({
      ...prev,
      [field]: value,
    }));
    handelIsChanged(true);
  };

  const handleDate = (date) => {
    if (!isEmpty(date)) {
      const parsedDate = dayjs(date);
      if (parsedDate.isValid()) {
        const newDate = parsedDate.format('YYYY-MM-DD');
        const newYear = parsedDate.format('YYYY');
        handelEditForm('event_date', newDate);
        handelEditForm('event_year', newYear);
      }
    } else {
      handelEditForm('event_date', '');
      handelEditForm('event_year', '');
    }
  };

  const resetState = () => {
    setLifeEvent({
      id: '',
      grave_id: '',
      event_title: '',
      description: '',
      creator_id: '',
      event_year: '',
      event_date: '',
      theme_color: '#fff',
      location: '',
      is_hidden: '',
    });
  };

  const handelFutureDate = (value) => {
    setIsFutureDate(value);
  };

  const saveEvent = async () => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    var formData = new FormData();
    setDisableBtn(true);
    try {
      if (lifeEvent.id) {
        formData.append('life_event[event_title]', lifeEvent.event_title);
        formData.append('life_event[location]', lifeEvent.location);
        formData.append('life_event[event_year]', lifeEvent.event_year);
        formData.append('life_event[event_date]', lifeEvent.event_date);
        formData.append('life_event[description]', lifeEvent.description);
        formData.append('life_event[theme_color]', lifeEvent.theme_color);
        const res = await request(
          `/life_events/${lifeEvent.id}`,
          formData,
          'put'
        );
        const newEvents = [...events];
        const index = events.findIndex((event) => event.id === lifeEvent.id);
        newEvents.splice(index, 1, res.data);
        dispatch(setLifeEvents([...newEvents]));
        // handelSaveModal(true);
        closeModal();
        showUserNotification('Livshendelsen er oppdatert og lagret', 'success');
      } else {
        handelUploading(true);
        formData.append('life_event[event_title]', lifeEvent.event_title);
        formData.append('life_event[grave_id]', grave.id);
        formData.append('life_event[creator_id]', user.id);
        formData.append('life_event[location]', lifeEvent.location);
        formData.append('life_event[event_year]', lifeEvent.event_year);
        formData.append('life_event[event_date]', lifeEvent.event_date);
        formData.append('life_event[description]', lifeEvent.description);
        formData.append('life_event[theme_color]', lifeEvent.theme_color);
        const res = await request(`/life_events`, formData, 'post');
        await uploadFiles(res.data.id);
        await fetch_request(
          `/api/life_events?grave_id=${grave.id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            dispatch(setLifeEvents([...data]));
            dispatch(setEventPhotoIndex(0));
          })
          .catch((err) => console.log(err));
        closeModal();
        handelUploading(false);
        dispatch(addLifeEventVideos([]));
        dispatch(addLifeEventPhotos([]));
        handelSaveModal(true);
        resetState();
        showUserNotification(
          'Livshendelsen er sendt og lagret på graven',
          'success'
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFiles = async (eventId) => {
    try {
      await uploadImages(eventId);
      await uploadVideos(eventId);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImages = async (eventId) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      if (eventsPhotos && eventsPhotos.length > 0) {
        for (const photo of eventsPhotos) {
          const formData = new FormData();
          formData.append('life_event_image[category]', photo.category);
          formData.append('life_event_image[life_event_id]', eventId);
          formData.append(
            'life_event_image[file]',
            photo.file,
            `${photo.file.name}`
          );
          const res = await request(
            `/life_event_images`,
            formData,
            'post',
            headers
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadVideos = async (eventId) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      if (eventsVideos && eventsVideos.length > 0) {
        for (const video of eventsVideos) {
          const formData = new FormData();
          formData.append('life_event_video[life_event_id]', eventId);
          formData.append(
            'life_event_video[file]',
            video.file,
            `${video.file.name}`
          );
          const res = await request(
            `/life_event_videos`,
            formData,
            'post',
            headers
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles['event-form-section']}>
      {isEmpty(event) && <AddEventImages event={event} />}
      <div className={styles['input-element-list']}>
        <div className={styles['title']}>Tittel på hendelsen</div>
        <input
          className={styles['inputs']}
          type='text'
          required={true}
          placeholder='tittel'
          value={lifeEvent?.event_title ? lifeEvent?.event_title : ''}
          onChange={(e) => {
            const inputValue = e.target.value;
            const trimmedValue = inputValue
              .replace(/^\s+/, '')
              .replace(/ {2,}$/, ' ');
            handelEditForm('event_title', trimmedValue);
          }}
        />
      </div>
      <div className={styles['event-container']}>
        <div className={styles['input-element']}>
          <div className={styles['title']}>Sett dato</div>
          <GraveDatePicker
            isFutureDate={isFutureDate}
            handelFutureDate={handelFutureDate}
            date={lifeEvent?.event_date ? lifeEvent.event_date.toString() : ''}
            setDatePickerValue={handleDate}
          />
        </div>
        <div className={styles['input-element']}>
          <div className={styles['title']}>Hvor</div>
          <select
            native
            value={lifeEvent?.location ? lifeEvent.location : ''}
            onChange={(e) => handelEditForm('location', e.target.value)}
            className={styles['selector']}
          >
            <option value='' disabled>
              Land
            </option>
            {countriesList.map((country) => (
              <option value={country.id} key={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['input-element']}>
          <div className={styles['title']}>Design</div>
          <select
            native
            value={lifeEvent?.theme_color ? lifeEvent.theme_color : ''}
            onChange={(e) => handelEditForm('theme_color', e.target.value)}
            className={styles['selector']}
          >
            <option value='' disabled>
              Tema
            </option>
            <option value='#fff'>Lyst tema</option>
            <option value='#19262D'>Mørkt tema</option>
          </select>
        </div>
      </div>
      <div className={styles['event-content-textarea']}>
        <div className={styles['input-element']}>
          <div className={styles['title']}>Beskrivelse</div>
          <textarea
            value={lifeEvent?.description ? lifeEvent.description : ''}
            onChange={(e) => handelEditForm('description', e.target.value)}
            placeholder='Skriv inn om hendelsen'
          />
        </div>
      </div>
      {!isUploading && (
        <div className={styles['grave-btn-container']}>
          <div className={styles['grave-information_content-verify']}>
            <button
              className={styles['rootDiscard']}
              onClick={() => {
                if (isChanged) {
                  handelDiscardModal(true);
                } else {
                  closeModal();
                }
              }}
            >
              Avbryt
            </button>
            <button
              className={
                disableBtn ? styles['disabledButton'] : styles['rootVerify']
              }
              disabled={disableBtn}
              onClick={() => saveEvent()}
            >
              Lagre
            </button>
          </div>
        </div>
      )}
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={handelDiscardModal}
          onConfirm={closeModal}
          title='Livshendelse'
        />
      )}
      {isUploading && (
        <div className={styles['loading-container']}>
          <div className={styles['loader']}>
            <CircularProgress style={{ color: '#fea65a' }} />
            <span>Sender...</span>
          </div>
        </div>
      )}
      {openWarningModal && isUploading && (
        <CloseImageModal
          body={`Modalen kan ikke lukkes akkurat nå da arrangementet skaper`}
          title='Avbryt oppretting av arrangement'
          isUploading={isUploading}
          onOpen={openWarningModal}
          onClose={() => setOpenWarningModal()}
          onConfirm={closeModal}
        />
      )}
    </div>
  );
};

export default LifeEventForm;
