import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import Popover from '@mui/material/Popover';
import styles from './Permissions.module.css';

const Permissions = () => {
  const [checked, setChecked] = useState('anyone');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleToggle = (value) => () => {
    setChecked(value);
  };

  return (
    <div className={styles['permission-container']}>
      <button onClick={(event) => handleClick(event)}>
        Angi tillatelser
        {open ? <ExpandLess /> : <ExpandMore />}
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List component='div' disablePadding>
          <ListItem>
            <ListItemText id='switch-list-label-wifi' primary='Alle kan se' />
            <Switch
              edge='end'
              onChange={handleToggle('anyone')}
              checked={checked === 'anyone'}
              inputProps={{
                'aria-labelledby': 'switch-list-label-anyone',
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              id='switch-list-label-wifi'
              primary='Alle kan redigere'
            />
            <Switch
              edge='end'
              onChange={handleToggle('family')}
              checked={checked === 'family'}
              inputProps={{
                'aria-labelledby': 'switch-list-label-family',
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              id='switch-list-label-relative'
              primary='Bare familie og slektninger kan redigere'
            />
            <Switch
              edge='end'
              onChange={handleToggle('relative')}
              checked={checked === 'relative'}
              inputProps={{
                'aria-labelledby': 'switch-list-label-relative',
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default Permissions;
