import React, { useState, useEffect } from 'react';
import styles from './Vipps.module.css';
import LoadingAnimation from '../../../../service/Loading_animation.gif';
import isEmpty from 'lodash.isempty';
import PlansDescription from '../PlansDescription/PlansDescription';
import {
  setPaymentMethods,
  updateUserSubscription,
  setSelectedPlan,
  setVippsAgreementData,
  setReturnPage,
  setPaymentMethod,
  setShowCardMethod,
  setSelectedCardMethod,
  setBillingInformation,
  setSelectedVippsMethod,
  setShowVippsPaymentMethod,
  setVippsPaymentMethods,
  setVippsPhoneNumber,
} from '../../../../redux/actions';
import { request } from '../../../../service/request';
import { useSelector, useDispatch } from 'react-redux';
import { CONSTANTS } from '../../../../service/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import VippsPhoneNumber from './VippsPhoneNumber';
import AddNewCard from '../AddNewCard/AddNewCard';
import SelectedPlan from '../SelectedPlan/SelectedPlan';
import PlanPaymentDetails from '../PlanPaymentDetails/PlanPaymentDetails';
import FeatureList from '../FeatureList/FeatureList';
import ProcessModal from '../ProcessModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import AgreementModal from './AgreementModal/AgreementModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import PrimaryButton from '../../../../components/PrimaryButton';
const DEFAULT_PAYMENT_METHOD =
  process.env.REACT_APP_ENV === 'production' ? 'card' : 'vipps';

const Vipps = ({
  plans,
  selectedPlan,
  currentSubscription,
  selectedPaymentMethod,
  isFreePlan,
  defaultPlan,
  currentSubscriptionPlan,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disable, setDisable] = useState(true);
  const vippsPhoneNumber = useSelector(
    (state) => state.paymentMethods.vippsPhoneNumber
  );
  const [showLoadingSection, setShowLoadingSection] = useState(false);
  const [agreementDetails, setAgreementDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [chargeId, setChargeId] = useState('');
  const [iframeSource, setIframeSource] = useState(null);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);

  const vippsAgreement = useSelector((state) => state.vipps.vippsAgreement);
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const selectedVippsMethod = useSelector(
    (state) => state.paymentMethods.selectedVippsMethod
  );
  const vippsPaymentMethods = useSelector(
    (state) => state.paymentMethods.vippsPaymentMethods
  );
  const returnPage = useSelector((state) => state.returnPage);
  const showCard = useSelector(
    (state) => state.paymentMethods.showVippsPaymentMethod
  );

  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleShowPayment = (method) => {
    if (!isEmpty(method)) {
      dispatch(setSelectedVippsMethod(method));
      dispatch(setVippsPhoneNumber(method.phone_number));
    } else {
      dispatch(setSelectedVippsMethod(method));
      dispatch(setVippsPhoneNumber(''));
    }
  };
  const handleShowCard = (value) => dispatch(setShowVippsPaymentMethod(value));
  const handleProductChosen = (plan) => {
    if (plan.key === 'free') {
      history.push(`/plans/payment`);
    } else {
      history.push(
        `/plans/payment?payment_method_type=${selectedPaymentMethod}`
      );
    }
    dispatch(setSelectedPlan(plan));
  };

  useEffect(() => {
    const isFreePlan = selectedPlan?.key === 'free';

    if (
      !isEmpty(selectedVippsMethod) &&
      (!isEmpty(currentSubscriptionPlan) || isFreePlan)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [selectedPlan, currentSubscriptionPlan, selectedVippsMethod]);

  useEffect(() => {
    if (isEmpty(selectedPlan) && isEmpty(currentSubscriptionPlan)) {
      setDisable(false);
      return;
    }

    if (!isEmpty(selectedPlan)) {
      if (selectedPlan.key === 'free' || !isEmpty(selectedVippsMethod)) {
        setDisable(false);
      } else {
        setDisable(true);
      }
      return;
    }

    if (!isEmpty(currentSubscriptionPlan)) {
      const isFreePlan = currentSubscriptionPlan.key === 'free';
      setDisable(currentSubscriptionPlan.id ? true : !isFreePlan);
    }
  }, [selectedPlan, currentSubscriptionPlan, selectedVippsMethod]);

  useEffect(() => {
    if (showLoadingSection) {
      const getVippsAgreement = () => {
        if (!vippsAgreement.agreementId) return;
        try {
          request(`/vipps/agreements/${vippsAgreement.agreementId}`).then(
            (response) => {
              if (response.data.agreement_details.status === 'ACTIVE') {
                setOpenAgreementModal(false);
                setIframeSource(null);
                clearInterval(intervalId);
                handleShowCard(true);
                handleShowPayment({});
                setAgreementDetails(response.data.agreement_details);
              }
              if (response.data.agreement_details.status === 'STOPPED') {
                setOpenAgreementModal(false);
                setIframeSource(null);
                clearInterval(intervalId);
                setShowLoadingSection(false);
                setErrorMessage('Du kansellerte betalingen');
                setOpenErrorModal(true);
              }
            }
          );
        } catch (err) {
          console.log(err);
        }
      };
      const intervalId = setInterval(getVippsAgreement, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showLoadingSection]);

  const handleVippsAgreement = (response) => {
    if (response.vippsConfirmationUrl) {
      setIframeSource(response.vippsConfirmationUrl);
      setOpenAgreementModal(true);
      handleCloseModal();
      setShowLoadingSection(true);
    } else {
      handleCloseModal();
      setErrorMessage(response.error);
      setOpenErrorModal(true);
    }
  };

  const handelCreateAgreement = () => {
    let requestPayload = {};
    let subscriptionParams = {
      price: amount,
      user_count: parseInt(subscriptionCount),
      product_id: selectedPlan.id,
    };

    if (!isEmpty(selectedVippsMethod)) {
      subscriptionParams['payment_method_id'] = selectedVippsMethod.id;
    }

    if (vippsPhoneNumber.trim !== '' && !isEmpty(selectedPlan)) {
      handleShowModal();
      requestPayload = {
        agreement: {
          phone_number: vippsPhoneNumber,
          product_name: selectedPlan.name,
          amount: amount,
          currency: 'NOK',
        },
        subscription: {
          ...subscriptionParams,
        },
      };

      return fetch(`${CONSTANTS.baseUrl}/api/vipps/agreements.json`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      })
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((res) => {
          if (res.agreement && res.agreement.status === 'ACTIVE') {
            handleCloseModal();
            setAgreementDetails(res.agreement);
            showUserNotification(
              'Avtalen mot dette telefonnummeret er allerede aktiv',
              'warning'
            );
          } else if (res.status === 400) {
            setErrorMessage('Vennligst oppgi et gyldig telefonnummer');
            handleCloseModal();
            setOpenErrorModal(true);
          } else {
            dispatch(setVippsAgreementData(res));
            handleVippsAgreement(res);
          }
        })
        .catch((error) => {
          setErrorMessage(error.data);
          handleCloseModal();
          setOpenErrorModal(true);
        });
    } else {
      showUserNotification('Velg en plan før du abonnerer', 'error');
    }
  };

  const handleSubscribe = () => {
    if (isEmpty(selectedVippsMethod)) {
      return;
    }

    if (isEmpty(selectedPlan)) {
      showUserNotification('Velg en plan før du abonnerer', 'error');
      return;
    }
    handleShowModal();
    handelCreateAgreement();
  };

  const handleServerResponse = (response) => {
    if (response.error) {
      handleCloseModal();
      setOpenErrorModal(true);
    } else {
      let url = returnPage.url || '/settings/subscriptions';
      if (subscriptionCount > 1) {
        url = returnPage.url || '/settings/subscription-invitations';
      }
      if (url) {
        dispatch(setShowCardMethod(false));
        dispatch(setShowVippsPaymentMethod(false));
        dispatch(setBillingInformation({}));
        dispatch(setPaymentMethod({}));
        dispatch(setSelectedVippsMethod({}));
        dispatch(setVippsPaymentMethods([]));
        dispatch(setPaymentMethods([]));
        dispatch(setSelectedCardMethod(DEFAULT_PAYMENT_METHOD));
        dispatch(setReturnPage(null));
        dispatch(setVippsPhoneNumber(''));
        setShowLoadingSection(false);
        setAgreementDetails({});
      }
      history.push(url, { show: 0 });
    }
  };

  return (
    <React.Fragment>
      <div className={styles['payment-page-container']}>
        <div className={styles['payment-container']}>
          {vippsPaymentMethods.length > 0 &&
            !isFreePlan &&
            vippsPaymentMethods.map((method) => (
              <PaymentMethods
                method={method}
                showPayment={
                  showCard ? false : method.id === selectedVippsMethod.id
                }
                handleShowPayment={handleShowPayment}
                handleShowCard={handleShowCard}
                key={method.id}
              />
            ))}
          {!showCard && !isFreePlan && vippsPaymentMethods.length > 0 && (
            <AddNewCard
              handleShowCard={handleShowCard}
              handleShowPayment={handleShowPayment}
              showCard={showCard}
            />
          )}
          {showCard && !isFreePlan && (
            <div className={styles['payment-details']}>
              {!showLoadingSection && isEmpty(agreementDetails) && (
                <React.Fragment>
                  <PlanPaymentDetails
                    handleProductChosen={handleProductChosen}
                    chosenPlan={defaultPlan}
                    plans={plans}
                  />
                  <VippsPhoneNumber />
                  <div className={styles['btn-container']}>
                    <button
                      className={styles['btn']}
                      disabled={vippsPhoneNumber.trim().length !== 8}
                      onClick={() => handelCreateAgreement()}
                    >
                      Neste
                    </button>
                  </div>
                  <PlansDescription isFreePlan={isFreePlan} amount={amount} />
                  <FeatureList chosenPlan={defaultPlan} />
                </React.Fragment>
              )}
              {showLoadingSection && !isEmpty(agreementDetails) && (
                <div className={styles['waiting-container']}>
                  <div className={styles['vipps-logo']}></div>
                  <div className={styles['waiting-message']}>
                    Du har godtatt avtalen og betaling pågår og vil bli
                    behandlet etter 2 dager, ditt abonnement er bekreftet
                  </div>
                  <PrimaryButton
                    children='Tilbake til abonnementssiden'
                    onClick={handleServerResponse}
                  />
                </div>
              )}
              {!showLoadingSection && !isEmpty(agreementDetails) && (
                <div className={styles['conform-message']}>
                  Avtalen mot dette telefonnummeret er allerede aktiv
                </div>
              )}
            </div>
          )}
          {!isEmpty(selectedVippsMethod) && !showCard && (
            <div className={styles['payment-details']}>
              <React.Fragment>
                <SelectedPlan
                  plans={plans}
                  chosenPlan={defaultPlan}
                  handleProductChosen={handleProductChosen}
                  disable={disable}
                  handleSubscribe={handleSubscribe}
                  isFreePlan={isFreePlan}
                />
                <FeatureList chosenPlan={defaultPlan} />
              </React.Fragment>
            </div>
          )}
        </div>
        {openModal && (
          <ProcessModal
            open={openModal}
            message='Betaling pågår. Vennligst vent'
          />
        )}
        {openErrorModal && (
          <ErrorModal
            message={errorMessage}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
          />
        )}
      </div>
      {openAgreementModal && (
        <AgreementModal
          onOpen={openAgreementModal}
          iframeSource={iframeSource}
        />
      )}
    </React.Fragment>
  );
};

export default Vipps;
