import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Relations.module.css';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Popover from '@material-ui/core/Popover';
import { useHistory } from 'react-router-dom';

const Relations = (props) => {
  const relationsArray = props.relationsArray;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (relationsArray.length === 0) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className={styles['relation-container']}>
      <AvatarGroup
        max={3}
        className={styles['relations-avatar-group']}
        onClick={handleClick}
      >
        {relationsArray.length > 0 &&
          relationsArray.map((item, index) => {
            return <Avatar alt='' src={item.user_avatar} key={index} />;
          })}
      </AvatarGroup>
      <span className={styles['relations-title']}>
        Relasjoner ({relationsArray.length}){' '}
      </span>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {relationsArray &&
          relationsArray.map((item, index) => {
            return (
              <div
                className={styles['popover-row-relations']}
                onClick={() => history.push(`/member/${item.slug}`)}
                key={index}
              >
                <div className={styles['avatar-wrapper']}>
                  <Avatar src={item.user_avatar} />
                </div>
                <div className={styles['grave-information-wrapper']}>
                  <span className={styles['grave-information_name']}>
                    {item.user_name}
                  </span>
                  <span className={styles['grave-information_lifespan']}>
                    {item.relation_name}
                  </span>
                </div>
              </div>
            );
          })}
      </Popover>
    </div>
  );
};

export default Relations;
