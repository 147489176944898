import React, { useEffect, useState } from 'react';
import './CardPayment.css';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Lock from '../../../../img/Lock.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { CONSTANTS } from '../../../../service/constants';
import { useDispatch, useSelector } from 'react-redux';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { request } from '../../../../service/request';
import {
  setInformationAboutUser,
  updateUserSubscription,
  setReturnPage,
  setBillingInformation,
  setShowCardMethod,
  setPaymentMethod,
  setPaymentMethods,
  setSelectedCardMethod,
} from '../../../../redux/actions';
import ErrorModal from '../ErrorModal/ErrorModal';
import isEmpty from 'lodash.isempty';

const DEFAULT_PAYMENT_METHOD =
  process.env.REACT_APP_ENV === 'production' ? 'card' : 'vipps';

const cardNumberStyle = {
  iconColor: '#c4f0ff',
  color: '#777777',
  fontWeight: '500',
  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
  fontSize: '16px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#fce883',
  },
  '::placeholder': {
    color: '#777777',
  },
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CardPayment = ({
  chosenPlan,
  submit,
  setSubmit,
  billingInformation,
  handleCloseModal,
  handelCardInputs,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const productDetails = chosenPlan || {};
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const currentSubscription = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionData
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  // const amount = productDetails.price || 0;
  // NO TAX FOR THIS VERSION
  //const tax = amount && ((amount * 25/ 100);
  //const totalAmount = amount + tax;)
  const { slug: userSlug, full_name: userName } = useSelector(
    (state) => state.user.user
  );
  const returnPage = useSelector((state) => state.returnPage);
  const dispatch = useDispatch();
  const [canceledButton, setCanceledButton] = useState(true);
  const [cardInputs, setCardInputs] = useState({
    cardNumber: '',
    Cvc: '',
    expiry: '',
  });
  const [checkoutError, setCheckoutError] = useState();
  const [nameOnCard, setNameOnCard] = useState('');

  const handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const card = elements.getElement(CardNumberElement);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: userName,
        },
      })
      .then((result) => {
        stripePaymentMethodHandler(result);
      });
  };

  const stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      // Show error in payment form
      handleCloseModal();
      setErrorMessage(result.error.message);
      setOpenErrorModal(true);
      showUserNotification('Betaling feilet. Prøv på nytt.', 'error');
    } else {
      try {
        const response = await stripeTokenHandler(result.paymentMethod, null);
        const json = await response.json();
        handleServerResponse(json);
      } catch (error) {
        handleCloseModal();
        // setOpenErrorModal(true);
        showUserNotification('Betaling feilet. Prøv på nytt.', 'error');
      }
    }
  };

  const handleServerResponse = (response) => {
    if (!response.success) {
      // Show error from server on payment form
      showUserNotification(response.message, 'error');
      handleCloseModal();
      setErrorMessage(response.message);
      setOpenErrorModal(true);
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      stripe
        .handleCardAction(response.payment_intent_client_secret)
        .then((result) => {
          handleStripeJsResult(result);
        });
    } else {
      setCardInputs((prev) => {
        return {
          ...prev,
          cardNumber: '',
          Cvc: '',
          expiry: '',
        };
      });
    }
  };

  const handleStripeJsResult = (result) => {
    if (result.error) {
      handleCloseModal();
      setErrorMessage(result.message);
      setOpenErrorModal(true);
      showUserNotification('Betaling feilet. Prøv på nytt.', 'error');
      return;
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      stripeTokenHandler(null, result.paymentIntent);
    }
  };

  const stripeTokenHandler = async (paymentMethod, paymentIntent) => {
    let requestPayload = {};
    if (paymentMethod) {
      requestPayload = {
        payment: {
          payment_method_id: paymentMethod?.id,
          price: amount,
          user_count: subscriptionCount,
          product_id: productDetails.id,
          payment_method: {
            payment_type: paymentMethod.type,
            stripe_id: paymentMethod.id,
            country: paymentMethod.card.country,
            exp_month: paymentMethod.card.exp_month,
            exp_year: paymentMethod.card.exp_year,
            is_active: true,
            card_type: paymentMethod.card.brand,
            card_number: paymentMethod.card.last4,
          },
          billing_information: {
            name: billingInformation.fullName,
            email: billingInformation.email,
            phone_number: billingInformation.phoneNumber,
            address: billingInformation.billingAddress,
            postal_code: billingInformation.postalCode,
            country_id: billingInformation.country_id,
          },
        },
      };
    } else {
      requestPayload = {
        payment: {
          payment_intent_id: paymentIntent?.id,
        },
      };
    }

    return fetch(`${CONSTANTS.baseUrl}/api/stripe/payments.json`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    })
      .then(function (confirmResult) {
        return confirmResult.json();
      })
      .then((json) => {
        if (json.success) {
          handelSubscription(json.payment_method_id, paymentIntent);
        }
        if (!json.success) {
          handleCloseModal();
          setErrorMessage(json.message);
          setOpenErrorModal(true);
        }
      })
      .catch((error) => {
        setOpenErrorModal(true);
        handleCloseModal();
      });
  };

  const handelSubscription = async (paymentMethodId, paymentIntent) => {
    let requestPayload = {};
    if (
      !isEmpty(currentSubscription) &&
      currentSubscription.product.id === productDetails.id
    ) {
      requestPayload = {
        subscription: {
          payment_method_id: paymentMethodId,
          price: amount + currentSubscription.price,
          user_count: parseInt(
            subscriptionCount + currentSubscription.user_count
          ),
          product_id: productDetails.id,
        },
      };
      return fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      )
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          if (json.error) {
            handleCloseModal();
            setErrorMessage(json.message);
            setOpenErrorModal(true);
          } else {
            dispatch(updateUserSubscription(true));
            handleCloseModal();
            const url = returnPage.url || '/settings/subscription-invitations';
            if (url) {
              dispatch(setShowCardMethod(false));
              dispatch(setBillingInformation({}));
              dispatch(setPaymentMethod({}));
              dispatch(setPaymentMethods([]));
              dispatch(setSelectedCardMethod(DEFAULT_PAYMENT_METHOD));
              dispatch(setReturnPage(null));
            }
            history.push(url, { show: 1 });
          }
        })
        .catch((error) => {
          handleCloseModal();
          setOpenErrorModal(true);
        });
    } else {
      requestPayload = {
        subscription: {
          payment_method_id: paymentMethodId,
          price: amount,
          user_count: parseInt(subscriptionCount),
          product_id: productDetails.id,
        },
      };
      return fetch(`${CONSTANTS.baseUrl}/api/subscriptions.json`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      })
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          if (json.success) {
            dispatch(updateUserSubscription(true));
            handleCloseModal();
            const url = returnPage.url || '/settings/subscription-invitations';
            if (url) {
              dispatch(setShowCardMethod(false));
              dispatch(setBillingInformation({}));
              dispatch(setPaymentMethod({}));
              dispatch(setPaymentMethods([]));
              dispatch(setSelectedCardMethod(DEFAULT_PAYMENT_METHOD));
              dispatch(setReturnPage(null));
            }
            history.push(url, { show: 1 });
          }
          if (!json.success) {
            handleCloseModal();
            setErrorMessage(json.message);
            setOpenErrorModal(true);
          }
        })
        .catch((error) => {
          handleCloseModal();
          setOpenErrorModal(true);
        });
    }
  };

  const fetchNewUserInfo = async () => {
    try {
      request(`/users/${userSlug}`).then((data) => {
        dispatch(setInformationAboutUser(data.data));
      });
    } catch (err) {
      console.log(err);
    }
  };

  const allEqual = (arr) => arr.every((v) => v === true);

  useEffect(() => {
    const values = Object.values(cardInputs);
    const result = allEqual(values);
    if (result && nameOnCard.trim() !== '') {
      setCanceledButton(false);
      handelCardInputs(true);
    } else {
      setCanceledButton(true);
      handelCardInputs(false);
    }
  }, [cardInputs, nameOnCard]);

  useEffect(() => {
    if (submit && !canceledButton) {
      setSubmit(false);
      handleSubmit();
    }
  }, [submit]);

  const onNameChange = ({ target }) => {
    const inputValue = target.value;
    const trimmedValue = inputValue.replace(/^\s+/, '').replace(/ {2,}$/, ' ');
    setNameOnCard(trimmedValue);
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      // allow spaces
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      // prevent numbers
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 47)
    ) {
      // prevent special characters
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <>
      <div className='card-payment'>
        <form onSubmit={handleSubmit} className='payment-form'>
          <div className='card-input-details'>
            <span className='card-input-title'>Navn på kort</span>
            <input
              type='text'
              value={nameOnCard}
              placeholder='Navn'
              className='card-inputs'
              onKeyPress={onKeyPress}
              onChange={onNameChange}
            />
          </div>
          <div className='card-input-details'>
            <span className='card-input-title'>Kortnummer</span>
            <CardNumberElement
              onChange={(event) => {
                event.error
                  ? setCheckoutError(event.error.message)
                  : setCheckoutError();
                setCardInputs((prev) => {
                  return {
                    ...prev,
                    cardNumber: event.complete,
                  };
                });
              }}
              options={{
                style: {
                  base: cardNumberStyle,
                },
                placeholder: 'XXXX XXXX XXXXX',
              }}
              className='card-inputs'
            />
          </div>
          <div className='card-details-wrapper'>
            <div className='card-input-details'>
              <span className='card-input-title'>Utløpsdato</span>
              <CardExpiryElement
                onChange={(event) => {
                  event.error
                    ? setCheckoutError(event.error.message)
                    : setCheckoutError();
                  setCardInputs((prev) => {
                    return {
                      ...prev,
                      Cvc: event.complete,
                    };
                  });
                }}
                options={{
                  style: {
                    base: cardNumberStyle,
                  },
                  placeholder: 'MM/YY',
                }}
                className='card-inputs'
              />
            </div>
            <div className='card-input-cvc-details'>
              <span className='card-input-title'>CVV</span>
              <CardCvcElement
                onChange={(event) => {
                  event.error
                    ? setCheckoutError(event.error.message)
                    : setCheckoutError();
                  setCardInputs((prev) => {
                    return {
                      ...prev,
                      expiry: event.complete,
                    };
                  });
                }}
                options={{
                  style: {
                    base: cardNumberStyle,
                  },
                  placeholder: '----',
                }}
                className='card-inputs'
              />
            </div>
          </div>
        </form>
        <div className='payment-security'>
          <img src={Lock} alt='Lock' />
          <span>Sikker betaling med SSL-kryptering</span>
        </div>
      </div>
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </>
  );
};

export default CardPayment;
