import React, { useState } from 'react';
import './EditMenu.css';
import SmallMenuWorld from '../../../../img/SmallMenuWorld.svg';
import SmallMenuRelatives from '../../../../img/FamilySilhouette.svg';
import SmallMenuLock from '../../../../img/SmallMenuLock.svg';
import PointerUpper from '../../../../img/PointerUpper.svg';
import PointerBottom from '../../../../img/PointerBottom.svg';
import { Button, Menu, MenuItem } from '@material-ui/core';

const EditMenu = (props) => {
    const [value, setValue] = useState(props.group);
    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleOpenMenu = (e) => setMenuAnchor(e.currentTarget);
    const handleCloseMenu = () => setMenuAnchor(null);

    const changeGroup = (value) => {
        setValue(value);
        props.changeGroup(value);
        handleCloseMenu();
    }

    return(
        <div className='edit-photo-menu'>
            <Button onClick={handleOpenMenu} className='edit-photo-button'>
                <div className='edit-photo-button_family'>
                    {value === 'public' && <img loading='lazy' src={SmallMenuWorld} alt='familie'/> }
                    {value === 'relatives' && <img loading='lazy' src={SmallMenuRelatives} alt='familie'/> }
                    {value === 'private' && <img loading='lazy' src={SmallMenuLock} alt='familie'/> }
                    <span>{value === 'public' ? "Alle" : value === 'relatives' ? "Familien" : value === 'private' ? "Privat" : "privacy"}</span>
                    {menuAnchor ? <img loading='lazy' src={PointerUpper} alt='PointerUpper'/> : <img loading='lazy' src={PointerBottom} alt='PointerBottom'/>}
                </div>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >    
                <MenuItem onClick={() => changeGroup('public')}>
                    <div className='edit-photo-menu_family'>
                        <img loading='lazy' src={SmallMenuWorld} alt='SmallMenuWorld'/>
                        <span>Alle</span>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => changeGroup('relatives')}>
                    <div className='edit-photo-menu_family'>
                        <img loading='lazy' src={SmallMenuRelatives} alt='SmallMenuRelatives'/>
                        <span>Familien</span>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => changeGroup('private')}>
                    <div className='edit-photo-menu_family'>
                        <img loading='lazy' src={SmallMenuLock} alt='SmallMenuLock' style = {{marginLeft: "3px", marginRight: "2px"}}/>
                        <span>Privat</span>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default EditMenu;