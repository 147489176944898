import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import styles from './LifeEventDetails.module.css';
import editIcon from '../../../../../img/EditSmallIcon.svg';
import deleteIcon from '../../../../../img/DeleteIcon.svg';
import { request } from '../../../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import { setLifeEvents } from '../../../../../redux/actions';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import ConformModal from '../../../../Settings/atoms/ConformDeleteModal';
import EventModal from '../AddEventModal/AddEventModal';

const LifeEventDetails = ({ event, countries, setOpenUpgradeModal }) => {
  const [country, setCountry] = useState({});
  const grave = useSelector((state) => state.grave.grave);
  const user = useSelector((state) => state.user.user || {});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const events = useSelector((state) => state.grave.lifeEvents);
  const [openModal, setOpenModal] = useState(false);
  const is_relative = grave?.is_relative;

  useEffect(async () => {
    const country = await countries.find(
      (country) => country.id.toString() === event.location
    );
    if (!isEmpty(country)) setCountry(country);
  }, [event]);

  const deleteEvent = () => {
    request(`/life_events/${event.id}`, null, 'delete')
      .then((res) => {
        const newEvents = events.filter((e) => e.id !== event.id);
        dispatch(setLifeEvents([...newEvents]));
        showUserNotification('Livshendelsen er slettet', 'success');
        setOpenDeleteModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDateString = (dateString) => {
    const options = { month: 'short', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString(
      'en-US',
      options
    );

    return formattedDate;
  };

  const textColor = () => {
    if (event.theme_color === '#FFFFFF' || event.theme_color === '#fff') {
      return '#19262D';
    } else {
      return '#E4F3FD';
    }
  };

  const descriptionColor = () => {
    if (event.theme_color === '#FFFFFF' || event.theme_color === '#fff') {
      return '#404D56';
    } else {
      return '#fff';
    }
  };

  const maxLength = 120;
  const truncatedDescription =
    event.description.length > maxLength
      ? event.description.slice(0, maxLength) + '....'
      : event.description;

  const handelOpenEditModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenModal(true);
  };

  const handelOpenDeleteModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenDeleteModal(true);
  };

  return (
    <div className={styles['details-container']}>
      <div className={styles['date-container']}>
        <div className={styles['date']}>
          {event.event_date ? formatDateString(event.event_date) : ''}
        </div>
        <div className={styles['location']}>
          {country.name ? country.name : ''}
        </div>
      </div>
      <div className={styles['title']} style={{ color: textColor() }}>
        {event.event_title}
      </div>
      <div
        className={styles['description']}
        style={{ color: descriptionColor() }}
      >
        {truncatedDescription}
      </div>
      {event.creator_id === user.id && (
        <div className={styles['action-btn']}>
          <button
            onClick={(event) => {
              if (user.is_subscribed) {
                handelOpenEditModal(event);
              } else {
                setOpenUpgradeModal(true);
              }
            }}
          >
            <img src={editIcon} alt='Edit' />
          </button>
          <button
            onClick={(event) => {
              if (user.is_subscribed) {
                handelOpenDeleteModal(event);
              } else {
                setOpenUpgradeModal(true);
              }
            }}
          >
            <img src={deleteIcon} alt='Delete' />
          </button>
        </div>
      )}
      {openDeleteModal && (
        <ConformModal
          title='Livshendelse'
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          deletePayment={deleteEvent}
        />
      )}
      {openModal && (
        <EventModal
          title='Rediger livshendelse'
          onOpen={openModal}
          onClose={() => setOpenModal(false)}
          grave={grave}
          event={event}
        />
      )}
    </div>
  );
};

export default LifeEventDetails;
