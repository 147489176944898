import React from 'react';
import './AddNewCard.css';

const AddNewCard = ({ handleShowCard, showCard, handleShowPayment }) => {
  return (
    <div
      className='user-payment-card'
      onClick={() => {
        handleShowCard(!showCard);
        handleShowPayment({});
      }}
    >
      <div className='payment-wrapper'>
        <div className='select'>
          <input
            type='checkbox'
            checked={showCard}
            readOnly
            // onChange={() => handleShowCard()}
          />
          <label>Legg til betalingsmåte</label>
        </div>
      </div>
    </div>
  );
};

export default AddNewCard;
