import React, { useState, useEffect } from 'react';
import './GraveDetails.css';
import AboutBox from '../About/AboutBox';
import { useSelector } from 'react-redux';
import AboutMenuBar from '../About/AboutMenuBar';
import GraveMenuBar from '../GraveWall/GraveMenuBar/GraveMenuBar';
import GraveWall from '../GraveWall/GraveWall';
import { Route } from 'react-router-dom';
import GraveMedia from '../../../GraveMedia/GraveMedia';
import GraveAdministrator from '../GraveAdministrator/GraveAdministrator';
import LifeEventPage from '../LifeEventPage/LifeEventPage';

const GraveDetails = (props) => {
  const { isAuth, grave } = props;
  const user = useSelector((state) => state.user) || {};
  const tabValue = useSelector(
    (state) => state.photoReducer.currentDeceasedTabIndex
  );
  const [hideMenu, setHideMenu] = useState(false);
  const [aboutMenuKey, setAboutMenuKey] = useState(1);
  const [graveWallMenuKey, setGraveWallMenuKey] = useState(1);
  const [graveUser, setGraveUser] = useState(null);

  const handelGraveWallMenuKey = (key) => {
    setGraveWallMenuKey(key);
  };

  const handelAboutMenuKey = (key) => {
    setAboutMenuKey(key);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1020) {
        setHideMenu(true);
      } else {
        setHideMenu(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [hideMenu]);

  useEffect(() => {
    const graveUser = grave.grave_users
      ? grave.grave_users.find((u) => u.user_id === user.user.id)
      : null;
    if (graveUser) setGraveUser(graveUser);
  }, [grave]);

  return (
    <div className='grave-details-container'>
      <Route
        exact
        path={`/deceased/${grave.slug}`}
        render={() => (
          <div className='about-container'>
            {hideMenu && (
              <GraveMenuBar
                handelAboutMenuKey={handelGraveWallMenuKey}
                aboutMenuKey={graveWallMenuKey}
              />
            )}
            <GraveWall
              user={user}
              isAuth={isAuth}
              grave={grave}
              hideMenu={hideMenu}
              aboutMenuKey={graveWallMenuKey}
              handelAboutMenuKey={handelGraveWallMenuKey}
            />
          </div>
        )}
      />
      <Route
        exact
        path={`/deceased/${grave.slug}/about`}
        render={() => (
          <div className='about-container'>
            {hideMenu && (
              <AboutMenuBar
                handelAboutMenuKey={handelAboutMenuKey}
                aboutMenuKey={aboutMenuKey}
              />
            )}
            <AboutBox
              grave={grave}
              hideMenu={hideMenu}
              aboutMenuKey={aboutMenuKey}
              graveUser={graveUser}
            />
          </div>
        )}
      />
      <Route
        exact
        path={`/deceased/${grave.slug}/media`}
        component={GraveMedia}
      />
      <Route
        exact
        path={`/deceased/${grave.slug}/administrators`}
        component={GraveAdministrator}
      />
      <Route
        exact
        path={`/deceased/${grave.slug}/life-events`}
        component={LifeEventPage}
      />
    </div>
  );
};

export default GraveDetails;
