import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './ChangePassword.module.css';
import { request } from '../../../service/request';
import showUserNotification from '../../../components/UserNotification/showUserNotification';

const ChangePassword = (props) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('old_password', currentPassword);
    formData.append('new_password', newPassword);
    formData.append('new_password_confirmation', repeatNewPassword);
    if (currentPassword && newPassword && repeatNewPassword) {
      request('/reset_passwords', formData, 'post')
        .then((response) => {
          if (response.status === 200) {
            showUserNotification('Nytt passord ble lagret', 'success');
            setCurrentPassword('');
            setNewPassword('');
            setRepeatNewPassword('');
          } else {
            showUserNotification('Ugyldig passord', 'error');
          }
        })
        .catch((err) => {
          showUserNotification(err.response.data.message, 'error');
        });
    } else {
      showUserNotification('vennligst skriv inn passordet', 'error');
    }
  };

  const isPasswordValid = () => {
    // Minimum password length: 7-8 characters
    if (newPassword.length < 7) {
      return false;
    }

    // Password complexity: at least 3 out of 4 categories
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const nonAlphanumericRegex = /[^A-Za-z0-9]/;
    let categoriesCount = 0;

    if (uppercaseRegex.test(newPassword)) {
      categoriesCount++;
    }
    if (lowercaseRegex.test(newPassword)) {
      categoriesCount++;
    }
    if (digitRegex.test(newPassword)) {
      categoriesCount++;
    }
    if (nonAlphanumericRegex.test(newPassword)) {
      categoriesCount++;
    }

    return categoriesCount >= 3;
  };

  const passwordsMatch = () => {
    return newPassword === repeatNewPassword;
  };

  return (
    <div className={styles['change-password']}>
      <form onSubmit={handleFormSubmit}>
        <div className={styles['change-password_border']}>
          <div className={styles['change-password_content']}>
            <span className={styles['change-password_content-header']}>
              Passord
            </span>
            {/* <div className={styles['change-password_orange-line']} /> */}
            <input
              // required
              type='password'
              className={styles['change-password_content-input_input']}
              placeholder='Nåværende passord'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />

            <input
              // required
              type='password'
              className={styles['change-password_content-input_input']}
              placeholder='Angi nytt passord'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            {newPassword.length > 0 && !isPasswordValid() && (
              <span className={styles['password-error-message']}>
                <span
                  className={styles['password-error-message-subheading']}
                >{`Passordet må bestå av minst 7 tegn og inneholde minst 3 av de 4 kategoriene:`}</span>
                <br />
                <br />
                {`⚙︎ Stor bokstav`}
                <br />
                {`⚙︎ Små bokstaver`}
                <br />
                {`⚙︎ Sifre`}
                <br />
                {`⚙︎ Ikke-alfanumeriske tegn`}
                <br />
              </span>
            )}
            <input
              // required
              type='password'
              className={styles['change-password_content-input_input']}
              placeholder='Skriv passordet på nytt'
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              disabled={!isPasswordValid()}
            />
            {repeatNewPassword.length > 0 &&
              !passwordsMatch() &&
              isPasswordValid() && (
                <span className={styles['password-error-message']}>
                  Passordene er ikke like
                </span>
              )}
            <div className={styles['change-password_content-button']}>
              <button
                className={styles['change-password_content-button_save']}
                type='submit'
                disabled={!isPasswordValid() || !passwordsMatch()}
              >
                Lagre
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
