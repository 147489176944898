import React, { useState, useRef, useEffect } from 'react';
import X from '../../../../../img/X.svg';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './AddEventImages.module.css';
import { request } from '../../../../../service/request';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import UploadImages from '../uploadImages/UploadImages';
import { useSelector, useDispatch } from 'react-redux';
import SelectedImages from '../SelectedImages/SelectedImages';
import {
  addLifeEventPhotos,
  setLifeEvents,
  setSelectedEvent,
  setEventPhotoIndex,
  setEditEventPhotoIndex,
} from '../../../../../redux/actions';
import CropperModal from '../CropperModal/CropperModal';
import DiscardModal from '../../DiscardPopupModal/DiscardModal';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash.isempty';
import TabButtons from '../../../../../components/TabButtons/TabButtons';
import UploadVideo from '../UploadVideo/UploadVideo';
import SelectedVideos from '../SelectedVideos/SelectedVideos';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: '#fff',
    borderRadius: 0,
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));
const AddEventImages = ({ title, onOpen, onClose, event, handelSaveModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [tabItems, setTabItems] = useState([
    { text: 'legge til bilder' },
    { text: 'legge til videoer' },
  ]);
  const selectedEvent = useSelector((state) => state.grave.selectedEvent);
  const [isChanged, setIsChanged] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const lifeEventPhotos = useSelector((state) => state.grave.lifeEventPhotos);
  const lifeEventVideos = useSelector((state) => state.grave.lifeEventVideos);
  const currentEditPhoto = useSelector(
    (state) => state.photoReducer.editEventPhotoIndex
  );
  const currentPhotoIndex = useSelector(
    (state) => state.photoReducer.currentEventPhotoIndex
  );
  const allEvents = useSelector((state) => state.grave.lifeEvents);
  const grave = useSelector((state) => state.grave.grave);
  const [openCropper, setOpenCropper] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(async () => {
    if (lifeEventPhotos[currentPhotoIndex]) {
      const index = await lifeEventPhotos.findIndex(
        (photo) => photo.id === lifeEventPhotos[currentPhotoIndex].id
      );
      if (currentEditPhoto > 0) {
        dispatch(setEventPhotoIndex(currentEditPhoto));
      } else {
        dispatch(setEventPhotoIndex(index));
        dispatch(setEditEventPhotoIndex(0));
      }
    }
  }, [lifeEventPhotos, currentPhotoIndex]);

  const handelDiscardModal = (value) => {
    setOpenDiscardModal(value);
  };

  const handleShowImages = () => {
    setShowImage(true);
  };

  const handelOpenCropper = () => {
    setOpenCropper(true);
  };

  return (
    <div className={classes.modalContent}>
      <div className={styles['upload-container']}>
        <TabButtons tab={tab} setTab={setTab} tabItems={tabItems} />
        {tab == 0 && showImage && lifeEventPhotos.length > 0 && (
          <React.Fragment>
            {openCropper ? (
              <CropperModal
                photo={lifeEventPhotos[currentEditPhoto]}
                handelCloseCropper={() => setOpenCropper(false)}
              />
            ) : (
              <SelectedImages
                backgroundImage={lifeEventPhotos[currentPhotoIndex]}
                handelOpenCropper={handelOpenCropper}
              />
            )}
          </React.Fragment>
        )}
        {tab == 1 && lifeEventVideos.length > 0 && (
          <React.Fragment>
            <SelectedVideos />
          </React.Fragment>
        )}
        {tab == 0 && <UploadImages handleShowImages={handleShowImages} />}
        {tab == 1 && <UploadVideo />}
        {isUploading && (
          <div className={styles['loading-container']}>
            <div className={styles['loader']}>
              <CircularProgress style={{ color: '#fea65a' }} />
              <span>Sender...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEventImages;
