import React from 'react';
import AboutMenuItem from '../../About/components/AboutMenuItem';
import styles from './GraveMenuBar.module.css';

const GraveMenuBar = (props) => {
  const menuItems = [
    {
      id: 1,
      title: 'Gravbesøk og minner',
    },
    {
      id: 2,
      title: 'Merkedager',
    },
  ];
  return (
    <div className={styles['menu-container']}>
      {menuItems.map((menuItem, index) => (
        <AboutMenuItem
          menuItem={menuItem}
          key={index}
          handelAboutMenuKey={props.handelAboutMenuKey}
          aboutMenuKey={props.aboutMenuKey}
        />
      ))}
    </div>
  );
};

export default GraveMenuBar;
