import {
    setScreenType,
    setUserConfirmed,
    setUserSignOut,
    setListOfCountries,
    setIdFromSignIn,
    setAuthForm,
    setStepRegist,
    setCheckedTerms
} from "../actions";


const signInState = {
    isAuthorized: Boolean(localStorage.getItem('auth')) || false,
    currentScreen: '',
    userId: '',
    countries: [],
    authorizationForm: {
        password: '',
        confirmPassword: '',
        fullName: '',
        gender: "mann",
        phoneNumber: '',
        code: [],
        chosenCountry: {dial_code: '+47', id: 1, name: "Norge"},
        step: 0,
        checkbox: false
    }
}

export const signInReducer = (state = signInState, action) => {
    switch (action.type) {

        case setUserConfirmed:
            return {
                ...state,
                isAuthorized: true,
            }         
        case setUserSignOut:
            return {
                ...state,
                isAuthorized: false,
            }
        case setScreenType:
            return {
                ...state,
                currentScreen: action.screen
            }
        case setListOfCountries:
            return {
                ...state,
                countries: action.countries
            }
        case setIdFromSignIn:
            return {
                ...state,
                userId: action.id
            }
        case setAuthForm:
            return {
                ...state,
                authorizationForm: {...state.authorizationForm, ...action.payload}
            }
        case setStepRegist:
            return {
                ...state,
                authorizationForm: {...state.authorizationForm, step: action.payload}
            }
        case setCheckedTerms:
            return {
                ...state,
                authorizationForm: {...state.authorizationForm, checkbox: true}
            }
        default: return state;
    }
};

