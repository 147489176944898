import './Content.css'
import Card from './Card'
import React, { useEffect, useState } from 'react';
import {fetch_request} from '../../../../../service/request';
import NoneGravesImage from '../../../../../img/NoneGravesImage.svg';
import { Link } from 'react-router-dom';


const Content = (props) => {
  const searchInput = props.searchInputValue || ''
  const [municipalitiesList, setMunicipalitiesList] = useState([])
  const currentPage = props.currentPage

  useEffect(
    () => {
      fetchMunicipalities()
    },
    [searchInput, currentPage]
  )

  const fetchMunicipalities = () => {
     fetch_request(`/api/search_municipalities?input=${searchInput}&page=${currentPage}`)
        .then((response) => response.json())
        .then((data) => {
          setMunicipalitiesList(data.municipalities)
          props.setTotalPages(data.total_pages)
        })
        .catch((err) => console.log(err));
  }

  return(
    <>
      {

        municipalitiesList && municipalitiesList.length === 0 ?
        <div className="municipality-not-found">
          <img src={NoneGravesImage} alt="not-found"/>
          <span className='search-graves_none-text'>Fant ingen resultater for <span>"{searchInput}"</span></span>
        </div>

        :

        <div className="municipality-search-content">
          {
            municipalitiesList && municipalitiesList.map(item => {
              return(
                <Link to = {`/finn-en-grav/${item.slug}`}>
                  <Card name={item.name} countryName={item.country_name}/>
                </Link>
              )
            })
          }
        </div>
      }
    </>
  )
}

export default Content;