import React, { useState } from 'react'
import Header from './Header'
import Content from './Content'
import Paginator from './Paginator'
import './Index.css'
import { useEffect } from 'react'
const Index = () => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchInputValue])

  return(
    <div className="municipality-index">
      <Header setSearchInputValue={setSearchInputValue}/>
      <Paginator
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <Content
        searchInputValue={searchInputValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
      />
    </div>
  )
}

export default Index;