import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import ThreeDots from "../../../img/three dots.svg";
import './UserProfileHeader.css'
import React, { useState } from 'react';
import './EditAndDeleteActions.css'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85px",
    cursor: 'pointer'
  },
}));

const EditAndDeleteActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.handleEditDisable()
  };

  const handleEditActionChosen = () => {
    props.handleEditEnable()
    setAnchorEl(null);
  }

  const handleDeleteActionChosen = () => {
    props.handleDeleteSelection()
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <img 
        src={ThreeDots} 
        alt="three-dots" 
        className="comment-row-three-dots"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography} variant="subtitle1" onClick={handleEditActionChosen}>
          <div
            className="actions-typography"
          >
            Edit
          </div>
        </Typography>
        <Typography className={classes.typography} variant="subtitle1" onClick={handleDeleteActionChosen}>
          <div
            className="actions-typography"
          >
            Delete
          </div>
        </Typography>
      </Popover>
    </>
  );
};

export default EditAndDeleteActions;
