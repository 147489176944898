import { Avatar } from "@material-ui/core";
import { TextareaAutosize } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { request } from "../../../service/request";
import { addUserWallComment } from "../../../redux/actions";
import {useDispatch} from 'react-redux'

import "./CommentRow.css";
import "./CommentBox.css";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: "1px solid #D8D8D8",
    cursor: "pointer",
    display: "flex",
    alignSelf: "center",
  },
  iconMenu: {
    cursor: "pointer !important",
  },
}));

const CommentBox = (props) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const isTextAdded = () => {
    return text;
  };

  const submitComment = async () => {
    await request("/user_wall_post_comments", buildCreateRequest(), "post")
      .then((res) => {
        fetchAndDispatchSavedComment(res)
        setText("");
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const fetchAndDispatchSavedComment = async (res) => {
    await request(`/user_wall_post_comments/${res.data.id}`, null, "get")
    .then((res) => {
      dispatch(addUserWallComment({questionId: props.question.id, comment: res.data}))
    })
  }

  const buildCreateRequest = () => {
    const formdata = new FormData();
    formdata.append("user_wall_post_content_id", props.question.content.id);
    formdata.append("author_id", props.loggedInUser.id);
    formdata.append("description", text);
    return formdata;
  };

  return (
    <>
      <div className="comment-row">
        <Avatar
          src={props.loggedInUser.avatar}
          className={classes.small}
          variant="rounded"
        />
        <div className="question-text-area-wrapper">
          <TextareaAutosize
            value={text}
            onChange={(e) => {
              e.target.value[0] !== " " && setText(e.target.value);
            }}
            placeholder="Skriv inn svaret ditt her"
            className={"comment-box-text-area"}
          />
        </div>
      </div>
      <div className="comment-box-footer">
        <button
          className="submit-question_button"
          onClick={submitComment}
          disabled={!isTextAdded()}
        >
          Svar
        </button>
      </div>
    </>
  );
};

export default CommentBox;
