import { LocalGasStation } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import EditPerson from '../../../img/EditSmallIcon.svg';
import styles from './SettingsPersonalInformation.module.css';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const SettingsPersonalInformation = ({ edit }) => {
  const monthArray = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  const user = useSelector((state) => state.user.user || {});

  return (
    <div className={styles['settings-personal-information']}>
      <div className={styles['settings-personal-information_content-header']}>
        <span
          className={
            styles['settings-personal-information_content-header_title']
          }
        >
          Personinfo
        </span>
        <div className={styles['action-btn']} onClick={() => edit(true)}>
          <img src={EditPerson} alt='Edit' />
          <button type='button'>Redigere</button>
        </div>
      </div>
      {/* <div className={styles['settings-personal-information_orange-line']} /> */}
      <div className={styles['settings-user-information-container']}>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Fornavn:</div>
          <div className={styles['user-info']}>
            {Array.isArray(user).length !== 0 ? user.full_name : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Kjønn:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user) ? user.gender : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Okkupasjon:</div>
          <div className={styles['user-info']}>
            {user.occupation ? user.occupation : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Bursdag:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user) && user.date_of_birth
              ? `${new Date(user.date_of_birth).getDate()} ${
                  monthArray[new Date(user.date_of_birth).getMonth()]
                }, ${new Date(user.date_of_birth).getFullYear()}`
              : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Epostadresse:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user) ? user.email?.toString() : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Telefonnummer:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user) ? user.phone_number : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Adressen din:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user) ? user.address : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Foretrukket Språk:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user.preferred_language)
              ? user.preferred_language?.dialect
              : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Foretrukket valuta:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user.preferred_currency)
              ? user.preferred_currency?.notation
              : ''}
          </div>
        </div>
        <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>Tidssone:</div>
          <div className={styles['user-info']}>
            {!isEmpty(user) ? user.time_zone : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPersonalInformation;
