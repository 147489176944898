import React, { useRef, useState } from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import { Cropper } from 'react-cropper';
// import { Cropper } from 'react-advanced-cropper';
// import 'react-advanced-cropper/dist/style.css';
import { makeFileFromURL } from '../../../../../service/makeFileFromBlob';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './GraveAvatar.module.css';
import { request } from '../../../../../service/request';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { useDispatch, useSelector } from 'react-redux';
import X from '../../../../../img/X.svg';
import { goToChosenGrave } from '../../../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const GraveAvatarModal = ({ handleCloseCropper, openCropper, image }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const grave = useSelector((state) => state.grave.grave);
  const cropperRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const saveCroppedImage = async () => {
    const formData = new FormData();
    if (image) {
      setIsLoading(true);
      if (typeof cropperRef.current?.cropper !== 'undefined') {
        const croppedImage = await makeFileFromURL(
          cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
          image.file?.name
        );
        formData.append('grave[avatar]', croppedImage, image.file?.name);
      } else {
        const croppedImage = await makeFileFromURL(
          image.file.getCroppedCanvas().toDataURL(),
          image.file?.name
        );
        formData.append('grave[avatar]', croppedImage, image.file?.name);
      }
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      try {
        const response = await request(
          `/graves/${grave.slug}`,
          formData,
          'put',
          headers
        );
        if (response.status >= 200 && response.status < 300) {
          dispatch(goToChosenGrave(response.data));
          showUserNotification('Gravbildet er oppdatert', 'success');
          handleCloseCropper();
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        showUserNotification(err.message, 'warning');
      }
    }
  };

  return (
    <Modal
      open={openCropper}
      onClose={() => handleCloseCropper()}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['heading']}>
          <span>Legg til gravbilde</span>
          <img
            loading='lazy'
            src={X}
            alt='x'
            onClick={() => handleCloseCropper()}
          />
        </div>
        {/* <Cropper
          src={image.url}
          onChange={onChange}
          aspectRatio={235 / 260}
          moveImage={false}
          className={styles['cropper']}
          autoZoom={false}
        /> */}
        <div className={styles['cropper-container']}>
          <Cropper
            ref={cropperRef}
            className={styles['grave-picture-gallery_photos-cropper']}
            src={image.url}
            viewMode={1}
            guides={true}
            zoomOnWheel={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            aspectRatio={235 / 260}
            checkOrientation={false}
          />
          {isLoading && (
            <div className='image-loader'>
              Sender....
              {/* <CircularProgress style={{ color: '#fea65a' }} /> */}
            </div>
          )}
        </div>
        <div className={styles['cropper-buttons']}>
          <div className={styles['btn-wrapper']}>
            <button
              className={styles['cancel']}
              onClick={() => handleCloseCropper()}
            >
              Avbryt
            </button>
            {!isLoading && (
              <button className={styles['save']} onClick={saveCroppedImage}>
                Lagre
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GraveAvatarModal;
