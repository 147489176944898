import { Modal, makeStyles } from '@material-ui/core';
import React from 'react';
import styles from './Agreement.module.css';
import IframeComponent from '../../../../../components/Iframe/IframeComponent';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#000',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const AgreementModal = ({ onOpen, iframeSource }) => {
  const classes = useStyles();

  const customStyle = {
    border: 'none',
    width: '100%',
    overflowY: 'hidden',
    height: '100vh',
  };
  return (
    <Modal
      open={onOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['heading']}>
          Åpne vipps-applikasjonen på mobilen din for bekreftelse
        </div>
        <IframeComponent
          title='Vipps'
          source={iframeSource}
          customStyle={customStyle}
        />
      </div>
    </Modal>
  );
};

export default AgreementModal;
