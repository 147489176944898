import React, { useEffect, useState } from 'react';
import BoyAvatar from '../../../../img/BoyAvatar.svg';
import GirlAvatar from '../../../../img/GirlsAvatar.svg';
import styles from './ConnectedGraves.module.css';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ConnectedGraves = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  const history = useHistory();
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    if (item.gender === 'mann') {
      setAvatar(BoyAvatar);
    } else if (item.gender === 'Kvinne') {
      setAvatar(GirlAvatar);
    } else {
      setAvatar(BoyAvatar);
    }
  }, [item]);

  return (
    <>
      {!isEmpty(item) && item.length > 0 ? (
        <div className={styles['connect-grave-container']}>
          {item.map((item, index) => {
            return (
              <div
                className={styles['connected-grave']}
                key={index}
                onClick={() => history.push(`/deceased/${item.slug}`)}
              >
                <LazyLoadImage
                  src={item.avatar ? item.avatar : avatar}
                  alt='grave_Avatar'
                  className={styles['grave-image']}
                />
                <div className={styles['grave-name']}>{item.name}</div>
                {item.date_of_birth && item.date_of_death && (
                  <div className={styles['age-years']}>
                    {`${item.date_of_birth.substring(
                      0,
                      4
                    )} - ${item.date_of_death.substring(0, 4)}`}
                  </div>
                )}
                <div className={styles['relation']}>{item.relation}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles['empty-grave-info']}>
          <span className={styles['empty-message']}>{emptyMessage}</span>
          {canEdit && (
            <button className={styles['add-btn']} onClick={onEdit}>
              Add {title}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default ConnectedGraves;
