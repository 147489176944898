import isEmpty from 'lodash.isempty';
import React from 'react';
import { useSelector } from 'react-redux';

const AuthFormWrapper = (props) => {
  const subscriptionInvitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );
  const tokeErrorMessage = useSelector(
    (state) => state.invitationToken.tokeErrorMessage
  );

  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );

  return (
    <React.Fragment>
      {!isEmpty(subscriptionInvitationToken) ? (
        <div className='invitation-message'>
          Du ble invitert inn til familieabonnementet til{' '}
          {subscriptionInvitationToken.invited_by}
        </div>
      ) : null}
      {!isEmpty(tokeErrorMessage) ? (
        <div className='invitation-message'>{tokeErrorMessage}</div>
      ) : null}
      <div className='auth-welcome'>
        <div className='grave-form-logo' />
        <div className='auth-form-wrapper'>{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default AuthFormWrapper;
