import { LocalGasStation } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import EditPerson from '../../../img/EditPerson.svg';
import './SettingsPersonalInformation.css';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const SettingsPersonalInformation = ({ edit }) => {
  const monthArray = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  const [fullName, setFullName] = useState([]);
  const user = useSelector((state) => state.user.user || {});

  useEffect(() => {
    if (!isEmpty(user)) setFullName(user.full_name?.split(' ') || []);
  }, [user]);

  return (
    <div className='settings-personal-information'>
      <div className='settings-personal-information_content-header'>
        <span className='settings-personal-information_content-header_title'>
          Personinfo
        </span>
        <div
          onClick={() => edit(true)}
          className='settings-personal-information_content-header-edit'
        >
          <img loading='lazy' src={EditPerson} alt='Rediger' />
          <span>Redigere</span>
        </div>
      </div>
      <div className='settings-personal-information_orange-line' />
      <div className='settings-user-information-container'>
        <div className='settings-user-information'>
          <div className='settings-labels'>Fornavn:</div>
          <div className='user-info'>
            {Array.isArray(fullName) ? fullName[0] : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Etternavn:</div>
          <div className='user-info'>
            {Array.isArray(fullName) ? fullName[1] : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Kjønn:</div>
          <div className='user-info'>{!isEmpty(user) ? user.gender : ''}</div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Bursdag:</div>
          <div className='user-info'>
            {!isEmpty(user)
              ? `${new Date(user.date_of_birth).getDate()} ${
                  monthArray[new Date(user.date_of_birth).getMonth()]
                }, ${new Date(user.date_of_birth).getFullYear()}`
              : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Epostadresse:</div>
          <div className='user-info'>
            {!isEmpty(user) ? user.email?.toString() : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Telefonnummer:</div>
          <div className='user-info'>
            {!isEmpty(user) ? user.phone_number : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Adressen din:</div>
          <div className='user-info'>{!isEmpty(user) ? user.address : ''}</div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Foretrukket Språk:</div>
          <div className='user-info'>
            {!isEmpty(user) ? user.preferred_language?.dailect : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Foretrukket valuta:</div>
          <div className='user-info'>
            {!isEmpty(user) ? user.preferred_currency?.nation : ''}
          </div>
        </div>
        <div className='settings-user-information'>
          <div className='settings-labels'>Tidssone:</div>
          <div className='user-info'>
            {!isEmpty(user) ? user.time_zone : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPersonalInformation;
