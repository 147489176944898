import React from 'react';
import './HomepageFindGraveSecond.css';
import { useParams } from 'react-router-dom';
const HomepageFindGraveSecond = (props) => {
    let { municipality, cemetery } = useParams()

    if (municipality || cemetery) {
      return null;
    }

    return(
        <div className='homepage-findgrave_second'>
            <div className="homepage-findgrave_second-content">
                <div className="homepage-findgrave_second-content_header">
                    <span className='homepage-findgrave_second-content_header-main'>Besøk graven når du selv <span>ønsker det</span></span>
                    <span className='homepage-findgrave_second-content_header-submain'>Nettgrav.no er en forlengelse av den fysiske graven. Besøk graven og samle historien til gravlagte ubegrenset.</span>
                </div>
                <div className="homepage-findgrave_second-content_main">
                    <div className="homepage-findgrave_second-content_main-row">
                        <div className='homepage-findgrave_second-content_main-row_block'>
                            <div className="homepage-findgrave_second-content_main-row_block-header">
                                <div className='homepage-findgrave_second-content_main-row_block-header_orange'/>
                                <span>Gravtavle</span>
                            </div>
                            <span>Gir familien mer plass til å skrive om og til den gravlagte. Last opp bilder, musikk, film og hilsener på merkedager med tre sikkerhetsinnstillinger: privat, offentlig eller familie.</span>
                        </div>
                        <div className='homepage-findgrave_second-content_main-row_block' style ={{maxWidth: "339px"}}>
                            <div className="homepage-findgrave_second-content_main-row_block-header">
                                <div className='homepage-findgrave_second-content_main-row_block-header_grey'/>
                                <span>Verdibevareren</span>
                            </div>
                            <span>Verdibevareren bidrar til at gravlagtes personlighet forblir bevart. Du kan velge blant 90 unike personlighetstrekk, som hjelpsom, forståelsesfull og god. Velg verdier som beskriver gravlagtes personlighet, og legg til en kort historie for å forklare nærmere om dem.</span>
                        </div>
                        <div className='homepage-findgrave_second-content_main-row_block' style ={{maxWidth: "257px"}}>
                            <div className="homepage-findgrave_second-content_main-row_block-header">
                                <div className='homepage-findgrave_second-content_main-row_block-header_orange'/>
                                <span>Bilder på graven</span>
                            </div>
                            <span>Er ofte bilder av personen som graven representerer. Med bilder blir graven en opplevelse og ivaretakelsen av gravlagte står seg i generasjoner.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageFindGraveSecond;
