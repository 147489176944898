import React, { useEffect, useState } from 'react';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import ConnectGraveIcon from '../../../../img/ConnectGrave.svg';
import styles from './DeceasedMenu.module.css';
import { useDispatch } from 'react-redux';
import { setCurrentDeceasedTabIndex } from '../../../../redux/actions';
import ConnectGraveModal from '../ConnectGraveModal/ConnectGraveModal';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import { useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
    gap: '24px',
  },
  indicator: {
    backgroundColor: '#985000',
  },
  tab: {
    marginRight: '24px',
    color: '#985000',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const DeceasedMenu = ({ isAuth, grave, user, isClaimed }) => {
  const userMenuItems = [
    {
      name: 'GRAVTAVLEN',
      url: `/deceased/${grave.slug}`,
    },
    {
      name: 'OM',
      url: `/deceased/${grave.slug}/about`,
    },
    {
      name: 'LIVSLINJEN',
      url: `/deceased/${grave.slug}/life-events`,
    },
    {
      name: 'MEDIA',
      url: `/deceased/${grave.slug}/media`,
    },
  ];

  const menuItemsWithAdmin = [
    ...userMenuItems,
    {
      name: 'Administrator',
      url: `/deceased/${grave.slug}/administrators`,
    },
  ];
  const [menuItems, setMenuItems] = useState([]);

  const [value, setValue] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [openGraveConnectionModal, setOpenGraveConnectionModal] =
    useState(false);
  const [canAccessLinkedGraves, setCanAccessLinkedGraves] = useState(true);
  const [admin, setAdmin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(user)) {
      const graveUsers = grave.grave_users;
      const graveUser = !isEmpty(graveUsers)
        ? graveUsers.find((u) => u.user_id === user.id)
        : null;
      if (!isEmpty(graveUser) && (graveUser.admin || graveUser.grave_owner)) {
        setAdmin(true);
        setMenuItems(menuItemsWithAdmin);
      } else {
        setAdmin(false);
        setMenuItems(userMenuItems);
      }
    } else {
      setMenuItems(userMenuItems);
    }
  }, [grave, user]);

  useEffect(() => {
    const foundIndex = menuItems.findIndex(
      (item) => item.url === location.pathname
    );
    if (foundIndex !== -1) {
      setValue(foundIndex);
      dispatch(setCurrentDeceasedTabIndex(foundIndex));
    }
  }, [location.pathname, menuItems]);

  const handleChange = (event, newValue) => {
    dispatch(setCurrentDeceasedTabIndex(newValue));
    history.push(menuItems[newValue].url);
    setValue(newValue);
  };

  const handleCloseGraveConnectionDialog = () =>
    setOpenGraveConnectionModal(false);

  const handleLinkedGraveOnClick = () => {
    if (user.subscription?.can_access_linked_graves) {
      setOpenGraveConnectionModal(true);
    } else {
      setCanAccessLinkedGraves(false);
    }
  };

  return (
    <div className={styles['tabs-container']}>
      <UpgradePlanModal
        isOpen={!canAccessLinkedGraves}
        handleCloseUpgradePlansModal={() => setCanAccessLinkedGraves(true)}
        body='Å knytte gravlagte sammen er tilgjengelig under Familie og Familie+ medlemskap.'
        title='Oppgradering av abonnement nødvendig'
      />
      <div className={classes.tabContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          id='id223223'
          // textColor='primary'
          aria-label='scrollable force tabs example'
          classes={{ indicator: classes.indicator }}
        >
          {menuItems.map((item, index) => {
            return (
              <Tab
                label={item.name}
                {...a11yProps(index)}
                className={classes.tab}
              />
            );
          })}
        </Tabs>
      </div>
      {isAuth && admin && isClaimed && (
        <div
          className={styles['connect-btn-container']}
          onClick={handleLinkedGraveOnClick}
        >
          <img src={ConnectGraveIcon} alt='connect grave' />
          <button className={styles['connect-btn']}>Knytt gravlagte</button>
        </div>
      )}
      {openGraveConnectionModal && (
        <ConnectGraveModal
          onOpen={openGraveConnectionModal}
          onClose={handleCloseGraveConnectionDialog}
          grave={grave}
          user={user}
        />
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default DeceasedMenu;
