import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./Grave.css";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// import HoverPopUp from './HoverPopUp';
// import Zoom from '@material-ui/core/Zoom';
import BirthDate from "../../../img/BirthDate.svg";
import calendar from "../../../img/Calnedar.svg";
import location from "../../../img/Location.svg";
import Upload from "../../../img/UploadImage.svg";
import Bird from "../../../img/Bird.svg";
import Physical from "../../../img/PhysicalGrave.svg";
import { Popover } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  rootTooltip: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 10px rgba(33, 32, 32, 0.18)",
    borderRadius: "6px",
    maxWidth: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#36434B",
    boxSizing: "border-box",
    padding: "19px 16px",
  },
  rootArrow: {
    color: "#fff",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "19px 16px",
    maxWidth: "309px",
  },
  confirmationPaper: {
    maxWidth:"unset !important"
  },
  root: {
    "& .MuiPaper-root": {
      background: "transparent",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset !important',
    }
  },
}));

const Grave = (props) => {
  const classes = useStyle();
  const { grave } = props;
  const [openPhysicalPopover, setOpenPhysicalPopover] = React.useState(null);
  const open = Boolean(openPhysicalPopover);
  const history = useHistory();
  const grave_images = grave.grave_images;
  const grave_display_image =
    grave_images[Math.floor(Math.random() * grave_images.length)];

  const dispatch = useDispatch();
  const convertDate = (data) => {
    if (data)
      return `${data.slice(-2)}.${data.slice(-5, -3)}.${data.slice(-10, -6)}`;
    else return "no info";
  };

  const handlePopoverOpen = (event) => {
    setOpenPhysicalPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenPhysicalPopover(null);
  };

  return (
    <div className="grave">
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={openPhysicalPopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="mouse-over-popover_physical">
          <span className="mouse-over-popover_physical-header">
            Graven er fortsatt å finne på en gravlund:
            <br />
            <br />
          </span>
          <span className="mouse-over-popover_physical-main">
            <span>{grave?.plot_number}:</span> Graven sin lokasjon på gravlunden er.
          </span>
          {grave?.plot_number?.split(".")[0] ? (
            <span className="mouse-over-popover_physical-main">
              <span>
                {grave?.plot_number?.split(".")[0]
                  ? grave?.plot_number?.split(".")[0]
                  : " "}
                :
              </span>{" "}
              Indikerer hvilken gravlund
            </span>
          ) : (
            grave?.plot_number?.split(":")[0] && (
              <span className="mouse-over-popover_physical-main">
                <span>{grave?.plot_number?.split(":")[0]}:</span> Navnet på gravlunden
              </span>
            )
          )}
          {grave?.plot_number?.split(".")[1] ? (
            <span className="mouse-over-popover_physical-main">
              <span>{grave?.plot_number?.split(".")[1]}:</span> Området inne på gravlunden
            </span>
          ) : (
            grave?.plot_number?.split(":")[1] && (
              <span className="mouse-over-popover_physical-main">
                <span>{grave?.plot_number?.split(":")[1]}:</span> Området inne på gravlunden
                
              </span>
            )
          )}
          {grave?.plot_number?.split(".")[2] ? (
            <span className="mouse-over-popover_physical-main">
              <span>{grave?.plot_number?.split(".")[2]}:</span> Rekken du finner graven
            </span>
          ) : (
            grave?.plot_number?.split(":")[2] && (
              <span className="mouse-over-popover_physical-main">
                <span>{grave?.plot_number?.split(":")[2]}:</span> Rekken du finner graven
              </span>
            )
          )}
          {grave?.plot_number?.split(".")[3] ? (
            <span className="mouse-over-popover_physical-main">
              <span>{grave?.plot_number?.split(".")[3]}:</span> Raden i rekken graven befinner seg
            </span>
          ) : (
            grave?.plot_number?.split(":")[3] && (
              <span className="mouse-over-popover_physical-main">
                <span>{grave?.plot_number?.split(":")[3]}:</span> Raden i rekken graven befinner seg
              </span>
            )
          )}
        </div>
      </Popover>
      <div className="grave-images">
        <img loading="lazy" src={Upload} alt="upload" />
        {grave?.category === "physical" ? (
          <div
            className="grave-images_circle"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <img loading="lazy" src={Physical} alt="Physical" />
          </div>
        ) : (
          <Tooltip
            placement="top"
            title="Denne graven finnes kun på nettgrav.no"
            arrow
            classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
          >
            <div className="grave-images_circle">
              <img loading="lazy" src={Bird} alt="Physical" />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="grave-avatar_listing">
        {grave_display_image?.file_url && (
          <img
            loading="lazy"
            src={grave_display_image.file_url}
            alt="grave_avatar"
            className={"grave_avatar"}
          />
        )}
        {/*<img loading='lazy' src={GraveImage} alt="Grave" className='grave-image'/>*/}
      </div>
      <span>{grave.name}</span>
      <div className="grave-data_date">
        <span>
          <img loading="lazy" src={BirthDate} alt="birth" />{" "}
          {convertDate(grave.date_of_birth)}
        </span>
        <span>
          <img loading="lazy" src={calendar} alt="calendar" />{" "}
          {convertDate(grave.date_of_death)}
        </span>
      </div>
      <div className="grave-location">
        <span className="grave-date second">
          <img loading="lazy" src={location} alt="location" />{" "}
          {grave.municipality.name}
        </span>
        <span> - </span>
        <span className="grave-id">{grave.graveyard.name}</span>
      </div>
      <span className="plot_number">
        {grave.plot_number && grave.plot_number !== "undefined"
          ? grave.plot_number
          : " "}
      </span>
      <Link to={`/deceased/${grave.slug}`} className="grave-link-deceased">
        <Button variant="outlined" className="grave-button">
          Besøk graven
        </Button>
      </Link>
    </div>
  );
};

export default Grave;
