import React from 'react';
import styles from './Loader.module.css';
import spinner from '../../service/Loading_animation.gif';

const Loader = () => {
  return (
    <div className={styles['loadingSpinner']}>
      <img loading='lazy' src={spinner} alt='elastic' />
    </div>
  );
};

export default Loader;
