import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import './AddByLink.css';
import MusicPLay from '../../../../../img/MusicButton.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentOnToggle } from '../../../../../redux/actions';

const useStyle = makeStyles(() => ({
    rootCancel: {
        width: '180px',
        height: '55px',
        color: '#C9CACB',
        backgroundColor: '#fff',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
    },
    rootSave: {
        width: '180px',
        height: '55px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#FEA65A',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#FEA65A',
        }
    },
    rootAdd: {
        width: '66px',
        height: '42px',
        color: '#fff',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        backgroundColor: '#FEA65A',
        marginLeft: '-10px',
        '&:hover': {
            backgroundColor: '#FEA65A',
        }
    }
}))

const AddByLink = (props) => {
    const classes = useStyle();
    const [link, setLink] = useState('');
    const [musicArray, setMusicArray] = useState([]);
    const dispatch = useDispatch();
    const currentComment = useSelector(state => state.grave.commentOnToggle);

    const handleAddMusic = () => {
        if (link) {
            setMusicArray(prev => [{title: 'Song Lorem', author: 'Lorem Lorem', duration: '10:00', id: Date.now().toString()}, ...prev]);
            setLink('');
        }
    }

    const saveMusicComment = () => {
        dispatch(addCommentOnToggle({...currentComment, title: '', music: musicArray}))
        props.closeModal();
    }

    return(
        <div className='add-by-link'>
            <div className="add-by-link_header">
                <span>Link to music</span>
                <div>
                    <input type='text' placeholder='https://' value={link} onChange={e => setLink(e.target.value)}/>
                    <Button className={classes.rootAdd} onClick={handleAddMusic}>Add</Button>
                </div>
                <span className='add-by-link_header-subtitle'>You can add music from <span>Spotify</span>, <span>Apple music</span>, <span>SoundCloud</span> and more.</span>
                {
                    musicArray.map(song => {
                        return(
                            <div className='add-by-link_header-music' key={song.id}>
                                <img loading='lazy' src={MusicPLay} alt='MusicPLay'/>
                                <span className='add-by-link_header-music_text'><span>{song.title} -</span> {song.author}</span>
                                <span className='add-by-link_header-music_duration'>{song.duration}</span>
                            </div>
                        )
                    })
                }
            </div>
            {
                musicArray.length > 0 &&
                <div className='add-by-link_header-image'>
                    <div className="add-by-link_header-image_buttons">
                        <Button className={classes.rootCancel} onClick={props.closeModal}>Cancel</Button>
                        <Button className={classes.rootSave} onClick={saveMusicComment}>Save</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default AddByLink;