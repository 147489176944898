import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: 10,
    marginBottom: 12,
  },
}));

const UserAvatar = ({ src }) => {
  const classes = useStyles();

  return <Avatar src={src} className={classes.large} />;
};

export default UserAvatar;
