export const setScreenType = 'SET_SCREEN_OF_PAGE';
export const setSearchInputType = 'SET_SEARCH_INPUT';
export const setUserConfirmed = 'SET_USER_CONFIRMED';
export const setUserInformation = 'SET_USER_INFORMATION';
export const setUserSignOut = 'SET_USER_SIGN_OUT';
export const setBackgroundPhoto = 'SET_BACKGROUND_PHOTO';
export const setPhotosFromCropper = 'SET_PHOTOS_FROM_CROPPER';
export const listOfTimelinesPhotos = 'LIST_OF_TIME_LINES_PHOTOS';
export const addRelationsMember = 'ADD_RELATIONS_MEMBER';
export const setConnectedGraves = 'SET_CONNECTED_GRAVES';
export const chooseGraveFromSearch = 'CHOOSE_GRAVE_FROM_SEARCH';
export const addRelationsToGrave = 'ADD_RELATIONS_TO_GRAVE';
export const addCommentToGrave = 'ADD_COMMENT_TO_GRAVE';
export const setCommentOnToggle = 'SET_COMMENT_ON_TOOGLE';
export const setGraveArray = 'SET_GRAVE_ARRAY';
export const availableSearchCount = 'SET_AVAILABLE_SEARCH_COUNT';
export const setRelationType = 'SET_RELATION_REQUEST';
export const setSectionEdit = 'SET_SECTION_EDIT';
export const setAuthForm = 'SET_AUTH_FORM';
export const setStepRegist = 'SET_STEP_REGISTRATION';
export const setCheckedTerms = 'SET_TERMS_CONDITIONS_CHECKED';
export const addGraveAdminType = 'ADD_GRAVE_ADMIN';
export const invitationToken = 'INVITATION-TOKEN';
export const invitationTokenErrorMessage = 'INVITATION-TOKEN-ERROR-MESSAGE';
export const invitationTokenData = 'INVITATION-TOKEN-Data';
export const subscriptionCount = 'SUBSCRIPTION-COUNT';
export const subscriptionTotalPrice = 'SUBSCRIPTION-TOTAL-PRICE';
export const vippsAgreementData = 'VIPPS-AGREEMENT-DATA';
export const vippsChargedData = 'VIPPS-CHARGED-DATA';
export const setSubscriptionValidation = 'SET-SUBSCRIPTION-VALIDATION';
export const currentSubscriptionData = 'CURRENT-SUBSCRIPTION-DATA';
export const disablePublishBtn = 'SET_PUBLISH_BTN';
export const uploadingMemory = 'UPLOADING_MEMORY';
export const setSearchInputClick = 'SET_SEARCH_INPUT_CLICK';

//---------------changes grave photos comments and replies logics---------------------
export const addCommentToGravePhoto = 'ADD_COMMENT_TO_GRAVE_PHOTO';
export const editCommentToGravePhoto = 'EDIT_COMMENT_TO_GRAVE_PHOTO';
export const removeCommentFromGravePhoto = 'REMOVE_COMMENT_FROM_GRAVE_PHOTO';
export const addReplyToCommentToGravePhoto =
  'ADD_REPLY_TO_COMMENT_TO_GRAVE_PHOTO';
export const editReplyToCommentToGravePhoto =
  'EDIT_REPLY_TO_COMMENT_TO_GRAVE_PHOTO';
export const removeReplyFromCommentToGravePhoto =
  'REMOVE_REPLY_FROM_COMMENT_TO_GRAVE_PHOTO';

//----------------------changes to virtues logics-------------------------------------
export const addVirtueToGrave = 'ADD_VIRTUE_TO_GRAVE';
export const editVirtueOnGrave = 'EDIT_VIRTUE_ON_GRAVE';
export const removeVirtueFromGrave = 'REMOVE_VIRTUE_FROM_GRAVE';

//--------------------changes to grave memories logics---------------------------------
export const addMemoryToGrave = 'ADD_MEMORY_TO_GRAVE';
export const editMemoryOnGrave = 'EDIT_MEMORY_ON_GRAVE';
export const removeMemoryFromGrave = 'REMOVE_MEMORY_FROM_GRAVE';
export const addCommentToMemoryOnGrave = 'ADD_COMMENT_TO_MEMORY';
export const editCommentToMemoryOnGrave = 'EDIT_COMMENT_TO_MEMORY';
export const removeCommentFromMemoryOnGrave = 'REMOVE_COMMENT_FROM_MEMORY';
export const addReplyToCommentOnGrave = 'ADD_REPLY_TO_COMMENT';
export const removeReplyFromCommentOnGrave = 'REMOVE_REPLY_FROM_COMMENT';
export const editReplyToCommentOnGrave = 'EDIT_REPLY_TO_COMMENT';

//----------------------changes to notifications logics---------------------------------
export const setUserNotifications = 'SET_USER_NOTIFICATIONS';
export const updateUserNotificationRead = 'SET_USER_NOTIFICATION_READ';
export const setNotificationChannelSubscribed =
  'SET_NOTIFICATION_CHANNEL_SUBSCRIBED';
export const setActionCableConsumer = 'SET_ACTION_CABLE_CONSUMER';
export const setUserNotificationRequestStatus =
  'SET_USER_NOTIFICATION_REQUEST_STATUS';

export const userReadNotifications = 'SET_USER_READ_NOTIFICATIONS';
export const userUnReadNotifications = 'SET_USER_UNREAD_NOTIFICATIONS';
export const setActiveSubscription = 'SET_ACTIVE_SUBSCRIPTION';
export const setProfilePhoto = 'SET_PROFILE_PHOTO';
export const setGraveUsers = 'SET_GRAVE_USERS';
export const setGraveInvitations = 'SET_GRAVE_INVITATIONS';
export const setProfilePhotoList = 'SET_PROFILE_PHOTO_LIST';
export const setUpdateGrave = 'SET_UPDATE_GRAVE';
export const setListOfCountries = 'SET_LIST_OF_COUNTRIES';
export const setGravesFromApi = 'SET_GRAVES_FROM_API';
export const setIdFromSignIn = 'SET_ID_FROM_SIGN_IN';
export const setPhotoForCropping = 'SET_PHOTO_FOR_CROPPING';
export const setProfilePhotoForCropping = 'SET_PROFILE_PHOTO_FOR_CROPPING';
export const setDeathCertificationForUpload =
  'SET_DEATH_CERTIFICATE_FOR_UPLOAD';
export const setGraveWorkplaces = 'SET_GRAVE_WORKPLACES';
export const setLinkedGraves = 'SET_LINKED_GRAVES';
export const setGraveInterests = 'SET_GRAVE_INTERESTS';
export const setGravePassion = 'SET_GRAVE_PASSION';
export const setLifeEventsPhoto = 'SET_LIFE_EVENTS_PHOTO';
export const setLifeEventsVideo = 'SET_LIFE_EVENTS_VIDEO';

export const startRequest = 'START_REQUEST';
export const finishRequest = 'FINISH_REQUEST';
export const setProducts = 'SET_PRODUCTS';
export const setPlan = 'SET_PLAN';

export const setCurrentUserInRelationQueue =
  'SET_CURRENT_USER_IN_RELATION_QUEUE';
export const setCurrentUserInClaimQueue = 'SET_CURRENT_USER_IN_CLAIM_QUEUE';
export const updateUserNotificationButton = 'UPDATE_USER_NOTIFICATION_BUTTON';

// --------------------------- User Profile --------------------------------//
export const addUserWallQuestion = 'ADD_USER_WALL_QUESTION';
export const initialiseUserWallQuestions = 'INITIALISE_USER_WALL_QUESTION';
export const editUserWallQuestion = 'EDIT_USER_WALL_QUESTION';
export const deleteUserWallQuestion = 'DELETE_USER_WALL_QUESTION';
export const addUserWallAnswer = 'ADD_USER_WALL_ANSWER';
export const removeUserWallAnswer = 'REMOVE_USER_WALL_ANSWER';
export const editUserWallAnswer = 'EDIT_USER_WALL_ANSWER';

export const updateSearchGraveObject = 'UPDATE_GRAVE_SEARCH_OBJECT';
export const returnPage = 'RETURN_PAGE';
export const updateUser = 'UPDATE_USER_SUBSCRIPTION';
export const paymentMethods = 'PAYMENT_METHODS';
export const vippsPaymentMethods = 'VIPPS_PAYMENT_METHODS';
export const currentSubscriptionPlan = 'CURRENT_SUBSCRIPTION_PLAN';
export const currentGravePhotoIndex = 'CURRENT_GRAVE_PHOTO_Index';
export const editGravePhotoIndex = 'EDIT_GRAVE_PHOTO_Index';
export const currentDeceasedTabIndex = 'CURRENT_DECEASED_TAB_Index';
export const currentEventPhotoIndex = 'CURRENT_EVENT_PHOTO_Index';
export const currentEventVideoIndex = 'CURRENT_EVENT_VIDEO_Index';
export const editEventPhotoIndex = 'EDIT_EVENT_PHOTO_Index';
export const lifeEvents = 'LIFE_EVENTS';
export const eventYearIndex = 'EVENT_YEAR_INDEX';
export const selectedEventYear = 'SELECTED_EVENT_YEAR';
export const selectedEvent = 'SELECTED_EVENT';
export const openSelectedEvent = 'OPEN_SELECTED_EVENT';
export const paymentMethod = 'PAYMENT_METHOD';
export const selectedVippsMethod = 'SELECTED_VIPPS_METHOD';
export const showPaymentCard = 'SHOW_PAYMENT_CARD';
export const showVippsPaymentMethod = 'SHOW_VIPPS_PAYMENT_METHOD';
export const selectedCardMethod = 'SELECTED_CARD_METHOD';
export const userBillingInformation = 'USER_BILLING_INFORMATION';
export const eventMonthIndex = 'EVENT_MONTH_INDEX';
export const vippsPhoneNumber = 'VIPPS_PHONE_NUMBER';

export const setScreenRedux = (screen) => {
  return {
    type: setScreenType,
    screen: screen,
  };
};

export const setLifeEvents = (data) => {
  return {
    type: lifeEvents,
    payload: data,
  };
};

export const setEventYearIndex = (data) => {
  return {
    type: eventYearIndex,
    payload: data,
  };
};

export const setEventMonthIndex = (data) => {
  return {
    type: eventMonthIndex,
    payload: data,
  };
};

export const setSelectedEventYear = (data) => {
  return {
    type: selectedEventYear,
    payload: data,
  };
};

export const setSelectedEvent = (data) => {
  return {
    type: selectedEvent,
    payload: data,
  };
};

export const setOpenSelectedEvent = (data) => {
  return {
    type: openSelectedEvent,
    payload: data,
  };
};

export const setSearchInput = (text) => {
  return {
    type: setSearchInputType,
    searchInput: text,
  };
};

export const searchInputClick = (data) => {
  return {
    type: setSearchInputClick,
    payload: data,
  };
};

export const setConfirmSignIn = () => {
  return {
    type: setUserConfirmed,
  };
};

export const setInformationAboutUser = (data) => {
  return {
    type: setUserInformation,
    userInfo: data.user,
  };
};

export const setConfirmSignOut = () => {
  return {
    type: setUserSignOut,
  };
};

export const saveBackgroundPhoto = (data) => {
  return {
    type: setBackgroundPhoto,
    backgroundPhoto: data,
  };
};

export const saveListOfPhotosFromCropper = (data) => {
  return {
    type: setPhotosFromCropper,
    listOfPhotos: data,
  };
};

export const setListOfTimelinesPhotos = (data) => {
  return {
    type: listOfTimelinesPhotos,
    payload: data,
  };
};

export const addRelationsMemberToGrave = (data) => {
  return {
    type: addRelationsMember,
    members: data,
  };
};

export const setRelationRequest = () => {
  return {
    type: setRelationType,
  };
};

export const goToChosenGrave = (data) => {
  return {
    type: chooseGraveFromSearch,
    grave: data,
  };
};

export const setGraveRelationsToGrave = (data) => {
  return {
    type: addRelationsToGrave,
    relations: data,
  };
};

export const addComment = (data) => {
  return {
    type: addCommentToGrave,
    comment: data,
  };
};

export const addCommentOnToggle = (data) => {
  return {
    type: setCommentOnToggle,
    comment: data,
  };
};

export const disableMemoryPublishBtn = (data) => {
  return {
    type: disablePublishBtn,
    payload: data,
  };
};

export const setUploadingMemory = (data) => {
  return {
    type: uploadingMemory,
    payload: data,
  };
};

export const setGraveArrayRedux = (graveArr) => {
  return {
    type: setGraveArray,
    graveArr,
  };
};

export const setAvailableSearchCount = (count) => {
  return {
    type: availableSearchCount,
    count: count,
  };
};

export const addMemory = (data) => {
  return {
    type: addMemoryToGrave,
    payload: data,
  };
};

export const addQuestion = (data) => {
  return {
    type: addUserWallQuestion,
    data: data,
  };
};

export const intialiseQuestions = (data) => {
  return {
    type: initialiseUserWallQuestions,
    data: data,
  };
};

export const editQuestion = (data) => {
  return {
    type: editUserWallQuestion,
    data: data,
  };
};

export const deleteQuestion = (data) => {
  return {
    type: deleteUserWallQuestion,
    data: data,
  };
};

export const addUserWallComment = (data) => {
  return {
    type: addUserWallAnswer,
    data: data,
  };
};

export const deleteUserWallComment = (data) => {
  return {
    type: removeUserWallAnswer,
    data: data,
  };
};

export const editUserWallComment = (data) => {
  return {
    type: editUserWallAnswer,
    data: data,
  };
};

export const editMemory = (data) => {
  return {
    type: editMemoryOnGrave,
    payload: data,
  };
};

export const removeMemory = (memoryId) => {
  return {
    type: removeMemoryFromGrave,
    payload: memoryId,
  };
};

export const addCommentToMemory = (memoryId, data) => {
  return {
    type: addCommentToMemoryOnGrave,
    payload: {
      memoryId,
      data,
    },
  };
};

export const editCommentToMemory = (memoryId, data) => {
  return {
    type: editCommentToMemoryOnGrave,
    payload: {
      memoryId,
      data,
    },
  };
};

export const deleteCommentFromMemory = (memoryId, commentId) => {
  return {
    type: removeCommentFromMemoryOnGrave,
    payload: {
      memoryId,
      commentId,
    },
  };
};

export const addReplyToComment = (commentId, memoryId, data) => {
  return {
    type: addReplyToCommentOnGrave,
    payload: {
      commentId,
      memoryId,
      data,
    },
  };
};

export const editReplyToComment = (commentId, memoryId, data) => {
  return {
    type: editReplyToCommentOnGrave,
    payload: {
      commentId,
      memoryId,
      data,
    },
  };
};

export const deleteReplyFromComment = (commentId, memoryId, replyId) => {
  return {
    type: removeReplyFromCommentOnGrave,
    payload: {
      commentId,
      memoryId,
      replyId,
    },
  };
};

export const addProfilePhoto = (data) => {
  return {
    type: setProfilePhoto,
    photo: data,
  };
};

export const addProfilePhotoList = (data) => {
  return {
    type: setProfilePhotoList,
    photos: data,
  };
};

export const addCommentToGravePhotoRedux = (
  photoId,
  photoCategory,
  comment
) => {
  return {
    type: addCommentToGravePhoto,
    payload: {
      photoId,
      photoCategory,
      comment,
    },
  };
};

export const editCommentToGravePhotoRedux = (
  photoId,
  photoCategory,
  comment
) => {
  return {
    type: editCommentToGravePhoto,
    payload: {
      photoId,
      photoCategory,
      comment,
    },
  };
};

export const removeCommentFromGravePhotoRedux = (
  photoId,
  photoCategory,
  comment
) => {
  return {
    type: removeCommentFromGravePhoto,
    payload: {
      photoId,
      photoCategory,
      comment,
    },
  };
};

export const addReplyToCommentToGravePhotoRedux = (
  photoId,
  photoCategory,
  commentId,
  reply
) => {
  return {
    type: addReplyToCommentToGravePhoto,
    payload: {
      photoId,
      photoCategory,
      commentId,
      reply,
    },
  };
};

export const editReplyToCommentToGravePhotoRedux = (
  photoId,
  photoCategory,
  commentId,
  reply
) => {
  return {
    type: editReplyToCommentToGravePhoto,
    payload: {
      photoId,
      photoCategory,
      commentId,
      reply,
    },
  };
};

export const removeReplyFromCommentToGravePhotoRedux = (
  photoId,
  photoCategory,
  commentId,
  reply
) => {
  return {
    type: removeReplyFromCommentToGravePhoto,
    payload: {
      photoId,
      photoCategory,
      commentId,
      reply,
    },
  };
};

export const makeUpdateGrave = (data) => {
  return {
    type: setUpdateGrave,
    grave: data,
  };
};

export const getListOfCountries = (data) => {
  return {
    type: setListOfCountries,
    countries: data.countries,
  };
};

export const setProductInfo = (data) => {
  return {
    type: setProducts,
    products: data,
  };
};

export const getGravesFromApi = (data) => {
  return {
    type: setGravesFromApi,
    graves: data,
  };
};

export const setUserId = (id) => {
  return {
    type: setIdFromSignIn,
    id: id,
  };
};

export const addPhotoForCropping = (photo) => {
  return {
    type: setPhotoForCropping,
    payload: photo,
  };
};

export const addLifeEventPhotos = (photo) => {
  return {
    type: setLifeEventsPhoto,
    payload: photo,
  };
};

export const addLifeEventVideos = (video) => {
  return {
    type: setLifeEventsVideo,
    payload: video,
  };
};

export const addProfilePhotoForCropping = (photo) => {
  return {
    type: setProfilePhotoForCropping,
    payload: photo,
  };
};

export const addDeathCertificationForUpload = (photo) => {
  return {
    type: setDeathCertificationForUpload,
    payload: photo,
  };
};

export const setConnectedGravesRedux = (connected_graves) => {
  return {
    type: setConnectedGraves,
    payload: connected_graves,
  };
};

export const setGraveWorkplacesRedux = (workplaces) => {
  return {
    type: setGraveWorkplaces,
    payload: workplaces,
  };
};

export const setLinkedGravesRedux = (linkedGraves) => {
  return {
    type: setLinkedGraves,
    payload: linkedGraves,
  };
};

export const setGraveInterestsRedux = (interests) => {
  return {
    type: setGraveInterests,
    payload: interests,
  };
};

export const setGravePassionRedux = (passion) => {
  return {
    type: setGravePassion,
    payload: passion,
  };
};

export const setGraveUsersList = (graveUsers) => {
  return {
    type: setGraveUsers,
    payload: graveUsers,
  };
};

export const setGraveInvitationsList = (graveInvitations) => {
  return {
    type: setGraveInvitations,
    payload: graveInvitations,
  };
};

export const updateCurrentUserInRelationQueue = (data) => {
  return {
    type: setCurrentUserInRelationQueue,
    payload: data,
  };
};

export const updateCurrentUserInClaimQueue = () => {
  return {
    type: setCurrentUserInClaimQueue,
  };
};

export const setUserNotificationsRedux = (notifications) => {
  return {
    type: setUserNotifications,
    payload: notifications,
  };
};

export const setUserReadNotifications = (notifications) => {
  return {
    type: userReadNotifications,
    payload: notifications,
  };
};

export const setUserUnReadNotifications = (notifications) => {
  return {
    type: userUnReadNotifications,
    payload: notifications,
  };
};

export const setSubscription = (subscription) => {
  return {
    type: setActiveSubscription,
    payload: subscription,
  };
};

export const setNotificationChannelSubscribedRedux = (val) => {
  return {
    type: setNotificationChannelSubscribed,
    payload: val,
  };
};

export const setCableConsumer = (val) => {
  return {
    type: setActionCableConsumer,
    payload: val,
  };
};

export const setUserNotificationReadRedux = () => {
  return {
    type: updateUserNotificationRead,
    // payload: notificationId,
  };
};

export const setUserNotificationButton = (notification) => {
  return {
    type: updateUserNotificationButton,
    payload: notification,
  };
};

export const setUserNotificationRequestStatusRedux = (
  notificationId,
  requestStatus
) => {
  return {
    type: setUserNotificationRequestStatus,
    payload: {
      notificationId,
      requestStatus,
    },
  };
};

export const setVirtuesRedux = (virtues) => {
  return {
    type: addVirtueToGrave,
    payload: virtues,
  };
};

export const editVirtueRedux = (virtue) => {
  return {
    type: editVirtueOnGrave,
    payload: virtue,
  };
};

export const deleteVirtueRedux = (virtueId) => {
  return {
    type: removeVirtueFromGrave,
    payload: virtueId,
  };
};

export const spinnerStart = () => {
  return {
    type: startRequest,
  };
};

export const spinnerFinish = () => {
  return {
    type: finishRequest,
  };
};

export const setSectionEditRedux = (value) => {
  return {
    type: setSectionEdit,
    payload: value,
  };
};

export const setAuthFormRedux = (field) => {
  return {
    type: setAuthForm,
    payload: field,
  };
};

export const setStepRegistration = (step) => {
  return {
    type: setStepRegist,
    payload: step,
  };
};

export const setCheckedTerms_Conditions = () => {
  return {
    type: setCheckedTerms,
  };
};

export const addGraveAdmin = (data) => {
  return {
    type: addGraveAdminType,
    payload: data,
  };
};

export const updateSearchGrave = (data) => {
  return {
    type: updateSearchGraveObject,
    payload: data,
  };
};

export const setSelectedPlan = (data) => {
  return {
    type: setPlan,
    selectedPlan: data,
  };
};

export const setCurrentSubscriptionPlan = (data) => {
  return {
    type: currentSubscriptionPlan,
    payload: data,
  };
};

export const setReturnPage = (payload) => {
  return {
    type: returnPage,
    payload,
  };
};

export const updateUserSubscription = (payload) => {
  return {
    type: updateUser,
    payload,
  };
};

export const setPaymentMethods = (data) => {
  return {
    type: paymentMethods,
    payload: data,
  };
};

export const setVippsPaymentMethods = (data) => {
  return {
    type: vippsPaymentMethods,
    payload: data,
  };
};

export const setPaymentMethod = (data) => {
  return {
    type: paymentMethod,
    payload: data,
  };
};

export const setVippsPhoneNumber = (data) => {
  return {
    type: vippsPhoneNumber,
    payload: data,
  };
};

export const setSelectedVippsMethod = (data) => {
  return {
    type: selectedVippsMethod,
    payload: data,
  };
};

export const setShowCardMethod = (data) => {
  return {
    type: showPaymentCard,
    payload: data,
  };
};

export const setShowVippsPaymentMethod = (data) => {
  return {
    type: showVippsPaymentMethod,
    payload: data,
  };
};

export const setSelectedCardMethod = (data) => {
  return {
    type: selectedCardMethod,
    payload: data,
  };
};

export const setBillingInformation = (data) => {
  return {
    type: userBillingInformation,
    payload: data,
  };
};

export const setCurrentGravePhotoIndex = (data) => {
  return {
    type: currentGravePhotoIndex,
    payload: data,
  };
};

export const setEditGravePhotoIndex = (data) => {
  return {
    type: editGravePhotoIndex,
    payload: data,
  };
};

export const setEventPhotoIndex = (data) => {
  return {
    type: currentEventPhotoIndex,
    payload: data,
  };
};

export const setEventVideoIndex = (data) => {
  return {
    type: currentEventVideoIndex,
    payload: data,
  };
};

export const setEditEventPhotoIndex = (data) => {
  return {
    type: editEventPhotoIndex,
    payload: data,
  };
};

export const setCurrentDeceasedTabIndex = (data) => {
  return {
    type: currentDeceasedTabIndex,
    payload: data,
  };
};

export const subscriptionInvitationToken = (data) => {
  return {
    type: invitationToken,
    payload: data,
  };
};

export const setInvitationTokenErrorMessage = (data) => {
  return {
    type: invitationTokenErrorMessage,
    payload: data,
  };
};

export const setSubscriptionInvitationData = (data) => {
  return {
    type: invitationTokenData,
    payload: data,
  };
};

export const setSubscriptionCount = (data) => {
  return {
    type: subscriptionCount,
    payload: data,
  };
};

export const setCurrentSubscriptionData = (data) => {
  return {
    type: currentSubscriptionData,
    payload: data,
  };
};

export const subscriptionValidation = (data) => {
  return {
    type: setSubscriptionValidation,
    payload: data,
  };
};

export const setSubscriptionTotalPrice = (data) => {
  return {
    type: subscriptionTotalPrice,
    payload: data,
  };
};

export const setVippsAgreementData = (data) => {
  return {
    type: vippsAgreementData,
    payload: data,
  };
};

export const setVippsChargedData = (data) => {
  return {
    type: vippsChargedData,
    payload: data,
  };
};
