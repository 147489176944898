import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import GraveConnectionsModal from '../GraveConnectionsModal/GraveConnectionsModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: '80%',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: '8px',
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
      borderRadius: '0px',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ConnectGraveModal = ({ onClose, onOpen, grave, user }) => {
  const classes = useStyles();
  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <GraveConnectionsModal
          grave={grave}
          user={user}
          handleCloseGraveConnectionDialog={onClose}
        />
      </div>
    </Modal>
  );
};

export default ConnectGraveModal;
