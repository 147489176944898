import React from 'react'
import './Registration.css';import CreateMembership from '../containers/Auth/CreateMembership';

const Registration = (props) => {

  return (
    <div className='sign-in-page'>
      <CreateMembership />
    </div>
  )
};

export default Registration;