import AudioIcon from "../../../img/AudioIcon.svg";
import React, { useRef } from 'react'
import showUserNotification from "../../../components/UserNotification/showUserNotification";

const AudioPost = (props) => {
  const selectAudioFile  = (e) => {
     if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener("load", async () => {
        if (file.size / (1024 * 1024) < 5) {
          props.setAudio({ file: file, url: reader.result });
          props.setCurrentFileType('audio');
        } else {
          showUserNotification(
            "This file is too big! It should be less than 5MB!",
            "warning"
          );
        }
      });
    }
  }

  const inputFile = useRef(null);

  const handleAudioSelection = () => {
    inputFile.current.click();
  };

  return(
    <>
      <img src={AudioIcon} onClick={handleAudioSelection} className="ask-question-input-group_icons" alt="audio-icon"/>
      <input
        style={{ display: "none" }}
        ref={inputFile}
        type="file"
        accept="audio/*"
        onChange={selectAudioFile}
      />
    </>
  )
  
}

export default AudioPost;