import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import closeIcon from '../../../../img/closeIcon.svg';
import './Modal.css';

const appearance = {
  theme: 'night',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '700px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '4px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const PopupModal = ({ open, handleClose, plan }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <button className='close-btn' onClick={() => handleClose()}>
          <img src={closeIcon} alt='close' />
        </button>
        <div className='modal-heading'>
          {plan?.name ? `${plan.name} aktivert` : ''}
        </div>
        <div className='details'>
          Abonnementet er aktivert. Du kan til enhver tid se eller administrere ditt{' '}
          {plan && plan.name ? plan.name : ''}-medlemskap på{' '}
          <Link to='/settings/subscriptions' style={{ color: '#3CA0FF' }}>
            medlemssiden
          </Link>{' '}
          din.
        </div>
        <div className='details'>
          Størst av alt er kjærligheten   
          <Link to='#' style={{ color: '#3CA0FF' }}>
              Takk for at du er her
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;
