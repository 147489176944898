import React, { useState, useEffect } from 'react';
import styles from './CardPaymentMethod.module.css';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import FreePlanContainer from '../FreePlanContainer/FreePlanContainer';
import AddNewCard from '../AddNewCard/AddNewCard';
import CardPayment from '../CardPayment/CardPayment';
import BillingInformation from '../BillingInformation/BillingInformation';
import SelectedPlan from '../SelectedPlan/SelectedPlan';
import FeatureList from '../FeatureList/FeatureList';
import { useSelector, useDispatch } from 'react-redux';
import {
  setInformationAboutUser,
  setPaymentMethods,
  updateUserSubscription,
  setSelectedPlan,
  setReturnPage,
  setPaymentMethod,
  setShowCardMethod,
  setSelectedCardMethod,
  setBillingInformation,
  setVippsPaymentMethods,
  setSelectedVippsMethod,
  setShowVippsPaymentMethod,
  setVippsPhoneNumber,
} from '../../../../redux/actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CONSTANTS } from '../../../../service/constants';
import { request } from '../../../../service/request';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import ProcessModal from '../ProcessModal';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const DEFAULT_PAYMENT_METHOD =
  process.env.REACT_APP_ENV === 'production' ? 'card' : 'vipps';

const appearance = {
  theme: 'night',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, appearance);

const CardPaymentMethod = ({
  isFreePlan,
  defaultPlan,
  currentSubscriptionPlan,
  plans,
  currentSubscription,
  selectedPlan,
  selectedPaymentMethod,
  countries,
  isCardValue,
  handelCardInputs,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submit, setSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [disable, setDisable] = useState(true);

  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods || null
  );
  const openPaymentMethod = useSelector(
    (state) => state.paymentMethods.selectedMethod
  );
  const billingInformation = useSelector(
    (state) => state.paymentMethods.billingInformation
  );
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const showCard = useSelector((state) => state.paymentMethods.showCard);
  const returnPage = useSelector((state) => state.returnPage);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const handleShowPayment = (method) => dispatch(setPaymentMethod(method));
  const handleShowCard = (value) => dispatch(setShowCardMethod(value));
  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleProductChosen = (plan) => {
    if (plan.key === 'free') {
      history.push(`/plans/payment`);
    } else {
      history.push(
        `/plans/payment?payment_method_type=${selectedPaymentMethod}`
      );
    }
    dispatch(setSelectedPlan(plan));
  };

  useEffect(() => {
    if (
      !isEmpty(currentSubscriptionPlan) &&
      !isEmpty(selectedPlan) &&
      !isEmpty(openPaymentMethod)
    ) {
      setDisable(false);
    } else if (!isEmpty(selectedPlan) && !isEmpty(openPaymentMethod)) {
      setDisable(false);
    } else if (
      !isEmpty(currentSubscriptionPlan) &&
      !isEmpty(openPaymentMethod)
    ) {
      setDisable(false);
    } else if (selectedPlan.key !== 'free') {
      setDisable(true);
    }
  }, [openPaymentMethod]);

  useEffect(() => {
    if (!isEmpty(selectedPlan)) {
      if (!isEmpty(currentSubscriptionPlan)) {
        const isFreePlan = selectedPlan.key === 'free';
        if (isFreePlan) {
          setDisable(false);
        } else {
          const areAllValuesFilled = Object.values(billingInformation).every(
            (value) => value !== ''
          );
          const newSubscription = isCardValue && areAllValuesFilled;
          setDisable(
            newSubscription || !isEmpty(openPaymentMethod) ? false : true
          );
        }
      } else if (selectedPlan.key === 'free') {
        setDisable(false);
      } else if (!isEmpty(openPaymentMethod)) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else if (!isEmpty(currentSubscriptionPlan)) {
      const isFreePlan = currentSubscriptionPlan.key === 'free';
      setDisable(currentSubscriptionPlan.id ? true : !isFreePlan);
    } else {
      setDisable(false);
    }
  }, [selectedPlan, currentSubscriptionPlan, plans]);

  // -------Fetch user data -------- //

  useEffect(() => {
    if (selectedPaymentMethod === 'card' && countries.length > 0) {
      request(`/users/${userSlug}`)
        .then((res) => {
          const currentUser = res.data.user;
          setUser(currentUser);
          dispatch(setInformationAboutUser(res.data));
          if (isEmpty(billingInformation)) {
            if (currentUser.country_id && countries) {
              const country = countries.find(
                (country) => country.id === currentUser.country_id
              );
              if (!isEmpty(country)) {
                const userPhoneNumber = `${country.dial_code}${currentUser.phone_number}`;
                dispatch(
                  setBillingInformation({
                    ...billingInformation,
                    fullName: currentUser?.full_name
                      ? currentUser.full_name
                      : '',
                    billingAddress: currentUser?.address
                      ? currentUser.address
                      : '',
                    email: currentUser?.email ? currentUser.email : '',
                    country_id: country.id,
                    postalCode: '',
                    phoneNumber: userPhoneNumber,
                  })
                );
              } else {
                dispatch(
                  setBillingInformation({
                    ...billingInformation,
                    fullName: currentUser?.full_name
                      ? currentUser.full_name
                      : '',
                    billingAddress: currentUser?.address
                      ? currentUser.address
                      : '',
                    email: currentUser?.email ? currentUser.email : '',
                    postalCode: '',
                    phoneNumber: '',
                    country_id: countries[0].id,
                  })
                );
              }
            } else {
              dispatch(
                setBillingInformation({
                  ...billingInformation,
                  fullName: currentUser?.full_name ? currentUser.full_name : '',
                  billingAddress: currentUser?.address
                    ? currentUser.address
                    : '',
                  email: currentUser?.email ? currentUser.email : '',
                  postalCode: '',
                  phoneNumber: '',
                  country_id: countries[0].id,
                })
              );
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedPaymentMethod, countries]);

  const handleServerResponse = (response) => {
    if (response.error) {
      handleCloseModal();
      setOpenErrorModal(true);
    } else {
      const url = returnPage.url || '/settings/subscription-invitations';
      if (url) {
        dispatch(setShowCardMethod(false));
        dispatch(setShowVippsPaymentMethod(false));
        dispatch(setBillingInformation({}));
        dispatch(setPaymentMethod({}));
        dispatch(setSelectedVippsMethod({}));
        dispatch(setVippsPaymentMethods([]));
        dispatch(setPaymentMethods([]));
        dispatch(setSelectedCardMethod(DEFAULT_PAYMENT_METHOD));
        dispatch(setReturnPage(null));
        dispatch(setVippsPhoneNumber(''));
      }
      history.push(url, { show: 1 });
    }
  };

  const createSubscription = () => {
    const requestPayload = {
      subscription: {
        product_id: defaultPlan.id,
        price: defaultPlan.price || 0,
        user_count: 0,
      },
    };
    fetch(`${CONSTANTS.baseUrl}/api/subscriptions`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(updateUserSubscription(true));
        handleServerResponse(data);
      })
      .catch((error) => {
        handleCloseModal();
        setOpenErrorModal(true);
      });
  };

  const handleUpdateSubscribe = async () => {
    let requestPayload = {};
    if (
      !isEmpty(currentSubscription) &&
      currentSubscription.product.id === selectedPlan.id
    ) {
      requestPayload = {
        subscription: {
          payment_method_id: openPaymentMethod?.id,
          price: amount + currentSubscription.price,
          user_count: parseInt(
            subscriptionCount + currentSubscription.user_count
          ),
          product_id: selectedPlan.id,
        },
      };
      return fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      )
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          if (json.error) {
            handleCloseModal();
            setErrorMessage(json.message);
            setOpenErrorModal(true);
          } else {
            dispatch(updateUserSubscription(true));
            handleServerResponse(json);
          }
        })
        .catch((error) => {
          handleCloseModal();
          setErrorMessage(error.data);
          setOpenErrorModal(true);
        });
    } else if (!isEmpty(selectedPlan)) {
      requestPayload = {
        subscription: {
          payment_method_id: openPaymentMethod?.id,
          price: amount,
          user_count: parseInt(subscriptionCount),
          product_id: selectedPlan.id,
        },
      };
      return fetch(`${CONSTANTS.baseUrl}/api/subscriptions.json`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      })
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          if (json.error) {
            handleCloseModal();
            setErrorMessage(json.message);
            setOpenErrorModal(true);
          } else {
            dispatch(updateUserSubscription(true));
            handleServerResponse(json);
          }
        })
        .catch((error) => {
          handleCloseModal();
          setErrorMessage(error.data);
          setOpenErrorModal(true);
        });
    } else {
      showUserNotification('Velg en plan før du abonnerer', 'error');
    }
  };

  const handleStripeCharged = async () => {
    let requestPayload = {};
    if (openPaymentMethod.id) {
      requestPayload = {
        payment: {
          payment_method_id: openPaymentMethod?.stripe_id,
          price: amount,
          user_count: parseInt(subscriptionCount),
          product_id: selectedPlan.id,
        },
        payment_method: {
          stripe_id: openPaymentMethod.stripe_id,
        },
      };
    } else {
      requestPayload = {
        payment: {
          payment_intent_id: openPaymentMethod?.id,
        },
      };
    }

    return fetch(`${CONSTANTS.baseUrl}/api/stripe/payments.json`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    })
      .then(function (confirmResult) {
        return confirmResult.json();
      })
      .then((json) => {
        if (json.success) {
          handleUpdateSubscribe();
        }
        if (!json.success) {
          handleCloseModal();
          setErrorMessage(json.message);
          setOpenErrorModal(true);
        }
      })
      .catch((error) => {
        handleCloseModal();
        setErrorMessage(error.data);
        setOpenErrorModal(true);
      });
  };

  const handleSubscribe = () => {
    if (isFreePlan) {
      if (!isEmpty(currentSubscription) && currentSubscription.key !== 'free') {
        setOpenConfirmModal(true);
      } else {
        createSubscription();
        setSubmit(false);
      }
    } else if (!isEmpty(openPaymentMethod)) {
      if (!isEmpty(selectedPlan)) {
        handleShowModal();
        handleStripeCharged();
      } else {
        showUserNotification('Velg en plan før du abonnerer', 'error');
      }
    } else {
      handleShowModal();
      setSubmit(true);
    }
  };

  return (
    <div className={styles['payment-page-container']}>
      {defaultPlan && (
        <div className={styles['payment-container']}>
          {isFreePlan && defaultPlan && <FreePlanContainer />}
          {paymentMethods &&
            !isFreePlan &&
            paymentMethods.map((method) => (
              <PaymentMethods
                method={method}
                showPayment={
                  showCard ? false : method.id === openPaymentMethod.id
                }
                handleShowPayment={handleShowPayment}
                handleShowCard={handleShowCard}
                key={method.id}
              />
            ))}
          {!showCard && !isFreePlan && paymentMethods.length > 0 && (
            <AddNewCard
              handleShowCard={handleShowCard}
              handleShowPayment={handleShowPayment}
              showCard={showCard}
            />
          )}
          {showCard && !isFreePlan && (
            <div className='payment-details'>
              <div className={styles['card-information']}>
                <Elements stripe={stripePromise}>
                  {defaultPlan ? (
                    <CardPayment
                      chosenPlan={defaultPlan}
                      submit={submit}
                      setSubmit={setSubmit}
                      billingInformation={billingInformation}
                      handleCloseModal={handleCloseModal}
                      handelCardInputs={handelCardInputs}
                    />
                  ) : null}
                </Elements>
              </div>
              {!isEmpty(billingInformation) && (
                <BillingInformation
                  handleDisable={setDisable}
                  billingInformation={billingInformation}
                  isCardValue={isCardValue}
                  countries={countries}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div className={styles['selected-plan']}>
        <React.Fragment>
          <div className={styles['selected-plan-details']}>
            <SelectedPlan
              plans={plans}
              chosenPlan={defaultPlan}
              handleProductChosen={handleProductChosen}
              disable={disable}
              handleSubscribe={handleSubscribe}
              isFreePlan={isFreePlan}
            />
          </div>
          <FeatureList chosenPlan={defaultPlan} />
        </React.Fragment>
      </div>
      {openModal && (
        <ProcessModal
          open={openModal}
          message='Betaling pågår. Vennligst vent'
        />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
      {openConfirmModal && (
        <ConfirmModal
          isOpen={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          title={`Nedgradere ${currentSubscriptionPlan.name} til gratis plan`}
          onConfirm={createSubscription}
          disableBtn={() => setSubmit(false)}
        />
      )}
    </div>
  );
};

export default CardPaymentMethod;
