import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import X from '../../../../img/X.svg';
import Warning from '../../../../img/orange_warning.svg';

import styles from './ConformModal.module.css';
const appearance = {
  theme: 'night',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '32px',
    height: 'maxContent',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ConfirmModal = (props) => {
  const isOpen = props.isOpen;
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      // onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['downgrade-plan-prompt_body_subheading']}>
          {props.title}
        </div>
        <img
          src={X}
          onClick={() => props.onClose()}
          alt=''
          className={styles['downgrade-plan-prompt_cancel']}
        />
        <div className={styles['downgrade-plan-prompt_body_content']}>
          <span>Du nedgraderer nå til Fri plan</span>
          <ul>
            <li>Du har ti minner og fem daglige søk inkludert i medlemskapet</li>
            <li>Bekreft for å fortsette</li>
          </ul>
        </div>
        <div className={styles['btn-container']}>
          <div className={styles['btn-wrapper']}>
            <button
              className={styles['rootVerify']}
              onClick={() => {
                props.onConfirm();
                props.disableBtn();
              }}
            >
              Bekrefte
            </button>
            <button
              className={styles['rootDiscard']}
              onClick={() => props.onClose()}
            >
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
