import { Button, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import './VideoPost.css';
import { Dialog } from '@material-ui/core';
import YoutubeIcon from "../../../img/youtube.svg";
import X from '../../../img/X.svg';

const useStyle = makeStyles(() => ({
    rootCancel: {
        width: '180px',
        height: '55px',
        color: 'rgba(0, 0, 0, 0.87);',
        borderRadius: '10px',
        backgroundColor: '#f5f5f5',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
    },
    rootSave: {
        width: '180px',
        height: '55px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#FEA65A',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#FEA65A',
        }
    },
    rootSaveDisabled: {
        width: '180px',
        height: '55px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#F5F5F5',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px'
    },
    rootDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: "650px !important"
      }
    }
}))

const VideoPost = (props) => {
  const classes = useStyle();
    const [link, setLink] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const handleDialogClose = () => {
      setLink('');
      setOpenVideoModal(false)
    }

    const saveVideoComment = () => {
      props.setVideoLink(link);
      props.setCurrentFileType('video')
      handleDialogClose();
    }

    const handleOnLinkChange = (event) => {
      setLink(event.target.value);
    }

    useEffect(() => {
        if(ReactPlayer.canPlay(link)) {
            setIsValidUrl(true)
        } else {
            setIsValidUrl(false)
        }
    },[link]);

    return(
    <>
      <img
        src={YoutubeIcon}
        alt=""
        className="ask-question-input-group_icons"
        onClick={() => setOpenVideoModal(true)}
      />

      <Dialog open={openVideoModal} className={classes.rootDialog} onClose={handleDialogClose}>
        <img 
          loading='lazy' 
          src={X} 
          alt='x' 
          onClick={handleDialogClose}
          style={{width: "28px", marginLeft: "auto", padding: "15px 15px 0px 0px", cursor: "pointer"}}/>
        <div className='video-post'>
            <div className="video-post_header">
                <span>Videolink</span>
                <input type='text' placeholder='https://' value={link} onChange={handleOnLinkChange}/>
                <span className={link ? 'video-post_header-subtitle disabled' : 'video-post_header-subtitle'}>Du kan legge til videoer fra sider som <span>Youtube</span>, <span>Vimeo</span> og andre.</span>
            </div>
            {
                link &&
                <div className='video-post_header-image'>
                    { isValidUrl && <ReactPlayer url={link} height='200px' width='360px' light={true}/>}
                    { !isValidUrl && <span className="video-link-validation-error">Vennligst legg inn en gyldig link</span> }
                    <div className="video-post_header-image_buttons">
                        <Button className={classes.rootCancel} onClick={props.closeModal}>Avbryt</Button>
                        <Button disabled={!isValidUrl} className={isValidUrl ? classes.rootSave : classes.rootSaveDisabled} onClick={saveVideoComment}>Lagre</Button>
                    </div>
                </div>
            }
        </div>
      </Dialog>
    </>
    )
}

export default VideoPost;