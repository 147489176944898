import React from 'react';
import styles from './VippsAgreement.module.css';
import { useSelector } from 'react-redux';

const VippsAgreement = ({ agreement }) => {
  const membersCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  return (
    <div className={styles['agreement-wrapper']}>
      <div className={styles['detail']}>
        <div className={styles['title']}>Abonnementsplan:</div>
        <div className={styles['value']}>{agreement.productName}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>Totalpris:</div>
        <div className={styles['value']}>
          {agreement.pricing.amount} {agreement.pricing.currency}
        </div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>Medlemmer av abonnement:</div>
        <div className={styles['value']}>{membersCount}</div>
      </div>
      <div className={styles['buttons']}>
        <button className={styles['cancel']}>Cancel</button>
        <button className={styles['conform']}>Conform</button>
      </div>
    </div>
  );
};

export default VippsAgreement;
