import React, { useState } from 'react';
import styles from './LifeEvent.module.css';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EventImages from '../EventImages/EventImages';
import LifeEventDetails from '../LifeEventDetails/LifeEventDetails';
import circle from '../../../../../img/EventCircle.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEventYearIndex,
  setEventMonthIndex,
} from '../../../../../redux/actions';
import UpgradePlanModal from '../../../../../components/Modals/UpgradePlanModal';
import Tabs from './Tabs/Tabs';
import Event from './Event/Event';

const LifeEvent = ({
  year,
  eventsInYear,
  countries,
  index,
  handelSaveModal,
}) => {
  const dispatch = useDispatch();
  const eventYearIndex = useSelector((state) => state.grave.eventYearIndex);
  const eventMonthIndex = useSelector((state) => state.grave.eventMonthIndex);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const getMonthName = (monthIndex) => {
    const monthNames = [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ];
    return monthNames[monthIndex];
  };

  return (
    <div className={styles['event-container']}>
      <div
        className={styles['year-btn']}
        onClick={() => {
          if (eventYearIndex === index) {
            dispatch(setEventYearIndex(-1));
          } else {
            dispatch(setEventYearIndex(index));
          }
        }}
      >
        <button>{year}</button>
        {eventYearIndex === index ? <ExpandLess /> : <ExpandMore />}
      </div>
      {eventYearIndex === index && (
        <div className={styles['container-year']}>
          <div className={styles['side-container-year']}>
            <div className={styles['line-container']}>
              <div className={styles['dot']}></div>
              <div className={styles['line']}></div>
              <div className={styles['dot']}></div>
            </div>
            <img src={circle} alt='line' />
          </div>
          <div>
            {Object.entries(eventsInYear).map(
              ([month, eventsInMonth], index) => (
                <div>
                  <div
                    className={styles['month-btn']}
                    onClick={() => {
                      if (eventMonthIndex === index) {
                        dispatch(setEventMonthIndex(-1));
                      } else {
                        dispatch(setEventMonthIndex(index));
                      }
                    }}
                  >
                    <button>{getMonthName(month)}</button>
                    {eventMonthIndex === index ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </div>
                  {eventMonthIndex === index && (
                    <div className={styles['container-month']}>
                      <div className={styles['side-container-month']}>
                        <div className={styles['line-container']}>
                          <div className={styles['dot']}></div>
                          <div className={styles['line']}></div>
                          <div className={styles['dot']}></div>
                        </div>
                        <img src={circle} alt='line' />
                      </div>
                      <div className={styles['event']}>
                        {eventsInMonth
                          .sort((a, b) => {
                            const dayA = new Date(a.event_date).getDate();
                            const dayB = new Date(b.event_date).getDate();
                            return dayA - dayB;
                          })
                          .map((event, index) => {
                            return (
                              <div
                                key={index}
                                className={styles['event-wrapper']}
                                style={{ background: event.theme_color }}
                              >
                                <Event
                                  event={event}
                                  year={year}
                                  handelSaveModal={handelSaveModal}
                                  setOpenUpgradeModal={setOpenUpgradeModal}
                                  countries={countries}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}
      {openUpgradeModal && (
        <UpgradePlanModal
          isOpen={openUpgradeModal}
          handleCloseUpgradePlansModal={() => setOpenUpgradeModal(false)}
          body='Ivaretakelse av livslinjen er tilgjengelig under Familie og Familie + medlemskap.'
          title='Oppdater til bevisst medlem'
        />
      )}
    </div>
  );
};

export default LifeEvent;
