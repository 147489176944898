import React from 'react';
import { useHistory } from 'react-router-dom';
import IframeComponent from '../../../components/Iframe/IframeComponent';
import styles from './Contact.module.css';

const Contact = ({ currentSubscription }) => {
  const history = useHistory();
  return (
    <React.Fragment>
      {currentSubscription.key === 'family-plus' ? (
        <div className={styles['contact']}>
          <span className={styles['header']}>Din kontaktperson</span>
          <IframeComponent
            title='Contact'
            source='https://www.wix.nettgrav.no/familiekontakt'
          />
        </div>
      ) : (
        history.push('/settings')
      )}
    </React.Fragment>
  );
};

export default Contact;
