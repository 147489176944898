import React from 'react';
import styles from './Relationships.module.css';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import Relations from '../../../../components/Relations/Relations';

const Relationships = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  const history = useHistory();

  return (
    <>
      {!isEmpty(item) && item.length > 0 ? (
        <div className={styles['relationship-container']}>
          {item.map((user, index) => {
            return <Relations user={user} key={index} />;
          })}
        </div>
      ) : (
        <div className={styles['empty-grave-info']}>
          <span className={styles['empty-message']}>{emptyMessage}</span>
          {canEdit && (
            <button className={styles['add-btn']} onClick={onEdit}>
              Legg til {title}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Relationships;
