import React from 'react';
import styles from './GravePassion.module.css';
import isEmpty from 'lodash.isempty';

const GravePassion = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  return (
    <>
      {!isEmpty(item) && item.comment ? (
        <div className={styles['grave-passion']}>
          <div className={styles['passion']}>{item.comment}</div>
        </div>
      ) : (
        <div className={styles['empty-grave-info']}>
          <span className={styles['empty-message']}>{emptyMessage}</span>
          {canEdit && (
            <button className={styles['add-btn']} onClick={onEdit}>
              Legg til {title}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default GravePassion;
