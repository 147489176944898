import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import X from '../../../../img/X.svg';
import Warning from '../../../../img/orange_warning.svg';

import styles from './CloseImageModal.module.css';
const useStyle = makeStyles(() => ({
  rootDialog: {
    borderRadius: '10px !important',
    '@media (max-width: 800px)': {
      width: '90%',
    },
  },
}));

const CloseImageModal = ({
  onOpen,
  onClose,
  onConfirm,
  title,
  body,
  isUploading,
}) => {
  const classes = useStyle();
  return (
    <Dialog
      open={onOpen}
      classes={{
        paper: classes.rootDialog,
      }}
    >
      <div className={styles['upgrade-plan-prompt']}>
        <img
          src={X}
          onClick={() => onClose()}
          alt=''
          className={styles['upgrade-plan-prompt_cancel']}
        />
        <div className={styles['upgrade-plan-prompt_body_wrapper']}>
          <div className={styles['upgrade-plan-prompt_body_subheading']}>
            <img src={Warning} alt='' style={{ marginRight: '10px' }} />
            {title}
          </div>
          <div className={styles['upgrade-plan-prompt_body_content']}>
            <span>{body}</span>
          </div>
          <div className={styles['btn-container']}>
            <div className={styles['btn-wrapper']}>
              {!isUploading && (
                <button
                  className={styles['rootVerify']}
                  onClick={() => onConfirm()}
                >
                  Bekrefte
                </button>
              )}
              <button
                className={styles['rootDiscard']}
                onClick={() => onClose()}
              >
                Avbryt
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CloseImageModal;
