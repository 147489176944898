import React from 'react';
import styles from './BillingInformation.module.css';

const BillingInformation = ({ billingInformation }) => {
  return (
    <div className={styles['billing-information']}>
      <div className={styles['billing-heading']}>Billing Information</div>
      <div className={styles['detail']}>
        <div className={styles['title']}>Navn:</div>
        <div className={styles['value']}>{billingInformation.name}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>Adresse:</div>
        <div className={styles['value']}>{billingInformation.address}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>Fylke:</div>
        <div className={styles['value']}>{billingInformation.country}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>E-post:</div>
        <div className={styles['value']}>{billingInformation.email}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>postnummer:</div>
        <div className={styles['value']}>{billingInformation.postal_code}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>Telefonnr:</div>
        <div className={styles['value']}>{billingInformation.phone_number}</div>
      </div>
    </div>
  );
};

export default BillingInformation;
