import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import SettingsPageHeader from './atoms/SettingsPageHeader';
import SettingsPersonalInformationEdit from './atoms/SettingsPageInformationEdit';
import SettingsPersonalInformation from './atoms/SettingsPagePersonalIformation';
import AccountInformation from './atoms/AccountInformation';
import styles from './SettingsPage.module.css';
import { Modal } from '@material-ui/core';
import X from './../../img/X.svg';
import ChangePassword from './atoms/ChangePassword';
import Notifications from './atoms/Notifications';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import { fetch_request } from '../../service/request';
import {
  setConfirmSignOut,
  setInformationAboutUser,
} from '../../redux/actions';
import GiftSubscription from './atoms/GiftSubscription/GiftSubscription';
import Subscriptions from './atoms/Subscriptions';
import PlaneMembers from './atoms/PlanMembers';
import PaymentMethods from './atoms/PaymentMethods';
import Contact from './atoms/Contact';
import { CONSTANTS } from '../../service/constants';
import axios from 'axios';
import spinner from '../../service/Loading_animation.gif';
import isEmpty from 'lodash.isempty';

const SettingsPage = () => {
  const [modalDelete, setModalDelete] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const user = useSelector((state) => state.user.user || {});
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [loading, setLoading] = useState(true);

  const openModalDelete = () => {
    setModalDelete(true);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };

  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    if (userSlug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      setLoading(true);
      fetch_request(`/api/users/${userSlug}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          dispatch(setInformationAboutUser(data));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [userSlug]);

  useEffect(() => {
    if (!isAuth) return;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          setCurrentSubscription(res.data.product);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [userSlug]);

  const deleteAccount = () => {
    fetch_request(`/api/users/${user.slug}`, {
      method: 'delete',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        closeModalDelete();
        if (data.success) {
          dispatch(setConfirmSignOut());
          localStorage.clear();
          showUserNotification('Konto er avsluttet', 'success');
        } else {
          showUserNotification(`${data.message}`, 'warning');
        }
      })
      .catch((err) => {
        console.log(err);
        showUserNotification(
          'Noe gikk galt og konto ble ikke slettet',
          'warning'
        );
      });
  };

  const pauseAccount = () => {
    fetch_request(`/api/deactivate_users/${user.slug}`, {
      method: 'post',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        closeModalDelete();
        if (data.success) {
          // dispatch(setConfirmSignOut());
          localStorage.clear();
          showUserNotification('Kontoen er satt på pause', 'success');
        } else {
          showUserNotification(`${data.message}`, 'warning');
        }
      })
      .catch((err) => {
        console.log(err);
        showUserNotification(
          'Noe gikk galt, og kontoen ble ikke satt på pause',
          'warning'
        );
      });
  };

  return (
    <>
      {loading && isEmpty(currentSubscription) ? (
        <div className={styles['loadingSpinner']}>
          <img loading='lazy' src={spinner} alt='elastic' />
        </div>
      ) : (
        <div className={styles['settings-page']}>
          <div className={styles['settings-page-wrapper']}>
            <div className={styles['settings-page_content']}>
              <SettingsPageHeader currentSubscription={currentSubscription} />
              <div className={styles['content_page_border']}>
                <Route
                  exact
                  path='/settings'
                  render={() =>
                    isEditing ? (
                      <SettingsPersonalInformationEdit
                        setIsEditing={setIsEditing}
                        onOpenModal={openModalDelete}
                      />
                    ) : (
                      <SettingsPersonalInformation edit={setIsEditing} />
                    )
                  }
                />
                <Route path='/settings/password' component={ChangePassword} />
                <Route
                  path='/settings/contact'
                  render={() => (
                    <Contact currentSubscription={currentSubscription} />
                  )}
                />
                <Route
                  path='/settings/payment-methods'
                  component={PaymentMethods}
                />
                <Route
                  path='/settings/subscriptions'
                  component={Subscriptions}
                />
                <Route path='/settings/plan-members' component={PlaneMembers} />
                <Route
                  path='/settings/subscription-invitations'
                  component={GiftSubscription}
                />
                <Route
                  path='/settings/notifications'
                  component={Notifications}
                />
                {/* <Route
                  path='/settings/account'
                  render={() => (
                    <AccountInformation
                      deleteAccount={deleteAccount}
                      pauseAccount={pauseAccount}
                    />
                  )}
                /> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {modalDelete && (
        <Modal
          open={modalDelete}
          onClose={closeModalDelete}
          className={styles['settings-personal-information_delete-modal']}
        >
          <div className={styles['']}>
            <div
              className={
                styles['settings-personal-information_delete-modal_content']
              }
            >
              <div
                className={
                  styles[
                    'settings-personal-information_delete-modal_content-headers'
                  ]
                }
              >
                <span>Skriv inn kortene dine (Debet/Kreditt)</span>
                <img
                  loading='lazy'
                  src={X}
                  alt='close'
                  onClick={closeModalDelete}
                />
              </div>
              <div
                className={
                  styles[
                    'settings-personal-information_delete-modal_content_inputs'
                  ]
                }
              >
                <input type='text' placeholder='Navn på kortet' />
                <input type='text' placeholder='Kortnummer' />
                <div
                  className={
                    styles[
                      'settings-personal-information_delete-modal_content_inputs_input'
                    ]
                  }
                >
                  <input type='text' placeholder='Gyldig gjennom' />
                  <input type='text' placeholder='CCV' />
                </div>
              </div>
              <div
                className={
                  styles[
                    'settings-personal-information_delete-modal_content_remember'
                  ]
                }
              >
                <input type='checkbox' />
                <span>Husk dette kortet</span>
              </div>
              <div
                className={
                  styles[
                    'settings-personal-information_delete-modal_content-buttons'
                  ]
                }
              >
                <button
                  className={
                    styles[
                      'settings-personal-information_delete-modal_content-cancel'
                    ]
                  }
                  onClick={closeModalDelete}
                  style={{ border: 'none', cursor: 'pointer' }}
                >
                  Fortsette
                </button>
              </div>
              <div
                className={
                  styles[
                    'settings-personal-information_delete-modal_content_border'
                  ]
                }
              />
              <div
                className={
                  styles[
                    'settings-personal-information_delete-modal_content_footer'
                  ]
                }
              >
                <span>Nettbank</span>
                <input type='checkbox' />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SettingsPage;
