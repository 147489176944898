import React, { useState } from 'react';
import styles from './FreePlanContainer.module.css';

const FreePlanContainer = () => {
  return (
    <div className={styles['payment-details']}>
      <div className={styles['free-heading']}>
        <span>Fri plan trenger ingen betaling da den er gratis</span>
      </div>
      <div className={styles['no-payment-message']}>
        Registrer deg for vår gratisplan uten betalingsinformasjon. Når du
        oppgraderer til familie eller familie +, vil vi be om dine
        betalingsdetaljer. Trykk på abonner for å fortsette med Fri plan.
      </div>
    </div>
  );
};

export default FreePlanContainer;
