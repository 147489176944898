import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import './BillingInformation/BillingInformation.css';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const CustomPhoneNumber = ({
  isValid,
  setIsValid,
  onNumberChange,
  userNumber,
  disable = false,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryName, setCountryName] = useState('NO');
  const user = useSelector((state) => state.user.user);
  const countries = useSelector((state) => state.countries.countries);

  useEffect(() => {
    if (userNumber) {
      setPhoneNumber(userNumber);
      setIsValid(null);
      return;
    }
    if (user.phone_number && user.country_id) {
      const country = countries.find(
        (country) => country.id === user.country_id
      );
      if (!isEmpty(country)) {
        const userPhoneNumber = `${country.dial_code}${user.phone_number}`;
        setPhoneNumber(userPhoneNumber);
        setIsValid(null);
      }
    } else {
      setPhoneNumber('');
    }
  }, [user]);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    const valid =
      typeof value === 'string' && isPossiblePhoneNumber(value, countryName);
    if (valid && value.trim() !== '') {
      setIsValid(null);
      onNumberChange(value);
    } else {
      onNumberChange(value);
      setIsValid('Invalid number');
    }
  };

  const handleCountryChange = (newCountryCode) => {
    setCountryName(newCountryCode);
    setPhoneNumber('');
  };

  return (
    <PhoneInput
      disabled={disable}
      international
      defaultCountry={countryName}
      country={countryName}
      countryCallingCodeEditable={false}
      withCountryCallingCode
      value={phoneNumber}
      onChange={handlePhoneNumberChange}
      onCountryChange={handleCountryChange}
      className={isValid ? 'custom-phone-input-error' : 'custom-phone-input'}
    />
  );
};

export default CustomPhoneNumber;
