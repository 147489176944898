import { Button, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import './SecondStep.css';
import DateInput from './DateInput';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import GraveDatePicker from './DatePicker/DatePicker';
import isEmpty from 'lodash.isempty';
import dayjs from 'dayjs';

const useStyle = makeStyles(() => ({
  clear: {
    display: 'none',
  },
}));

const SecondStep = (props) => {
  const classes = useStyle();
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);
  const [selectedDeathDate, setSelectedDeathDate] = useState(null);
  const [passing, setPassing] = useState({});
  const [otherPassing, setOtherPassing] = useState('');

  const reasonProps = {
    options: props.reasonOfPassing,
    getOptionLabel: (option) => option.reason,
  };

  useEffect(() => {
    if (props.secondStepData) {
      setSelectedBirthDate(props.secondStepData.selectedBirthDate);
      setSelectedDeathDate(props.secondStepData.selectedDeathDate);
      setPassing(props.secondStepData.passing);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const onNextClick = () => {
    if (!selectedBirthDate || !selectedDeathDate || !passing.id) {
      showUserNotification('Husk å fylle inn alle feltene', 'warning');
    } else {
      if (selectedBirthDate > selectedDeathDate)
        showUserNotification(
          'Fødselsdatoen kan ikke være senere enn dødsdatoen',
          'warning'
        );
      else if (!isEmpty(passing) && selectedDeathDate && selectedBirthDate) {
        const data = {
          selectedBirthDate: selectedBirthDate,
          selectedDeathDate: selectedDeathDate,
          passing: passing,
          otherPassing: otherPassing,
        };
        props.onNextClick(data);
      } else {
        showUserNotification('Husk å fylle inn alle feltene', 'warning');
      }
    }
  };

  const handleDateOfBirth = (date) => {
    if (!isEmpty(date)) {
      const parsedDate = dayjs(date);
      if (parsedDate.isValid()) {
        const newDate = parsedDate.format('YYYY-MM-DD');
        setSelectedBirthDate(newDate);
      }
    } else {
      setSelectedBirthDate(null);
    }
  };

  const handleDateOfDeath = (date) => {
    if (!isEmpty(date)) {
      const parsedDate = dayjs(date);
      if (parsedDate.isValid()) {
        const newDate = parsedDate.format('YYYY-MM-DD');
        setSelectedDeathDate(newDate);
      }
    } else {
      setSelectedDeathDate('');
    }
  };

  return (
    <div className='first-step'>
      <div className='input-element-list'>
        <div className='title'>
          <label htmlFor='name'>Fødselsdato</label>
        </div>
        <GraveDatePicker
          date={selectedBirthDate}
          setDatePickerValue={handleDateOfBirth}
        />
      </div>
      <div className='input-element-list'>
        <div className='title'>
          <label htmlFor='name'>Dødsdato</label>
        </div>
        <GraveDatePicker
          date={selectedDeathDate}
          setDatePickerValue={handleDateOfDeath}
        />
      </div>
      <div className='input-element-list'>
        <div className='title'>
          <label htmlFor='name'>Dødsårsak</label>
        </div>
        <div className='choose-relation-selection'>
          <Autocomplete
            id='relation-combo-box'
            {...reasonProps}
            classes={{ input: classes.input }}
            style={{ width: '99%' }}
            value={passing}
            renderInput={(params) => (
              <TextField placeholder='Dødsårsak' {...params} />
            )}
            onChange={(e, value) => setPassing(value)}
          />
        </div>
      </div>

      {passing && passing.id === 11 && (
        <div className='input-element-list'>
          <div className='title'>
            <label htmlFor='name'>Skriv inn en annen årsak</label>
          </div>
          <input
            className='inputs'
            value={otherPassing}
            onChange={(e) => setOtherPassing(e.target.value)}
            placeholder='Enter other reason'
          />
        </div>
      )}

      <div className='second-step-buttons'>
        <button className='first-step-back' onClick={props.onBackClick}>
          Tilbake
        </button>
        <button className='next-step-button' onClick={onNextClick}>
          Neste
        </button>
      </div>
    </div>
  );
};

export default SecondStep;
