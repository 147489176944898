import React, { useEffect, useState } from 'react';
import styles from './GiftSubscriptionForm.module.css';
import { Avatar, makeStyles, Popover } from '@material-ui/core';
import DiscardModal from '../../../NewDeceased/atoms/DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../../../NewDeceased/atoms/SavedPopupModal/SavedPopupModal';
import { request, fetch_request } from '../../../../service/request';
import { useDispatch } from 'react-redux';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import ConformModal from '../ConformDeleteModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import PhoneNumber from './PhoneNumberInput/PhoneNumber';
import CopyInvitationLink from './CopyInvitationLink/CopyInvitationLink';
import { CONSTANTS } from '../../../../service/constants';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles((theme) => ({
  rootIconMenu: {
    color: '#9f9f9f !important',
  },
  rootIconMenuOpen: {
    display: 'none',
  },
  selector: {
    width: '100%',
    background: '#F2F5F8 !important',
    borderRadius: '8px',
    padding: '18px 28px',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
      border: '1px solid',
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const GiftSubscriptionForm = ({
  subscriptionInvitations,
  handelSubscriptionInvitations,
  saveSubscriptionInvitationsRedux,
  invitation,
  invitationId,
  subscriptionId,
  disable,
  handelCloseForm,
  handelInvitationId,
  handleTabChange,
  relationList,
  countriesList,
  handelRemainingCount,
  setCount,
}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [selectedReciprocal, setSelectedReciprocal] = useState('');
  const [selectedRelation, setSelectedRelation] = useState('');
  const [openInValidModal, setOpenInValidModal] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [copied, setCopied] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [editInvitation, setEditInvitation] = useState({
    id: '',
    full_name: '',
    phone_number: '',
    country_id: '',
    invitation_token: '',
    invitation_accepted_at: '',
    invited_at: '',
    user_slug: null,
  });

  useEffect(() => {
    if (countriesList.length > 0) {
      setEditInvitation({
        ...editInvitation,
        country_id: countriesList[0].id,
      });
    }
  }, [countriesList]);

  useEffect(() => {
    if (!isEmpty(invitation)) {
      setEditInvitation({ ...invitation });
    }
  }, [invitation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied]);

  const handelEditForm = (field, value) => {
    setEditInvitation((prev) => ({
      ...prev,
      [field]: value,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    if (isFieldsNotEmpty()) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [editInvitation]);

  const isFieldsNotEmpty = () => {
    return (
      editInvitation.full_name.trim() !== '' &&
      editInvitation.phone_number.trim() !== '' &&
      editInvitation.country_id !== 0
    );
  };

  const deleteRelation = () => {
    if (editInvitation.id) {
      request(`/subscription_invitations/${editInvitation.id}`, null, 'delete')
        .then((res) => {
          const newArray = subscriptionInvitations.filter(
            (invitation) => invitation.id !== editInvitation.id
          );
          handelSubscriptionInvitations(newArray);
          setCount();
          saveSubscriptionInvitationsRedux(newArray);
          setOpenDeleteModal(false);
          showUserNotification('invitasjonen er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
      setOpenDeleteModal(false);
    }
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(invitation).length > 0) {
      setEditInvitation({ ...invitation });
      handelInvitationId(null);
    } else {
      clearRelation();
      handelCloseForm();
      handelInvitationId(null);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelCloseForm();
    handelInvitationId(null);
    handleTabChange();
  };

  const define_env = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'staging':
        return 'https://stage.nettgrav.no';
      case 'production':
        return 'https://nettgrav.no';
      case 'local':
        return 'http://localhost:3000';
      default:
        return 'https://stage.nettgrav.no';
    }
  };

  const baseUrl = define_env();

  const createNewRelative = async () => {
    if (invitationId) {
      const inviteRelation = {
        subscription_invitation: {
          full_name: editInvitation.full_name,
          country_id: editInvitation.country_id,
          phone_number: editInvitation.phone_number,
          subscription_id: subscriptionId,
        },
      };
      request(
        `/subscription_invitations/${invitationId}`,
        inviteRelation,
        'put'
      )
        .then((res) => {
          setOpenProcessModal(false);
          setOpenSaveModal(true);
          saveSubscriptionInvitationsRedux(
            subscriptionInvitations.map((w) =>
              w.id === invitationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
          handelSubscriptionInvitations(
            subscriptionInvitations.map((w) =>
              w.id === invitationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
        })
        .catch((err) => {
          setOpenProcessModal(false);
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    } else {
      const inviteRelation = {
        subscription_invitation: {
          full_name: editInvitation.full_name,
          country_id: editInvitation.country_id,
          phone_number: editInvitation.phone_number,
          subscription_id: subscriptionId,
          user_slug: editInvitation.user_slug,
        },
      };
      request(`/subscription_invitations`, inviteRelation, 'post')
        .then((res) => {
          saveSubscriptionInvitationsRedux([
            res.data,
            ...subscriptionInvitations,
          ]);
          handelSubscriptionInvitations([res.data, ...subscriptionInvitations]);
          setOpenProcessModal(false);
          setOpenSaveModal(true);
          clearRelation();
          handelRemainingCount();
        })
        .catch((error) => {
          if (error.response) {
            setOpenProcessModal(false);
            showUserNotification(`${error.response.data.error}`, 'error');
          }
          setIsValidNumber(true);
        });
    }
  };

  const clearRelation = () => {
    setEditInvitation({
      id: '',
      full_name: '',
      phone_number: '',
      country_id: countriesList[0].id,
      invitation_token: '',
      invitation_accepted_at: '',
      invited_at: '',
    });
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 47)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <>
      <div>
        {!disable && (
          <div className={styles['grave-actions-container']}>
            <div className={styles['grave-information-edit-delete-container']}>
              <div
                className={styles['action-btn']}
                onClick={() => {
                  handelInvitationId(editInvitation.id);
                  handelCloseForm();
                }}
              >
                <img src={EditIcon} alt='Edit' />
                <button type='button'>Redigere</button>
              </div>
              <div
                className={styles['action-btn']}
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                <img src={DeleteIcon} alt='Delete' />
                <button type='button'>Slett</button>
              </div>
            </div>
          </div>
        )}
        <div className={styles['company-name-container']}>
          <div className={styles['input-element']}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles['title']}>Telefonnummer</div>
            </div>
            <PhoneNumber
              disable={!disable}
              onNumberChange={handelEditForm}
              relation={editInvitation}
              countriesList={countriesList}
            />
          </div>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Navn</div>
            <input
              disabled={!disable}
              className={styles['inputs']}
              type='text'
              required={true}
              placeholder='Navn'
              value={editInvitation.full_name}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const inputValue = e.target.value;
                const trimmedValue = inputValue
                  .replace(/^\s+/, '')
                  .replace(/ {2,}$/, ' ');
                handelEditForm('full_name', trimmedValue);
              }}
            />
          </div>
        </div>
        {editInvitation.invitation_token.trim() !== '' && (
          <div className={styles['Link-container']}>
            <div className={styles['link-wrapper']}>
              <div className={styles['link-sub-heading']}>
                Kopier og del din Nettgrav.no medlemskaps-invitasjon
              </div>
              {copied && (
                <div className={styles['copy-link']}>
                  lenke kopiert til utklippstavlen
                </div>
              )}
            </div>
            <CopyInvitationLink
              handelSetCopied={(value) => setCopied(value)}
              link={`${baseUrl}/registration?invitation_token=${editInvitation.invitation_token}`}
            />
          </div>
        )}
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                type='button'
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={
                  disableBtn ? styles['disabledButton'] : styles['rootVerify']
                }
                disabled={disableBtn}
                type='submit'
                onClick={() => {
                  setOpenProcessModal(true);
                  createNewRelative();
                }}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='invitasjon'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          title='Abonnement invitasjon har vært sende vellykket'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='invitasjon'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteRelation}
        />
      )}
      {openProcessModal && (
        <ProcessModal
          open={openProcessModal}
          message='Sender invitasjonen. Vennligst vent'
        />
      )}
    </>
  );
};

export default GiftSubscriptionForm;
