import React, { useState, useEffect } from "react";
import "./SearchCount.css";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  rootTooltip: {
    backgroundColor: "#19262D",
    boxShadow: "0px 4px 10px rgba(33, 32, 32, 0.18)",
    borderRadius: "6px",
    // maxWidth: '250px',
    fontFamily: "Inter",
    fontSize: "17px",
    lineHeight: "150%",
    color: "#E4F3FD",
    padding: "20px",
  },
}));

const SearchCount = (props) => {
  const classes = useStyles();
  const availableSearchCount = useSelector(
    (state) => state.grave.availableSearchCount
  );
  const searchWord =
    availableSearchCount &&
    (availableSearchCount === 1 || availableSearchCount === 0)
      ? "Søk"
      : "søk";

  if (!availableSearchCount || availableSearchCount < 0) {
    return null;
  }
  return (
    <div className="count-tab">
      <Tooltip
        arrow
        interactive={true}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 200 }}
        placement="right-start"
        title={
          <span className="count_span">
            {`Du benytter Nettgrav Fri som gir deg fem søk pr dag. Du har ${availableSearchCount} gjenværende søk på graver`}
            <div>
              <Link
                to={"/plans"}
                className="count_span light"
              >{`Oppgrader her`}</Link>{" "}
              for ubegrenset antall søk
            </div>
          </span>
        }
        classes={{ tooltip: classes.rootTooltip }}
      >
        <div className="count-pill">
          <span className="count_span">
            <span className="light">{availableSearchCount}</span>
            <span className="dark">{searchWord} gjenstår på Fri</span>
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

export default SearchCount;
