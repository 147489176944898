import React, { useState, useEffect } from 'react';
import './RegisterFirstStep.css';
import {
  Button,
  makeStyles,
  Select,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CountriesArrowClosed from '../../../img/CountriesPopoverClosed.svg';
import CountriesArrowOpen from '../../../img/CountriesPopoverOpen.svg';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import CountriesPopover from './CountriesPopover';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListOfCountries,
  subscriptionInvitationToken,
} from '../../../redux/actions';
import { useLocation, useHistory } from 'react-router-dom';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { fetch_request } from '../../../service/request';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: 'none',
    minWidth: '350px',
    maxWidth: '350px',
    width: '100%',
    marginTop: '6px',
    borderRadius: '10px',
    maxHeight: '271px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
  },
}));

const RegisterFirstStep = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [checked, setChecked] = useState(props.authorizationFormData.checkbox);
  const [anchorEl, setEnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;
  const userInvitation = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );
  const countries = useSelector((state) => state.auth.countries);

  const { phoneNumber, chosenCountry, gender } = props.authorizationFormData;

  const handleOpenPopover = (e) => {
    setEnchorEl(anchorEl ? null : e.target.parentElement.parentElement);
  };

  const handleChoseCountryFromList = (country) => {
    props.setAuthFormData({ chosenCountry: country });
    setEnchorEl(null);
  };

  useEffect(() => {
    if (!isEmpty(userInvitation) && userInvitation.phone_number) {
      props.setAuthFormData({ phoneNumber: userInvitation.phone_number });
    }
    if (
      !isEmpty(userInvitation) &&
      countries.length > 0 &&
      userInvitation.country_id
    ) {
      const country = countries.find(
        (country) => country.id === userInvitation.country_id
      );
      if (!isEmpty(country)) {
        props.setAuthFormData({ chosenCountry: country });
      }
    }
  }, [userInvitation]);

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const handlePhoneNumberChange = (event) => {
    const str = event.target.value.replace(/[^\d]/g, '');
    props.setAuthFormData({ phoneNumber: str });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (phoneNumber) {
      let formdata = new FormData();
      formdata.append('phone_number', `${phoneNumber}`);
      formdata.append('country_id', `${chosenCountry.id}`);
      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };
      fetch_request('/api/generate_auth_token', requestOptions)
        .then((response) => {
          if (response.status === 200) {
            props.onNextClick();
          } else if (response.status === 500) {
            showUserNotification(
              'Vennligst skriv inn et gyldig mobilnummer',
              'warning'
            );
          } else {
            showUserNotification('Noe gikk av. Prøv igjen senere', 'error');
          }
        })
        .catch((error) => console.log('error', error));
    } else
      showUserNotification('Husk å skrive inn telefonnummeret ditt', 'warning');
  };

  const handleCheckBoxChange = (event) => {
    props.setAuthFormData({
      checkbox: !checked,
    });
    setChecked(!checked);
  };

  return (
    <div className='create-membership_first'>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.paper}>
              <CountriesPopover
                handleChoseCountryFromList={handleChoseCountryFromList}
                country={chosenCountry.id}
              />
            </div>
          </Fade>
        )}
      </Popper>
      <form
        className='auth-form-register'
        autoComplete='off'
        onSubmit={handleFormSubmit}
      >
        <div className='auth-welcome_input create-membership_first__input'>
          <div className='create-membership_first-input_block'>
            <span>Skriv inn mobilnummeret ditt</span>
            <div className='create-membership_first-input_block-inputs'>
              <Button
                onClick={handleOpenPopover}
                className='create-membership_first-input_block-inputs_button'
              >
                {chosenCountry.dial_code}
                <img
                  loading='lazy'
                  src={anchorEl ? CountriesArrowOpen : CountriesArrowClosed}
                  className='countries-arrow_popover'
                  alt=''
                />
              </Button>
              <input
                className='mobile'
                type='tel'
                placeholder='00 00 00 00'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <input
                className='desktop'
                type='text'
                placeholder='00 00 00 00'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <div className='create-membership_first-input_gender'>
              <Select
                native
                value={gender}
                onChange={(e) =>
                  props.setAuthFormData({
                    gender: e.target.value.toLowerCase(),
                  })
                }
                className='create-membership_first-input_gender-select'
              >
                <option value={'kvinne'}>Kvinne</option>
                <option value={'mann'}>Mann</option>
                <option value={'other'}>Annen</option>
              </Select>
            </div>
          </div>
          <div className='create-membership_second__check-container'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckBoxChange}
                  inputProps={{ 'aria-label': 'checkbox' }}
                  className='checkbox'
                  required
                />
              }
              className='create-membership_second__checkbox'
            />
            <span className='check-container_terms_conditions'>
              Jeg godtar å motta SMS fra Nettgrav.no og er inneforstått med våre 
              <span
                onClick={() => history.push(`/terms_conditions`)}
                className='link'
              >
                 vilkår og betingelser
              </span>
            </span>
          </div>
        </div>
        <div className='create-membership_first__buttons'>
          <button
            variant='contained'
            className='auth-welcome_contained-button'
            type='submit'
          >
            Neste
          </button>
        </div>
        <div className='auth-welcome_inline-span-wrapper'>
          <span>Har du allerede registrert deg?</span>
          <span
            variant='outlined'
            className='auth-welcome_register_link_span'
            onClick={() => props.setRedirect(true)}
          >
            Logg inn
          </span>
        </div>
      </form>
    </div>
  );
};

export default RegisterFirstStep;
