import React from 'react';
import './Terms_Conditions.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAuthFormRedux, setStepRegistration } from '../../../redux/actions';

const TermsConditions = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const setAuthFormData = (data) => dispatch(setAuthFormRedux(data));
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const userSlug = localStorage.getItem('user-slug');

  const OnAccept = () => {
    setAuthFormData({ checkbox: true });
    dispatch(setStepRegistration(0));
    if (location.key) {
      history.goBack();
    } else if (isAuth && userSlug) {
      history.push(`/member/${userSlug}`);
    } else {
      history.push('/');
    }
  };

  const OnReject = () => {
    setAuthFormData({ checkbox: false });
    dispatch(setStepRegistration(0));
    if (location.key) {
      history.goBack();
    } else if (isAuth && userSlug) {
      history.push(`/member/${userSlug}`);
    } else {
      history.push('/');
    }
  };

  return (
    <div className='terms_conditions'>
      <header className='header_terms_condiyions'>Vilkår & betingelser</header>
      <header className='header_terms_condiyions subheader_terms_condiyions'>
        Generelle brukervilkår og personvernpolicy for nettgrav.no
      </header>
      <div>
        <span className='listHeader_terms_condiyions'>1. Generelt</span>
        <label>
          Nettgrav.no er en portal for fakta og sannhet for ivaretakelse av det
          indre livet vårt. Portalen starter med fornyelse av den fysiske graven
          gjennom et internettbasert gravsted som tilrettelegger for at
          pårørende og andre skal få mulighet til å besøke og være i kontakt med
          graven gjennom graven på nett - en Nettgrav. I tillegg til muligheten
          for å opprette og besøke en Nettgrav, tilrettelegges det for at
          brukere kan lagre bilder, minner, oppmerksomheter og andre tjenester
          knyttet til ivaretakelse av gravlagte og øvrig familie. For enkelte
          deler av Tjenesten gjelder i tillegg særskilte vilkår som fremgår av
          Nettstedet med hensyn til blant annet priser, betalingsvilkår og
          betalingsmetoder, avtaleperiode og annet som Nettgrav AS tilbyr. Ved
          avvik mellom slike særskilte vilkår og disse generelle brukervilkårene
          vil de særskilte vilkårene gjelde foran de generelle brukervilkårene.
        </label>
        <span className='listHeader_terms_condiyions'>
          2. REGISTRERING, BRUKERNAVN OG PASSORD
        </span>
        <label>
          Fremgangsmåten for å få tilgang til Tjenestene varierer etter som du
          er fester for den fysiske graven eller besøkende. Registrering av
          brukerkonto kan enten skje på Nettstedet eller via andre tjenester og
          sosiale medier i den grad Nettgrav tilbyr dette. Dersom du registrerer
          deg via andre tjenester eller sosiale medier gir du samtykke til at
          Nettgrav kan innhente brukerinformasjon for å sette opp brukerkontoen.
          Du er selv ansvarlig for at alle opplysninger som oppgis ved
          registrering er korrekte. Ditt passord er personlig og kan ikke brukes
          av andre. Dersom du oppdager uautorisert bruk av din konto må du
          umiddelbart ta kontakt med Nettgrav AS og endre ditt passord. For å
          ivareta sikkerheten ved Tjenestene har Nettgrav AS rett til å kreve at
          du når som helst endrer ditt passord.
        </label>
        <span className='listHeader_terms_condiyions'>
          3. BRUK AV TJENESTENE M.M
        </span>
        <label>
          Du er selv ansvarlig for din bruk av Tjenestene, og de skal kun
          benyttes til det formål de er ment for. Du forplikter deg til å holde
          Nettgrav AS skadesløs for følger av lovovertredelser og for ethvert
          krav som tredjemann måtte rette mot Nettgrav AS som følge av din bruk
          av Tjenestene og Nettstedet.
        </label>
        <span className='listHeader_terms_condiyions'>
          4. PRISER OG BETALING
        </span>
        <label>Priser for de ulike Tjenestene fremgår av Nettstedet.</label>
        <span className='listHeader_terms_condiyions'>
          5. ANSVARSFRASKRIVELSE
        </span>
        <label>
          Med mindre annet klart fremgår er Nettgrav AS ikke ansvarlig for
          informasjon eller innhold plassert på eller sendt via våre Tjenester.
          Nettgrav AS har ikke kontroll over andre nettsider som det lenkes til,
          og har heller ikke ansvar for innholdet som finnes på slike nettsider.
          Innenfor det som er tillatt etter gjeldende rett fraskriver Nettgrav
          AS seg ethvert ansvar for skade eller tap som følge av dine handlinger
          eller unnlatelser i forbindelse med Tjenestene.
        </label>
        <span className='listHeader_terms_condiyions'>6. ENDRINGER</span>
        <label>
          Nettgrav AS har rett til å endre innholdet i og omfange av Tjenestene.
          Mindre endringer kan gjennomføres uten varsel og uten at du kan heve
          avtalen. Nettgrav AS har rett til å endre priser og andre vilkår,
          herunder vilkårene for Tjenestene. Når endringene er av en viss
          betydning og hvis til ugunst for deg, skal Nettgrav AS varsle deg via
          brev eller e-post på den adresse du har registrert, og senest 30 dager
          før endringen trer i kraft. Om du ikke ønsker å godta slike nye vilkår
          kan du, med mindre endringen skyldes endringer i offentligrettslige
          regler eller forhold utenfor Nettgrav AS sin kontroll, si opp
          Tjenestene med opphør fra den dag da endringen trer i kraft.
        </label>
        <span className='listHeader_terms_condiyions'>
          7. VARIGHET OG OPPSIGELSE
        </span>
        <label>
          Du kan når som helst slette din brukerkonto. Send e-post til
          kontakt@nettgrav.no hvis du har spørsmål eller trenger hjelp.
        </label>
        <span className='listHeader_terms_condiyions'>8. REKLAMASJON</span>
        <label>
          For å benytte deg av reklamasjon må du meddele Nettgrav AS via e-post,
          telefon, brev eller gjennom Nettstedet innen 5 dager fra
          leveringstidspunktet. Blomster og planter regnes som ferskvare og er
          unnlatt angrefristloven.
        </label>
        <span className='listHeader_terms_condiyions'>9. PERSONVERN </span>
        <div className='order'>
          <span className='nested_terms_condiyions'>9.1 Generelt</span>
          Ditt personvern er viktig, og i tråd med lover og regler vil vi derfor
          informere deg om følgende: <br />
          Nettgrav AS, Damstredet 3, 0177 Oslo (org.nr. 912 857 336) er
          behandlingsansvarlig for personopplysninger som behandles i
          forbindelse med levering av Tjenestene. Nettgrav AS behandler
          personopplysninger i henhold den norske personopplysningsloven.
          <br />
          Ved å registrere en brukerkonto samtykker du til at Nettgrav AS
          behandler personopplysninger i henhold til disse brukervilkårene og
          personopplysningspolicyen
          <span className='nested_terms_condiyions'>9.2 Formål</span>
          Nettgrav AS behandler personopplysninger for å kunne tilby Tjenestene,
          for å analysere hvordan våre brukere benytter Nettstedet og
          Tjenestene, administrasjon av kundeforhold, for å kommunisere med
          brukerne, og for å kunne oppfylle våre forpliktelser i henhold til
          lov.
          <span className='nested_terms_condiyions'>
            9.3 Hvilke personopplysninger behandles?
          </span>
          Personopplysningene som vi behandler er den informasjonen som oppgis
          av brukerne i forbindelse med registrering av brukerkonto, samt
          informasjon som ellers oppgis av brukerne ved bruk av Tjenestene.
          Dette omfatter opplysninger som navn, adresse, telefonnummer,
          e-postadresse og betalingsopplysninger. I tillegg behandles relevante
          opplysninger om avdøde.
          <span className='nested_terms_condiyions'>
            9.4 Utlevering av opplysninger
          </span>
          Nettgrav AS kan utlevere personopplysninger til foretak som utfører
          tjenester for Nettgrav AS, slik at disse foretakene skal kunne utføre
          de tjenester som Nettgrav AS etterspør. Dette gjelder blant annet
          levering av infrastruktur og IT-tjenester og levering av
          kundetjenester. Vi vil ikke utlevere personopplysninger til andre
          tredjeparter.
          <span className='nested_terms_condiyions'>
            9.5 Retting, sletting og innsyn
          </span>
          Du har rett til å få innsyn i hva slags personopplysninger Nettgrav AS
          behandler om deg i tråd med personopplysningslovens bestemmelser.
          Videre har du rett til å kreve retting av personopplysninger som er
          uriktige, ufullstendige eller som det ikke er anledning til å
          behandle. Hvor det foreligger grunnlag for det, kan du også kreve at
          opplysninger slettes.
          <span className='nested_terms_condiyions'>
            9.6 Bruk av informasjonskapsler
          </span>
          Vi bruker informasjonskapsler (såkalte cookies) på Nettsiden, som er
          små tekstfiler som lagres på din datamaskin. Informasjonskapslene gjør
          at dine valg og ditt brukermønster kan bli lagret mens du er inne på
          Nettsiden. Vi bruker disse opplysningene til å tilpasse Nettsiden, og
          for å gjøre Tjenestene så brukervennlige som mulig. I tillegg måler vi
          trafikken på Nettsiden.
          <br />
          <br />
          De fleste moderne nettlesere aksepterer informasjonskapsler
          automatisk, men du kan selv stenge av for informasjonskapsler dersom
          du ønsker det. Vær oppmerksom på at noen av våre funksjoner eller
          Tjenester ikke vil fungere dersom du slår av informasjonskapsler.
          <br />
          <br />
          For å analysere informasjon bruker vi verktøyet Google Analytics.
          Dette verktøyet registrerer din IPadresse ved hjelp av
          informasjonskapsler, og gir oss informasjon om dine bevegelser på
          nett. Statistikken vi mottar gir oss blant annet svar på hvor mange
          som besøker ulike sider, hvor lenge brukeren er på hver side, hvilke
          nettsteder våre brukere kommer fra, og informasjon om netthandel.
          Denne informasjonen lagres på Googles servere i USA, og mottatte
          opplysninger er underlagt Googles personvernpolicy.
          <br />
          <br />
          We use Hotjar in order to better understand our users’ needs and to
          optimize this service and ex-perience. Hotjar is a technology service
          that helps us better understand our users’ experience (e.g. how much
          time they spend on which pages, which links they choose to click, what
          users do and don’t like, etc.) and this enables us to build and
          maintain our service with user feedback. Hotjar uses cookies and other
          technologies to collect data on our users’ behavior and their devices.
          This includes a device's IP address (processed during your session and
          stored in a de-identified form), device screen size, device type
          (unique device identifiers), browser information, geographic location
          (country only), and the preferred language used to display our
          website. Hotjar stores this information on our behalf in a
          pseudonymized user profile. Hotjar is contractually forbidden to sell
          any of the data collected on our behalf.
        </div>
        <span className='listHeader_terms_condiyions'>10. Tvister </span>
        Disse brukervilkår skal reguleres og tolkes i samsvar med gjeldende
        norsk rett. Tvister skal være underlagt norske domstolers jurisdiksjon.
        Oslo tingrett avtales som verneting.
        <span className='listHeader_terms_condiyions'>
          11. Kontaktinformasjon
        </span>
        Ved spørsmål knyttet til Tjenestene og Nettstedet og for å benytte
        angreretten kan du ta kontakt med Nettgrav AS på kontakt@nettgrav.no,
        +4745007009, eller per post: Damstredet 3, 0151, OSLO.
      </div>
      <div className='button_container'>
        <button className='button_terms_condiyions' onClick={OnAccept}>
          Aksepter
        </button>
        <button className='button_terms_condiyions' onClick={OnReject}>
          Avvis
        </button>
      </div>
    </div>
  );
};

export default TermsConditions;
