import React, { useEffect, useState } from 'react';
import styles from './GraveWall.module.css';
import { Modal, Dialog } from '@material-ui/core';
import VideoModal from '../ChatModals/VideoModal/VideoModal';
import VideoMobile from '../ChatModals/VideoModal/VideoMobile';
import MusicModal from '../ChatModals/MusicModal/MusicModal';
import MusicMobile from '../ChatModals/MusicModal/MusicMobile';
import Memories from '../Memories/Memories';
import DownArrow from '../../../../img/SmallDownArrow.svg';
import UpArrow from '../../../../img/SmallUpArrow.svg';
import GraveWallImageModal from '../GraveWallImagesModal/GraveWallImageModal';
import GraveWallVideoModal from '../GraveWallVideoModal/GraveWallVideoModal';
import GraveNote from '../About/GraveNote';
import GraveNotesModal from '../GraveNotes/GraveNotesModal/GraveNotesModal';
import { useDispatch } from 'react-redux';
import { addMemory, setSubscription } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import Loader from '../../../../components/Loader/Loader';

const GraveWall = (props) => {
  const { user, grave, isAuth } = props;
  const dispatch = useDispatch();
  const is_relative = grave?.is_relative;
  const [isExpanded, setIsExpanded] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openMusicModal, setOpenMusicModal] = useState(false);
  const [openMusicMobileModal, setOpenMusicMobileModal] = useState(false);
  const [openVideoMobileModal, setOpenVideoMobileModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpenVideoMobileModal = () => setOpenVideoMobileModal(true);
  const handleCloseVideoMobileModal = () => setOpenVideoMobileModal(false);
  const handleOpenMusicMobileModal = () => setOpenMusicMobileModal(true);
  const handleCloseMusicMobileModal = () => setOpenMusicMobileModal(false);
  const handleOpenMusicModal = () => setOpenMusicModal(true);
  const handleCloseMusicModal = () => setOpenMusicModal(false);
  const handleOpenVideoModal = () => setOpenVideoModal(true);
  const handleCloseVideoModal = () => setOpenVideoModal(false);
  const handleOpenImageModal = () => setOpenImageModal(true);
  const handleCloseImageModal = () => setOpenImageModal(false);
  const handleCloseNotesModal = () => setOpenNotesModal(false);
  const handleOpenNotesModal = () => setOpenNotesModal(true);

  useEffect(async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(`/memories?memory[grave_id]=${grave.id}`);
      if (newData.data.memories.length > 0) {
        dispatch(addMemory(newData.data.memories));
        dispatch(setSubscription(newData.data.subscription));
      } else {
        dispatch(addMemory([]));
        dispatch(setSubscription(newData.data.subscription));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [grave.id]);

  return (
    <div className={styles['grave-wall']}>
      {openVideoModal && (
        <GraveWallVideoModal
          onOpen={openVideoModal}
          onClose={handleCloseVideoModal}
          title='Legg til video'
        />
      )}

      {openImageModal && (
        <GraveWallImageModal
          onOpen={openImageModal}
          onClose={handleCloseImageModal}
          title='Legg til bilde'
        />
      )}
      <Modal
        open={openMusicModal}
        className={styles['edit-virtue-modal_modal']}
        onClose={handleCloseMusicModal}
      >
        <MusicModal closeModal={handleCloseMusicModal} />
      </Modal>
      <Dialog
        open={openMusicMobileModal}
        className={styles['edit-virtue-modal_modal']}
        onClose={handleCloseMusicMobileModal}
      >
        <MusicMobile closeModal={handleCloseMusicMobileModal} />
      </Dialog>
      <Dialog
        open={openVideoMobileModal}
        className={styles['edit-virtue-modal_modal']}
        onClose={handleCloseVideoMobileModal}
      >
        <VideoMobile closeModal={handleCloseVideoMobileModal} />
      </Dialog>
      {openNotesModal && (
        <GraveNotesModal
          onOpen={openNotesModal}
          onClose={handleCloseNotesModal}
          grave={grave}
        />
      )}
      {props.hideMenu && props.aboutMenuKey ? (
        <React.Fragment>
          <div className={styles['grave-notes']}>
            <GraveNote
              notes={grave.notes}
              canEdit={grave.can_edit}
              onEdit={handleOpenNotesModal}
            />
          </div>
          <div className={styles['grave-wall-box-container']}>
            {props.aboutMenuKey === 1 && (
              <React.Fragment>
                <div className={styles['grave-wall-heading']}>Gravtavlen</div>
                {loading ? (
                  <Loader />
                ) : (
                  <Memories
                    isAuth={isAuth}
                    is_relative={is_relative}
                    user={user}
                    grave_name={grave?.name}
                    openVideoModal={handleOpenVideoModal}
                    openMusicModal={handleOpenMusicModal}
                    openMusicModalMobile={handleOpenMusicMobileModal}
                    openVideoModalMobile={handleOpenVideoMobileModal}
                    openImageModal={handleOpenImageModal}
                  />
                )}
              </React.Fragment>
            )}
            {props.aboutMenuKey === 2 && (
              <React.Fragment>
                <div className={styles['grave-wall-heading']}>Jubileer</div>
                <div className={styles['grave-wall-anniversary']}></div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles['grave-notes']}>
            <GraveNote
              notes={grave.notes}
              canEdit={grave.can_edit}
              onEdit={handleOpenNotesModal}
            />
          </div>
          <div className={styles['grave-wall-container-mobile']}>
            {/* ----------------memories for mobile screen  */}
            <div
              className={styles['grave-wall-item-header']}
              onClick={() => {
                setIsExpanded(!isExpanded);
                props.handelAboutMenuKey(1);
              }}
            >
              <div className={styles['grave-wall-heading-mobile']}>
                Gravtavlen
              </div>
              {props.aboutMenuKey === 1 && isExpanded ? (
                <img
                  src={UpArrow}
                  alt='down'
                  className={styles['collapse-arrow']}
                />
              ) : (
                <img
                  src={DownArrow}
                  alt='down'
                  className={styles['collapse-arrow']}
                />
              )}
            </div>
            {props.aboutMenuKey === 1 && isExpanded ? (
              loading ? (
                <Loader />
              ) : (
                <Memories
                  isAuth={isAuth}
                  is_relative={is_relative}
                  user={user}
                  grave_name={grave?.name}
                  openVideoModal={handleOpenVideoModal}
                  openMusicModal={handleOpenMusicModal}
                  openMusicModalMobile={handleOpenMusicMobileModal}
                  openVideoModalMobile={handleOpenVideoMobileModal}
                  openImageModal={handleOpenImageModal}
                />
              )
            ) : null}
          </div>
          <div className={styles['grave-wall-container-mobile']}>
            {/* anniversary for mobile screen */}
            <div
              className={styles['grave-wall-item-header']}
              onClick={() => {
                setIsExpanded(!isExpanded);
                props.handelAboutMenuKey(2);
              }}
            >
              <div className={styles['grave-wall-heading-mobile']}>
                Merkedager
              </div>
              {props.aboutMenuKey === 2 && isExpanded ? (
                <img
                  src={UpArrow}
                  alt='down'
                  className={styles['collapse-arrow']}
                />
              ) : (
                <img
                  src={DownArrow}
                  alt='down'
                  className={styles['collapse-arrow']}
                />
              )}
            </div>
            {props.aboutMenuKey === 2 && isExpanded ? (
              <div className={styles['grave-wall-anniversary']}></div>
            ) : null}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GraveWall;
