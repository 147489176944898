import { connect } from "react-redux";
import {
  addUserWallQuestion,
  initialiseUserWallQuestions,
  editUserWallQuestion,
  deleteUserWallQuestion,
  addUserWallAnswer,
  removeUserWallAnswer,
  editUserWallAnswer,
} from "../actions";

const initialUserProfile = {
  questions: [],
};

export const userProfileReducer = (state = initialUserProfile, action) => {
  switch (action.type) {
    case addUserWallQuestion:
      const newArray = [...action.data, ...state.questions];
      return {
        questions: newArray,
      };

    case initialiseUserWallQuestions:
      return {
        questions: action.data,
      };

    case editUserWallQuestion:
      const newQuestionsArray = state.questions.map((item) => {
        if (item.id === action.data.questionId) {
          item.content.description = action.data.upDatedquestion;
        }
        return item;
      });

      return {
        questions: newQuestionsArray,
      };

    case deleteUserWallQuestion:
      const newDeletedQuestionsArray = state.questions.filter((item) => {
        if (item.id === action.data.questionId) {
          return false;
        }

        return true;
      });

      return {
        questions: newDeletedQuestionsArray,
      };

    case addUserWallAnswer:
      const mappedQuestionArray = state.questions.map((item) => {
        if (item.id === action.data.questionId) {
          item.content.comments = [
            action.data.comment,
            ...item.content.comments,
          ];
        }
        return item;
      });
      return {
        questions: mappedQuestionArray,
      };

    case removeUserWallAnswer:
      const deletedAnswerArray = state.questions.filter((item) => {
        if (item.id === action.data.questionId) {
          item.content.comments = item.content.comments.filter((comment) => {
            if (comment.id === action.data.commentId) {
              return false;
            }

            return true;
          });
        }
        return true;
      });

      return {
        questions: deletedAnswerArray,
      };

    case editUserWallAnswer:
      const editedAnswerArray = state.questions.filter((item) => {
        if (item.id === action.data.questionId) {
          item.content.comments = item.content.comments.map((comment) => {
            if (comment.id === action.data.commentId) {
              comment.description = action.data.commentText;
            }

            return comment;
          });
        }
        return true;
      });

      return {
        questions: editedAnswerArray,
      };

    default:
      return state;
  }
};
