import React, { useEffect, useState } from 'react';
import styles from './UpdatePlanModal.module.css';
import './PlansCard.css';
import { Modal, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  setSubscriptionCount,
  setSelectedPlan,
  setSubscriptionTotalPrice,
  updateUserSubscription,
} from '../../../redux/actions';
import { fetch_request, request } from '../../../service/request';
import { CONSTANTS } from '../../../service/constants';
import loadingAnimation from '../../../service/Loading_animation.gif';
import isEmpty from 'lodash.isempty';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import SubscriptionMember from './SubscriptionMember';
import X from '../../../img/X.svg';
import Increment from './Increment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const UpdatePlanModal = ({
  onOpen,
  onClose,
  plan,
  isCurrentPlan,
  currentSubscription,
  handlePlanSelect,
  handelUpdateCurrentSubscription,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [subscriptionTotalCount, setSubscriptionTotalCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [planAction, setPlanAction] = useState('add');
  const [subscriptionValidation, setSubscriptionValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState(false);

  useEffect(() => {
    if (subscriptionTotalCount === 1) {
      setTotalPrice(plan.price);
    }
  }, [currentSubscription.id]);

  const handelSubscriptionValidation = () => {
    setLoading(true);
    const requestData = {
      subscription: {
        action_type: planAction,
        user_count: subscriptionTotalCount,
      },
    };
    fetch(
      `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}/validate_subscription_change`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.error) {
          showUserNotification(data.error, 'error');
        } else {
          setSubscriptionValidation(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handelUpdateSubscription = () => {
    const requestPayload = {
      subscription: {
        price: currentSubscription.price - totalPrice,
        user_count: parseInt(
          currentSubscription.user_count - subscriptionTotalCount
        ),
        product_id: plan.id,
      },
    };
    request(
      `/subscriptions/${currentSubscription.id}.json`,
      requestPayload,
      'put'
    )
      .then((res) => {
        dispatch(updateUserSubscription(true));
        const price = currentSubscription.price - totalPrice;
        handelUpdateCurrentSubscription(res.data, price);
        showUserNotification('abonnementsmedlemmer er fjernet', 'success');
        setSubscriptionTotalCount(1);
        dispatch(setSubscriptionCount(1));
        onClose();
      })
      .catch((error) => {
        console.log(error);
        showUserNotification('noe gikk galt, prøv igjen', 'error');
      });
  };

  const setTotalCount = (value) => {
    if (planAction === 'remove' && value < currentSubscription.user_count) {
      setRemove(false);
      if (subscriptionValidation.can_change_plan) {
        setSubscriptionValidation({});
      }
      setSubscriptionTotalCount(value);
      dispatch(setSubscriptionCount(value));
      const total = plan.price * value;
      dispatch(setSubscriptionTotalPrice(total));
      setTotalPrice(total);
    } else {
      setRemove(true);
    }
    if (planAction === 'add') {
      setRemove(false);
      setSubscriptionTotalCount(value);
      dispatch(setSubscriptionCount(value));
      const total = plan.price * value;
      dispatch(setSubscriptionTotalPrice(total));
      setTotalPrice(total);
    }
  };

  return (
    <Modal
      open={onOpen}
      // onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['modal-header']}>
          <div className={styles['plan-details-header']}>
            <span className={styles['plan-details-header_product_name']}>
              Endring {plan.name} plan
            </span>
            <span className={styles['plan-details-header_product_desc']}>
              {plan.description}
            </span>
          </div>
          <img
            loading='lazy'
            src={X}
            alt='x'
            onClick={() => {
              setSubscriptionTotalCount(1);
              dispatch(setSubscriptionCount(1));
              onClose();
            }}
          />
        </div>
        {loading && (
          <React.Fragment>
            <div className={styles['loading-heading']}>
              Vennligst vent Se etter abonnementsvalidering
            </div>
            <div className={styles['loading']}>
              <img src={loadingAnimation} alt='Loading....' />
            </div>
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            <div className='plan-amount'>
              <span
                className={
                  plan.key === 'free'
                    ? 'plan-amount-currency'
                    : 'plan-amount-currency-family'
                }
              >
                NOK {plan.price}
              </span>
              <span className='plan-amount-price'>
                {plan.key === 'free' ? ' per år' : ' per medlem /år'}
              </span>
            </div>
            {currentSubscription &&
            plan.key !== 'free' &&
            Object.keys(currentSubscription).length > 0 ? (
              <div className='subscription-user-count'>
                Nåværende medlemmer: {currentSubscription.user_count}
              </div>
            ) : null}
            {currentSubscription &&
            plan.key !== 'free' &&
            Object.keys(currentSubscription).length > 0 ? (
              <div className='subscription-user-count'>
                Planpris: {currentSubscription.price} NOK
              </div>
            ) : null}
            <div className={styles['payment-methods-container']}>
              <div
                className={styles['payment-method']}
                onClick={() => {
                  setPlanAction('add');
                  setRemove(false);
                  setSubscriptionValidation({});
                  setSubscriptionTotalCount(1);
                  dispatch(setSubscriptionCount(1));
                }}
              >
                <div className={styles['select-method']}>
                  <input
                    type='checkbox'
                    checked={planAction === 'add'}
                    readOnly
                  />
                  <label>Legg til medlemmer</label>
                </div>
              </div>
              <div
                className={styles['payment-method']}
                onClick={() => {
                  setPlanAction('remove');
                  setRemove(false);
                  setSubscriptionTotalCount(1);
                  dispatch(setSubscriptionCount(1));
                  setSubscriptionValidation({});
                }}
              >
                <div className={styles['select-method']}>
                  <input
                    type='checkbox'
                    checked={planAction === 'remove'}
                    readOnly
                  />
                  <label>Fjern medlemmer</label>
                </div>
              </div>
            </div>
            {plan.key !== 'free' && (
              <>
                {planAction === 'add' ? (
                  <div className='family-message'>
                    Hvor mange flere familiemedlemmer ønsker å legge til?
                  </div>
                ) : (
                  <div className='family-message'>
                    Hvor mange familiemedlemmer vil du fjerne?
                  </div>
                )}
                <Increment
                  setTotalCount={setTotalCount}
                  totalCount={subscriptionTotalCount}
                />
              </>
            )}
            {remove && (
              <div className={styles['remove-message']}>
                Du kan ikke fjerne alle medlemmene.
              </div>
            )}
            {!loading &&
              !isEmpty(subscriptionValidation) &&
              !subscriptionValidation.can_change_plan && (
                <SubscriptionMember
                  subscriptionId={subscriptionValidation.subscription_id}
                />
              )}
            {!loading &&
              !isEmpty(subscriptionValidation) &&
              subscriptionValidation.can_change_plan &&
              planAction === 'remove' && (
                <div>
                  <div className='subscription-user-count'>
                    Oppdatert pris etter endringene
                  </div>
                  <div className={styles['valid-subscription-user-count']}>
                    Totalt gjenværende medlemmer:{' '}
                    {subscriptionValidation.updated_user_count}
                  </div>
                  <div className={styles['valid-subscription-user-count']}>
                    Totalpris: {subscriptionValidation.updated_price} per år
                  </div>
                </div>
              )}
            {subscriptionTotalCount > 0 && planAction === 'add' && (
              <>
                <div className='count-number-message'>
                  For ekstra {subscriptionTotalCount} familiemedlemmer er en
                  ekstra årlig betaling
                </div>
                <div className='total-price-details'>
                  <div className='plan-amount'>
                    <span className='plan-amount-currency'>
                      NOK {totalPrice}
                    </span>
                    <span className='plan-amount-price'> /år</span>
                  </div>
                  <div className='total-counting'>
                    {subscriptionTotalCount} * NOK {plan.price}
                  </div>
                </div>
              </>
            )}
            <div className={styles['plans-btn-wrapper']}>
              <div className={styles['plans-btn-container']}>
                {!isEmpty(subscriptionValidation) &&
                subscriptionValidation.can_change_plan &&
                planAction === 'add' ? (
                  <button
                    className={styles['plans-process-btn']}
                    onClick={() => {
                      dispatch(setSelectedPlan(plan));
                      handlePlanSelect();
                    }}
                  >
                    Fortsett til betaling
                  </button>
                ) : !isEmpty(subscriptionValidation) &&
                  subscriptionValidation.can_change_plan &&
                  planAction === 'remove' ? (
                  <button
                    className={styles['plans-process-btn']}
                    onClick={() => {
                      dispatch(setSelectedPlan(plan));
                      handelUpdateSubscription();
                    }}
                  >
                    Fortsette
                  </button>
                ) : (
                  <button
                    className={styles['plans-process-btn']}
                    onClick={() => {
                      handelSubscriptionValidation();
                    }}
                  >
                    Neste
                  </button>
                )}
                <button
                  className={styles['plans-cancel-btn']}
                  onClick={() => {
                    setSubscriptionTotalCount(1);
                    dispatch(setSubscriptionCount(1));
                    onClose();
                  }}
                >
                  Avbryt
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

export default UpdatePlanModal;
