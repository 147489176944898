import React, { useState } from 'react';
import styles from './Admin.module.css';
import { Avatar, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    marginBottom: '8px',
  },
}));

const Admin = ({ user }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <div
        className={styles['container']}
        onClick={() => history.push(`/member/${user.slug}`)}
      >
        <Avatar src={user.user_avatar} className={classes.large} />
        <div className={styles['user-name']}>{user.user_name}</div>
        <div className={styles['user-relation']}>{user.relation_name}</div>
        {user.grave_owner ? (
          <div className={styles['user-role']}>graveier</div>
        ) : user.admin ? (
          <div className={styles['user-role']}>Admin</div>
        ) : null}
      </div>
    </div>
  );
};

export default Admin;
