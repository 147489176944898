import React, { useState, useEffect } from 'react';
import styles from './GiftSubscription.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { fetch_request, request } from '../../../../service/request';
import axios from 'axios';
import { CONSTANTS } from '../../../../service/constants';
import loadingAnimation from '../../../../service/Loading_animation.gif';
import PopupModal from '../../../Payment/atoms/Modal/Modal';
import {
  getListOfCountries,
  setCurrentSubscriptionPlan,
} from '../../../../redux/actions';
import GiftSubscriptionForm from '../GiftSubscriptionForm/GiftSubscriptionForm';
import SearchInput from '../../../../components/SearchInput';
import { Link, useHistory, useLocation } from 'react-router-dom';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  tabContainer: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  indicator: {
    backgroundColor: '#985000',
  },
  tab: {
    flex: 1,
    marginRight: '70px',
    color: '#985000',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const GiftSubscription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyle();
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptionInvitations, setSubscriptionInvitations] = useState([]);
  const [invitationId, setInvitationId] = useState('');
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [remainingCount, setRemainingCount] = useState('');
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState({});
  const plan = useSelector((state) => state.selectedPlan || null);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    const show = location.state?.show;

    if (show) {
      setModalOpen(true);
      history.replace({ show: undefined });
    }
  }, [location]);

  // Get Countries
  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch(getListOfCountries(data));
        setCountriesList(data.countries);
      })
      .catch((err) => console.log(err));
  }, []);

  // Get Relations
  useEffect(() => {
    request('/relations')
      .then((res) => setRelationList(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data) {
          setCurrentSubscriptionPlan(res.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (currentSubscriptionPlan.id) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/subscription/${currentSubscriptionPlan.id}/subscription_invitations`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            showUserNotification('noe gikk galt, prøv igjen', 'error');
          } else {
            setSubscriptionInvitations(data.subscription_invitations);
            setRemainingCount(data.remaining_count);
          }
        })
        .catch((err) => {
          showUserNotification(err.data, 'error');
          console.log(err);
        });
    }
  }, [currentSubscriptionPlan]);

  const handelSubscriptionInvitations = (value) => {
    setSubscriptionInvitations([...value]);
  };

  const saveSubscriptionInvitationsRedux = (value) => {
    // dispatch(setiftInvitationssRedux([...value]));
  };

  const handelCloseForm = () => {
    setOpenForm(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handelInvitationId = (value) => {
    if (value === invitationId) {
      setInvitationId(null);
    } else {
      setInvitationId(value);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles['invitations']}>
      <div className={styles['invitations-content']}>
        <div className={styles['header']}>
          <span className={styles['heading']}>Gaveinvitasjoner</span>
          <span className={styles['sub_heading']}>
            Vær en god gjeter og vis vei til de som kommer etter deg. Her gir du
            medlemskap, til de du tror trenger det.
          </span>
        </div>
        {loading && isEmpty(currentSubscriptionPlan) && (
          <div className={styles['loading']}>
            <img src={loadingAnimation} alt='Loading....' />
          </div>
        )}
        {currentSubscriptionPlan.product?.key === 'free' && !loading && (
          <div className={styles['zero-invitation']}>
            <div className={styles['zero-invitation-message']}>
              Du er nå på Fri planen. Oppdater abonnementet ditt for ubegrenset
              tilgang til alle Nettgravs tjenester og for å invitere flere
              medlemmer:
            </div>
            <button
              className={styles['btn-invitation']}
              onClick={() => history.push('/plans')}
            >
              Se abonnementene
            </button>
          </div>
        )}
        {!loading &&
        currentSubscriptionPlan.product?.key !== 'free' &&
        currentSubscriptionPlan?.status !== 'pending' ? (
          <>
            <div className={styles['plan-container']}>
              <span className={styles['plan-heading']}>
                Du har {remainingCount}{' '}
                {Object.keys(currentSubscriptionPlan).length > 0
                  ? currentSubscriptionPlan.product?.name
                  : ''}{' '}
                invitasjoner igjen.{' '}
                <Link className={styles['plan-link']} to={'/plans'}>
                  Klikk her for å
                </Link>{' '}
                legge til flere medlemskap
              </span>
            </div>
            <div className={classes.tabContainer}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant='scrollable'
                id='id223223'
                aria-label='scrollable force tabs example'
                classes={{ indicator: classes.indicator }}
                style={{ width: '100%' }}
              >
                <Tab
                  label='Legg til medlem'
                  {...a11yProps(0)}
                  className={classes.tab}
                />
                <Tab
                  label='Inviterte medlemmer'
                  {...a11yProps(1)}
                  className={classes.tab}
                />
              </Tabs>
            </div>
            {tabValue === 0 && (
              <React.Fragment>
                {remainingCount > 0 ? (
                  <GiftSubscriptionForm
                    subscriptionInvitations={subscriptionInvitations}
                    handelSubscriptionInvitations={
                      handelSubscriptionInvitations
                    }
                    saveSubscriptionInvitationsRedux={
                      saveSubscriptionInvitationsRedux
                    }
                    invitation={{}}
                    invitationId={null}
                    subscriptionId={currentSubscriptionPlan.id}
                    disable={true}
                    handelCloseForm={handelCloseForm}
                    handelInvitationId={handelInvitationId}
                    handleTabChange={() => setTabValue(1)}
                    relationList={relationList}
                    countriesList={countriesList}
                    handelRemainingCount={() =>
                      setRemainingCount(remainingCount - 1)
                    }
                    setCount={() => setRemainingCount(remainingCount + 1)}
                  />
                ) : (
                  <div>
                    <span className={styles['plan-heading']}>
                      Du har {remainingCount}{' '}
                      {Object.keys(currentSubscriptionPlan).length > 0
                        ? currentSubscriptionPlan.product?.name
                        : ''}{' '}
                      invitasjoner igjen.{' '}
                      <Link className={styles['plan-link']} to={'/plans'}>
                        Klikk her
                      </Link>{' '}
                      for å legge til flere medlemskap i planen din.
                    </span>
                  </div>
                )}
              </React.Fragment>
            )}
            <div className={styles['invitations-list-wrapper']}>
              {tabValue === 1 && (
                <>
                  {subscriptionInvitations.length > 0 ? (
                    subscriptionInvitations.map((invitation) => {
                      return (
                        <div
                          className={styles['invitations-list']}
                          style={{
                            marginBottom: 24,
                            border: '1px solid rgb(221 225 229)',
                            borderRadius: 10,
                            padding: 24,
                          }}
                          key={invitation.id}
                        >
                          <GiftSubscriptionForm
                            subscriptionInvitations={subscriptionInvitations}
                            handelSubscriptionInvitations={
                              handelSubscriptionInvitations
                            }
                            saveSubscriptionInvitationsRedux={
                              saveSubscriptionInvitationsRedux
                            }
                            invitation={invitation}
                            invitationId={invitationId}
                            subscriptionId={currentSubscriptionPlan.id}
                            disable={invitationId === invitation.id}
                            handelCloseForm={handelCloseForm}
                            handelInvitationId={handelInvitationId}
                            handleTabChange={() => setTabValue(1)}
                            relationList={relationList}
                            countriesList={countriesList}
                            handelRemainingCount={() =>
                              setRemainingCount(remainingCount - 1)
                            }
                            setCount={() =>
                              setRemainingCount(remainingCount + 1)
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles['empty-invitation-container']}>
                      <div className={styles['empty-invitation-wrapper']}>
                        <span>Ingen ubesvarte invitasjoner</span>
                        <button onClick={() => setTabValue(0)}>
                          Klikk her for å invitere inn til ditt
                          familieabonnement
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          !loading &&
          currentSubscriptionPlan.product?.key !== 'free' &&
          currentSubscriptionPlan?.status === 'pending' && (
            <div className={styles['subscription-status']}>
              Abonnementet ditt venter for øyeblikket.
            </div>
          )
        )}
      </div>
      {modalOpen && (
        <PopupModal open={modalOpen} handleClose={closeModal} plan={plan} />
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default GiftSubscription;
