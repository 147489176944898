import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './BackButton.module.css';

const BackButton = ({ fallBackUrl, title, icon }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div
      className={styles['container']}
      onClick={() => {
        if (location.key) {
          history.goBack();
        } else {
          history.push(`${fallBackUrl}`);
        }
      }}
    >
      <img src={icon} alt='back' />
      <span className={styles['title']}>{title}</span>
    </div>
  );
};

export default BackButton;
