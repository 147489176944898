import React from 'react';
import './Graves.css';
import SubMenu from '../../components/SubMenu/SubMenu'
const Graves = (props) => {
    return(
        <div className='connected-graves-route'>
            <SubMenu/>
        </div>
    )
}

export default Graves;