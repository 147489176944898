import { ListItem, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './SettingsPageHeader.module.css';
import noGender from '../../../img/SexType.svg';
import MenPhoto from '../../../img/NonePhotoMen.svg';
import WomenPhoto from '../../../img/NonePhotoWomen.svg';
import BackIcon from '../../../img/BackIcon.svg';
import 'cropperjs/dist/cropper.css';
import UserProfileHeader from '../../../pages/UserProfile/components/UserProfileHeader';
import UserProfileImage from '../../../components/UserProfileImage';
import isEmpty from 'lodash.isempty';
import BackButton from '../../../components/BackButton/BackButton';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '32px 14px',
    cursor: 'pointer',
    color: '#19262D',
    fontFamily: 'Roboto Condensed',
    fontSize: '22px',
    fontWeight: '700',
    lineHeight: '18px',
    wordWrap: 'anywhere',
    backgroundColor: '#E4F3FD!important',
    marginBottom: '4px',
    '&:focus': {
      backgroundColor: '#19262D',
    },
    '&:last-child': {
      borderRadius: '0px 0px 10px 10px',
    },
    '@media (max-width: 750px)': {
      padding: '15px 25px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  listItemSelected: {
    color: '#E4F3FD',
    backgroundColor: '#19262D!important',
  },
}));

const familySubscriptionMenu = [
  {
    name: 'Personinfo',
    url: `/settings`,
  },
  {
    name: 'Passord',
    url: `/settings/password`,
  },
  {
    name: 'Betaling',
    url: `/settings/payment-methods`,
  },
  {
    name: 'Abonnement',
    url: `/settings/subscriptions`,
  },
  {
    name: 'Medlemmer',
    url: `/settings/plan-members`,
  },
  {
    name: 'Gaveinvitasjoner',
    url: `/settings/subscription-invitations`,
  },
  {
    name: 'Varslinger',
    url: `/settings/notifications`,
  },
  // {
  //   name: 'Konto',
  //   url: `/settings/account`,
  // },
];

const familyPlusSubscriptionMenu = [
  ...familySubscriptionMenu,
  { name: 'Kontakt Nettgrav', url: `/settings/contact` },
];

const SettingsPageHeader = ({ currentSubscription }) => {
  const monthArray = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  const [menuItems, setMenuItems] = useState([]);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!isEmpty(currentSubscription)) {
      currentSubscription.key === 'family-plus'
        ? setMenuItems(familyPlusSubscriptionMenu)
        : setMenuItems(familySubscriptionMenu);
    } else {
      setMenuItems(familySubscriptionMenu);
    }
  }, [currentSubscription]);

  const handleClick = (url) => {
    history.push(url);
  };

  const genderAvatar_forBackground = () => {
    if (user.avatar) return user.avatar;
    return user.gender?.toLowerCase() === 'mann'
      ? MenPhoto
      : user.gender?.toLowerCase() === 'kvinne'
      ? WomenPhoto
      : noGender;
  };

  return (
    <div className={styles['settings-header']}>
      <BackButton title='Tilbake' icon={BackIcon} fallBackUrl={'/'} />
      <div className={styles['header-title']}>Profil</div>
      <div className={styles['settings-page-nav']}>
        <div className={styles['settings-page_header-info']}>
          <div className={styles['settings-page_header-info-userImage']}>
            <UserProfileImage user={user} />
          </div>
          <span className={styles['settings-page_header-info_name']}>
            {!isEmpty(user) ? user.full_name : ''}
          </span>
          <span className={styles['settings-page_header-info_date-of-birth']}>
            {!isEmpty(user)
              ? `${new Date(user.date_of_birth).getDate()} ${
                  monthArray[new Date(user.date_of_birth).getMonth()]
                }, ${new Date(user.date_of_birth).getFullYear()}`
              : ''}
          </span>
        </div>
        <div className={styles['settings-page_header-navigation']}>
          <div className={styles['settings-page_header-navigation_first']}>
            {menuItems.map((item, index) => (
              <ListItem
                key={index}
                selected={item.url === location.pathname}
                onClick={() => {
                  handleClick(item.url);
                }}
                classes={{
                  root: classes.listItem,
                  selected: classes.listItemSelected,
                }}
              >
                {item.name}
              </ListItem>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPageHeader;
