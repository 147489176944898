import React, { useState } from 'react';
import EndMore from '../../../img/EndMore.svg';
import EndLess from '../../../img/EndLess.svg';
import './FrequentlyAskedQuestions.css';

const FrequentlyAskedQuestions = ({ question }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className='faq-container'>
      <div className='faq-question' onClick={handleClick}>
        <span className='question'>{question.question}</span>
        <span>
          {open ? (
            <img src={EndLess} alt='Less' />
          ) : (
            <img src={EndMore} alt='More' />
          )}
        </span>
      </div>
      {open && (
        <div className='answer'>
          <span>{question.answer}</span>
        </div>
      )}
    </div>
  );
};

export default FrequentlyAskedQuestions;
