import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './CountriesPopover.css';

const CountriesPopover = (props) => {
  const [country, setCountry] = React.useState('');
  const [searchArr, setSearchArr] = React.useState([]);
  const countries = useSelector((state) => state.auth.countries);

  useEffect(() => {
    if (country.trim().length > 0) {
      const search = countries.filter((countryElem) =>
        countryElem.name.toLowerCase().includes(country.toLocaleLowerCase())
      );
      setSearchArr(search);
    }
  }, [country, countries]);

  const norwayData = countries.find((country) => country.dial_code === '+47');
  const germanyData = countries.find((country) => country.dial_code === '+49');
  const usaData = countries.find(
    (country) => country.dial_code === '+44'  );

  return (
    <div className='countries-popover_content'>
      <div className='countries-popover_content-header'>
        <input
          autoFocus
          placeholder='Search country'
          type='text'
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <Button
          onClick={() => props.handleChoseCountryFromList(norwayData)}
          className={
            props.country === norwayData.id
              ? 'countries-popover_content-header_country chosen'
              : 'countries-popover_content-header_country'
          }
        >
          Norway
          <span
            className={
              props.country === norwayData.id
                ? 'countries-popover_content-header_country-dial chosen'
                : 'countries-popover_content-header_country-dial'
            }
          >
            +47
          </span>
        </Button>
        <Button
          onClick={() => props.handleChoseCountryFromList(germanyData)}
          className={
            props.country === germanyData.id
              ? 'countries-popover_content-header_country chosen'
              : 'countries-popover_content-header_country'
          }
        >
          Germany
          <span
            className={
              props.country === germanyData.id
                ? 'countries-popover_content-header_country-dial chosen'
                : 'countries-popover_content-header_country-dial'
            }
          >
            +49
          </span>
        </Button>
        <Button
          onClick={() => props.handleChoseCountryFromList(usaData)}
          className={
            props.country === usaData.id
              ? 'countries-popover_content-header_country chosen'
              : 'countries-popover_content-header_country'
          }
        >
          United Kingdom
          <span
            className={
              props.country === usaData.id
                ? 'countries-popover_content-header_country-dial chosen'
                : 'countries-popover_content-header_country-dial'
            }
          >
            +44
          </span>
        </Button>
      </div>
      <div className='countries-popover_content-line' />
      <div className='countries-popover_content-list'>
        {!country &&
          countries.map((country) => (
            <Button
              onClick={() => props.handleChoseCountryFromList(country)}
              className={
                props.country === country.id
                  ? 'countries-popover_content-header_country chosen'
                  : 'countries-popover_content-header_country'
              }
              key={country.id}
            >
              {country.name}
              <span
                className={
                  props.country === country.id
                    ? 'countries-popover_content-header_country-dial chosen'
                    : 'countries-popover_content-header_country-dial'
                }
              >
                {country.dial_code}
              </span>
            </Button>
          ))}
        {country &&
          searchArr.map((country) => (
            <Button
              onClick={() => props.handleChoseCountryFromList(country)}
              className={
                props.country === country.id
                  ? 'countries-popover_content-header_country chosen'
                  : 'countries-popover_content-header_country'
              }
              key={country.id}
            >
              {country.name}
              <span
                className={
                  props.country === country.id
                    ? 'countries-popover_content-header_country-dial chosen'
                    : 'countries-popover_content-header_country-dial'
                }
              >
                {country.dial_code}
              </span>
            </Button>
          ))}
      </div>
    </div>
  );
};

export default CountriesPopover;
