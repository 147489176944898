import { Button, InputLabel, TextField } from "@material-ui/core";
import React, { useState } from "react";
import "../ResetPass.css";
import axios from "axios";
import { fetch_request } from "../../../service/request";
import { CONSTANTS } from "../../../service/constants";
import showUserNotification from "../../../components/UserNotification/showUserNotification";

const ResetThirdStep = (props) => {
  const [code, setCode] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlepPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const isSubmitEnabled = () => {
    return password && confirmPassword;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      let mainData = new FormData();
      mainData.append("phone_number", props.phoneData.phoneNumber);
      mainData.append("auth_code", props.authCode);
      mainData.append("new_password", password);
      mainData.append("new_password_confirmation", confirmPassword);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "*/*",
        },
        redirect: "follow",
      };
      var verificationData = new FormData();
      verificationData.append("phone_number", `${props.phoneData.phoneNumber}`);
      verificationData.append("auth_code", `${code.join("")}`);
      var verificationOptions = {
        method: "POST",
        body: verificationData,
        redirect: "follow",
      };
      axios
        .post(`${CONSTANTS.baseUrl}/api/forgot_passwords`, mainData, config)
        .then((response) => {
          if (response.data.success) {
            showUserNotification("Passord er oppdatert", "success");
            setTimeout(3000);
            window.location.reload();
          }
        })
        .catch((err) => console.log(err));
    } else if (password !== confirmPassword) {
      showUserNotification('"Passordene stemmer ikke', "error");
    }
  };

  const isPasswordValid = () => {
    // Minimum password length: 7-8 characters
    if (password.length < 7) {
      return false;
    }

    // Password complexity: at least 3 out of 4 categories
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const nonAlphanumericRegex = /[^A-Za-z0-9]/;
    let categoriesCount = 0;

    if (uppercaseRegex.test(password)) {
      categoriesCount++;
    }
    if (lowercaseRegex.test(password)) {
      categoriesCount++;
    }
    if (digitRegex.test(password)) {
      categoriesCount++;
    }
    if (nonAlphanumericRegex.test(password)) {
      categoriesCount++;
    }

    return categoriesCount >= 3;
  };

  const passwordsMatch = () => {
    return password === confirmPassword;
  };

  return (
    <div className="auth-reset_first-step">
      <form
        className="auth-form-reset"
        autoComplete="off"
        onSubmit={handleFormSubmit}
      >
        <div className="auth-welcome_input create-membership_first__input third-step">
          <InputLabel className="auth-welcome_input-label">
            Nytt passord
          </InputLabel>
          <TextField
            id="filled-basic"
            placeholder="Skriv inn passord"
            variant="filled"
            className="auth-welcome_email-button"
            type="password"
            value={password}
            onChange={handlepPasswordChange}
            required
          />
        </div>

        {password.length > 0 && !isPasswordValid() && (
          <span className="password-error-message">
            <span className="register-password-error-message-subheading-new-password">{`Passordet må bestå av minst 7 tegn og inneholde minst 3 av de 4 kategoriene:`}</span>
            <br />
            <br />
            {`⚙︎ Stor bokstav`}
            <br />
            {`⚙︎ Små bokstaver`}
            <br />
            {`⚙︎ Sifre`}
            <br />
            {`⚙︎ Ikke-alfanumeriske tegn`}
            <br />
          </span>
        )}

        <div className="auth-welcome_input create-membership_first__input third-step">
          <InputLabel className="auth-welcome_input-label">
            Bekreft nytt passord{" "}
          </InputLabel>
          <TextField
            id="filled-basic"
            placeholder="Skriv inn passordet på nytt"
            variant="filled"
            className="auth-welcome_email-button"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            disabled={!isPasswordValid()}
          />
        </div>

        {confirmPassword.length > 0 &&
          !passwordsMatch() &&
          isPasswordValid() && (
            <span className="password-error-message">
              Passordene er ikke like
            </span>
          )}

        <div className="create-membership_first__buttons second">
          <button
            className="create-membership_second__button_back"
            onClick={props.prevPage}
          >
            Tilbake
          </button>
          <button
            variant="contained"
            className="auth-welcome_contained-button"
            disabled={!isPasswordValid() || !passwordsMatch()}
            type="submit"
          >
            Lagre
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetThirdStep;
