import React from 'react';
import styles from './OpenClaimModal.module.css';
import { makeStyles, Modal } from '@material-ui/core';
import X from '../../../../../../img/X.svg';
import PrimaryButton from '../../../../../../components/PrimaryButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 10,
    outline: 'none',
    '@media (max-width: 600px)': {
      width: '100%',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const buttonStyles = {
  backgroundColor: '#fff',
  color: '#404D56',
  borderRadius: 6,
  border: '1px solid #404D56',
  padding: '7px 16px',
  width: 'max-content',
};

const OpenClaimModal = ({ onOpen, onClose, onConfirm, grave }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Modal
      open={onOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['header']}>
          <span>Krav grav</span>
          <img loading='lazy' src={X} alt='x' onClick={onClose} />
        </div>
        <div className={styles['sub-heading']}>
          Vil du gjøre krav på dette gravstedet?
        </div>
        <div className={styles['btn-container']}>
          <PrimaryButton
            onClick={onConfirm}
            children='Nei'
            style={buttonStyles}
          />
          <PrimaryButton
            onClick={() => history.push(`/grave/${grave.slug}/claim`)}
            children='Ja'
          />
        </div>
      </div>
    </Modal>
  );
};

export default OpenClaimModal;
