import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Filter from '../../img/Filter.svg';
import './SubMenu.css';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';
import { request } from "../../service/request";

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SearchGraves from '../../containers/Search/SearchGraves';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { returnPage, setReturnPage } from '../../redux/actions';
import SearchCount from './SearchCount';

const SubMenu = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  // const [showSearchCount, setShowSearchCount] = useState(false)
  // const [searchCount, setSearchCount] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const onClickNewGrave = () => {
    dispatch(setReturnPage('/ny-grav'));
    if (!user || !user.is_subscribed) {
      setRedirect(false);
      history.push('/plans');
    } else {
      setRedirect(true);
      history.push('/ny-grav');
    }
  };

  useEffect(() => {
    props.searchInput === '' && !isFilterOpen ? setValue(0) : setValue(1);
  }, [props.searchInput, isFilterOpen]);

  // useEffect(() => {
  //   updateSearchCountLimit()
  // }, [props.searchInput])

  // const updateSearchCountLimit = async () => {
  //   const slug = localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  //   request(`/users/${slug}/available_search_count`)
  //     .then(res => { 
  //       if (res.status == 200) {
  //         if (res.data.count > 0) {
  //           setSearchCount(res.data.count)
  //           // setShowSearchCount(true)
  //         }
  //       } 
  //     })
  //     .catch(err => console.log(err))
  // }

  return (
    <div className='sub-menu'>
      <SearchCount/>
      {redirect && <Link to='/ny-grav' />}

      <TabPanel value={1} index={1}>
        <div className='button-container'>
          <Button
            variant='outlined'
            className={'sub-menu_button__response'}
            onClick={handleClick}
          >
            <img
              loading='lazy'
              src={Filter}
              alt='Filter'
              className='sub-menu_img'
            />
            Filter
          </Button>
          <Button
            variant='outlined'
            className={
              isFilterOpen
                ? 'sub-menu_button__response active'
                : 'sub-menu_button__response'
            }
            onClick={onClickNewGrave}
          >
            Opprett ny nettgrav
          </Button>
        </div>
        <SearchGraves
          searchInput={props.searchInput}
          isFilterOpen={isFilterOpen}
        />
      </TabPanel>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => {
  return {
    searchInput: state.search.searchInput,
    peopleArr: state.search.peopleArr,
  };
};

export default connect(mapStateToProps, null)(SubMenu);
