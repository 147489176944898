import React, { useState, useEffect } from 'react';
import styles from './Subscriptions.module.css';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ActiveGiftMembership from './ActiveGiftMembership';
import axios from 'axios';
import { fetch_request } from '../../../service/request';
import { CONSTANTS } from '../../../service/constants';
import PopupModal from '../../Payment/atoms/Modal/Modal';
import loadingAnimation from '../../../service/Loading_animation.gif';
import CancelSubscriptionModal from './CancelSubscriptionModal/CancelSubscriptionModal';
import isEmpty from 'lodash.isempty';
import { Badge } from '@material-ui/core';
import BillingInformation from './BillingInformation/BillingInformation';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import PaidSubscription from './PaidSubscription/PaidSubscription';
import FreeSubscription from './FreeSubscription/FreeSubscription';

const Subscriptions = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loadingGiftMembers, setLoadingGiftMembers] = useState(true);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [giftedSubscriptionMembers, setGiftedSubscriptionMembers] = useState(
    []
  );
  const [paymentMethod, setPaymentMethod] = useState({});
  const [billingInformation, setBillingInformation] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const plan = useSelector((state) => state.selectedPlan || null);
  const user = useSelector((state) => state.user?.user) || {};
  const subscription = user.subscription || {};
  const validUntil =
    subscription.valid_until && new Date(subscription.valid_until);
  const userReadableDate =
    validUntil &&
    `${validUntil.toLocaleString('en-US', {
      month: 'long',
    })} ${validUntil.toLocaleString('en-US', {
      day: '2-digit',
    })}, ${validUntil.getFullYear()}`;
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    const show = location.state?.show;

    if (show) {
      setModalOpen(true);
      history.replace({ show: undefined });
    }
  }, [location]);

  useEffect(() => {
    if (userSlug) {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: '*/*',
        },
        redirect: 'follow',
      };
      axios
        .get(
          `${CONSTANTS.baseUrl}/api/users/${userSlug}/subscriptions/current_subscription`,
          config
        )
        .then((res) => {
          if (res.data) {
            setCurrentSubscription(res.data.subscription);
            setPaymentMethod(res.data.payment_method);
            setSubscriptionStatus(res.data.subscription.status);
            setBillingInformation(res.data.billing_information);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [userSlug]);

  useEffect(() => {
    if (userSlug) {
      setLoadingGiftMembers(true);
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/users/${userSlug}/subscriptions/gifted_subscriptions`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setGiftedSubscriptionMembers(data.gifted_subscriptions);
          setLoadingGiftMembers(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingGiftMembers(false);
        });
    }
  }, [userSlug]);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <div className={styles['subscription-heading']}>Aktivt abonnement</div>
      {loading && currentSubscription && isEmpty(currentSubscription) && (
        <div className={styles['loading']}>
          <img src={loadingAnimation} alt='Loading....' />
        </div>
      )}
      {currentSubscription && !isEmpty(currentSubscription) && !loading ? (
        <React.Fragment>
          <div className={styles['subscription-card']}>
            {subscriptionStatus === 'pending' && (
              <div className={styles['subscription-status']}>pending</div>
            )}
            {currentSubscription.plan_key === 'free' ? (
              <FreeSubscription currentSubscription={currentSubscription} />
            ) : (
              <React.Fragment>
                <PaidSubscription currentSubscription={currentSubscription} />
                <div className={styles['payment-details-wrapper']}>
                  {paymentMethod && (
                    <PaymentMethod paymentMethod={paymentMethod} />
                  )}
                  {billingInformation && (
                    <BillingInformation
                      billingInformation={billingInformation}
                    />
                  )}
                </div>
                <div className={styles['btn-container']}>
                  <button
                    className={styles['change-plan']}
                    onClick={() => history.push('/plans')}
                  >
                    Endre plan
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
          {currentSubscription.plan_key !== 'free' && (
            <div className={styles['payment-details-wrapper-mobile']}>
              {paymentMethod && <PaymentMethod paymentMethod={paymentMethod} />}
              {billingInformation && (
                <BillingInformation billingInformation={billingInformation} />
              )}
            </div>
          )}
        </React.Fragment>
      ) : !loading ? (
        <div className={styles['empty-gift-memberships']}>
          Du har ikke et aktivt abonnement. Gå til plansiden for å velge en ny
          plan.{' '}
          <Link className={styles['plan-link']} to={'/plans'}>
            Klikk her
          </Link>
        </div>
      ) : null}
      <div className={styles['active-memberships-header']}>
        Medlemskap du deltar i <span>(Mottatt medlemskap)</span>
      </div>
      {giftedSubscriptionMembers && giftedSubscriptionMembers.length > 0 ? (
        <React.Fragment>
          {loadingGiftMembers &&
            giftedSubscriptionMembers &&
            isEmpty(giftedSubscriptionMembers) && (
              <div className={styles['loading']}>
                <img src={loadingAnimation} alt='Loading....' />
              </div>
            )}
          {!loadingGiftMembers && giftedSubscriptionMembers.length > 0 && (
            <div className={styles['membership-cards']}>
              {giftedSubscriptionMembers.map((membership) => {
                return <ActiveGiftMembership membership={membership} />;
              })}
            </div>
          )}
        </React.Fragment>
      ) : !loadingGiftMembers ? (
        <div className={styles['empty-gift-memberships']}>
          Du har ingen familieinvitasjoner akkurat nå
        </div>
      ) : null}

      {modalOpen && (
        <PopupModal open={modalOpen} handleClose={closeModal} plan={plan} />
      )}
      {openCancelModal && (
        <CancelSubscriptionModal
          open={openCancelModal}
          planeName={currentSubscription.plan_key}
          handleClose={() => setOpenCancelModal(false)}
          cancelSubscription={() => setOpenCancelModal(false)}
        />
      )}
    </div>
  );
};

export default Subscriptions;
