import React, { useEffect, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import styles from './SubscriptionMembers.module.css';
import { request } from '../../../service/request';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../service/constants';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));
const DeleteSubscriptionMembers = ({
  members,
  invitations,
  currentSubscriptionData,
  updateSubscription,
}) => {
  const classes = useStyles();

  const handelRemoveMember = (id) => {
    if (id) {
      fetch(`${CONSTANTS.baseUrl}/api/subscription_members/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const newArray = currentSubscriptionData.subscription_members.filter(
            (member) => member.id !== id
          );
          updateSubscription('members', newArray);
          showUserNotification('invitasjonsmedlemmet er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    }
  };

  const handelRemoveInvitation = (id) => {
    if (id) {
      fetch(`${CONSTANTS.baseUrl}/api/subscription_invitations/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          console.log('delete-invitation');
          const newArray =
            currentSubscriptionData.subscription_invitations.filter(
              (invitation) => invitation.invitation_id !== id
            );
          updateSubscription('invitation', newArray);
          showUserNotification('invitasjonsmedlemmet er slettet', 'success');
        })
        .catch((error) => {
          console.log('error');
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    }
  };

  return (
    <div style={{ marginTop: 24 }}>
      <div className={styles['subscription-members']}>
        <div className={styles['member-heading']}>Aktive medlemmer av abonnementet ditt</div>
        <div className={styles['member-sub-heading']}>
          Du må deaktivere medlemmene for å fortsette til fri plan
        </div>
        {members.length > 0 ? (
          members.map((member, index) => {
            return (
              <div className={styles['member-container']} key={index}>
                <div className={styles['avatar-wrapper']}>
                  <Avatar src={member?.user_avatar} className={classes.large} />
                  <div className={styles['user-name']}>{member?.full_name}</div>
                </div>
                <button
                  className={styles['remove-button']}
                  onClick={() => handelRemoveMember(member.id)}
                >
                  Deaktivere
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles['empty-message']}>
            Ingen aktive medlemmer funnet i ditt abonnement
          </div>
        )}
      </div>
      <div className={styles['subscription-invitations']}>
        <div className={styles['member-heading']}>
          Aktive invitasjoner til ditt abonnement
        </div>
        <div className={styles['member-sub-heading']}>
        Du må deaktivere medlemsinvitasjonene for å fortsette til fri plan
        </div>
        {invitations.length > 0 ? (
          invitations.map((invite, index) => {
            return (
              <div className={styles['member-container']} key={index}>
                <div className={styles['invitation-wrapper']}>
                  <div className={styles['user-phone-number']}>
                    {invite?.phone_number}
                  </div>
                  <div className={styles['user-name']}>{invite?.full_name}</div>
                </div>
                <button
                  className={styles['remove-button']}
                  onClick={() => handelRemoveInvitation(invite.invitation_id)}
                >
                  Deaktiver
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles['empty-message']}>
            Ingen utestående invitasjoner knyttet til ditt abonnement
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteSubscriptionMembers;
