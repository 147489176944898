import React, { useEffect, useState } from 'react';
import styles from './GraveNotesForm.module.css';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import isEmpty from 'lodash.isempty';
import { request } from '../../../../service/request';
import { useDispatch } from 'react-redux';
import { goToChosenGrave } from '../../../../redux/actions';

const GraveNotesForm = ({
  grave,
  onClose,
  isChanged,
  openDiscardModal,
  handelDiscardModal,
  handelIsChanged,
}) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState('');
  const [openSaveModal, setOpenSaveModal] = useState(false);

  useEffect(() => {
    if (!isEmpty(grave.notes)) {
      setNotes(grave.notes);
    }
  }, [grave]);

  const createNotes = () => {
    handelIsChanged(false);
    const formData = new FormData();
    formData.append('grave[notes]', notes);
    request(`/graves/${grave.slug}`, formData, 'put')
      .then((res) => {
        dispatch(goToChosenGrave(res.data));
        setOpenSaveModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <div className={styles['notes_content-textarea']}>
          <textarea
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
              handelIsChanged(true);
            }}
            placeholder='Begynn å skrive notat her'
          />
        </div>
        <div className={styles['grave-btn-container']}>
          <div className={styles['grave-information_content-verify']}>
            <button
              className={styles['rootDiscard']}
              onClick={() => {
                if (isChanged) {
                  handelDiscardModal(true);
                } else {
                  onClose();
                }
              }}
            >
              Avbryt
            </button>
            <button
              className={
                isChanged ? styles['rootVerify'] : styles['disabledButton']
              }
              disabled={!isChanged}
              onClick={createNotes}
            >
              Lagre
            </button>
          </div>
        </div>
      </div>
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          title='Notatet ditt ble lagret vellykket.'
          handleClose={() => {
            onClose();
            setOpenSaveModal(false);
          }}
        />
      )}
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => handelDiscardModal(value)}
          title='Notat'
          onConfirm={onClose}
        />
      )}
    </React.Fragment>
  );
};

export default GraveNotesForm;
