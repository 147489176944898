import React, { useEffect, useState } from 'react';
import './SetUserConnectionModal.css';
import X from '../../../img/X.svg';
import Bird from '../../../img/Bird.svg';
import { Button, makeStyles, TextField } from '@material-ui/core';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { request } from '../../../service/request';
import { fetch_request } from '../../../service/request';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HorizontalLine from '../../../img/HorizontalLine.svg';
import OrangeDot from '../../../img/OrangeDot.svg';
import EmptyOrangeDot from '../../../img/EmptyOrangeDot.svg';
import { useSelector } from 'react-redux';
import noGender from '../../../img/SexTypeGrey.svg';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  rootMembers: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootMembersClicked: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: '1px solid #FEA65A',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootConfirm: {
    width: '171px',
    height: '37px',
    borderRadius: '10px',
    backgroundColor: '#FEA65A',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  input: {
    padding: '10px !important',
  },
}));

const SetUserConnectionModal = (props) => {
  const classes = useStyle();
  const relationsList = props.relationsList;
  const slug = props.slug;
  const currentUser = useSelector((state) => state.user?.user) || {};
  const chosenUser = props.user;
  const [leftToRightRelation, setLeftToRightRelation] = useState({});
  const [rightToLeftRelation, setRightToLeftRelation] = useState({});
  const [directionsList, setDirectionsList] = useState([]);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const setRelation = async () => {
    const formdata = new FormData();
    formdata.append('slug', slug);
    formdata.append('relation_id', rightToLeftRelation.id);
    formdata.append('reciprocal_relation_id', leftToRightRelation.id);

    await request('/user_connections', formdata, 'post')
      .then((res) => res.data)
      .then((data) => {
        showUserNotification('Henvendelsen er sendt', 'success');
        props.setRequestAlreadyInQueue();
        props.closeModal();
      })
      .catch((error) => console.log(error));
  };

  const addRelationsMember = () => {
    setRelation();
    props.closeModal();
  };

  const handleLeftToRightRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setLeftToRightRelation({});
    } else if (reason === 'select-option') {
      setLeftToRightRelation(value);
    }
    setRightToLeftRelation({});
  };

  const handleRightToLeftRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setRightToLeftRelation({});
    } else if (reason === 'select-option') {
      setRightToLeftRelation(value);
    }
  };

  const isSubmitEnabled = () => {
    return !isEmpty(leftToRightRelation) && !isEmpty(rightToLeftRelation);
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  return (
    <div className='set-user-connection-modal'>
      <div className='set-user-connection-modal_content'>
        <div className='set-user-connection-modal_content-close'>
          <img loading='lazy' src={X} alt='x' onClick={props.closeModal} />
        </div>
        <div className='set-user-connection-modal_content-header'>
          <img loading='lazy' src={Bird} alt='Bird' />
          <span>Sett relasjon</span>
        </div>

        <div className='step-2-user-connection-content-wrapper'>
          <div className='user-connections-grid-item'>
            <div className='set-relation_display_image_wrapper'>
              <div className='set-relation_display_image'>
                <img
                  loading='lazy'
                  src={currentUser.avatar || noGender}
                  alt='grave_avatar'
                  className={
                    currentUser.avatar
                      ? 'user-connection-avatar'
                      : 'no-gender-avatar'
                  }
                />
              </div>
            </div>
            <div className='grave-info-content'>
              <span className='grave-content-name_span'>
                {currentUser.full_name.toUpperCase()}
              </span>
            </div>
            <div className='choose-relation'>
              <Autocomplete
                id='relation-combo-box'
                options={relationsList}
                classes={{ input: classes.input }}
                getOptionLabel={(option) => option.name}
                style={{ width: '99%' }}
                renderInput={(params) => (
                  <TextField placeholder='Velg relasjon' {...params} />
                )}
                onChange={handleLeftToRightRelation}
              />
            </div>
          </div>

          <div className='directions'>
            <div className='direction-span-wrapper'>
              {!isEmpty(rightToLeftRelation) && (
                <span>{rightToLeftRelation.name}</span>
              )}
            </div>
            <div className='left-to-right'>
              <img src={EmptyOrangeDot} alt='' />
              <img src={HorizontalLine} alt='' />
              <img src={OrangeDot} alt='' />
            </div>
            <div className='right-to-left'>
              <img src={OrangeDot} alt='' />
              <img src={HorizontalLine} alt='' />
              <img src={EmptyOrangeDot} alt='' />
            </div>
            <div className='direction-span-wrapper'>
              {!isEmpty(leftToRightRelation) && (
                <span>{leftToRightRelation.name}</span>
              )}
            </div>
          </div>

          <div className='user-connections-grid-item'>
            <div className='set-relation_display_image_wrapper'>
              <div className='set-relation_display_image'>
                <img
                  loading='lazy'
                  src={chosenUser.avatar || noGender}
                  alt='grave_avatar'
                  className={
                    chosenUser.avatar
                      ? 'user-connection-avatar'
                      : 'no-gender-avatar'
                  }
                />
              </div>
            </div>
            <div className='grave-info-content'>
              <span className='grave-content-name_span'>
                {chosenUser.full_name.toUpperCase()}
              </span>
            </div>
            <div className='choose-relation'>
              <Autocomplete
                id='relation-combo-box'
                options={filterDirectionList(leftToRightRelation)}
                classes={{ input: classes.input }}
                getOptionLabel={(option) => option.name || ''}
                value={rightToLeftRelation}
                style={{ width: '99%' }}
                disabled={isEmpty(leftToRightRelation)}
                renderInput={(params) => (
                  <TextField placeholder='Velg relasjon' {...params} />
                )}
                onChange={handleRightToLeftRelation}
              />
            </div>
          </div>
        </div>

        <div className='helper-span'>
          <span>
            {isSubmitEnabled() &&
              `${chosenUser.full_name} er min ${rightToLeftRelation.name} og jeg er ${leftToRightRelation.name} til ${chosenUser.full_name}`}
          </span>
        </div>

        <div className='set-user-connection-modal_content-confirm'>
          <Button
            disabled={!isSubmitEnabled()}
            className={classes.rootConfirm}
            onClick={addRelationsMember}
          >
            Bekreft
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SetUserConnectionModal;
