import React, { useState } from 'react';
import MasterCard from '../../../../img/MasterCard.svg';
import VisaCard from '../../../../img/Visa.svg';
import './SelectPaymentMethod.css';

const SelectPaymentMethod = ({
  handelSelectPayment,
  selectedPaymentMethod,
}) => {
  return (
    <div className='payment-methods'>
      <div className='payment-methods-heading'>
        <span>Velg betalingsmetode</span>
      </div>
      <div className='payment-methods-container'>
        {process.env.REACT_APP_ENV !== 'production' && (
          <div
            className='payment-method'
            onClick={() => handelSelectPayment('vipps')}
          >
            <div className='select-method'>
              <input
                type='checkbox'
                checked={selectedPaymentMethod === 'vipps' ? true : false}
              />
              <label>Vipps</label>
            </div>
            <div className='vipps'></div>
          </div>
        )}
        <div
          className='payment-method'
          onClick={() => handelSelectPayment('card')}
        >
          <div className='select-method'>
            <input
              type='checkbox'
              checked={selectedPaymentMethod === 'card' ? true : false}
            />
            <label>Credit/Debit Card</label>
          </div>
          <div className='options'>
            <img src={MasterCard} alt='MasterCard' />
            <img src={VisaCard} alt='VisaCard' />
          </div>
        </div>
        {/* <div className='payment-method'>
          <div className='select-method'>
            <input
              type="checkbox"
              checked={selectedPaymentMethod === "Google Pay" ? true : false}
              onChange={() => handelSelectPayment("Google Pay")}
            />
            <label>Google Pay</label>
          </div>
          <div className='options'>
            <img src={GooglePay} alt="GooglePay" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
