import React, { useEffect, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import styles from './SubscriptionMembers.module.css';
import { request } from '../../../service/request';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../service/constants';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const SubscriptionMember = ({ subscriptionId }) => {
  const classes = useStyles();
  const [currentSubscriptionData, setCurrentSubscriptionData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (subscriptionId) {
      setLoading(true);
      fetch(`${CONSTANTS.baseUrl}/api/subscriptions/${subscriptionId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCurrentSubscriptionData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [subscriptionId]);

  const updateSubscriptionValidation = (field, value) => {
    if (field === 'invitation') {
      setCurrentSubscriptionData({
        ...currentSubscriptionData,
        subscription_invitations: value,
      });
    } else if (field === 'members') {
      setCurrentSubscriptionData({
        ...currentSubscriptionData,
        subscription_members: value,
      });
    }
  };

  const handelRemoveMember = (id) => {
    if (id) {
      request(`/subscription_members/${id}`, null, 'delete')
        .then((res) => {
          const newArray = currentSubscriptionData.subscription_members.filter(
            (member) => member.id !== id
          );
          updateSubscriptionValidation('members', newArray);
          showUserNotification('Abonnementsmedlem er fjernet', 'success');
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    }
  };

  const handelRemoveInvitation = (id) => {
    if (id) {
      request(`/subscription_invitations/${id}`, null, 'delete')
        .then((res) => {
          const newArray =
            currentSubscriptionData.subscription_invitations.filter(
              (invitation) => invitation.invitation_id !== id
            );
          updateSubscriptionValidation('invitation', newArray);
          showUserNotification(
            'Medlem av abonnementsinvitasjonen er fjernet',
            'success'
          );
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    }
  };

  return (
    <div style={{ marginTop: 24 }}>
      {!loading &&
        currentSubscriptionData &&
        currentSubscriptionData.subscription_members.length > 0 && (
          <div className={styles['subscription-members']}>
            <div className={styles['member-heading']}>
              Medlemmer av abonnement
            </div>
            <div className={styles['member-sub-heading']}>
              Fjern abonnementsmedlemmer ved å klikke fjern
            </div>
            {currentSubscriptionData.subscription_members.map(
              (member, index) => {
                return (
                  <div className={styles['member-container']} key={index}>
                    <div className={styles['avatar-wrapper']}>
                      <Avatar
                        src={member?.user_avatar}
                        className={classes.large}
                      />
                      <div className={styles['user-name']}>
                        {member?.full_name}
                      </div>
                    </div>
                    <button
                      className={styles['remove-button']}
                      onClick={() => handelRemoveMember(member.id)}
                    >
                      Fjerne
                    </button>
                  </div>
                );
              }
            )}
          </div>
        )}
      {!loading &&
        currentSubscriptionData &&
        currentSubscriptionData.subscription_invitations.length > 0 && (
          <div className={styles['subscription-invitations']}>
            <div className={styles['member-heading']}>
              Invitasjoner til abonnement
            </div>
            <div className={styles['member-sub-heading']}>
              Fjern abonnementsinvitasjonen ved å klikke på fjern
            </div>
            {currentSubscriptionData.subscription_invitations.map(
              (invite, index) => {
                return (
                  <div className={styles['member-container']} key={index}>
                    <div className={styles['invitation-wrapper']}>
                      <div className={styles['user-phone-number']}>
                        {invite?.phone_number}
                      </div>
                      <div className={styles['user-name']}>
                        {invite?.full_name}
                      </div>
                    </div>
                    <button
                      className={styles['remove-button']}
                      onClick={() =>
                        handelRemoveInvitation(invite.invitation_id)
                      }
                    >
                      Fjerne
                    </button>
                  </div>
                );
              }
            )}
          </div>
        )}
    </div>
  );
};

export default SubscriptionMember;
