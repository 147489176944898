import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import X from '../../img/X.svg';
import { history } from 'react-router-dom';

import './AuthModal.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '32px',
    maxWidth: '600px',
    height: 'maxContent',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
    '@media (max-width :720px)': {
      maxWidth: '100%',
      borderRadius: '0px',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const AuthModal = (props) => {
  const isOpen = props.isOpen;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className='downgrade-plan-prompt_body_subheading'>
          {props.title}
        </div>
        <img
          src={X}
          onClick={() => props.handleCloseUpgradePlansModal()}
          alt=''
          className='downgrade-plan-prompt_cancel'
        />
        <div className='downgrade-plan-prompt_body_content'>
          <span>{props.body}</span>
        </div>
        <div className='btn-container'>
          <div className='auth-modal_footer'>
            <button
              className='sign-in-options login_btn'
              onClick={() => history.push('/auth/sign-in')}
            >
              Logg In
            </button>

            <button
              className='sign-in-options register_btn'
              onClick={() => history.push('/registration')}
            >
              Registrer
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
