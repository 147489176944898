// import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import './MusicPost.css';
import TrianglePLay from '../../../../img/TrianglePlay.svg';

import MusicPlayer from '../CommentTextArea/atoms/MusicPlayer';

// const useStyle = makeStyles(() => ({
//     rootDotsButton: {
//         marginLeft: 'auto',
//         minWidth: '40px'
//     },
//     rootSongButton: {
//         textTransform: 'none',
//         width: '100%',
//         boxSizing: 'border-box',
//         padding: '5px 25px'
//     },
//     rootSongButtonChoosen: {
//         textTransform: 'none',
//         width: '100%',
//         boxSizing: 'border-box',
//         padding: '5px 25px',
//         backgroundColor: 'rgba(254, 166, 90, 0.1)',
//         '&:hover': {
//             backgroundColor: 'rgba(254, 166, 90, 0.1)',
//         }
//     },
//     rootFinalButtons: {
//         width: '50%',
//         borderRadius: '10px',
//         height: '47px'
//     }
// }))

const MusicPost = ({ music }) => {
  // const classes = useStyle();
  const [chosenSong, setChosenSong] = useState();
  // const chooseSong = (song) => setChosenSong(song);

  return (
    <div>
      <div className='music-post_content'>
        <div className='music-post_content-songs'>
          {music?.map((song, i) => {
            return <MusicPlayer key={i} song={song} />;
            // return(
            //     <Button key={song.id} className={chosenSong ? chosenSong.id === song.id ? classes.rootSongButtonChoosen : classes.rootSongButton : classes.rootSongButton} onClick={() => chooseSong(song)}>
            //         <img loading='lazy' src={PlayMusic} alt='MusicPLay' className='add-by-link_header-music_image'/>
            //         <span className='add-by-link_header-music_text'><span>{song.title} -</span> {song.author}</span>
            //         <span className='add-by-link_header-music_duration'>{song.duration}</span>
            //     </Button>
            // )
          })}
        </div>
        {chosenSong && (
          <div className='music-post_content-player'>
            <div className='music-post_content-player_play'>
              <img loading='lazy' src={TrianglePLay} alt='play' />
            </div>
            <div className='music-post_content-player_author'>
              <span className='add-by-link_header-music_text'>
                <span>{chosenSong.title} -</span> {chosenSong.author}
              </span>
              <div />
            </div>
            <div className='music-post_content-player_duration'>
              <span>{chosenSong.duration}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPost;
