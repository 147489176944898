import React from 'react'
import Icon from '../../../img/BenefitsIcon.svg';
import './AdditionalBenefits.css';

const AdditionalBenefits = () => {
  return (
    <div className='benefits'>
      <div className='additional-benefits-heading'>
        Å være litt forut
      </div>
      <div className='additional-benefits'>
        <div className='additional-benefits-details'>
          <span className='additional-benefits-icon'>
            <img src={Icon} alt="icon" />
          </span>
          <span className='additional-benefits-title'>Nærhet</span>
          <span className='additional-benefits-desc'>Å være nær når man ønsker det.
          </span>
        </div>
        <div className='additional-benefits-details'>
          <span className='additional-benefits-icon'>
            <img src={Icon} alt="icon" />
          </span>
          <span className='additional-benefits-title'>Varig gravsted</span>
          <span className='additional-benefits-desc'>Nettgrav.no er her til enden av all tid.</span>
        </div>
        <div className='additional-benefits-details'>
          <span className='additional-benefits-icon'>
            <img src={Icon} alt="icon" />
          </span>
          <span className='additional-benefits-title'>Bevisst</span>
          <span className='additional-benefits-desc'>Når vi ser på livet sammen fra perspektivet til nettgrav.no gjør vi mer av det gode.</span>
        </div>
        <div className='additional-benefits-details'>
          <span className='additional-benefits-icon'>
            <img src={Icon} alt="icon" />
          </span>
          <span className='additional-benefits-title'>Kontroll over graven</span>
          <span className='additional-benefits-desc'>Se, opplev og bygg graven på nett. Graveier har alltid oversikt og kontroll.</span>
        </div>
      </div>
    </div>
  )
}

export default AdditionalBenefits