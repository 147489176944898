import Card from './Card'
import React, { useEffect, useState } from 'react';
import {fetch_request} from '../../../../../service/request';
import { Link } from 'react-router-dom';
import NoneGravesImage from '../../../../../img/NoneGravesImage.svg';

const Content = (props) => {
  const searchInput = props.searchInputValue || ''
  const [cemeteriesList, setCemeteriesList] = useState([])
  const currentPage = props.currentPage
  const municipalitySlug = props.municipalitySlug
  const cemeteriesCount =props.cemeteriesCount

  useEffect(
    () => {
      fetchCemeteries()
    },
    [searchInput, currentPage]
  )

  const fetchCemeteries = () => {
    if ( municipalitySlug ) {
      fetch_request(`/api/search_cemeteries?input=${searchInput}&page=${currentPage}&municipality_slug=${municipalitySlug}`)
        .then((response) => response.json())
        .then((data) => {
          setCemeteriesList(data.cemeteries);
          props.setTotalPages(data.total_pages)
        })
        .catch((err) => console.log(err));
    }
  }

  return(
    <>
      {

        cemeteriesList && cemeteriesList.length === 0 && cemeteriesCount !== 0 ?
        <div className="municipality-not-found">
          <img src={NoneGravesImage} alt="not-found"/>
          <span className='search-graves_none-text'>Fant ingen resultater for <span>"{searchInput}"</span></span>
        </div>

        :

        <div className="municipality-search-content">
          {
            cemeteriesList && cemeteriesList.map(item => {
              return(
                <Link to={`/finn-en-grav/${municipalitySlug}/${item.slug}`}>
                  <Card name={item.name} countryName={item.municipality_name}/>
                </Link>
              )
            })
          }
        </div>
      }
    </>
  )
}

export default Content;