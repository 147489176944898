import React from 'react';

import HomepageFindGraveFifth from './FindGrave/HomepageFindGraveFifth';
import HomepageFindGraveFirst from './FindGrave/HomepageFindGraveFirst';
import HomepageFindGraveFourth from './FindGrave/HomepageFindGraveFourth';
import HomepageFindGraveSecond from './FindGrave/HomepageFindGraveSecond';
// import HomepageFindGraveSeventh from './FindGrave/HomepageFindGraveSeventh';
// import HomepageFindGraveSixth from './FindGrave/HomepageFindGraveSixth';
import HomepageFindGraveThird from './FindGrave/HomepageFindGraveThird';
import './HomepageFindGrave.css';

const HomepageFindGrave = (props) => {
    return(
        <div className='homepage-findgrave'>
            <HomepageFindGraveFirst/>
            <HomepageFindGraveSecond/>
            <HomepageFindGraveThird location={props.location} />
            <HomepageFindGraveFourth/>
            <HomepageFindGraveFifth/>
            {/* <HomepageFindGraveSixth/> */}
            {/* <HomepageFindGraveSeventh/> */}
        </div>
    )
}

export default HomepageFindGrave;