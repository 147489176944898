import Header from './Header'
import Content from './Content'
import Paginator from './Paginator'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { fetch_request } from '../../../../../service/request';

const Index = (props) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const municipalitySlug = props.municipalitySlug
  const [cemeteriesCount, setCemeteriesCount] = useState(0);

  useEffect(() => {
    setCurrentPage(1)
  }, [searchInputValue])


  useEffect(() => {
		if (municipalitySlug) {
			fetch_request(`/api/cemeteries_count?municipality_slug=${municipalitySlug}`)
				.then((response) => response.json())
				.then((data) => {
					setCemeteriesCount(data.count);
				})
				.catch((err) => console.log(err));
		}
	}, []);

  return(
    <div className="municipality-index">
      <Header setSearchInputValue={setSearchInputValue}
        municipalitySlug={municipalitySlug}
        cemeteriesCount={cemeteriesCount}
        searchDisabled={cemeteriesCount === 0}
      />
      {
        cemeteriesCount !== 0 &&
        <Paginator 
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      }
      <Content 
        searchInputValue={searchInputValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        municipalitySlug={municipalitySlug}
        cemeteriesCount={cemeteriesCount}
      />
    </div>
  )
}

export default Index;