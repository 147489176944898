import React, { useState } from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './GraveNotesModal.module.css';
import X from '../../../../../img/X.svg';
import GraveNotesForm from '../GraveNotesForm';

const GraveNotesModal = ({ onOpen, onClose, grave }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      height: 'calc(100% - 32px)',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '32px',
      borderRadius: 0,
      outline: 'none',
      '@media (max-width: 800px)': {
        width: '100%',
        height: 'calc(100% - 32px)',
        overflow: 'auto',
        borderRadius: 0,
      },
    },
    modalBackground: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));
  const classes = useStyles();
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handelDiscardModal = (value) => {
    setOpenDiscardModal(value);
  };

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['notes-content_header']}>
            <span>Legg til et notat</span>
            <img
              loading='lazy'
              src={X}
              alt='x'
              onClick={() => {
                if (isChanged) {
                  handelDiscardModal(true);
                } else {
                  onClose();
                }
              }}
            />
          </div>
          <div className={styles['notes-content_subheader']}>
            <span>
              Notatet er en velkomstmelding til pårørende og andre
              som har tilgang til graven.
            </span>
          </div>
        </div>
        <GraveNotesForm
          grave={grave}
          onClose={onClose}
          openDiscardModal={openDiscardModal}
          handelDiscardModal={handelDiscardModal}
          isChanged={isChanged}
          handelIsChanged={(value) => setIsChanged(value)}
        />
      </div>
    </Modal>
  );
};

export default GraveNotesModal;
