import React from 'react';
import styles from './Increment.module.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Increment = ({ setTotalCount, totalCount }) => {
  return (
    <div className={styles['increment-container']}>
      <div
        className={styles['increment']}
        onClick={() => {
          if (totalCount > 1) {
            setTotalCount(totalCount - 1);
          }
        }}
      >
        <RemoveIcon />
      </div>
      <div className={styles['increment-value']}>{totalCount}</div>
      <div
        className={styles['decrement']}
        onClick={() => {
          setTotalCount(totalCount + 1);
        }}
      >
        <AddIcon />
      </div>
    </div>
  );
};

export default Increment;
