import React from 'react';
import featureCheck from '../../../../img/featureCheck.svg';
import './FeatureList.css';

const FeatureList = ({ chosenPlan }) => {
  return (
    <div className='selected-plan-features'>
      <div className='plan-details-header'>
        <span className='plan-details-header_product_name'>
          {chosenPlan.name}
        </span>
        <span className='plan-details-header_product_desc'>
          {chosenPlan.description}
        </span>
      </div>
      <ul className='features-list'>
        {Array.isArray(chosenPlan.features) &&
          chosenPlan.features.map((feature) => {
            return (
              <li className='feature-list-item' key={feature.id}>
                <img src={featureCheck} alt='check' />
                <span>{feature.title}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default FeatureList;
