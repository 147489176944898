import React, { useEffect, useState } from 'react';
import styles from './AboutBox.module.css';
import AboutItem from './components/AboutItem';
import gender from '../../../../img/AboutGenderBlack.svg';
import location from '../../../../img/AboutLocationBlack.svg';
import nickname from '../../../../img/NickName.svg';
import GraveYard from '../../../../img/GraveYard.svg';
import date from '../../../../img/AboutDateBirth.svg';
import interests from '../../../../img/Interest.svg';
import company from '../../../../img/Company.svg';
import reason from '../../../../img/ReasonAboutDeath.svg';
import puzzle from '../../../../img/AboutPuzzle.svg';
import RelationIcon from '../../.././../img/RelationIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setGraveWorkplacesRedux } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import ConnectGraveModal from '../ConnectGraveModal/ConnectGraveModal';
import ConnectRelationsModal from '../ConnectRelationsModal/ConnectRelationsModal';
import BasicInformationModal from '../BasicInformationModal/BasicInformationModal';
import WorkPlacesModal from '../WorkPlacesModal/WorkPlacesModal';
import InviteRelativesModal from '../InviteRelativesModal/InviteRelativesModal';
import GraveInterestsModal from '../GraveInterestsModal/GraveInterestsModal';
import GravePassionModal from '../GravePassionModal/GravePassionModal';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import moment from 'moment/moment';
import isEmpty from 'lodash.isempty';

const AboutBox = (props) => {
  const dispatch = useDispatch();
  const { graveUser } = props;
  const [canAccessLinkedGraves, setCanAccessLinkedGraves] = useState(true);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const user = useSelector((state) => state.user) || {};
  const [nickName, setNickName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dateOfDeath, setDateOfDeath] = useState('');
  const [birthName, setBirthName] = useState('');
  const [openEditGraveModal, setOpenEditGraveModal] = useState(false);
  const [openWorkPlaceModal, setOpenWorkPlaceModal] = useState(false);
  const [openGraveConnectionModal, setOpenGraveConnectionModal] =
    useState(false);
  const [openRelationModal, setOpenRelationModal] = useState(false);
  const [openInterestsModal, setOpenInterestsModal] = useState(false);
  const [openPassionModal, setOpenPassionModal] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [openConfirmationDialog, setOpenConfimationDialog] = useState(false);
  const grave = props.grave;
  const graveUsersArray = grave.grave_users
    ? grave.grave_users.map((user) => user.user_name)
    : [];
  const relationsArray = graveUsersArray.join(', ');
  const handleOpenEditGraveModal = () => setOpenEditGraveModal(true);
  const handleCloseEditGraveModal = () => setOpenEditGraveModal(false);
  const handleOpenWorkPlaceModal = () => setOpenWorkPlaceModal(true);
  const handleCloseWorkPlaceModal = () => setOpenWorkPlaceModal(false);
  const handleOpenGraveConnectionModal = () => {
    if (user.activeSubscription?.can_access_linked_graves) {
      setOpenGraveConnectionModal(true);
    } else {
      setCanAccessLinkedGraves(false);
    }
  };
  const handleCloseGraveConnectionModal = () =>
    setOpenGraveConnectionModal(false);
  const handleCloseRelationModal = () => setOpenRelationModal(false);
  const handleOpenRelationModal = () => setOpenRelationModal(true);
  const handleCloseInterestsModal = () => setOpenInterestsModal(false);
  const handleOpenInterestsModal = () => setOpenInterestsModal(true);
  const handleClosePassionModal = () => setOpenPassionModal(false);
  const handleOpenPassionModal = () => setOpenPassionModal(true);

  useEffect(() => {
    if (grave.grave_additional_names) {
      const nickname = grave.grave_additional_names.find(
        (name) => name.name_type == 'Nickname'
      );
      if (nickname) setNickName(nickname.name);

      const birthname = grave.grave_additional_names.find(
        (name) => name.name_type == 'Birthname'
      );
      if (birthname) setBirthName(birthname.name);
    }
  }, [grave.grave_additional_names]);

  useEffect(() => {
    if (!isEmpty(grave.date_of_birth)) {
      const birthYear = moment(grave.date_of_birth).format('DD/MM/YYYY');
      const birthDate = `${grave.day_of_birth} ${birthYear} ${
        grave.time_of_birth ? grave.time_of_birth : ''
      } `;
      setDateOfBirth(birthDate);
    } else if (!isEmpty(grave.time_of_birth)) {
      setDateOfBirth(grave.time_of_birth);
    } else setDateOfBirth('');
    if (!isEmpty(grave.date_of_death)) {
      const birthYear = moment(grave.date_of_death).format('DD/MM/YYYY');
      const deathDate = `${grave.day_of_death} ${birthYear}  ${
        grave.time_of_death ? grave.time_of_death : ''
      } `;
      setDateOfDeath(deathDate);
    } else if (!isEmpty(grave.time_of_death)) {
      setDateOfDeath(grave.time_of_death);
    } else {
      setDateOfDeath('');
    }
  }, [
    grave.date_of_birth,
    grave.date_of_death,
    grave.time_of_birth,
    grave.time_of_death,
  ]);

  const graveInformation = [
    {
      key: 'Kjønn',
      value: grave.gender,
      icon: gender,
    },
    {
      key: 'Fødselsby',
      value: grave.location_of_birth,
      icon: location,
    },
    {
      key: 'Kallenavn',
      value: nickName,
      icon: nickname,
    },
    {
      key: 'Fødselsnavn',
      value: birthName,
      icon: nickname,
    },
    {
      key: 'Dødslokasjon',
      value: grave.location_of_death ? grave.location_of_death : '',
      icon: location,
    },
    {
      key: 'Gravlund',
      value: grave.graveyard && grave.graveyard.name,
      icon: GraveYard,
    },
    {
      key: 'Bursdag',
      value: `${dateOfBirth} `,
      icon: date,
    },
    {
      key: 'Dødsdato',
      value: dateOfDeath,
      icon: date,
    },
    {
      key: 'Relasjoner',
      value: relationsArray,
      icon: RelationIcon,
    },
    {
      key: 'Dødsårsak',
      value:
        grave.reason_of_death === 'Others'
          ? `Other ${
              grave.other_reason_of_death
                ? `(${grave.other_reason_of_death})`
                : ''
            }`
          : grave.reason_of_death,
      icon: reason,
    },
  ];

  return (
    <div className={styles['about-box-container']}>
      {/* // ///////////////------Open Basic Information Modal-----//////// */}
      {openEditGraveModal && (
        <BasicInformationModal
          onOpen={openEditGraveModal}
          onClose={handleCloseEditGraveModal}
          grave={grave}
        />
      )}
      {/* // ///////////////------Open WorkPlace Modal-----//////// */}
      {openWorkPlaceModal && (
        <WorkPlacesModal
          onOpen={openWorkPlaceModal}
          onClose={handleCloseWorkPlaceModal}
          grave={grave}
        />
      )}
      {/* ///////////////////---- open connect grave modal----/////// */}
      {openGraveConnectionModal ? (
        <ConnectGraveModal
          onOpen={openGraveConnectionModal}
          onClose={handleCloseGraveConnectionModal}
          grave={grave}
          user={user}
        />
      ) : (
        <UpgradePlanModal
          isOpen={!canAccessLinkedGraves}
          handleCloseUpgradePlansModal={() => setCanAccessLinkedGraves(true)}
          body='Så fint at du ønsker å knytte gravlagte sammen. For å gjøre det trenger du Familie eller Familie+ medlemskap.'
          title='Oppdater medlemskapet ditt'
        />
      )}
      {/* ///////////////////---- open interests modal----/////// */}
      {openInterestsModal && (
        <GraveInterestsModal
          onOpen={openInterestsModal}
          onClose={handleCloseInterestsModal}
          grave={grave}
        />
      )}
      {/* ///////////////////---- open Passion modal----/////// */}
      {openPassionModal && (
        <GravePassionModal
          onOpen={openPassionModal}
          onClose={handleClosePassionModal}
          grave={grave}
        />
      )}
      {/*/////////////////// Set Relation or Claim modals /////////////////////////*/}
      {openRelationModal && (
        <InviteRelativesModal
          onOpen={openRelationModal}
          onClose={handleCloseRelationModal}
          grave={grave}
        />
      )}
      {props.hideMenu && props.aboutMenuKey ? (
        <div className={styles['about-content']}>
          {props.aboutMenuKey === 1 && (
            <AboutItem
              item={graveInformation}
              title={'Grunnleggende informasjon'}
              onEdit={handleOpenEditGraveModal}
              canEdit={grave.can_edit}
            />
          )}
          {props.aboutMenuKey === 2 && (
            <AboutItem
              item={grave?.grave_users ? grave.grave_users : {}}
              title={'Relasjoner'}
              emptyMessage='Bekjente du inviterer eller som har knyttet seg til graven vil vises her.'
              canEdit={grave.is_admin || graveUser?.grave_owner}
              onEdit={handleOpenRelationModal}
            />
          )}
          {props.aboutMenuKey === 3 && (
            <AboutItem
              item={grave?.grave_workplaces ? grave.grave_workplaces : {}}
              onEdit={handleOpenWorkPlaceModal}
              title={'Arbeidsplass'}
              emptyMessage='Man kan legge til arbeidsplasser gravlagte arbeidet ved, som vil vises her.'
              canEdit={graveUser?.status === 'accepted'}
            />
          )}
          {props.aboutMenuKey === 4 && (
            <AboutItem
              item={grave?.grave_interests ? grave.grave_interests : {}}
              title={'Interesser'}
              onEdit={handleOpenInterestsModal}
              emptyMessage='Ingen interesser er lagt til.'
              canEdit={graveUser?.status === 'accepted'}
            />
          )}
          {props.aboutMenuKey === 5 && (
            <AboutItem
              item={grave?.passion ? grave.passion : {}}
              title={'Lidenskap'}
              emptyMessage='Ingen lidenskap er lagt til'
              canEdit={grave.can_edit}
              onEdit={handleOpenPassionModal}
            />
          )}
          {props.aboutMenuKey === 6 && (
            <AboutItem
              item={grave?.linked_graves ? grave.linked_graves : {}}
              title={'Gravlenken'}
              emptyMessage='Ingen andre gravlagte er linket'
              canEdit={grave.is_admin || graveUser?.grave_owner}
              onEdit={handleOpenGraveConnectionModal}
            />
          )}
        </div>
      ) : (
        <div className={styles['about-content']}>
          <AboutItem
            item={graveInformation}
            title={'Grunnleggende informasjon'}
            onEdit={handleOpenEditGraveModal}
            canEdit={grave.can_edit}
          />
          <AboutItem
            item={grave?.grave_users ? grave.grave_users : {}}
            title={'Relasjoner'}
            emptyMessage='Bekjente man inviterer eller som knytter seg til graven vil vises her'
            canEdit={grave.is_admin || graveUser?.grave_owner}
            onEdit={handleOpenRelationModal}
          />
          <AboutItem
            item={grave?.grave_workplaces ? grave.grave_workplaces : {}}
            onEdit={handleOpenWorkPlaceModal}
            title={'Arbeidsplass'}
            emptyMessage='Man kan legge til arbeidsplasser gravlagte arbeidet ved, som vil vises her.'
            canEdit={graveUser?.status === 'accepted'}
          />
          <AboutItem
            item={grave?.grave_interests ? grave.grave_interests : {}}
            title={'Interesser'}
            onEdit={handleOpenInterestsModal}
            emptyMessage='Ingen interesser er lagt til'
            canEdit={graveUser?.status === 'accepted'}
          />
          <AboutItem
            item={grave?.passion ? grave.passion : {}}
            title={'Lidenskap'}
            emptyMessage='Ingen lidenskap er lagt til'
            canEdit={grave.can_edit}
            onEdit={handleOpenPassionModal}
          />
          <AboutItem
            item={grave?.linked_graves ? grave.linked_graves : {}}
            title={'Gravlenke'}
            emptyMessage='Ingen andre graver har blitt koblet til ennå'
            canEdit={grave.is_admin || graveUser?.grave_owner}
            onEdit={handleOpenGraveConnectionModal}
          />
        </div>
      )}
    </div>
  );
};

export default AboutBox;
