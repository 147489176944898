import AskQuestion from "./AskQuestion";
import QuestionBox from "./QuestionBox";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import "./UserWall.css";
import { fetch_request } from "../../../service/request";
import { useState } from "react";
import { intialiseQuestions } from "../../../redux/actions";

const UserWall = (props) => {
  const user = props.user;
  const questionLabel = user.full_name ? `Still ${user.full_name} spørsmål` : '';
  const loggedInUser = useSelector((state) => state.user.user);
  const initialQuestionsList = useSelector((state) => state.userProfile.questions);
  const [questionsList, setQuestionsList] = useState(initialQuestionsList)
  const [startRefresh, setStartRefresh] = useState(false)
  const dispatch = useDispatch();

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem("user-slug") ||
      user.slug === sessionStorage.getItem("user-slug")
    );
  };

  useEffect(() => {
    if (loggedInUser && user.slug) {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      fetch_request(`/api/user_wall_posts?slug=${user.slug}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          dispatch(intialiseQuestions(data.questions));
          setQuestionsList(data.questions)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return setStartRefresh(false);
  }, [startRefresh]);

  // useEffect(() => {}, [questionsList]);

  return (
    <>
      <div className="user-wall-header">
        <h1>{questionLabel}</h1>
        <p>
        Det er ofte våre nærmeste kjære vi har mest å lære av og om. Livssiden tilbyr en egen vegg for å stille og besvare viktige spørsmål om livet. Når du har mottatt spørsmål, vil de vises her.
        </p>
      </div>
      {!isUserCurrentUser() && (
        <AskQuestion user={user} loggedInUser={loggedInUser} setStartRefresh={setStartRefresh}/>
      )}
      {questionsList &&
        questionsList.map((item, index) => {
          if (item) {
            return (
              <QuestionBox
                user={user}
                key={index}
                loggedInUser={loggedInUser}
                question={item}
                setStartRefresh={setStartRefresh}
              />
            );
          }
          return false;
        })}
    </>
  );
};

export default UserWall;
