import React,{ useEffect } from 'react';
import SettingsPage from '../../containers/Settings/SettingsPage';
import axios from 'axios';
import { CONSTANTS } from '../../service/constants';
import { setInformationAboutUser } from '../../redux/actions';
import {Redirect} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const Settings = () => {
    const isAuth = useSelector(state => state.auth.isAuthorized);
    const dispatch = useDispatch();

    useEffect(() => {
        const userSlug =
          localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
    
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: '*/*',
          },
          redirect: 'follow',
        };
        axios
          .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}`, config)
          .then((res) => {
            if (res.data) dispatch(setInformationAboutUser(res.data));
          })
          .catch((err) => console.log(err));
      }, [dispatch]);

    return(
        <div>
            {
                !isAuth && <Redirect to={{
                    pathname: "/auth/sign-in",
                }}/>
            }
            <SettingsPage/>
        </div>
    )
}

export default Settings;