import React, { useEffect, useState } from 'react';
import styles from './PlanMembers.module.css';
import GiftPlans from './GiftPlans/GiftPlans';
import loadingAnimation from '../../../service/Loading_animation.gif';
import { fetch_request } from '../../../service/request';

const PlanMembers = () => {
  const [loading, setLoading] = useState(true);
  const [loadingGift, setLoadingGift] = useState(true);
  const [openedIndex, setOpenedIndex] = useState('');
  const [currentSubscriptionMembers, setCurrentSubscriptionMembers] = useState(
    {}
  );
  const [giftedSubscriptionMembers, setGiftedSubscriptionMembers] = useState(
    []
  );
  const [relationList, setRelationList] = useState([]);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    if (userSlug) {
      setLoading(true);
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/users/${userSlug}/subscriptions/subscription_members`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setCurrentSubscriptionMembers(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userSlug]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => {
        setRelationList(relations);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (userSlug) {
      setLoadingGift(true);
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/users/${userSlug}/subscriptions/gifted_subscriptions`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setGiftedSubscriptionMembers(data.gifted_subscriptions);
          setLoadingGift(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userSlug]);

  const updateSubscriptionMembers = (data) => {
    setCurrentSubscriptionMembers({
      ...currentSubscriptionMembers,
      subscription_members: [...data],
    });
  };

  const updateGiftedSubscriptionMembers = (data) => {
    setGiftedSubscriptionMembers([...data]);
  };

  return (
    <div>
      <div className={styles['plan-members-heading']}>Medlemmer</div>
      <div className={styles['sub-heading']}>Din familieplan.</div>
      {loading && loadingGift && (
        <div className={styles['loading']}>
          <img src={loadingAnimation} alt='Loading....' />
        </div>
      )}
      {giftedSubscriptionMembers &&
      giftedSubscriptionMembers.length > 0 &&
      !loadingGift ? (
        giftedSubscriptionMembers.map((family, index) => {
          return (
            <GiftPlans
              family={family}
              key={index}
              index={index}
              subscriptionMembers={giftedSubscriptionMembers}
              updateSubscriptionMembers={updateGiftedSubscriptionMembers}
              open={index === openedIndex}
              handelMemberOpen={(value) => {
                if (value === openedIndex) {
                  setOpenedIndex('');
                } else {
                  setOpenedIndex(value);
                }
              }}
              relationList={relationList}
            />
          );
        })
      ) : !loadingGift ? (
        <div className={styles['empty-gift-memberships']}></div>
      ) : null}
      {currentSubscriptionMembers &&
      Object.keys(currentSubscriptionMembers).length > 0 &&
      !loading ? (
        <GiftPlans
          family={currentSubscriptionMembers}
          subscriptionMembers={currentSubscriptionMembers}
          updateSubscriptionMembers={updateSubscriptionMembers}
          open={openedIndex === null ? true : false}
          index={null}
          handelMemberOpen={(value) => {
            if (value === openedIndex) {
              setOpenedIndex('');
            } else {
              setOpenedIndex(value);
            }
          }}
          relationList={relationList}
        />
      ) : !loading ? (
        <div className={styles['empty-gift-memberships']}>
          Du har ingen abonnementsmedlemmer
        </div>
      ) : null}
    </div>
  );
};

export default PlanMembers;
