import React from 'react';

const Paginator = (props) => {

  const handlePrevClick = () => {
    props.setCurrentPage(props.currentPage - 1)
  }

  const handleNextClick = () => {
    props.setCurrentPage(props.currentPage + 1)
  }

  return(
    <div className="paginator">
      <button className="paginator_prev" onClick={handlePrevClick} disabled={props.currentPage === 1}>
        Tilbake
      </button>
      <button className="paginator_next" onClick={handleNextClick} disabled={props.currentPage >= props.totalPages}>
        Neste
      </button>
    </div>
  )
}

export default Paginator;