import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const GraveDatePicker = ({
  date,
  setDatePickerValue,
  isFutureDate,
  handelFutureDate,
}) => {
  const customDatePicker = {
    '& .MuiInputBase-root': {
      border: '1px solid transparent !important',
      background: '#F2F5F8',
      padding: '8px 28px',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: '18px',
    },
  };

  const customDatePickerError = {
    '& .MuiInputBase-root': {
      border: '1px solid red !important',
      background: '#F2F5F8',
      padding: '8px 28px',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: '18px',
    },
  };

  const [value, setValue] = useState();
  useEffect(() => {
    if (date) {
      setValue(dayjs(date));
    }
  }, [date]);

  const handleDateChange = (newValue) => {
    const futureDate = newValue && newValue > new Date();

    if (futureDate) {
      handelFutureDate(true);
    } else {
      setValue(newValue);
      if (newValue) {
        handelFutureDate(false);
        setDatePickerValue(newValue);
      } else {
        handelFutureDate(false);
        setDatePickerValue('');
      }
    }
  };

  const clearDate = (e) => {
    e.preventDefault();
    setValue('');
    setDatePickerValue('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            sx: isFutureDate ? customDatePickerError : customDatePicker,
          },
        }}
        disableFuture
      />
      {value && (
        <Button
          onClick={clearDate}
          color='#737a7f'
          type='text'
          style={{ textTransform: 'capitalize' }}
        >
          Nullstill dato
        </Button>
      )}
    </LocalizationProvider>
  );
};

export default GraveDatePicker;
