import React from 'react';
import company from '../../../../img/Company.svg';
import styles from './WorkPlaces.module.css';
import isEmpty from 'lodash.isempty';

const Workplaces = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  return (
    <>
      {!isEmpty(item) && item.length > 0 ? (
        <div className={styles['workplace-container']}>
          {item.map((workPlace) => {
            return (
              <div className={styles['workplace-wrapper']} key={workPlace.id}>
                <div className={styles['workplace-logo']}>
                  <img src={company} alt='company' />
                </div>
                <div className={['workplace-details']}>
                  <div className={styles['workplace-position']}>
                    {workPlace.position}
                  </div>
                  <div className={styles['workplace-company-name']}>
                    {workPlace.company_name}
                  </div>
                  <div
                    className={styles['workplace-years']}
                  >{`${workPlace.start_year} - ${workPlace.end_year}`}</div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles['empty-grave-info']}>
          <span className={styles['empty-message']}>{emptyMessage}</span>
          {canEdit && (
            <button className={styles['add-btn']} onClick={onEdit}>
              Legg til {title}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Workplaces;
