import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './EndStep.css';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HintMark from '../../../img/HintMark.svg';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import HintMessage from './HintMessage';

const useStyle = makeStyles(() => ({
  clear: {
    display: 'none',
  },
}));

const EndStep = (props) => {
  const classes = useStyle();
  const [country, setCountry] = useState();
  const [municipality, setMunicipality] = useState();
  const [cemetery, setCemetery] = useState();
  // const [gravenumber, setGravenumber] = useState();
  const countries = useSelector((state) => state.auth.countries);

  const [showMunicipalityHint, setShowMunicipalityHint] = useState(false);
  const [isFirstTimeMunHint, setIsFirstTimeMunHint] = useState(true);
  const [showCemeteryHint, setShowCemeteryHint] = useState(false);
  const [isFirstTimeCemHint, setIsFirstTimeCemHint] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const countryProps = {
    options: countries,
    getOptionLabel: (option) => option.name,
  };

  const municipalityProps = {
    options: props.municipalityList,
    getOptionLabel: (option) => option.name,
  };

  const cemeteryProps = {
    options: props.cemeteryList,
    getOptionLabel: (option) => option.name,
  };

  // const onGravenumberChange = (event) => setGravenumber(event.target.value);

  const onDoneClick = () => {
    if (country && municipality && cemetery) {
      const data = {
        country: country,
        municipality: municipality,
        cemetery: cemetery,
        // gravenumber: gravenumber
      };
      props.onDoneClick(data);
    } else {
      showUserNotification('Alle felt må fylles ut');
    }
  };
  useEffect(() => {
    if (showCemeteryHint && isFirstTimeCemHint) {
      setTimeout(() => {
        setIsFirstTimeCemHint(false);
        setShowCemeteryHint(false);
      }, 10000);
    }
  }, [showCemeteryHint]);

  useEffect(() => {
    if (showMunicipalityHint && isFirstTimeMunHint) {
      setTimeout(() => {
        setIsFirstTimeMunHint(false);
        setShowMunicipalityHint(false);
      }, 10000);
    }
  }, [showMunicipalityHint]);
  return (
    <div className='first-step'>
      <div className='input-element-list'>
        <div className='title'>
          <label htmlFor='name'>Land</label>
        </div>
        <div className='choose-relation-selection'>
          <Autocomplete
            id='relation-combo-box'
            autoHighlight
            {...countryProps}
            classes={{ input: classes.input }}
            style={{ width: '99%' }}
            renderInput={(params) => (
              <TextField placeholder='Land' {...params} />
            )}
            onChange={(e, value) => {
              setCountry(value);
              props.setChosenCountryId(value?.id);
            }}
          />
        </div>
      </div>

      <div className='end-step_autocomplete-with-hint municipality'>
        {showMunicipalityHint && isFirstTimeMunHint && (
          <HintMessage message='Hvis du ikke finner kommunen du leter etter kan du velge nettgrav som kommune' />
        )}
        <div className='input-element-list'>
          <div className='title'>
            <label htmlFor='name'>By/by</label>
          </div>
          <div className='choose-relation-selection'>
            <Autocomplete
              id='relation-combo-box'
              autoHighlight
              {...municipalityProps}
              classes={{ input: classes.input }}
              style={{ width: '99%' }}
              renderInput={(params) => (
                <TextField placeholder='Kommune' {...params} />
              )}
              onChange={(e, value) => {
                setMunicipality(value);
                props.setChosenMunicipalityId(value?.id);
              }}
              onFocus={() => setShowMunicipalityHint(true)}
              onBlur={() => setShowMunicipalityHint(false)}
              popupIcon={
                showMunicipalityHint && isFirstTimeMunHint ? (
                  <img loading='lazy' src={HintMark} alt='hint' />
                ) : (
                  <ArrowDropDownIcon className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled' />
                )
              }
              className={`add-grave_autocomplete ${
                showMunicipalityHint ? 'mun_hint_shown' : ''
              }`}
              disabled={!props.chosenCountryId}
            />
          </div>
        </div>
      </div>

      <div className='end-step_autocomplete-with-hint cemetery'>
        {showCemeteryHint && isFirstTimeCemHint && (
          <HintMessage message='Velg The End Of Time gravlund hvis du har valgt nettgrav som kommune' />
        )}
        <div className='input-element-list'>
          <div className='title'>
            <label htmlFor='name'>Gravplass</label>
          </div>
          <div className='choose-relation-selection'>
            <Autocomplete
              id='relation-combo-box'
              autoHighlight
              {...cemeteryProps}
              classes={{ input: classes.input }}
              style={{ width: '99%' }}
              renderInput={(params) => (
                <TextField placeholder='Gravlund' {...params} />
              )}
              onChange={(e, value) => {
                setCemetery(value);
                props.setChosenCemeteryId(value?.id);
              }}
              onFocus={() => setShowCemeteryHint(true)}
              onBlur={() => setShowCemeteryHint(false)}
              popupIcon={
                showCemeteryHint && isFirstTimeCemHint ? (
                  <img loading='lazy' src={HintMark} alt='hint' />
                ) : (
                  <ArrowDropDownIcon className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled' />
                )
              }
              className={`add-grave_autocomplete ${
                showCemeteryHint ? 'cem_hint_shown' : ''
              }`}
              disabled={!props.chosenCountryId || !props.chosenMunicipalityId}
            />
          </div>
        </div>

        <div className='second-step-buttons'>
          <button className='first-step-back' onClick={props.onBackClick}>
            Tilbake
          </button>
          <button className='create-button' onClick={onDoneClick}>
            Opprett graven
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndStep;
