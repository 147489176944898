import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import { fetch_request } from '../../../../../service/request';

const Header = (props) => {
	const gravesCount = props.gravesCount
  const [cemeteryName, setCemeteryName] = useState('')
	const cemeterySlug = props.cemeterySlug;

  useEffect(() => {
		if (cemeterySlug) {
			fetch_request(`/api/graveyards/${cemeterySlug}`)
				.then((response) => response.json())
				.then((data) => {
					setCemeteryName(data.name);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	return (
		<div className='municipality-header'>
			<div className='municipality-header_left'>
				<div className='municipality-header_title'>
					<span>Graven</span>
				</div>
				<div className='municipality-header_info'>
					<span className='search-graves_none-text'>
            Det er { gravesCount === 0 ? 'ingen graver' : 'totalt'} { gravesCount === 0 ? '': <span>{gravesCount}</span>} graver i {<span>{cemeteryName}</span>}. { gravesCount === 0 ? '' : 'Du kan besøke graven ved å søke etter navn og ivareta gravlagte på nett.'}
					</span>
				</div>
			</div>
			<div className='municipality-header_right'>
				<SearchBar setSearchInputValue={props.setSearchInputValue} searchDisabled={props.searchDisabled} />
			</div>
		</div>
	);
};

export default Header;
