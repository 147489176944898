const define_env = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return 'https://stage-api.nettgrav.no';
    case 'production':
      return 'https://api.nettgrav.com';
    case 'local':
      return 'http://localhost:3001';
    default:
      return 'https://stage-api.nettgrav.no';
  }
};


const setActionCableEndpoints = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return 'wss://stage-api.nettgrav.no/cable';
    case 'production':
      return 'wss://api.nettgrav.com/cable';
    case 'local':
      return 'ws://localhost:3000/cable';
    default:
      return 'ws://localhost:3000/cable';
  }
};
const CONSTANTS = {
  grave: {
    claimed: 'claimed',
    rejected: 'rejected',
  },
  actionCable: setActionCableEndpoints(),
  baseUrl: define_env(),
};

export { CONSTANTS };
