import React from 'react';
import styles from './ActiveGiftMembership.module.css';

const ActiveGiftMembership = ({ membership }) => {
  return (
    <div className={styles['gift-membership-card']}>
      <div className={styles['plan-name']}>{membership.plan_name}</div>
      <div className={styles['gifted-member-name']}>
        {membership.owner_name}
      </div>
      <div className={styles['price']}>
        <span>NOK {membership.plan_price}</span>/mnd
      </div>
    </div>
  );
};

export default ActiveGiftMembership;
