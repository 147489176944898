import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SignInPage, NotFoundPage } from '../index';
import Registration from '../Registration';
import { connect, useDispatch } from 'react-redux';
import animClasses from '../Router.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { subscriptionInvitationToken } from '../../redux/actions';
import isEmpty from 'lodash.isempty';

const AuthRouter = ({ component: Component, ...props }) => {
  const dispatch = useDispatch();
  const action = props.location.pathname.match(/([^*/]+)/g)[1];
  const screen = useSelector((state) => state.auth.currentScreen);
  const userSlug = localStorage.getItem('user-slug');
  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );

  const slugRedirect = localStorage.getItem('slug_for_redirect');

  const redirectToLastPage = () => {
    if (
      slugRedirect !== 'null' &&
      slugRedirect !== null &&
      slugRedirect !== undefined
    ) {
      localStorage.setItem('slug_for_redirect', 'null');
      setTimeout(() => props.history.push(`/deceased/${slugRedirect}`), 1000);
      // } else return <Redirect to = {{ pathname: `/${screen ? screen : ""}`}}/>
    } else if (userSlug) {
      if (props.location.key && isEmpty(invitationToken)) {
        props.history.goBack();
      } else {
        dispatch(subscriptionInvitationToken(''));
        props.history.push(`/member/${userSlug}`);
      }
    } else {
      props.history.push(`/${screen ? screen : ''}`);
    }
  };

  return (
    <TransitionGroup>
      <CSSTransition
        key={action}
        appear
        unmountOnExit
        classNames={{ ...animClasses }}
      >
        <Switch location={props.location}>
          {props.isAuthorized ? (
            redirectToLastPage()
          ) : (
            <Redirect exact from='/auth/' to='/auth/sign-in' />
          )}

          <Route exact path='/auth/sign-in' component={SignInPage} />
          <Route exact path='/registration' component={Registration} />
          <Route component={NotFoundPage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
});

export default connect(mapStateToProps)(AuthRouter);
