import React, { useState } from 'react';
import styles from './VippsAgreements.module.css';
import { Popover } from '@material-ui/core';
import vippsLogo from '../../../../img/VippsLogo.svg';
import MoreHorizontal from '../../../../img/MoreHorizontal.svg';
import ConformModal from '../ConformDeleteModal';
import { request } from '../../../../service/request';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const VippsAgreements = ({
  agreement,
  vippsAgreements,
  setVippsAgreements,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [conformModal, setConformModal] = useState(false);

  const closeConformModal = () => {
    setConformModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stopeAgreement = async () => {
    const data = {
      status: 'STOPPED',
    };
    try {
      request(`/vipps/agreements/${agreement.id}`, data, 'put')
        .then(() => {
          const updateAgreements = vippsAgreements.filter(
            (item) => item.id !== agreement.id
          );
          setVippsAgreements(updateAgreements);
          setConformModal(false);
          showUserNotification('avtale stoppet stoppet', 'success');
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles['heading-container']}>
        <div className={styles['heading']}>Vipps Agreemwnt</div>
        <div>
          <span className={styles['more-icon-container']} onClick={handleClick}>
            <img
              className={styles['more-icon']}
              src={MoreHorizontal}
              alt='moreIcon'
            />
          </span>
          <Popover
            id='long-menu'
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              style: {
                minWidth: '100px',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div
              className={styles['stope-button']}
              onClick={() => setConformModal(true)}
            >
              stope
            </div>
          </Popover>
        </div>
      </div>
      <div className={styles['card-methods']}>
        <img
          src={vippsLogo}
          alt='card'
          style={{ height: 25 }}
          className={styles['logo']}
        />
        <span>+47{agreement.phone_number}</span>
        <span>{agreement.status}</span>
      </div>
      {conformModal && (
        <ConformModal
          open={conformModal}
          handleClose={closeConformModal}
          deletePayment={stopeAgreement}
          title='Vipps Agreement'
        />
      )}
    </div>
  );
};

export default VippsAgreements;
