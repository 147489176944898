import { makeStyles, Select } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import './SettingsPageInformationEdit.css';
import { request } from '../../../service/request';
import DateInput from '../../AddNewGrave/atoms/DateInput';
import { useDispatch, useSelector } from 'react-redux';
import { setInformationAboutUser } from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { CustomExpandMore } from '../../../components/customIcon_select';
import { fetch_request } from '../../../service/request';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles(() => ({
  selector: {
    width: '100%',
    marginBottom: '16px',
    borderRadius: '10px',
    outline: 'none',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    height: '60px',
    padding: '20px',
  },
  selectorFocus: {
    backgroundColor: '#f5f5f5',
  },
  date: {
    marginBottom: '16px',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    height: '55px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SettingsPersonalInformationEdit = ({ onOpenModal, edit }) => {
  const user = useSelector((state) => state.user.user || {});
  const classes = useStyles();
  const genderMaleRef = useRef(null);
  const genderFemaleRef = useRef(null);
  const genderOthersRef = useRef(null);
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [birthDate, setBirthDate] = useState('00/00/0000');
  const [currencies, setCurrencies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [timeZone, setTimeZone] = useState('');
  const [currency_id, setCurrency_id] = useState('');
  const [language_id, setLanguage_id] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(user)) {
      setFullName(user.full_name);
      setGender(user.gender);
      setEmail(user.email);
      setPhoneNumber(user.phone_number);
      setAddress(user.address);
      setBirthDate(
        user.date_of_birth
          ? new Date(user.date_of_birth)
          : new Date('00/00/0000')
      );
      setTimeZone(user.time_zone);
    }
  }, [user]);

  const handleCancel = (event) => {
    event.preventDefault();
    edit(false);
  };

  useEffect(() => {
    fetch_request('/api/currencies', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => setCurrencies(data.currencies));

    fetch_request('/api/languages', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => setLanguages(data.currencies));
  }, []);

  const editOrCompleteUserInfo = async () => {
    try {
      const formdata = new FormData();
      if (email !== '') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          showUserNotification('Ugyldig epostadresse', 'error');
          setIsValidEmail(true);
          return;
        }
        formdata.append('user[email]', email);
        setIsValidEmail(false);
      }
      fullName !== '' && formdata.append('user[full_name]', fullName);
      phoneNumber !== '' && formdata.append('user[phone_number]', phoneNumber);
      gender !== '' && formdata.append('user[gender]', gender);
      birthDate !== '' && formdata.append('user[date_of_birth]', birthDate);
      address !== '' &&
        formdata.append('user[user_address_attributes][address]', address);
      timeZone !== '' && formdata.append('user[time_zone]', timeZone);
      currency_id !== '' &&
        formdata.append(
          'user[preferred_currency_attributes][currency_id]',
          currency_id.id
        );

      language_id !== '' &&
        formdata.append(
          'user[preferred_language_attributes][language_id]',
          language_id.id
        );

      const response = await request(`/users/${user.slug}`, formdata, 'put');
      if (response.status >= 200 && response.status < 300) {
        try {
          const newUserInfo = await request(`/users/${user.slug}`);
          dispatch(setInformationAboutUser(newUserInfo.data));
          edit(false);
          showUserNotification('Personinfo oppdatert', 'success');
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='settings-personal-information'>
      <div className='settings-personal-information_content'>
        <div className='settings-personal-information-edit_content-headers'>
          <span className='settings-personal-information-edit_content-headers_header'>
            Personinfo
          </span>
          <div className='settings-personal-information_orange-line' />
        </div>
        <div className='settings-personal-information-edit_content-headers_inputs'>
          <div className='settings-personal-information-edit_content-headers_inputs-column'>
            <input
              type='text'
              placeholder='Fullt navn'
              className='settings-input-text'
              value={fullName}
              onChange={(event) =>
                event.target.value[0] !== ' ' &&
                setFullName(
                  event.target.value
                    .replace(new RegExp('[0-9]'), '')
                    .replace(
                      new RegExp(/["'-@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/),
                      ''
                    )
                )
              }
            />
            <input
              className='settings-input-text'
              type='tel'
              placeholder='Telefonnummer'
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
            <input
              type='email'
              placeholder='Epostadresse'
              className={
                isValidEmail
                  ? 'settings-input-text-email'
                  : 'settings-input-text'
              }
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            {isValidEmail && (
              <p className='error-message'>Invalid email address</p>
            )}
            <div className='settings-inputs-gender'>
              <button
                ref={genderMaleRef}
                className={`${
                  gender === 'mann'
                    ? 'settings-inputs-gender-male_active'
                    : 'settings-inputs-gender-male'
                }`}
                onClick={() => {
                  setGender('mann');
                  genderMaleRef.current.style.backgroundColor = '#fea65a';
                  genderMaleRef.current.style.color = '#fff';
                  genderFemaleRef.current.style.backgroundColor = '#f5f5f5';
                  genderFemaleRef.current.style.color = '#777777';
                  genderOthersRef.current.style.backgroundColor = '#f5f5f5';
                  genderOthersRef.current.style.color = '#777777';
                }}
              >
                Mann
              </button>

              <button
                ref={genderFemaleRef}
                className={`${
                  gender === 'kvinne'
                    ? 'settings-inputs-gender-female_active'
                    : 'settings-inputs-gender-female'
                }`}
                onClick={() => {
                  setGender('kvinne');
                  genderFemaleRef.current.style.backgroundColor = '#fea65a';
                  genderFemaleRef.current.style.color = '#fff';
                  genderMaleRef.current.style.backgroundColor = '#f5f5f5';
                  genderMaleRef.current.style.color = '#777777';
                  genderOthersRef.current.style.backgroundColor = '#f5f5f5';
                  genderOthersRef.current.style.color = '#777777';
                }}
              >
                Kvinne
              </button>
              <button
                ref={genderOthersRef}
                className={`${
                  gender === 'annen'
                    ? 'settings-inputs-gender-others_active'
                    : 'settings-inputs-gender-others'
                }`}
                onClick={() => {
                  setGender('annen');
                  genderOthersRef.current.style.backgroundColor = '#fea65a';
                  genderOthersRef.current.style.color = '#fff';
                  genderFemaleRef.current.style.backgroundColor = '#f5f5f5';
                  genderFemaleRef.current.style.color = '#777777';
                  genderMaleRef.current.style.backgroundColor = '#f5f5f5';
                  genderMaleRef.current.style.color = '#777777';
                }}
              >
                Annen
              </button>
            </div>
            <DateInput
              placeholder='Bursdag'
              selectedDate={new Date(birthDate)}
              setSelectedDate={setBirthDate}
              label=''
            />
            <input
              type='text'
              className='settings-input-text'
              placeholder='Adresse'
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
            <Select
              native
              value={language_id}
              onChange={(event) => setLanguage_id(event.target.value)}
              className={classes.selector}
              IconComponent={CustomExpandMore}
            >
              <option value='' disabled>
                Foretrukket Språk
              </option>
              {languages.map((lan) => (
                <option value={lan.id}>{lan.dialect}</option>
              ))}
            </Select>
            <Select
              native
              value={currency_id}
              onChange={(event) => setCurrency_id(event.target.value)}
              className={classes.selector}
              IconComponent={CustomExpandMore}
            >
              <option value='' disabled>
                Foretrukket valuta
              </option>
              {currencies.map((curr) => (
                <option value={curr.id}>{curr.notation}</option>
              ))}
            </Select>
            <Select
              native
              value={timeZone}
              onChange={(event) => setTimeZone(event.target.value)}
              className={classes.selector}
              IconComponent={CustomExpandMore}
            >
              <option value='' disabled>
                Tidssone
              </option>
              <option value='Greenwich Mean Time	(GMT)'>
                Greenwich Mean Time (GMT)
              </option>
              <option value='Universal Coordinated Time	(GMT)'>
                Universal Coordinated Time (GMT)
              </option>
              <option value='European Central Time	(GMT+1:00)'>
                European Central Time (GMT+1:00)
              </option>
              <option value='Eastern European Time	(GMT+2:00)'>
                Eastern European Time (GMT+2:00)
              </option>
              <option value='(Arabic) Egypt Standard Time	(GMT+2:00)'>
                (Arabic) Egypt Standard Time (GMT+2:00)
              </option>
              <option value='Eastern African Time	(GMT+3:00)'>
                Eastern African Time (GMT+3:00)
              </option>
              <option value='Middle East Time	(GMT+3:30)'>
                Middle East Time (GMT+3:30)
              </option>
              <option value='Near East Time	(GMT+4:00)'>
                Near East Time (GMT+4:00)
              </option>
              <option value='Pakistan Lahore Time	(GMT+5:00)'>
                Pakistan Lahore Time (GMT+5:00)
              </option>
              <option value='India Standard Time	(GMT+5:30)'>
                India Standard Time (GMT+5:30)
              </option>
              <option value='Bangladesh Standard Time	(GMT+6:00)'>
                Bangladesh Standard Time (GMT+6:00)
              </option>
              <option value='Vietnam Standard Time	(GMT+7:00)'>
                Vietnam Standard Time (GMT+7:00)
              </option>
              <option value='China Taiwan Time	(GMT+8:00)'>
                China Taiwan Time (GMT+8:00)
              </option>
              <option value='Japan Standard Time	(GMT+9:00)'>
                Japan Standard Time (GMT+9:00)
              </option>
              <option value='Australia Central Time	(GMT+9:30)'>
                Australia Central Time (GMT+9:30)
              </option>
              <option value='Australia Eastern Time	(GMT+10:00)'>
                Australia Eastern Time (GMT+10:00)
              </option>
              <option value='Solomon Standard Time	(GMT+11:00)'>
                Solomon Standard Time (GMT+11:00)
              </option>
              <option value='New Zealand Standard Time	(GMT+12:00)'>
                New Zealand Standard Time (GMT+12:00)
              </option>
              <option value='Midway Islands Time	(GMT-11:00)'>
                Midway Islands Time (GMT-11:00)
              </option>
              <option value='Hawaii Standard Time	(GMT-10:00)'>
                Hawaii Standard Time (GMT-10:00)
              </option>
              <option value='Alaska Standard Time	(GMT-9:00)'>
                Alaska Standard Time (GMT-9:00)
              </option>
              <option value='Pacific Standard Time	(GMT-8:00)'>
                Pacific Standard Time (GMT-8:00)
              </option>
              <option value='Phoenix Standard Time	(GMT-7:00)'>
                Phoenix Standard Time (GMT-7:00)
              </option>
              <option value='Mountain Standard Time	(GMT-7:00)'>
                Mountain Standard Time (GMT-7:00)
              </option>
              <option value='Central Standard Time	(GMT-6:00)'>
                Central Standard Time (GMT-6:00)
              </option>
              <option value='Eastern Standard Time	(GMT-5:00)'>
                Eastern Standard Time (GMT-5:00)
              </option>
              <option value='Indiana Eastern Standard Time	(GMT-5:00)'>
                Indiana Eastern Standard Time (GMT-5:00)
              </option>
              <option value='Puerto Rico and US Virgin Islands Time	(GMT-4:00)'>
                Puerto Rico and US Virgin Islands Time (GMT-4:00)
              </option>
              <option value='Canada Newfoundland Time	(GMT-3:30)'>
                Canada Newfoundland Time (GMT-3:30)
              </option>
              <option value='Argentina Standard Time	(GMT-3:00)'>
                Argentina Standard Time (GMT-3:00)
              </option>
              <option value='Brazil Eastern Time	(GMT-3:00)'>
                Brazil Eastern Time (GMT-3:00)
              </option>
              <option value='Central African Time	(GMT-1:00)'>
                Central African Time (GMT-1:00)
              </option>
            </Select>
          </div>
        </div>
        <div className='settings_buttons'>
          <button className='settings_buttons-cancel' onClick={handleCancel}>
            Avbryt
          </button>
          <button
            className='settings_buttons-save'
            onClick={editOrCompleteUserInfo}
          >
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPersonalInformationEdit;
