import React, { useEffect, useState } from 'react';
import './Welcome.css';
import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import Logo from '../../img/Logo.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FilledInput from '@material-ui/core/FilledInput';
import { Link, Redirect } from 'react-router-dom';
import {
  getListOfCountries,
  setConfirmSignIn,
  setSubscription,
  setSubscriptionInvitationData,
  setUserId,
  subscriptionInvitationToken,
} from '../../redux/actions';
import AuthFormWrapper from './AuthFormWrapper';
import { useDispatch, useSelector } from 'react-redux';
import CountriesArrowClosed from '../../img/CountriesPopoverClosed.svg';
import CountriesArrowOpen from '../../img/CountriesPopoverOpen.svg';
import GraveLogo from '../../img/LoGo_main.svg';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import CountriesPopover from './atoms/CountriesPopover';
import 'react-toastify/dist/ReactToastify.css';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import { request } from '../../service/request';
import { fetch_request } from '../../service/request';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: 'none',
    minWidth: '350px',
    maxWidth: '350px',
    width: '100%',
    marginTop: '6px',
    borderRadius: '10px',
    maxHeight: '271px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
  },
}));

const Welcome = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [phoneNumber, setPhonenumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [anchorEl, setEnchorEl] = useState(null);
  const [chosenCountry, setChosenCountry] = useState({
    dial_code: '+47',
    id: 206,
    name: 'Norge',
  });
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;
  const userInvitation = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );
  const countries = useSelector((state) => state.auth.countries);

  useEffect(() => {
    if (!isEmpty(userInvitation) && countries.length > 0) {
      const country = countries.find(
        (country) => country.id === userInvitation.country_id
      );
      if (!isEmpty(country)) {
        setChosenCountry(country);
      }
      setPhonenumber(userInvitation.phone_number);
    }
  }, [userInvitation, countries]);

  const handleChoseCountryFromList = (country) => {
    setChosenCountry(country);
    setEnchorEl(null);
  };

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleOpenPopover = (e) => {
    setEnchorEl(anchorEl ? null : e.target.parentElement.parentElement);
  };

  React.useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const handlePhoneNumberChange = (event) => {
    const str = event.target.value.replace(/[^\d]/g, '');
    if (str.length > 0)
      setPhonenumber(event.target.value.replace(/[^\d]/g, ''));
    else setPhonenumber('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formdata = new FormData();
    formdata.append('phone_number', `${phoneNumber}`);
    formdata.append('password', `${password}`);

    if (phoneNumber) {
      request('/sessions', formdata, 'post')
        .then((response) => {
          if (response.data.success) {
            const userId = response.data.user.id;
            dispatch(setUserId(userId));
            dispatch(setSubscriptionInvitationData({}));
            dispatch(setSubscription(response.data.user.subscription));
            localStorage.setItem(
              'token',
              response.headers['x-authentication-token']
            );
            localStorage.setItem('auth', 'true');
            localStorage.setItem('rememberme', checked);

            if (checked) {
              localStorage.setItem('id', response.data.user.id);
              localStorage.setItem('user-slug', response.data.user.slug);
            } else {
              sessionStorage.setItem('id', response.data.user.id);
              sessionStorage.setItem('user-slug', response.data.user.slug);
            }
            dispatch(setConfirmSignIn());
          }
        })
        .catch((err) => {
          showUserNotification(
            'Telefonnummer og passord stemmer ikke',
            'error'
          );
        });
    }
  };

  return (
    <>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.paper}>
              <CountriesPopover
                handleChoseCountryFromList={handleChoseCountryFromList}
                country={chosenCountry.id}
              />
            </div>
          </Fade>
        )}
      </Popper>
      {redirect && <Redirect to={{ pathname: '/registration' }} />}

      {/*<ButtonGroup disableElevation variant="contained" className='auth-welcome-res-btng'>
        <Button className='auth-welcome-res-one'>Logg inn</Button>
        <Button className='auth-welcome-res-two' onClick={() => setRedirect(true)}>Registrer</Button>
      </ButtonGroup>*/}

      {/*<span className='auth-welcome_title'>Kjære deg</span>
      <span className='auth-welcome_subtitle'>Velkommen</span>*/}
      <AuthFormWrapper>
        <form
          className='auth-form'
          autoComplete='off'
          onSubmit={handleFormSubmit}
        >
          <div className='auth-welcome_input'>
            <InputLabel className='auth-welcome_input-label input-label-number'>
              Mobilnummer
            </InputLabel>
            <div className='create-membership_first-input_block-inputs'>
              <Button
                onClick={handleOpenPopover}
                className='create-membership_first-input_block-inputs_button'
              >
                {chosenCountry.dial_code}
                <img
                  loading='lazy'
                  src={anchorEl ? CountriesArrowOpen : CountriesArrowClosed}
                  className='countries-arrow_popover'
                  alt=''
                />
              </Button>
              <input
                className='mobile'
                type='tel'
                placeholder='00 00 00 00'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <input
                className='desktop'
                type='text'
                placeholder='00 00 00 00'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
          </div>

          <div className='auth-welcome_input second-password'>
            <InputLabel className='auth-welcome_input-label'>
              Passord
            </InputLabel>
            <FilledInput
              id='standard-adornment-password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              className='auth-welcome_email-button'
              placeholder='Skriv inn passordet'
              onChange={handlePasswordChange}
              required
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>

          <div className='auth-welcome_container'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckBoxChange}
                  inputProps={{ 'aria-label': 'checkbox' }}
                  className='checkbox'
                />
              }
              label='Husk meg'
            />
          </div>

          <div className='auth-welcome_button-group'>
            <button
              variant='contained'
              className='auth-welcome_contained-button'
              type='submit'
            >
              Logg inn
            </button>
          </div>
          <div className='auth-welcome_inline-span-wrapper'>
            <span>Er du ikke registrert?</span>
            <span
              variant='outlined'
              className='auth-welcome_register_link_span'
              onClick={() => setRedirect(true)}
            >
              Registrer
            </span>
          </div>
          <div className='reset-button-wrapper'>
            <button
              className='auth-welcome_orange-res_button'
              onClick={props.goToResetPass}
            >
              Glemt passordet?
            </button>
          </div>
        </form>
      </AuthFormWrapper>
    </>
  );
};

export default Welcome;
