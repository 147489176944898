import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import arrow from '../img/PointerBottom.svg';
const iconStyles = {
    selectIcon: {
      position: "unset",
      marginLeft: "-17px",
      zIndex: 0,

    }
  };

export const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <img src = {arrow} alt=""
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);