import React, { useState, useEffect } from 'react';
import styles from './AboutItem.module.css';
import { useSelector } from 'react-redux';
import DownArrow from '../../../../../img/SmallDownArrow.svg';
import UpArrow from '../../../../../img/SmallUpArrow.svg';
import ExpandIcon from '../../../../../img/Expand.svg';
import EditIcon from '../../../../../img/EditOutlinePen.svg';
import EditSmallIcon from '../../../../../img/EditSmallIcon.svg';
import BasicInformation from '../BasicInformation';
import ConnectedGraves from '../ConnectedGraves';
import BlackPulsIcon from '../../../../../img/BlackPulsIcon.svg';
import Relationships from '../Relationships';
import Workplaces from '../Workplaces';
import GraveInterests from '../GraveInterests';
import GravePassion from '../GravePassion';
import isEmpty from 'lodash.isempty';
import GraveNote from '../GraveNote';

const AboutItem = (props) => {
  const grave = useSelector((state) => state.grave.grave);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hideEdit, setHideEdit] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1020) {
        setIsExpanded(true);
        setHideEdit(false);
      } else {
        setHideEdit(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded, hideEdit]);

  return (
    <div className={styles['container-wrapper']}>
      <div
        className={styles['about-item-header']}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{props.title}</span>
        {hideEdit ? (
          isExpanded ? (
            <img
              src={UpArrow}
              alt='down'
              className={styles['collapse-arrow']}
            />
          ) : (
            <img
              src={DownArrow}
              alt='down'
              className={styles['collapse-arrow']}
            />
          )
        ) : props.canEdit && props.title === 'Gravlenker' ? (
          <div className={styles['action-btn']} onClick={props.onEdit}>
            <img src={BlackPulsIcon} alt='Edit' />
            <button>Knytt gravlagt</button>
          </div>
        ) : (
          props.canEdit && (
            <div className={styles['action-btn']} onClick={props.onEdit}>
              <img src={EditSmallIcon} alt='Edit' />
              <button>Redigere</button>
            </div>
          )
        )}
      </div>
      {isExpanded && (
        <div>
          {hideEdit && props.canEdit && (
            <div className={styles['action-btn']} onClick={props.onEdit}>
              <img src={EditSmallIcon} alt='Edit' />
              <button>Redigere</button>
            </div>
          )}
          {props.title === 'Grunnleggende informasjon' && (
            <BasicInformation
              item={props.item}
              onEdit={props.onEdit}
              title={props.title}
              emptyMessage={props.emptyMessage}
              canEdit={props.canEdit}
            />
          )}
          {props.title === 'Gravlenker' && (
            <ConnectedGraves
              item={props.item}
              onEdit={props.onEdit}
              title={props.title}
              emptyMessage={props.emptyMessage}
              canEdit={props.canEdit}
            />
          )}
          {props.title === 'Relasjoner' && (
            <Relationships
              item={props.item}
              onEdit={props.onEdit}
              title={props.title}
              emptyMessage={props.emptyMessage}
              canEdit={props.canEdit}
            />
          )}
          {props.title === 'Arbeidsplass' && (
            <Workplaces
              item={props.item}
              onEdit={props.onEdit}
              title={props.title}
              emptyMessage={props.emptyMessage}
              canEdit={props.canEdit}
            />
          )}
          {props.title === 'Interesser' && (
            <GraveInterests
              item={props.item}
              onEdit={props.onEdit}
              title={props.title}
              emptyMessage={props.emptyMessage}
              canEdit={props.canEdit}
            />
          )}
          {props.title === 'Lidenskap' && (
            <GravePassion
              item={props.item}
              onEdit={props.onEdit}
              title={props.title}
              emptyMessage={props.emptyMessage}
              canEdit={props.canEdit}
            />
          )}
          {!isEmpty(props.item) && props.title === 'Gravlenke' && (
            <div className={styles['set-relation-btn']}>
              {props.canEdit && (
                <button className={styles['btn']} onClick={props.onEdit}>
                  Sett relasjon
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AboutItem;
