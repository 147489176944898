import React, { useState, useEffect } from 'react';
import styles from './NewPaymentPage.module.css';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import SelectPaymentMethod from './atoms/SelectedPaymentMethod/SelectPaymentMethod';
import {
  setCurrentSubscriptionPlan,
  setPaymentMethods,
  setProductInfo,
  setShowCardMethod,
  setSelectedCardMethod,
  setVippsPaymentMethods,
  getListOfCountries,
  setCurrentSubscriptionData,
  setShowVippsPaymentMethod,
} from '../../redux/actions';
import CardPaymentMethod from './atoms/CardPaymentMethod/CardPaymentMethod';
import Vipps from './atoms/Vipps/Vipps';
import { request, fetch_request } from '../../service/request';
import { CONSTANTS } from '../../service/constants';
import Loader from '../../components/Loader/Loader';
import { useLocation, useHistory } from 'react-router-dom';
import BackPageButton from '../../components/BackPageButton/BackPageButton';

const customStyles = {
  padding: '24px 0px 0px 20px',
};

const NewPaymentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isFreePlan, setIsFreePlan] = useState(false);
  const [defaultPlan, setDefaultPlan] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCardValue, setIsCardValue] = useState(false);

  const selectedPaymentMethod = useSelector(
    (state) => state.paymentMethods.cardMethod || 'card'
  );
  const plans = useSelector((state) => state.products) || [];
  const selectedPlan = useSelector((state) => state.selectedPlan || null);
  const currentSubscriptionPlan = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionPlan || null
  );
  const currentSubscription = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionData
  );
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const vippsParams = new URLSearchParams(location.search).get('vipps');
  const cardParams = new URLSearchParams(location.search).get('card');

  useEffect(() => {
    if (vippsParams) {
      dispatch(setSelectedCardMethod('vipps'));
    } else if (cardParams) {
      dispatch(setSelectedCardMethod('card'));
    }
  }, [vippsParams, cardParams]);

  const handelCardInputs = (value) => setIsCardValue(value);
  const handelSelectPayment = (method) => {
    history.push(`/plans/payment?payment_method_type=${method}`);
    dispatch(setSelectedCardMethod(method));
  };

  // -------Fetch Countries-------//

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  //  ------- Fetch products------//
  useEffect(() => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/products`, config)
      .then((res) => {
        const products = res.data.filter((product) => product.key !== 'free');
        dispatch(setProductInfo(products));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  // ----------Fetch user current Subscription------------//

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          dispatch(setCurrentSubscriptionPlan(res.data.product));
          dispatch(setCurrentSubscriptionData(res.data));
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  // -----------------Fetch User Payments methods-------------------//

  const fetchPaymentMethods = async () => {
    if (userSlug) {
      try {
        request(`/users/${userSlug}/payment_methods`).then((res) => {
          const vippsMethods = res.data.filter(
            (method) => method.payment_type === 'vipps'
          );
          const cards = res.data.filter(
            (method) => method.payment_type === 'card'
          );
          if (cards.length > 0) {
            dispatch(setPaymentMethods(cards));
          } else {
            dispatch(setPaymentMethods([]));
            dispatch(setShowCardMethod(true));
          }
          if (vippsMethods.length > 0) {
            dispatch(setVippsPaymentMethods(vippsMethods));
            dispatch(setShowVippsPaymentMethod(true));
          } else {
            dispatch(setVippsPaymentMethods([]));
            dispatch(setShowVippsPaymentMethod(true));
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [userSlug]);

  useEffect(() => {
    if (!isEmpty(selectedPlan)) {
      setDefaultPlan(selectedPlan);
      if (!isEmpty(currentSubscriptionPlan)) {
        const isFreePlan = selectedPlan.key === 'free';
        if (isFreePlan) {
          setIsFreePlan(true);
        } else {
          setIsFreePlan(isFreePlan);
        }
      } else if (selectedPlan.key === 'free') {
        setIsFreePlan(true);
      } else {
        setIsFreePlan(false);
      }
    } else if (!isEmpty(currentSubscriptionPlan)) {
      const isFreePlan = currentSubscriptionPlan.key === 'free';
      setIsFreePlan(isFreePlan);
      const subscription = plans.find(
        (plan) => plan.id === currentSubscriptionPlan.id
      );
      if (subscription) {
        setDefaultPlan(subscription);
      }
    } else {
      setDefaultPlan(plans[0]);
      setIsFreePlan(true);
    }
  }, [selectedPlan, currentSubscriptionPlan, plans]);

  if (
    loading ||
    isEmpty(plans) ||
    isEmpty(defaultPlan) ||
    countries.length === 0
  ) {
    return <Loader />;
  }

  return (
    <div className={styles['payment-page']}>
      <BackPageButton backLink='/plans' customStyles={customStyles} />
      {currentSubscription.status !== 'pending' ? (
        <React.Fragment>
          <div className={styles['payment-page-heading']}>
            <span>Utsjekk</span>
          </div>
          {!isFreePlan && (
            <div className={styles['payment-page-container']}>
              <SelectPaymentMethod
                handelSelectPayment={handelSelectPayment}
                selectedPaymentMethod={selectedPaymentMethod}
              />
            </div>
          )}
          {(selectedPaymentMethod === 'card' || isFreePlan) && (
            <CardPaymentMethod
              isFreePlan={isFreePlan}
              defaultPlan={defaultPlan}
              plans={plans}
              selectedPlan={selectedPlan}
              selectedPaymentMethod={selectedPaymentMethod}
              currentSubscriptionPlan={currentSubscriptionPlan}
              currentSubscription={currentSubscription}
              countries={countries}
              handelCardInputs={handelCardInputs}
              isCardValue={isCardValue}
            />
          )}
          {selectedPaymentMethod === 'vipps' && !isFreePlan && (
            <Vipps
              isFreePlan={isFreePlan}
              defaultPlan={defaultPlan}
              plans={plans}
              selectedPlan={selectedPlan}
              selectedPaymentMethod={selectedPaymentMethod}
              currentSubscriptionPlan={currentSubscriptionPlan}
              currentSubscription={currentSubscription}
            />
          )}
        </React.Fragment>
      ) : (
        !loading && (
          <div className={styles['pending-status']}>
            Mens abonnementet ditt venter, kan du ikke oppdatere eller endre
            planen.
          </div>
        )
      )}
    </div>
  );
};

export default NewPaymentPage;
