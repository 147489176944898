import React, { useEffect, useState } from 'react';
import SelectPaymentMethod from './atoms/SelectedPaymentMethod/SelectPaymentMethod';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import './PaymentPage.css';
import CardPayment from './atoms/CardPayment/CardPayment';
import BillingInformation from './atoms/BillingInformation/BillingInformation';
import { useHistory, useLocation } from 'react-router-dom';
import SelectedPlan from './atoms/SelectedPlan/SelectedPlan';
import { CONSTANTS } from '../../service/constants';
import { request, fetch_request } from '../../service/request';
import {
  setCurrentSubscriptionPlan,
  setInformationAboutUser,
  setPaymentMethods,
  updateUserSubscription,
  setSelectedPlan,
  setProductInfo,
  setVippsAgreementData,
  setReturnPage,
  setPaymentMethod,
  setShowCardMethod,
  setSelectedCardMethod,
  setBillingInformation,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import FeatureList from './atoms/FeatureList/FeatureList';
import PaymentMethods from './atoms/PaymentMethods/PaymentMethods';
import ProcessModal from './atoms/ProcessModal';
import ErrorModal from './atoms/ErrorModal/ErrorModal';
import { getListOfCountries } from '../../redux/actions';
import Vipps from './atoms/Vipps/Vipps';
import isEmpty from 'lodash.isempty';
import ConfirmModal from './atoms/ConfirmModal/ConfirmModal';
import AddNewCard from './atoms/AddNewCard/AddNewCard';

const DEFAULT_PAYMENT_METHOD =
  process.env.REACT_APP_ENV === 'production' ? 'card' : 'vipps';
const appearance = {
  theme: 'night',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, appearance);

const PaymentPage = () => {
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const selectedPaymentMethod = useSelector(
    (state) => state.paymentMethods.cardMethod || 'card'
  );
  const [vippsPhoneNumber, setVippsPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [chargeId, setChargeId] = useState('');
  const [showLoadingSection, setShowLoadingSection] = useState(false);
  const plans = useSelector((state) => state.products) || [];
  const selectedPlan = useSelector((state) => state.selectedPlan || null);
  const vippsAgreement = useSelector((state) => state.vipps.vippsAgreement);
  const currentSubscriptionPlan = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionPlan || null
  );
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const [disable, setDisable] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [isFreePlan, setIsFreePlan] = useState(false);
  const [user, setUser] = useState({});
  const billingInformation = useSelector(
    (state) => state.paymentMethods.billingInformation
  );
  const returnPage = useSelector((state) => state.returnPage);
  const [openModal, setOpenModal] = useState(false);
  const [agreementDetails, setAgreementDetails] = useState({});
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods || null
  );
  const showCard = useSelector((state) => state.paymentMethods.showCard);
  const openPaymentMethod = useSelector(
    (state) => state.paymentMethods.selectedMethod
  );
  const [defaultPlan, setDefaultPlan] = useState({});
  const [isCardValue, setIsCardValue] = useState(false);
  const [countries, setCountries] = useState([]);
  const subscriptionValidation = useSelector(
    (state) => state.subscriptionValidationData
  );
  const currentSubscription = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionData
  );

  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  useEffect(() => {
    if (selectedPaymentMethod === 'card') {
      request(`/users/${userSlug}`)
        .then((res) => {
          const currentUser = res.data.user;
          setUser(currentUser);
          dispatch(setInformationAboutUser(res.data));
          if (isEmpty(billingInformation)) {
            if (currentUser.country_id && countries) {
              const country = countries.find(
                (country) => country.id === currentUser.country_id
              );
              if (!isEmpty(country)) {
                const userPhoneNumber = `${country.dial_code}${currentUser.phone_number}`;
                dispatch(
                  setBillingInformation({
                    ...billingInformation,
                    fullName: currentUser?.full_name
                      ? currentUser.full_name
                      : '',
                    billingAddress: currentUser?.address
                      ? currentUser.address
                      : '',
                    email: currentUser?.email ? currentUser.email : '',
                    country_id: country.id,
                    postalCode: '',
                    phoneNumber: userPhoneNumber,
                  })
                );
              } else {
                dispatch(
                  setBillingInformation({
                    ...billingInformation,
                    fullName: currentUser?.full_name
                      ? currentUser.full_name
                      : '',
                    billingAddress: currentUser?.address
                      ? currentUser.address
                      : '',
                    email: currentUser?.email ? currentUser.email : '',
                    postalCode: '',
                    phoneNumber: '',
                    country_id: countries[0].id,
                  })
                );
              }
            } else {
              dispatch(
                setBillingInformation({
                  ...billingInformation,
                  fullName: currentUser?.full_name ? currentUser.full_name : '',
                  billingAddress: currentUser?.address
                    ? currentUser.address
                    : '',
                  email: currentUser?.email ? currentUser.email : '',
                  postalCode: '',
                  phoneNumber: '',
                  country_id: countries[0].id,
                })
              );
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedPaymentMethod, countries]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [userSlug]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/products`, config)
      .then((res) => {
        dispatch(setProductInfo(res.data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          dispatch(setCurrentSubscriptionPlan(res.data.product));
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(selectedPlan)) {
      setDefaultPlan(selectedPlan);
      if (!isEmpty(currentSubscriptionPlan)) {
        const isFreePlan = selectedPlan.key === 'free';
        if (isFreePlan) {
          setIsFreePlan(true);
          setDisable(false);
          dispatch(setPaymentMethod({}));
        } else {
          setIsFreePlan(isFreePlan);
          const areAllValuesFilled = Object.values(billingInformation).every(
            (value) => value !== ''
          );
          const newSubscription =
            // selectedPlan.id !== currentSubscriptionPlan.id &&
            isCardValue && areAllValuesFilled;
          setDisable(
            newSubscription || !isEmpty(openPaymentMethod) ? false : true
          );
        }
      } else if (selectedPlan.key === 'free') {
        setIsFreePlan(true);
        setDisable(false);
        dispatch(setPaymentMethod({}));
      } else {
        setIsFreePlan(false);
        setDisable(true);
      }
    } else if (!isEmpty(currentSubscriptionPlan)) {
      const isFreePlan = currentSubscriptionPlan.key === 'free';
      setIsFreePlan(isFreePlan);
      setDisable(currentSubscriptionPlan.id ? true : !isFreePlan);
      const subscription = plans.find(
        (plan) => plan.id === currentSubscriptionPlan.id
      );
      if (subscription) {
        setDefaultPlan(subscription);
      }
    } else {
      setDefaultPlan(plans[0]);
      setIsFreePlan(true);
      setDisable(false);
    }
  }, [selectedPlan, currentSubscriptionPlan, plans]);

  useEffect(() => {
    if (selectedPaymentMethod === 'vipps') {
      setIsCardValue(false);
    } else {
      setVippsPhoneNumber('');
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (
      !isEmpty(currentSubscriptionPlan) &&
      !isEmpty(selectedPlan) &&
      !isEmpty(openPaymentMethod)
    ) {
      handleDisable(false);
    } else if (!isEmpty(selectedPlan) && !isEmpty(openPaymentMethod)) {
      handleDisable(false);
    } else if (
      !isEmpty(currentSubscriptionPlan) &&
      !isEmpty(openPaymentMethod)
    ) {
      handleDisable(false);
    } else if (selectedPlan.key !== 'free') {
      handleDisable(true);
    }
  }, [openPaymentMethod]);

  useEffect(() => {
    if (showLoadingSection) {
      const getVippsAgreement = () => {
        if (!vippsAgreement.agreementId) return;
        try {
          request(`/vipps/agreements/${vippsAgreement.agreementId}`).then(
            (response) => {
              if (response.data.status === 'ACTIVE') {
                clearInterval(intervalId);
                setShowLoadingSection(false);
                setDisable(false);
                setAgreementDetails(response.data);
              } else if (response.data.status === 'STOPPED') {
                clearInterval(intervalId);
                setShowLoadingSection(false);
                setErrorMessage('Du kansellerte betalingen');
                setOpenErrorModal(true);
              }
            }
          );
        } catch (err) {
          console.log(err);
        }
      };
      const intervalId = setInterval(getVippsAgreement, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showLoadingSection]);

  const createVippsCharged = () => {
    let requestPayload = {};
    const description = selectedPlan.description.slice(0, 60);
    const agreementId =
      agreementDetails?.agreement_id !== undefined
        ? agreementDetails.agreement_id
        : openPaymentMethod?.agreement_id
        ? openPaymentMethod.agreement_id
        : agreementDetails.id;

    if (vippsPhoneNumber.trim !== '') {
      requestPayload = {
        agreement_id: agreementId,
        phone_number:
          vippsPhoneNumber.trim() !== ''
            ? `+47${vippsPhoneNumber}`
            : openPaymentMethod.card_number,
        amount: amount,
        description: description,
      };

      return fetch(
        `${CONSTANTS.baseUrl}/api/vipps/agreement_charges/${agreementId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 400) {
            handleCloseModal();
            setErrorMessage(data.detail);
            setOpenErrorModal(true);
          }
          if (data.error) {
            handleCloseModal();
            setErrorMessage(data.error);
            setOpenErrorModal(true);
          } else {
            setChargeId(data.chargeId);
            if (data.payment_method_id) {
              handelVippsSubscription(data.payment_method_id);
            }
          }
        })
        .catch((error) => {
          handleCloseModal();
          setOpenErrorModal(true);
        });
    }
  };

  const handelVippsSubscription = async (paymentMethodId) => {
    let requestPayload = {};
    if (
      currentSubscription &&
      currentSubscription.product.id === selectedPlan.id
    ) {
      requestPayload = {
        subscription: {
          payment_method_id: paymentMethodId,
          price: amount + currentSubscription.price,
          user_count: parseInt(
            subscriptionCount + currentSubscription.user_count
          ),
          product_id: selectedPlan.id,
        },
      };
      return fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      )
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          dispatch(updateUserSubscription(true));
          handleServerResponse(json);
        })
        .catch((error) => {
          setErrorMessage(error.data);
          handleCloseModal();
          setOpenErrorModal(true);
        });
    } else {
      requestPayload = {
        subscription: {
          payment_method_id: paymentMethodId,
          price: amount,
          user_count: parseInt(subscriptionCount),
          product_id: selectedPlan.id,
        },
      };
      return fetch(`${CONSTANTS.baseUrl}/api/subscriptions.json`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      })
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          dispatch(updateUserSubscription(true));
          handleServerResponse(json);
        })
        .catch((error) => {
          setErrorMessage(error.data);
          handleCloseModal();
          setOpenErrorModal(true);
        });
    }
  };

  const handelSelectPayment = (method) => {
    dispatch(setSelectedCardMethod(method));
  };

  const handleProductChosen = (plan) => {
    dispatch(setSelectedPlan(plan));
  };

  const handleDisable = (value) => {
    setDisable(value);
  };

  const handleSubscribe = () => {
    if (isFreePlan) {
      if (!isEmpty(currentSubscription) && currentSubscription.key !== 'free') {
        setOpenConfirmModal(true);
      } else {
        createSubscription();
        setSubmit(false);
      }
    } else if (Object.keys(openPaymentMethod).length > 0) {
      handleShowModal();
      if (openPaymentMethod.payment_type === 'vipps') {
        createVippsCharged();
      } else {
        handleStripeCharged();
      }
    } else if (selectedPaymentMethod === 'vipps') {
      handleShowModal();
      createVippsCharged();
    } else {
      handleShowModal();
      setSubmit(true);
    }
  };

  const handleVippsAgreement = (response) => {
    if (response.vippsConfirmationUrl) {
      window.open(response.vippsConfirmationUrl, '_blank');
      handleCloseModal();
      setShowLoadingSection(true);
    } else {
      handleCloseModal();
      setErrorMessage(response.error);
      setOpenErrorModal(true);
    }
  };

  const handelCreateAgreement = () => {
    let requestPayload = {};
    handleShowModal();

    if (vippsPhoneNumber.trim !== '') {
      requestPayload = {
        agreement: {
          phone_number: vippsPhoneNumber,
          product_name: selectedPlan.name,
          amount: amount,
          currency: 'NOK',
        },
      };

      return fetch(`${CONSTANTS.baseUrl}/api/vipps/agreements.json`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      })
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((res) => {
          if (res.agreement && res.agreement.status === 'ACTIVE') {
            handleCloseModal();
            setAgreementDetails(res.agreement);
            setDisable(false);
          } else if (res.status === 400) {
            setErrorMessage('Vennligst oppgi et gyldig telefonnummer');
            handleCloseModal();
            setOpenErrorModal(true);
          } else {
            dispatch(setVippsAgreementData(res));
            handleVippsAgreement(res);
          }
        })
        .catch((error) => {
          setErrorMessage(error.data);
          handleCloseModal();
          setOpenErrorModal(true);
        });
    }
  };

  const handleStripeCharged = async () => {
    let requestPayload = {};
    if (openPaymentMethod.id) {
      requestPayload = {
        payment: {
          payment_method_id: openPaymentMethod?.stripe_id,
          price: amount,
          user_count: parseInt(subscriptionCount),
          product_id: selectedPlan.id,
        },
        payment_method: {
          stripe_id: openPaymentMethod.stripe_id,
        },
      };
    } else {
      requestPayload = {
        payment: {
          payment_intent_id: openPaymentMethod?.id,
        },
      };
    }

    return fetch(`${CONSTANTS.baseUrl}/api/stripe/payments.json`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    })
      .then(function (confirmResult) {
        return confirmResult.json();
      })
      .then((json) => {
        console.log(json);
        if (json.success) {
          handleUpdateSubscribe();
        }
        if (!json.success) {
          handleCloseModal();
          setErrorMessage(json.message);
          setOpenErrorModal(true);
        }
      })
      .catch((error) => {
        handleCloseModal();
        setErrorMessage(error.data);
        setOpenErrorModal(true);
      });
  };

  const handleUpdateSubscribe = async () => {
    let requestPayload = {};
    if (
      !isEmpty(currentSubscription) &&
      currentSubscription.product.id === selectedPlan.id
    ) {
      requestPayload = {
        subscription: {
          payment_method_id: openPaymentMethod?.id,
          price: amount + currentSubscription.price,
          user_count: parseInt(
            subscriptionCount + currentSubscription.user_count
          ),
          product_id: selectedPlan.id,
        },
      };
      return fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      )
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          if (json.error) {
            handleCloseModal();
            setErrorMessage(json.message);
            setOpenErrorModal(true);
          } else {
            dispatch(updateUserSubscription(true));
            handleServerResponse(json);
          }
        })
        .catch((error) => {
          handleCloseModal();
          setErrorMessage(error.data);
          setOpenErrorModal(true);
        });
    } else {
      requestPayload = {
        subscription: {
          payment_method_id: openPaymentMethod?.id,
          price: amount,
          user_count: parseInt(subscriptionCount),
          product_id: selectedPlan.id,
        },
      };
      return fetch(`${CONSTANTS.baseUrl}/api/subscriptions.json`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      })
        .then(function (confirmResult) {
          return confirmResult.json();
        })
        .then((json) => {
          if (json.error) {
            handleCloseModal();
            setErrorMessage(json.message);
            setOpenErrorModal(true);
          } else {
            dispatch(updateUserSubscription(true));
            handleServerResponse(json);
          }
        })
        .catch((error) => {
          handleCloseModal();
          setErrorMessage(error.data);
          setOpenErrorModal(true);
        });
    }
  };

  const fetchPaymentMethods = async () => {
    if (paymentMethods.length > 0) {
      return;
    } else if (userSlug) {
      try {
        request(`/users/${userSlug}/payment_methods`).then((data) => {
          dispatch(setPaymentMethods(data.data));
          if (data.data.length > 0) {
            dispatch(setShowCardMethod(false));
          } else {
            dispatch(setShowCardMethod(true));
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const createSubscription = () => {
    const requestPayload = {
      subscription: {
        product_id: defaultPlan.id,
        price: defaultPlan.price || 0,
        user_count: 0,
      },
    };
    fetch(`${CONSTANTS.baseUrl}/api/subscriptions`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(updateUserSubscription(true));
        handleServerResponse(data);
      })
      .catch((error) => {
        handleCloseModal();
        setOpenErrorModal(true);
      });
  };

  const handleServerResponse = (response) => {
    if (response.error) {
      handleCloseModal();
      setOpenErrorModal(true);
    } else {
      const url = returnPage.url || '/settings/subscription-invitations';
      if (url) {
        dispatch(setShowCardMethod(false));
        dispatch(setBillingInformation({}));
        dispatch(setPaymentMethod({}));
        dispatch(setPaymentMethods([]));
        dispatch(setSelectedCardMethod(DEFAULT_PAYMENT_METHOD));
        dispatch(setReturnPage(null));
      }
      history.push(url, { show: 1 });
    }
  };

  const handleShowCard = (value) => {
    dispatch(setShowCardMethod(value));
  };

  const handleShowModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handelCardInputs = (value) => {
    setIsCardValue(value);
  };

  const handleShowPayment = (method) => dispatch(setPaymentMethod(method));

  return (
    <>
      {defaultPlan && (
        <div className='payment-page'>
          <div className='payment-page-heading'>
            <span>Utsjekk</span>
          </div>
          <div className='payment-page-container'>
            {defaultPlan && (
              <div className='payment-container'>
                {paymentMethods &&
                  !isFreePlan &&
                  paymentMethods.map((method) => (
                    <PaymentMethods
                      method={method}
                      showPayment={
                        showCard ? false : method.id === openPaymentMethod.id
                      }
                      handleShowPayment={handleShowPayment}
                      handleShowCard={handleShowCard}
                      key={method.id}
                    />
                  ))}
                {!showCard && !isFreePlan && paymentMethods.length > 0 && (
                  <AddNewCard
                    handleShowCard={handleShowCard}
                    handleShowPayment={handleShowPayment}
                    showCard={showCard}
                  />
                )}
                {isFreePlan && defaultPlan && (
                  <div className='payment-details'>
                    <div className='payment-methods'>
                      <div className='payment-methods-heading'>
                        <span>
                          Fri plan trenger ingen betaling da den er gratis
                        </span>
                      </div>
                    </div>
                    <div className='no-payment-message'>
                      Registrer deg for vår gratisplan uten
                      betalingsinformasjon. Når du oppgraderer til familie eller
                      familie +, vil vi be om dine betalingsdetaljer. Trykk på
                      abonner for å fortsette med Fri plan.
                    </div>
                  </div>
                )}
                {showCard && !isFreePlan && (
                  <div className='payment-details'>
                    <SelectPaymentMethod
                      handelSelectPayment={handelSelectPayment}
                      selectedPaymentMethod={selectedPaymentMethod}
                    />
                    {selectedPaymentMethod === 'card' && (
                      <React.Fragment>
                        <div className='card-information'>
                          <Elements stripe={stripePromise}>
                            {defaultPlan ? (
                              <CardPayment
                                chosenPlan={defaultPlan}
                                submit={submit}
                                setSubmit={setSubmit}
                                billingInformation={billingInformation}
                                handleCloseModal={handleCloseModal}
                                handelCardInputs={handelCardInputs}
                              />
                            ) : null}
                          </Elements>
                        </div>
                        {!isEmpty(billingInformation) && (
                          <BillingInformation
                            handleDisable={handleDisable}
                            billingInformation={billingInformation}
                            setBillingInformation={setBillingInformation}
                            isCardValue={isCardValue}
                            countries={countries}
                            vippsPhoneNumber={vippsPhoneNumber}
                          />
                        )}
                      </React.Fragment>
                    )}
                    {selectedPaymentMethod === 'vipps' && (
                      <Vipps
                        phoneNumber={vippsPhoneNumber}
                        showLoadingSection={showLoadingSection}
                        handelCreateAgreement={handelCreateAgreement}
                        agreementDetails={agreementDetails}
                        handlePhoneNumber={(value) =>
                          setVippsPhoneNumber(value)
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            <div className='selected-plan'>
              <>
                <div className='selected-plan-details'>
                  <SelectedPlan
                    plans={plans}
                    chosenPlan={defaultPlan}
                    handleProductChosen={handleProductChosen}
                    disable={disable}
                    handleSubscribe={handleSubscribe}
                    isFreePlan={isFreePlan}
                  />
                </div>
                <FeatureList chosenPlan={defaultPlan} />
              </>
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <ProcessModal
          open={openModal}
          message='Betaling pågår. Vennligst vent'
        />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
      {openConfirmModal && (
        <ConfirmModal
          isOpen={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          title={`Nedgradere ${currentSubscriptionPlan.name} til gratis plan`}
          onConfirm={createSubscription}
          disableBtn={() => setSubmit(false)}
        />
      )}
    </>
  );
};

export default PaymentPage;
