import {
  Avatar,
  Button,
  TextareaAutosize,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import showUserNotification from '../../UserNotification/showUserNotification';
import styles from './CommentsArea.module.css';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles((theme) => ({
  large: {
    width: '55px',
    height: '55px',
  },
}));

const CommentCreateForm = (props) => {
  const classes = useStyles();
  const [messageText, setMessageText] = useState('');
  const user = useSelector((state) => state.user.user);
  const commentAuthor = useSelector((state) =>
    state.grave.grave.grave_users.find((u) => u.user_id === user.id)
  );
  const can_comment = useSelector((state) => state.grave.grave.can_comment);
  const handleCreateComment = () => {
    if (messageText?.trim()) {
      props.createComment(messageText);
      setMessageText('');
    } else showUserNotification('Skriv inn en kommentar', 'warning');
  };

  return (
    <div className={styles['memories_comments-area']}>
      {can_comment && (
        <div className={styles['memories_comments-area_content']}>
          {/* <div className={styles['memories_comments-area_content-field']}> */}
          {/* {commentAuthor?.user_avatar ? (
              <Avatar
                src={commentAuthor?.user_avatar}
                className={classes.large}
              />
            ) : (
              <Avatar>{commentAuthor?.user_name?.charAt(0)}</Avatar>
            )} */}
          <div className={styles['memories_comments-area_content-input']}>
            <textarea
              type='text'
              placeholder='Skriv her'
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <button
              onClick={handleCreateComment}
              className={styles['memories_comments-area_content-input_button']}
            >
              <SendIcon style={{ color: '#fff' }} />
            </button>
          </div>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};
export default CommentCreateForm;
