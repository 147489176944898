import React, { useState, useRef, useEffect } from 'react';
import X from '../../../../../img/X.svg';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './AddEventImageModal.module.css';
import { request } from '../../../../../service/request';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import UploadImages from '../uploadImages/UploadImages';
import { useSelector, useDispatch } from 'react-redux';
import SelectedImages from '../SelectedImages/SelectedImages';
import {
  addLifeEventPhotos,
  setLifeEvents,
  setSelectedEvent,
  setEventPhotoIndex,
  addLifeEventVideos,
} from '../../../../../redux/actions';
import CropperModal from '../CropperModal/CropperModal';
import DiscardModal from '../../DiscardPopupModal/DiscardModal';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash.isempty';
import CloseImageModal from '../../CloseImageModal/CloseImageModal';
import SelectedVideos from '../SelectedVideos/SelectedVideos';
import UploadVideo from '../UploadVideo/UploadVideo';
import TabButtons from '../../../../../components/TabButtons/TabButtons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));
const EventImageModal = ({
  title,
  onOpen,
  onClose,
  event,
  handelSaveModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [tabItems, setTabItems] = useState([
    { text: 'legge til bilder' },
    { text: 'legge til videoer' },
  ]);
  const selectedEvent = useSelector((state) => state.grave.selectedEvent);
  const [isChanged, setIsChanged] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const lifeEventPhotos = useSelector((state) => state.grave.lifeEventPhotos);
  const lifeEventVideos = useSelector((state) => state.grave.lifeEventVideos);
  const currentEditPhoto = useSelector(
    (state) => state.photoReducer.editEventPhotoIndex
  );
  const allEvents = useSelector((state) => state.grave.lifeEvents);
  const grave = useSelector((state) => state.grave.grave);
  const [openCropper, setOpenCropper] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  useEffect(() => {
    if (lifeEventPhotos.length > 0 || lifeEventVideos.length > 0) {
      setDisableBtn(false);
      setIsChanged(true);
    }
  }, [lifeEventPhotos, lifeEventVideos]);

  const handelDiscardModal = (value) => {
    setOpenDiscardModal(value);
  };

  const handleShowImages = () => {
    setShowImage(true);
  };

  const handelOpenCropper = () => {
    setOpenCropper(true);
  };

  const uploadFiles = async (eventId) => {
    setIsUploading(true);
    const uploadedImage = [];
    const uploadedVideos = [];
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      if (lifeEventPhotos && lifeEventPhotos.length > 0) {
        for (const photo of lifeEventPhotos) {
          const formData = new FormData();
          formData.append('life_event_image[category]', photo.category);
          formData.append('life_event_image[life_event_id]', eventId);
          formData.append(
            'life_event_image[file]',
            photo.file,
            `${photo.file.name}`
          );
          const res = await request(
            `/life_event_images`,
            formData,
            'post',
            headers
          );
          uploadedImage.push(res.data);
        }
      }
      if (lifeEventVideos && lifeEventVideos.length > 0) {
        for (const video of lifeEventVideos) {
          const formData = new FormData();
          formData.append('life_event_video[life_event_id]', eventId);
          formData.append(
            'life_event_video[file]',
            video.file,
            `${video.file.name}`
          );
          const res = await request(
            `/life_event_videos`,
            formData,
            'post',
            headers
          );
          uploadedVideos.push(res.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
    if (!isEmpty(selectedEvent)) {
      const events = [...allEvents];
      const index = allEvents.findIndex(
        (event) => event.id === selectedEvent.id
      );
      const images = [...selectedEvent.life_event_images, ...uploadedImage];
      const videos = [...selectedEvent.life_event_videos, ...uploadedVideos];

      events.splice(index, 1, {
        ...selectedEvent,
        life_event_images: images,
        life_event_videos: videos,
      });
      console.log(events);
      dispatch(setLifeEvents([...events]));
      dispatch(
        setSelectedEvent({
          ...selectedEvent,
          life_event_images: images,
          life_event_videos: videos,
        })
      );
      dispatch(setEventPhotoIndex(0));
      setIsUploading(false);
      showUserNotification(
        'Livshendelsen er sendt og lagret på graven',
        'success'
      );
    } else {
      const events = [...allEvents];
      const index = allEvents.findIndex((event) => event.id === eventId);
      const images = [...event.life_event_images, ...uploadedImage];
      const videos = [...event.life_event_videos, ...uploadedVideos];

      events.splice(index, 1, {
        ...event,
        life_event_images: images,
        life_event_videos: videos,
      });
      dispatch(setLifeEvents([...events]));
      setIsUploading(false);
      dispatch(setEventPhotoIndex(0));
    }
    if (!isUploading) {
      onClose();
      dispatch(addLifeEventPhotos([]));
      dispatch(addLifeEventVideos([]));
      handelSaveModal(true);
    }
  };

  return (
    <Modal
      open={onOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['event-content_header']}>
            <span>{title}</span>
            <img
              loading='lazy'
              src={X}
              alt='x'
              onClick={() => {
                if (isChanged && isUploading) {
                  setOpenWarningModal(true);
                } else if (isChanged) {
                  setOpenDiscardModal(true);
                } else {
                  onClose();
                  dispatch(addLifeEventPhotos([]));
                }
              }}
            />
          </div>
          <div className={styles['event-content_subheader']}>
            <span>Legg inn bilder som viser livshendelsen</span>
          </div>
        </div>
        <div className={styles['upload-container']}>
          <TabButtons tab={tab} setTab={setTab} tabItems={tabItems} />
          {tab == 0 && showImage && lifeEventPhotos.length > 0 && (
            <React.Fragment>
              {openCropper ? (
                <CropperModal
                  photo={lifeEventPhotos[currentEditPhoto]}
                  handelCloseCropper={() => setOpenCropper(false)}
                />
              ) : (
                <SelectedImages
                  backgroundImage={lifeEventPhotos[0]}
                  handelOpenCropper={handelOpenCropper}
                />
              )}
            </React.Fragment>
          )}
          {tab == 1 && lifeEventVideos.length > 0 && (
            <React.Fragment>
              <SelectedVideos />
            </React.Fragment>
          )}
          {tab == 0 && <UploadImages handleShowImages={handleShowImages} />}
          {tab == 1 && <UploadVideo />}
          {isUploading && (
            <div className={styles['loading-container']}>
              <div className={styles['loader']}>
                <CircularProgress style={{ color: '#fea65a' }} />
                <span>Sender...</span>
              </div>
            </div>
          )}
        </div>
        {!isUploading && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    handelDiscardModal(true);
                  } else {
                    onClose();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={
                  disableBtn ? styles['disabledButton'] : styles['rootVerify']
                }
                disabled={disableBtn}
                onClick={() => uploadFiles(event.id)}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
        {openDiscardModal && (
          <DiscardModal
            open={openDiscardModal}
            handleClose={handelDiscardModal}
            onConfirm={onClose}
            title='Laster opp bilder'
          />
        )}
        {openWarningModal && isUploading && (
          <CloseImageModal
            body={`Modalen kan ikke lukkes akkurat nå da bildene er i ferd med å lastes opp`}
            title='Fjern valgte bilder'
            isUploading={isUploading}
            onOpen={openWarningModal}
            onClose={() => setOpenWarningModal(false)}
            onConfirm={onClose}
          />
        )}
      </div>
    </Modal>
  );
};

export default EventImageModal;
