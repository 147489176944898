import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import TabIcon from '../MemoriesPosts/icon/MusicIcon';
import styles from './CommentOnToggle.module.css';
import MusicComment from './MusicComment';
import PhotoComment from './PhotoComment';
import VideoComment from './VideoComment';
import MusicTab from '../../../../img/MusicTab.svg';
import VideoTab from '../../../../img/VideoTab.svg';
import PictureTab from '../../../../img/PictureTab.svg';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  rootIndicator: {
    backgroundColor: '#FEA65A',
    minWidth: '70px',
  },
  rootFlexContainer: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    width: '100%',
  },
  rootFlexSoloContainer: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-around !important',
    width: '100%',
  },
  rootTabsRoot: {
    maxWidth: '300px',
    width: '100%',
  },
}));

const CommentOnToggle = (props) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(0);
  const comment = useSelector((state) => state.grave.commentOnToggle);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (
      comment.photos &&
      comment.photos.length > 0 &&
      comment.embeddedPhotos &&
      comment.embeddedPhotos.length > 0
    ) {
      setImages([...comment.photos, ...comment.embeddedPhotos]);
    } else if (comment.photos && comment.photos.length > 0) {
      setImages([...comment.photos]);
    } else if (comment.embeddedPhotos && comment.embeddedPhotos.length > 0) {
      setImages([...comment.embeddedPhotos]);
    }
  }, [comment]);

  useEffect(() => {
    if ((comment.video) && comment.embeddedVideo) {
      setVideos([comment.video, comment.embeddedVideo]);
    } else if (comment.video) {
      setVideos([comment.video]);
    } else if (comment.embeddedVideo) {
      setVideos([comment.embeddedVideo]);
    }
  }, [comment]);

  useEffect(() => {
    if (
      ((comment.photos && comment.photos.length > 0) ||
        (comment.embeddedPhotos && comment.embeddedPhotos.length > 0)) &&
      !comment.video &&
      !comment.embeddedVideo &&
      !(comment.music && comment.music.length > 0)
    ) {
      setValue('image');
    } else if (
      ((comment.photos && comment.photos.length > 0) ||
        (comment.embeddedPhotos && comment.embeddedPhotos.length > 0)) &&
      (comment.video || comment.embeddedVideo) &&
      !(comment.music && comment.music.length > 0)
    ) {
      setValue('image');
    } else if (
      ((comment.photos && comment.photos.length > 0) ||
        (comment.embeddedPhotos && comment.embeddedPhotos.length > 0)) &&
      !comment.video &&
      !comment.embeddedVideo &&
      comment.music &&
      comment.music.length > 0
    ) {
      setValue('image');
    } else if (
      (comment.video || comment.embeddedVideo) &&
      !(comment.photos && comment.photos.length > 0) &&
      !(comment.embeddedPhotos && comment.embeddedPhotos.length > 0) &&
      comment.music &&
      comment.music.length > 0
    ) {
      setValue('video');
    } else if (
      (comment.video || comment.embeddedVideo) &&
      !(comment.photos && comment.photos.length > 0) &&
      !(comment.embeddedPhotos && comment.embeddedPhotos.length > 0) &&
      !(comment.music && comment.music.length > 0)
    ) {
      setValue('video');
    } else if (
      (comment.video || comment.embeddedVideo) &&
      ((comment.photos && comment.photos.length > 0) ||
        (comment.embeddedPhotos && comment.embeddedPhotos.length > 0)) &&
      !(comment.music && comment.music.length > 0)
    ) {
      setValue('video');
    } else if (
      !(
        comment.photos &&
        comment.photos.length > 0 &&
        comment.embeddedPhotos &&
        comment.embeddedPhotos.length > 0
      ) &&
      !comment.video &&
      !comment.embeddedVideo &&
      comment.music &&
      comment.music.length > 0
    ) {
      setValue('music');
    } else if (
      !(
        (comment.photos && comment.photos.length > 0) ||
        (comment.embeddedPhotos && comment.embeddedPhotos.length > 0)
      ) &&
      (comment.video || comment.embeddedVideo) &&
      comment.music &&
      comment.music.length > 0
    ) {
      setValue('music');
    } else if (
      comment.photos &&
      comment.photos.length > 0 &&
      !comment.video &&
      !comment.embeddedVideo &&
      comment.music &&
      comment.music.length > 0
    ) {
      setValue('music');
    } else if (isEmpty(comment)) {
      setValue(0);
    }
  }, [comment]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {!isEmpty(comment) &&
        (comment.photos ||
          comment.embeddedPhotos ||
          comment.video ||
          comment.embeddedVideo ||
          comment.music) && (
          <div className={styles['comment-on-toogle']}>
            {value === 'music' && comment.music.length > 0 && (
              <MusicComment isUploading={props.isUploading} />
            )}
            {value === 'video' && (comment.video || comment.embeddedVideo) && (
              <VideoComment isUploading={props.isUploading} videos={videos} />
            )}
            {value === 'image' &&
              ((comment.photos && comment.photos.length > 0) ||
                (comment.embeddedPhotos &&
                  comment.embeddedPhotos.length > 0)) && (
                <PhotoComment
                  images={images}
                  deletePhoto={props.deletePhoto}
                  isUploading={props.isUploading}
                />
              )}
            <div className={styles['comment-on-toogle_tabs']}>
              {((comment.photos && comment.photos.length > 0) ||
                (comment.embeddedPhotos &&
                  comment.embeddedPhotos.length > 0)) &&
                (comment.video || comment.embeddedVideo) &&
                comment.music &&
                comment.music.length > 0 && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                      indicator: classes.rootIndicator,
                      flexContainer: classes.rootFlexContainer,
                      root: classes.rootTabsRoot,
                    }}
                  >
                    <Tab
                      value='image'
                      icon={<TabIcon img={PictureTab} />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      value='video'
                      icon={<TabIcon img={VideoTab} />}
                      {...a11yProps(1)}
                    />
                    <Tab
                      value='music'
                      icon={<TabIcon img={MusicTab} />}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                )}
              {!(comment.photos && comment.photos.length > 0) &&
                !(
                  comment.embeddedPhotos && comment.embeddedPhotos.length > 0
                ) &&
                (comment.video || comment.embeddedVideo) &&
                comment.music &&
                comment.music.length > 0 && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                      indicator: classes.rootIndicator,
                      flexContainer: classes.rootFlexContainer,
                      root: classes.rootTabsRoot,
                    }}
                  >
                    <Tab
                      value='video'
                      icon={<TabIcon img={VideoTab} />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      value='music'
                      icon={<TabIcon img={MusicTab} />}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                )}
              {((comment.photos && comment.photos.length > 0) ||
                (comment.embeddedVideo && comment.embeddedVideo.length > 0)) &&
                isEmpty(comment.video) &&
                isEmpty(comment.embeddedVideo) &&
                comment.music &&
                comment.music.length > 0 && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                      indicator: classes.rootIndicator,
                      flexContainer: classes.rootFlexContainer,
                      root: classes.rootTabsRoot,
                    }}
                  >
                    <Tab
                      value='image'
                      icon={<TabIcon img={PictureTab} />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      value='music'
                      icon={<TabIcon img={MusicTab} />}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                )}
              {((comment.photos && comment.photos.length > 0) ||
                (comment.embeddedPhotos &&
                  comment.embeddedPhotos.length > 0)) &&
                (comment.video || comment.embeddedVideo) &&
                !(comment.music && comment.music.length > 0) && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                      indicator: classes.rootIndicator,
                      flexContainer: classes.rootFlexContainer,
                      root: classes.rootTabsRoot,
                    }}
                  >
                    <Tab
                      value='image'
                      icon={<TabIcon img={PictureTab} />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      value='video'
                      icon={<TabIcon img={VideoTab} />}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                )}
            </div>
          </div>
        )}
    </>
  );
};

// const mapStateToProps = (state) => ({
//   comment: state.grave.commentOnToggle,
// });

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default CommentOnToggle;
