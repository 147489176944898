import React from 'react';
import './Questions.css';
import QuestionMark from '../../img/QuestionMark.svg';
import Question from './atoms/Question';

const Questions = (props) => {
  return (
    <div className='questions-section'>
      <div className='questions-section-title'>
        <span className='questions-section-title_button'>
          <img loading='lazy' src={QuestionMark} alt="QuestionMark"/>
        </span>
        Spørsmål og svar 
      </div>

      <div className='questions-section-questions'>
        <Question
          title='Hva gjør en Nettgrav forskjellig fra en tradisjonell grav?'
          content='En Nettgrav gir en mer personlig måte å bevare minnet om de gravlagte på. I motsetning til en tradisjonell grav, gir en Nettgrav en plattform for å dele historier, bilder og annet innhold som gir et mer fullstendig bilde av gravlagte.
          I tillegg er en Nettgrav tilgjengelig når man ønsker det, noe som gir tilgang for familie og venner uansett hvor de er i verden. Dette gir en mer inkluderende opplevelse for alle som ønsker å være nær hele familien.'
        />
        <Question
          title='Er det mulig å si opp den fysiske graven via nettgrav.no?'
          content='Foreløpig ikke. Vi jobber med å gjøre dette mulig i fremtiden slik at det blir enklere og friere å være ansvarlig for graver.'
        />
        <Question
          title='Fri, Familie og Familie +?'
          content='Nettgrav kommer med tre abonnementer. Fri, familie og familie +. Fri er gratis å komme i gang med og gir 5 søk per dag og begrenset med funksjoner. Bevisst Familie og Familie + er medlemskap med alle funksjoner og full tilgang. Familie + kommer med egen kontokontakt som hjelper med å knytte deg med gravlagte og oppsett av din konto.'
        />
        <Question
          title='Er det mulig å opprette flere graver for samme person på nettgrav.no?'
          content='Nei, det er kun tillatt med en grav per person, akkurat som på en tradisjonell gravlund.'
        />
        <Question
          title='Hvem har tillatelse til å laste opp ord og minner på gravtavlen?'
          content='Kun gravfestere eller administratorer av graven kan godkjenne relasjoner til den gravlagte, som deretter kan legge til minner på gravtavlen og ivareta verdiene.'
        />
        <Question
          title='Hvorfor må dokumenter lastes opp for å vise at man er ansvarlig for graven?'
          content='For å sikre et sannferdig nettverk, krever Nettgrav bevis for ansvar for graven før vi gir administrasjonsrett. Godkjente dokumenter inkluderer dødsattest, gravstell- eller festefaktura, eller advokatbrev som bekrefter eierrettigheter.'
        />
      </div>
    </div>
  )
};


export default Questions;
