import { Button, InputLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import '../ResetPass.css';
import axios from 'axios';
import {fetch_request} from '../../../service/request';
import { useSelector } from 'react-redux';
import {CONSTANTS} from "../../../service/constants";
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';

const formatTimer = (timer) => {
    if(String(timer).length === 2) {
      return `${timer}`
    } else if (String(timer).length === 1) {
      return `0${timer}`
    }
}

const ResetSecondStep = (props) => {
    const [code, setCode] = useState([]);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timer, setTimer] = useState(59);
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [input3, setInput3] = useState('');
    const [input4, setInput4] = useState('');
    const [input5, setInput5] = useState('');
    const countries = useSelector((state) => state.auth.countries);
    const [country, setCountry] = useState({dial_code: '+47', id: 1, name: "Norge"})

    
    React.useEffect( () => {
     if(props.phoneData.country && countries.length > 0){
        const selectedCountry = countries.find((c)=> c.id.toString() === props.phoneData.country.toString() )
        if(!isEmpty(selectedCountry)){
            setCountry(selectedCountry)
        }
     }
    }, [])

    React.useEffect( () => {
        document && document.getElementById("1").focus() 
    }, [])

    const handleInputChange = (value, currentField) => {
        const re = /^(\s*|\d+)$/
        if (re.test(value)) {
            if (currentField === 1) {
                setInput1(value)
            }   else if (currentField === 2) {
                    setInput2(value)
            }   else if (currentField === 3) {
                setInput3(value)
            }   else if (currentField === 4) {
                setInput4(value)
            }   else if (currentField === 5) {
                setInput5(value)
            }

            if (value === "") {
                if (currentField !== 1) {
                    document.getElementById((currentField - 1).toString()).focus()
                }
            } else {
                if (currentField !== 5) {
                    document.getElementById((currentField + 1).toString()).focus()
                }
            }
        }

    }
    // const moveFocusTo = (prevField, currentField, nextField) => {
    //     const currentFieldValue = document.getElementById(currentField);
    //     let codeArray = code;
    //     codeArray[parseInt(currentField) - 1] = currentFieldValue.value;
    //     setCode(codeArray)
    //     if (currentFieldValue.value.length === 1) {
    //       document.getElementById(nextField).focus();
    //     } else if (currentFieldValue.value.length === 0) {
    //       document.getElementById(prevField).focus();
    //     }
    // }

    const handlepPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value)
    }

    const resendOTPCode = () => {
        if(props.phoneData) {
            let formdata = new FormData();
            formdata.append("phone_number", `${props.phoneData.phoneNumber}`);
            formdata.append("country_id", `${props.phoneData.country}`);
            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch_request("/api/generate_auth_token_for_forgot_passwords", requestOptions)
            .then(response => response.json())
            .then(result => {
                setTimer(59)
            })
            .catch(error => console.log('error', error));
        }
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if(password === confirmPassword) {
            let mainData = new FormData();
            mainData.append('phone_number', props.phoneData.phoneNumber)
            mainData.append('auth_code', code.join(''))
            mainData.append('new_password', password)
            mainData.append('new_password_confirmation', confirmPassword)
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    "Accept": '*/*'
                },
                redirect: 'follow'
            }
            var verificationData = new FormData();
            verificationData.append("phone_number", `${props.phoneData.phoneNumber}`)
            verificationData.append("auth_code", `${code.join('')}`);
            var verificationOptions = {
                method: 'POST',
                body: verificationData,
                redirect: 'follow',
            };
            fetch_request('/api/verification', verificationOptions) 
            .then(response => response.json())
            .then(data => {
              if(data.success) {
                axios.post(`${CONSTANTS.baseUrl}/api/forgot_passwords`, mainData, config)
                .then(response => {
                    if(response.data.success) {
                        window.location.reload();
                    }
                })
                .catch(err => console.log(err))
              }
            })
            .catch(err => console.log(err))
        }
        else if (password !== confirmPassword) {
            alert("Passordene stemmer ikke")
        }
    }

    const isButtonEnabled = () => {
        let regex = /^[0-9]/
        return (regex.test(input1) && regex.test(input2) && regex.test(input3) && regex.test(input4) && regex.test(input5))
    }

    React.useEffect(() => {
        let timerTimeout;
        if(timer > 0) {
          timerTimeout = setTimeout(() => setTimer(prev => prev - 1), 1000)
        };
        return () => {
          if (timerTimeout) {
            clearTimeout(timerTimeout);
          };
        };
    }, [timer]);

    const handleOnNextClick = () => {
        var verificationData = new FormData();
        verificationData.append("phone_number", `${props.phoneData.phoneNumber}`)
        verificationData.append("auth_code", stringCode());
        var verificationOptions = {
            method: 'POST',
            body: verificationData,
            redirect: 'follow',
        };

        fetch_request('/api/verification', verificationOptions) 
        .then(response => {
            if (response.status === 401 ) {
                showUserNotification('Engangspassordet er ikke gyldig', 'error')
            } else if (response.status === 200 ){
                props.setAuthCodeFromSecondStep(stringCode())
                showUserNotification('Vellykket verifisering', 'success')
                props.nextPage()
            } else {
                showUserNotification('Noe gikk av. Prøv igjen senere', 'error')
            }
        })
    }

    const stringCode = () => {
        return (input1.toString() + input2.toString() + input3.toString() + input4.toString() + input5.toString())
    }

    return(
        <div className='auth-reset_first-step'>
            <span className="auth-reset_subtitle auth-reset_second-step">Skriv inn engangskoden sendt til {country.dial_code}{props.phoneData.phoneNumber}</span>
            <div className='auth-welcome_input auth-reset auth-thy'>
                <InputLabel className='auth-welcome_input-label'>Skriv inn koden</InputLabel>
                <div className='auth-welcome_input-thy'>
                    <input className='input-thy__inner' type='text' maxLength={1} value={input1} id="1" onChange={(e) => handleInputChange(e.target.value, 1)}/>
                    <input className='input-thy__inner' type='text' maxLength={1} value={input2} id="2" onChange={(e) => handleInputChange(e.target.value, 2)}/>
                    <input className='input-thy__inner' type='text' maxLength={1} value={input3} id="3" onChange={(e) => handleInputChange(e.target.value, 3)}/>
                    <input className='input-thy__inner' type='text' maxLength={1} value={input4} id="4" onChange={(e) => handleInputChange(e.target.value, 4)}/>
                    <input className='input-thy__inner' type='text' maxLength={1} value={input5} id="5" onChange={(e) => handleInputChange(e.target.value, 5)}/>
                </div>
            </div>

            <div className="auth-welcome_input-resend">
                <span className="auth-welcome_input-resend_text">
                Ikke mottatt engangskode? 
                {
                    timer > 0 && ` Du kan sende ny engangskode om 00:${formatTimer(timer)}`
                }
                {
                    timer === 0 && <Button onClick={resendOTPCode} className='auth-welcome_input-resend_text-button_resend'>Send på ny</Button>
                }
                </span>
            </div>
            <div className='create-membership_first__buttons second'>
                <button className='auth-welcome_contained-button_back' onClick={props.prevPage}>Tilbake</button>
                <button variant="contained" className='auth-welcome_contained-button' onClick={handleOnNextClick} disabled={!isButtonEnabled()}>Bekrefte</button>
            </div>
        </div>
    )
}

export default ResetSecondStep;