import React, { useEffect, useState } from 'react';
import './Header.css'
import SearchBar from './SearchBar'
import {fetch_request} from '../../../../../service/request';

const Header = (props) => {
  const [municipalitiesCount, setMunicipalitiesCount] = useState(0)

  useEffect(
    () => {
      fetch_request(`/api/municipalities_count`)
        .then((response) => response.json())
        .then((data) => {
          setMunicipalitiesCount(data.count)
        })
        .catch((err) => console.log(err));
    },
    []
  )

  return(
    <div className="municipality-header">
      <div className="municipality-header_left">
        <div className="municipality-header_title">
          <span>Finn en grav i disse kommunene</span>
        </div>
        <div className="municipality-header_info">
          <span className="search-graves_none-text">Det er <span>{municipalitiesCount}</span> tilgjengelige kommuner. Du kan søke etter kommunen for å enklere finne graven og ivareta graven på nett.</span>
        </div>
      </div>
      <div className="municipality-header_right">
        <SearchBar setSearchInputValue={props.setSearchInputValue}/>
      </div>
    </div>
  )
}

export default Header;