import { Button, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player'
import { addCommentOnToggle } from '../../../../../redux/actions';
import './AddByLink.css';

const useStyle = makeStyles(() => ({
    rootCancel: {
        width: '180px',
        height: '55px',
        color: '#C9CACB',
        backgroundColor: '#fff',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
    },
    rootSave: {
        width: '180px',
        height: '55px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#FEA65A',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#FEA65A',
        }
    },
    rootSaveDisabled: {
        width: '180px',
        height: '55px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#F5F5F5',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px'
    }
}))

const AddByLink = (props) => {
    const classes = useStyle();
    const [link, setLink] = useState('');
    const [headline, setHeadline] = useState('');
    const [description, setDescription] = useState('');
    const dispatch = useDispatch();
    const currentComment = useSelector(state => state.grave.commentOnToggle);
    const [isValidUrl, setIsValidUrl] = useState(false);

    const saveVideoComment = () => {
        dispatch(addCommentOnToggle({...currentComment, title: description, text: headline, video: link}))
        props.closeModal();
    }

    const handleOnLinkChange = (event) => {
        setLink(event.target.value);
    }

    useEffect(() => {
        if(ReactPlayer.canPlay(link)) {
            setIsValidUrl(true)
        } else {
            setIsValidUrl(false)
        }
    },[link]);

    return(
        <div className='add-by-link'>
            <div className="add-by-link_header">
                <span>Lim inn videolinken du ønsker å legge til graven</span>
                <input type='text' placeholder='https://' value={link} onChange={handleOnLinkChange}/>
                <span className={link ? 'add-by-link_header-subtitle disabled' : 'add-by-link_header-subtitle'}>Du kan legge inn videor fra sider som <span>Youtube</span>, <span>Vimeo</span> med mere.</span>
            </div>
            {
                link &&
                <div className='add-by-link_header-image'>
                    { isValidUrl && <ReactPlayer url={link} height='200px' width='360px' light={true}/>}
                    {/* <span className='add-by-link_header-image_middle'>Headline</span>
                    <input type='text' value={headline} onChange={e => setHeadline(e.target.value)}/>
                    <span>Description</span>
                    <textarea value={description} onChange={e => setDescription(e.target.value)}/> */}
                    { !isValidUrl && <span className="video-link-validation-error">Legg til gyldig videolink</span> }
                    <div className="add-by-link_header-image_buttons">
                        <Button className={classes.rootCancel} onClick={props.closeModal}>Avbryt</Button>
                        <Button disabled={!isValidUrl} className={isValidUrl ? classes.rootSave : classes.rootSaveDisabled} onClick={saveVideoComment}>Lagre</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default AddByLink;