import React, { useState, useEffect } from 'react';
import './CreateMembership.css';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthFormRedux, setStepRegistration } from '../../redux/actions';
import { fetch_request } from '../../service/request';
import AuthFormWrapper from './AuthFormWrapper';
import RegisterFirstStep from './atoms/RegisterFirstStep';
import RegisterSecondStep from './atoms/RegisterSecondStep';
import RegisterThirdStep from './atoms/RegisterThirdStep';
import {
  subscriptionInvitationToken,
  setSubscriptionInvitationData,
  setInvitationTokenErrorMessage,
} from '../../redux/actions';
import showUserNotification from '../../components/UserNotification/showUserNotification';

const CreateMembership = (props) => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const authorizationFormData = useSelector(
    (state) => state.auth.authorizationForm
  );
  const step = authorizationFormData.step;
  const dispatch = useDispatch();
  const setAuthFormData = (data) => dispatch(setAuthFormRedux(data));

  const onNextStep = () => dispatch(setStepRegistration(step + 1));
  const onBackStep = () => dispatch(setStepRegistration(step - 1));
  const resetStep = () => dispatch(setStepRegistration(0));

  const findGrave = new URLSearchParams(location.search).get(
    'invitation_token'
  );

  if (findGrave) {
    dispatch(subscriptionInvitationToken(findGrave));
  }

  useEffect(() => {
    if (findGrave) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/find_subscription_invitations/${findGrave}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            showUserNotification(data.error, 'error');
            dispatch(setInvitationTokenErrorMessage(data.error));
            history.push('/registration');
          } else {
            dispatch(setSubscriptionInvitationData(data));
            dispatch(setInvitationTokenErrorMessage(''));
            if (data.user_slug) {
              history.push('/auth/sign-in');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [findGrave]);

  return (
    <AuthFormWrapper>
      <div className='create-membership'>
        {redirect && <Redirect to={{ pathname: '/auth/sign-in' }} />}

        {step === 0 && (
          <span className='create-membership_title'>
            Velkommen <span>til nettgrav.no</span>
          </span>
        )}
        {step === 1 && (
          <span className='create-membership_subtitle'>
            Du vil nå motta en melding
          </span>
        )}
        {step === 2 && (
          <span className='create-membership_text'>
            Passordet ble sendt til: {authorizationFormData.country?.dial_code}{' '}
            {authorizationFormData.phoneNumber}
          </span>
        )}

        {step === 0 && (
          <RegisterFirstStep
            onNextClick={onNextStep}
            authorizationFormData={authorizationFormData}
            setAuthFormData={setAuthFormData}
            setRedirect={setRedirect}
          />
        )}
        {step === 1 && (
          <RegisterSecondStep
            onBackClick={onBackStep}
            onNextClick={onNextStep}
            setAuthFormData={setAuthFormData}
            authorizationFormData={authorizationFormData}
          />
        )}
        {step === 2 && (
          <RegisterThirdStep
            onBackClick={onBackStep}
            resetStep={resetStep}
            setAuthFormData={setAuthFormData}
            authorizationFormData={authorizationFormData}
          />
        )}
      </div>
    </AuthFormWrapper>
  );
};

export default CreateMembership;
