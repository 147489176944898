import React from 'react';
import './SearchField.css';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../../img/LoGo_main.svg';
import SearchIcon from '../../../img/seach223.svg';
import { Link } from 'react-router-dom';
import SearchArrow from '../../../img/SearchArrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { searchInputClick } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#9F9F9F',
    '@media (max-width: 500px)': {
      padding: '0 0 0 7px',
    },
  },
  inputRoot: {
    color: '#9F9F9F',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '56%',
    height: '37px',
    transitionDuration: '2s',
    '@media (max-width: 750px)': {
      width: '50%',
      paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    },
  },
  rightArrow: {
    position: 'absolute',
    right: '3px',
    top: '8px',
    opacity: 0.5,
    transition: 'opacity 0.3s ease-in',
    zIndex: 1,
    width: '25px',
    cursor: 'pointer',
  },
  focused: {
    width: '100%',
  },
}));

const SearchField = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSearchInputClick = useSelector(
    (state) => state.search.isSearchInputClick
  );
  const { setSearchInput, searchInput, history } = props;

  const [searchInputValue, setSearchInputValue] = React.useState(searchInput);
  const showRightArrow = props.isSearchFocused && searchInputValue.length >= 3;

  const handleSearch = () => {
    if (searchInputValue.length >= 3) {
      dispatch(searchInputClick(!isSearchInputClick));
      setSearchInput(searchInputValue);
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' || event.target.value === '') {
      handleSearch();
    }
  };

  React.useEffect(() => {
    if (searchInput !== '' && history.location.pathname !== '/search')
      history.push('/search');
  }, [searchInput, isSearchInputClick]);

  return (
    <div className='search-menu'>
      <div className='search-section'>
        <div>
          <Link to='/'>
            <div className='search-section_logo'>
              <img loading='lazy' src={Logo} alt='Logo' />
            </div>
          </Link>
        </div>
        <div
          className={`search-section-search ${
            props.isSearchFocused ? 'focused' : ''
          }`}
        >
          <div className={classes.searchIcon}>
            <img src={SearchIcon} alt='Search Icon' />
          </div>
          <InputBase
            placeholder={props.isSearchFocused ? 'Søk på navn' : ''}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
              focused: classes.focused,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={props.isSearchFocused ? searchInputValue : ''}
            onFocus={props.handleSearchFocus}
            onBlur={props.handleSearchBlur}
            onChange={(event) => {
              setSearchInputValue(event.target.value);
            }}
            onKeyUp={handleEnterKey}
          />
          {showRightArrow && (
            <img
              className={classes.rightArrow}
              src={SearchArrow}
              alt='Right Arrow'
              onMouseDown={(event) => {
                event.preventDefault();
                handleSearch(event);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchField;
