import React, { useState, useEffect } from 'react';
import styles from './PlanPaymentDetails.module.css';
import { useSelector, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { MenuItem, makeStyles } from '@material-ui/core';
import {
  setSubscriptionTotalPrice,
  setSubscriptionCount,
} from '../../../../redux/actions';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
  children: {
    whiteSpace: 'normal',
    backgroundColor: '#fff!important',
    color: '#36434B',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: '10px',
  },
  listItemText: {
    fontSize: '1em !important',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const PlanPaymentDetails = ({ chosenPlan, plans, handleProductChosen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const [tax, setTax] = useState(0.0);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (chosenPlan && subscriptionCount > 0) {
      const total = chosenPlan.price * subscriptionCount + tax;
      dispatch(setSubscriptionTotalPrice(total));
    } else {
      const total = chosenPlan.price + tax;
      dispatch(setSubscriptionTotalPrice(total));
    }
  }, [chosenPlan]);

  const handleMenuItemClick = (selectedProductId) => {
    const selectedProduct = plans.find(
      (product) => product.id === selectedProductId
    );
    handleProductChosen(selectedProduct);
    if (selectedProduct && subscriptionCount > 0) {
      const total = selectedProduct.price * subscriptionCount + tax;
      dispatch(setSubscriptionTotalPrice(total));
    } else {
      const total = selectedProduct.price + tax;
      dispatch(setSubscriptionTotalPrice(total));
    }
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSubscriptionMembers = (member) => {
    if (member > 0 && !isEmpty(chosenPlan)) {
      dispatch(setSubscriptionCount(member));
      const total = chosenPlan.price * member + tax;
      dispatch(setSubscriptionTotalPrice(total));
    } else {
      const total = chosenPlan.price + tax;
      dispatch(setSubscriptionTotalPrice(total));
      dispatch(setSubscriptionCount(1));
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      <div className={styles['order-details']}>
        <span className={styles['order-heading']}>Ordre detaljer</span>
        <div className={styles['plans-list']}>
          <button onClick={(event) => handleClick(event)}>
            endre plan
            {open ? <ExpandLess /> : <ExpandMore />}
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {plans.map((product, index) => {
              return (
                <MenuItem
                  key={index}
                  className={classes.children}
                  onClick={() => handleMenuItemClick(product.id)}
                >
                  {product.name}
                </MenuItem>
              );
            })}
          </Popover>
        </div>
      </div>
      {chosenPlan.key !== 'free' && (
        <div className={styles['selected-plan-price']}>
          <span className={styles['selected-plan-name']}>Familiemedlemmer</span>
          <div className={styles['selected-plan-add-more']}>
            <span
              className={styles['add-more-member']}
              onClick={() => {
                if (subscriptionCount > 1) {
                  const members = subscriptionCount - 1;
                  updateSubscriptionMembers(members);
                }
              }}
            >
              <RemoveIcon style={{ color: '#404d56' }} />
            </span>
            <span className={styles['selected-price']}>
              {subscriptionCount}
            </span>
            <span
              className={styles['add-more-member']}
              onClick={() => {
                if (subscriptionCount > 0) {
                  const members = subscriptionCount + 1;
                  updateSubscriptionMembers(members);
                }
              }}
            >
              <AddIcon style={{ color: '#404d56' }} />
            </span>
          </div>
        </div>
      )}
      <div className={styles['selected-plan-price']}>
        <span className={styles['selected-plan-name']}>
          {chosenPlan ? chosenPlan.name : ''}
        </span>
        <span className={styles['selected-price']}>
          NOK{' '}
          {chosenPlan && chosenPlan.price ? chosenPlan.price.toFixed(2) : 0.0}
        </span>
      </div>
      <div className={styles['selected-plan-taxes']}>
        <span className={styles['tax-heading']}>Skatter</span>
        <span className={styles['tax-price']}>Kr {tax.toFixed(2)}</span>
      </div>
      <div className={styles['total-price']}>
        <span className={styles['total-price-heading']}>Total</span>
        <span className={styles['total-price-monthly']}>
          NOK {amount.toFixed(2)}
          <span>/Måned</span>
        </span>
      </div>
    </div>
  );
};

export default PlanPaymentDetails;
