import React, { useEffect, useState } from 'react';
import styles from './PaymentMethods.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../service/request';
import { setPaymentMethods } from '../../../redux/actions';
import BillingMethod from './BillingMethod';
import VippsAgreements from './VippsAgreements/VippsAgreements';
import Loader from '../../../components/Loader/Loader';

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user?.user) || {};
  const subscription = user.subscription || {};
  const amount = subscription.amount && `${subscription.amount} kroner`;
  const [vippsAgreements, setVippsAgreements] = useState([]);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods || null
  );

  useEffect(() => {
    fetchPaymentMethods();
    fetchVippsAgreements();
  }, [userSlug]);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    if (!userSlug) return;
    try {
      await request(`/users/${userSlug}/payment_methods`).then((data) => {
        dispatch(setPaymentMethods(data.data));
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchVippsAgreements = async () => {
    try {
      await request('/vipps/agreements').then((response) => {
        setVippsAgreements(response.data.agreement);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className={styles['membership-page']}>
      <div className={styles['membership-page_content-header']}>
        <span className={styles['membership-page_content-header_header']}>
          Betalingsmetoder
        </span>
        <div className={styles['membership-page_content-header_subheader']}>
          Se og administrer betalingsmåten din
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {paymentMethods &&
            Array.isArray(paymentMethods) &&
            paymentMethods.length == 0 && (
              <div
                className={styles['membership-page_content-header-no-result']}
              >
                Det er ingen betalingsmetoder lagt til ennå
              </div>
            )}
          {paymentMethods &&
            paymentMethods.map((method) => (
              <BillingMethod
                paymentMethod={method}
                amount={amount}
                key={method.id}
              />
            ))}
          <div className={styles['membership-page_content-header']}>
            <span className={styles['membership-page_content-header_header']}>
              Vipps Agreements
            </span>
            <div className={styles['membership-page_content-header_subheader']}>
              Se og administrer avtalene dine
            </div>
          </div>
          {vippsAgreements.length > 0 &&
            vippsAgreements.map((agreement, index) => {
              return (
                <VippsAgreements
                  agreement={agreement}
                  key={index}
                  vippsAgreements={vippsAgreements}
                  setVippsAgreements={setVippsAgreements}
                />
              );
            })}
        </React.Fragment>
      )}
    </div>
  );
};

export default PaymentMethods;
