import './App.css';
import AppRouter from './pages/Router';
import Cookies from './components/Cookies';
import actionCable from 'actioncable';
import React, { useEffect } from 'react';
import subscribe from "./actionCable/subscribe";
import { useSelector, useDispatch } from 'react-redux';
import {
  setCableConsumer
} from "./redux/actions";
import { CONSTANTS } from './service/constants';
import './global.css'
import isEmpty from 'lodash.isempty';

function App() {
  const user = useSelector((state) => state.user?.user)
  const isAuth = useSelector((state) => state.auth.isAuthorized)
  const consumerCable = useSelector(state => state.user.actionCableConsumer)
  
  const dispatch = useDispatch()
  const script = document.createElement("script");
  script.src="//js.hs-scripts.com/25892365.js";
  script.async = true;
  document.body.appendChild(script);

  const token = localStorage.getItem('token');
  // const encodedToken = encodeURIComponent(token);
  const url = `${CONSTANTS.actionCable}?token=${token}`;

  useEffect(() => {
  if (isEmpty(consumerCable)) {
      dispatch(setCableConsumer(actionCable.createConsumer(CONSTANTS.actionCable)))
    }
    subscribe(user)
  }, [user])

  useEffect(() => {
    if (!isAuth) {
      if (consumerCable.subscriptions) {
        consumerCable.subscriptions.subscriptions = []
      }

      dispatch(setCableConsumer({}))
    }
  }, [isAuth])

  return (
    <div className="App">
      <AppRouter />
      <Cookies />
    </div>
  );
}

export default App;