import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './BillingInformation.css';
import { setBillingInformation } from '../../../../redux/actions';
import CustomPhoneNumber from '../CustomPhoneNumber';
import AutocompleteAddress from '../AutoCompleteAddress/AutoCompleteAddress';
import isEmpty from 'lodash.isempty';

const BillingInformation = ({
  handleDisable,
  billingInformation,
  isCardValue,
  countries,
}) => {
  const dispatch = useDispatch();
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const selectedPlan = useSelector((state) => state.selectedPlan || null);
  const currentSubscriptionPlan = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionPlan || null
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  useEffect(() => {
    const areAllValuesFilled = Object.values(billingInformation).every(
      (value) => value !== ''
    );
    if (
      areAllValuesFilled &&
      !isValidEmail &&
      !isValid &&
      isCardValue &&
      !isEmpty(selectedPlan) &&
      !isEmpty(currentSubscriptionPlan)
    ) {
      const newSubscription = selectedPlan.key !== 'free';
      if (newSubscription) {
        handleDisable(false);
      }
    } else if (
      areAllValuesFilled &&
      !isValidEmail &&
      !isValid &&
      isCardValue &&
      !isEmpty(selectedPlan)
    ) {
      handleDisable(false);
    } else if (selectedPlan.key !== 'free') {
      handleDisable(true);
    }
  }, [
    billingInformation,
    isValidEmail,
    isValid,
    isCardValue,
    currentSubscriptionPlan,
    selectedPlan,
  ]);

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const onNameChange = ({ target }) => {
    const inputValue = target.value;
    const trimmedValue = inputValue.replace(/^\s+/, '').replace(/ {2,}$/, ' ');
    dispatch(
      setBillingInformation({
        ...billingInformation,
        fullName: trimmedValue,
      })
    );
  };

  const onPostCodeChange = (event) => {
    const code = event.target.value.replace(/[^\d()\-_]/g, '');
    dispatch(
      setBillingInformation({
        ...billingInformation,
        postalCode: code,
      })
    );
  };

  const onNumberChange = (value) => {
    dispatch(
      setBillingInformation({
        ...billingInformation,
        phoneNumber: value,
      })
    );
  };

  const onBillingAddressChange = (value) => {
    dispatch(
      setBillingInformation({
        ...billingInformation,
        billingAddress: value,
      })
    );
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 47)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <div className='billing-info'>
      <div className='billing-info-title'>
        <span>Faktureringsinformasjon</span>
      </div>
      <div className='billing-details'>
        <span>Fullt navn</span>
        <input
          type='text'
          required
          placeholder='Fullt navn *'
          value={billingInformation.fullName}
          onKeyPress={onKeyPress}
          onChange={onNameChange}
        />
      </div>
      <div className='billing-details'>
        <span>Fakturaadresse</span>
        <AutocompleteAddress
          onBillingAddressChange={onBillingAddressChange}
          billingAddress={billingInformation.billingAddress}
        />
      </div>
      <div className='billing-container'>
        <div className='billing-details-container'>
          <span>Land</span>
          <span>
            <select
              className='country-list'
              value={billingInformation.country_id}
              onChange={(e) =>
                dispatch(
                  setBillingInformation({
                    ...billingInformation,
                    country_id: parseInt(e.target.value),
                  })
                )
              }
            >
              {countries.map((country) => {
                return (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className='billing-details-container-email'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className='email-heading'>Epostadresse</span>
            {isValidEmail && (
              <span style={{ color: 'red' }}>{isValidEmail}</span>
            )}
          </div>
          <input
            className={isValidEmail ? 'error-email' : 'email'}
            type='email'
            placeholder='Epostadresse *'
            value={billingInformation.email}
            onChange={(event) => {
              if (validateEmail(event.target.value)) {
                setIsValidEmail(null);
              } else {
                setIsValidEmail('Invalid email');
              }
              dispatch(
                setBillingInformation({
                  ...billingInformation,
                  email: event.target.value,
                })
              );
            }}
          />
        </div>
      </div>
      <div className='billing-container'>
        <div className='billing-details-container'>
          <span>Postkode</span>
          <input
            type='text'
            placeholder='Postkode *'
            value={billingInformation.postalCode}
            onChange={onPostCodeChange}
          />
        </div>
        <div className='billing-details-container-phoneNumber'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className='phoneNumber-heading'>Telefonnummer</span>
            {isValid && <span style={{ color: 'red' }}>{isValid}</span>}
          </div>
          <CustomPhoneNumber
            setIsValid={setIsValid}
            isValid={isValid}
            userNumber={billingInformation.phoneNumber}
            onNumberChange={onNumberChange}
          />
        </div>
      </div>
      <div className='user-message'>
        <span>
          Denne informasjonen brukes til å utstede den første fakturaen. Du kan
          oppdatere detaljene for fremtidige fakturaer fra kontoprofilen din.
        </span>
      </div>
    </div>
  );
};

export default BillingInformation;
