import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './SearchGraves.css';
import Filters from './atoms/Filters';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import NoneGravesImage from './../../img/NoneGravesImage.svg';
import AddGraveImage from './../../img/AddGraveImage.svg';
import axios from 'axios';
import Grave from './atoms/Grave';
import {
  setGraveArrayRedux,
  spinnerStart,
  spinnerFinish,
  setAvailableSearchCount,
} from '../../redux/actions';
import { Link, useHistory } from 'react-router-dom';
import { CONSTANTS } from '../../service/constants';
import spinner from '../../service/Loading_animation.gif';
import UpgradePlanModal from '../../components/Modals/UpgradePlanModal';

const SearchGraves = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSearchInputClick = useSelector(
    (state) => state.search.isSearchInputClick
  );
  const graveArr = useSelector((state) => state.search.graveArray);
  const isSpinnerLoading = useSelector((state) => state.spinnerLoad.isLoading);
  const [isEmpty, setIsEmpty] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [chosenCountry, setChosenCountry] = useState(null);
  const [chosenMunicipality, setChosenMunicipality] = useState(null);
  const [chosenCemetery, setChosenCemetery] = useState(null);
  const [searchLimitExceeded, setSearchLimitExceeded] = useState(false);

  const axiosData = (page) => {
    let params = { page: page };
    props.searchInput.length > 2 &&
      Object.assign(params, { 'search[query]': props.searchInput });
    props.isFilterOpen &&
      chosenCountry &&
      Object.assign(params, { 'search[filter][country_id]': chosenCountry.id });
    props.isFilterOpen &&
      chosenMunicipality &&
      Object.assign(params, {
        'search[filter][municipality_id]': chosenMunicipality.id,
      });
    props.isFilterOpen &&
      chosenCemetery &&
      Object.assign(params, {
        'search[filter][graveyard_id]': chosenCemetery.id,
      });

    return axios({
      url: `${CONSTANTS.baseUrl}/api/graves`,
      method: 'get',
      params,
      headers: localStorage.getItem('token')
        ? {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
          }
        : {
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
    });
  };

  const handleCloseUpgradePlansModal = () => {
    history.push('/');
  };

  //-------------PAGINATION----------------------
  useEffect(() => {
    if (currentPage !== 1) {
      graveArr.length !== 0 &&
        axiosData(currentPage)
          .then((response) => {
            currentPage === 1 &&
              dispatch(setGraveArrayRedux(response.data.graves));
            if (response.data.graves.length === 0) {
              setHasMore(false);
              setCurrentPage(1);
            } else {
              currentPage !== 1 &&
                dispatch(
                  setGraveArrayRedux([...graveArr, ...response.data.graves])
                );
              setHasMore(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }, [currentPage]);

  //-------------SEARCH_WITH_PARAMETERS----------
  useEffect(() => {
    let cancel = false;
    setHasMore(true);
    const runEffect = async () => {
      currentPage !== 1 && setCurrentPage(1);
      isEmpty && setIsEmpty(false);

      dispatch(spinnerStart());
      try {
        const response = await axiosData(1);
        if (cancel) {
          return;
        }
        dispatch(setAvailableSearchCount(response.data.available_search_count));
        if (response.data.max_limit_exceeded) {
          dispatch(spinnerFinish());
          setSearchLimitExceeded(true);
          return;
        }
        response && dispatch(spinnerFinish());
        if (currentPage === 1) {
          dispatch(setGraveArrayRedux(response.data.graves));
          if (response.data.graves.length === 0) {
            setHasMore(false);
            setIsEmpty(true);
          }
        } else
          dispatch(setGraveArrayRedux([...graveArr, ...response.data.graves]));
      } catch (err) {
        console.log(err);
      }
    };

    !cancel && runEffect();

    return () => {
      cancel = true;
    };
  }, [
    props.searchInput,
    chosenMunicipality,
    chosenCemetery,
    props.isFilterOpen,
    isSearchInputClick,
  ]);

  return (
    <div className='search-graves'>
      {searchLimitExceeded ? (
        <>
          <UpgradePlanModal
            isOpen={searchLimitExceeded}
            handleCloseUpgradePlansModal={handleCloseUpgradePlansModal}
            body='Dykk dypere inn i Nettgrav med vår Familieplan. Oppgrader i dag for ubegrenset søk.'
            title='Nådde dagens grense!'
          />
        </>
      ) : (
        <>
          {props.isFilterOpen && (
            <Filters
              setChosenCountry={setChosenCountry}
              chosenCountry={chosenCountry}
              setChosenMunicipality={setChosenMunicipality}
              chosenMunicipality={chosenMunicipality}
              setChosenCemetery={setChosenCemetery}
              chosenCemetery={chosenCemetery}
            />
          )}

          {isSpinnerLoading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={spinner} atl='loading spinner' />
            </div>
          )}

          {!isSpinnerLoading && graveArr && (
            <InfiniteScroll
              dataLength={graveArr.length}
              next={() => setCurrentPage(currentPage + 1)}
              hasMore={hasMore}
              loader={
                <div className='loader'>
                  <LoopOutlinedIcon /> Laster{' '}
                </div>
              }
            >
              <div className='search-graves_grave'>
                {graveArr.map((grave) => (
                  <Grave grave={grave} key={grave.key} />
                ))}
              </div>
            </InfiniteScroll>
          )}

          {isEmpty && (
            <div className='search-graves_none'>
              <img
                loading='lazy'
                src={NoneGravesImage}
                alt='no graves'
                className='search-graves_none-image'
              />
              <span className='search-graves_none-text'>
                Fant ingen resultater for <span>"{props.searchInput}"</span>.
              </span>
              <span className='search-graves_none-subtext'>
                Hvis du ikke finner gravlagte kan du opprette en ny Nettgrav?
              </span>
              <Link to='/ny-grav' className='search-graves_none-button'>
                <img loading='lazy' src={AddGraveImage} alt='Opprett ny grav' />
                <span>Opprett ny grav</span>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchGraves;
