import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import animClasses from './Router.css';
import { SearchPage, AddNewGrave, NotFoundPage, AuthRouter } from './index';
import Layout from './Layout';

import Graves from '../containers/Graves/Graves';

import PlansRouter from './PlansPage/Router';
// import AdminRouter from './Admin/AdminRouter';

import HomePage from './LandingPage/homepage/HomePage';
import About from './LandingPage/atoms/About';
import HomepageGrave from './LandingPage/atoms/HomepageGrave';
import HomepageInvestigate from './LandingPage/atoms/HomepageInvestigate';
import Features from './LandingPage/atoms/Features';
import HomepageScientists from './LandingPage/atoms/HomepageScientists';
import LearnMore from './LandingPage/atoms/LearnMore';
import HomepageFindGrave from './LandingPage/atoms/HomepageFindGrave';
import TermsConditions from './LandingPage/terms_conditions/Terms_Conditions';
import SettingsPage from './Settings/Settings';
import NewSettings from '../containers/NewSettings/SettingsPage';
import UserProfile from './UserProfile/UserProfile';
import HowToFindGrave from './LandingPage/atoms/HowToFindGrave';
import HowToCreateGrave from './LandingPage/atoms/HowToCreateGrave';
import NewDeceasedPage from './NewDeceasedPage/NewDeceasedPage';
import Plans from './Wix/Plans';
import VippsPageAgreementPage from './VippsAgreementPage/VippsPageAgreementPage';
import GraveClaimPage from '../containers/NewDeceased/atoms/GraveClaimPage/GraveClaimPage';
import PostDetails from '../containers/NewDeceased/atoms/MemoriesPostDetails/PostDetails';
import HowToTakeGraveOwnership from './LandingPage/atoms/HowToTakeGraveOwnerShip';
import FeaturesMemoryAlbum from './LandingPage/atoms/FeaturesMemoryAlbum';
import FeaturesArchive from './LandingPage/atoms/FeaturesArchive';
import FeaturesCommemorative from './LandingPage/atoms/FeaturesCommemorative';
import FeaturesLifeLine from './LandingPage/atoms/FeaturesLifeLine';
import TheEndOfTime from './LandingPage/atoms/TheEndOfTime';
import FreePlan from './LandingPage/atoms/FreePlan';
import ConsciousPlan from './LandingPage/atoms/ConsciousPlan';
import ConsciousPlus from './LandingPage/atoms/ConsciousPlus';
import AboutProduct from './LandingPage/atoms/AboutProduct';

const AppRouter = (props) => {
  const [locationApp, setLocationApp] = React.useState('');

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationApp]);

  return (
    <Router>
      <Route>
        {({ location, history }) => {
          const url = location.pathname.match(/([^*/]+)/g);
          let controller = null;

          setLocationApp(location.pathname);

          if (url) {
            [controller] = url;
          }
          return (
            <Layout history={history}>
              <TransitionGroup>
                <CSSTransition
                  key={controller}
                  timeout={200}
                  appear
                  unmountOnExit
                  classNames={{ ...animClasses }}
                >
                  <Switch>
                    <Route
                      exact
                      path='/'
                      location={props.location}
                      component={HomePage}
                    />
                    <Route
                      path='/about'
                      location={props.location}
                      component={About}
                    />
                    <Route
                      path='/about-product'
                      location={props.location}
                      component={AboutProduct}
                    />
                    <Route
                      path='/graven'
                      location={props.location}
                      component={HomepageGrave}
                    />
                    <Route
                      path='/invest'
                      location={props.location}
                      component={HomepageInvestigate}
                    />
                    <Route
                      path='/funksjoner'
                      location={props.location}
                      component={Features}
                    />
                    <Route
                      path='/find'
                      location={props.location}
                      component={HowToFindGrave}
                    />
                    <Route
                      path='/create'
                      location={props.location}
                      component={HowToCreateGrave}
                    />
                    <Route
                      path='/ownership'
                      location={props.location}
                      component={HowToTakeGraveOwnership}
                    />
                    <Route
                      path='/funksjoner'
                      location={props.location}
                      component={Features}
                    />
                    <Route
                      path='/scientists'
                      location={props.location}
                      component={HomepageScientists}
                    />
                    <Route
                      exact
                      path='/finn-en-grav'
                      location={props.location}
                      component={HomepageFindGrave}
                    />
                    <Route
                      exact
                      path='/finn-en-grav/:municipality'
                      location={props.location}
                      component={HomepageFindGrave}
                    />
                    <Route
                      exact
                      path='/finn-en-grav/:municipality/:cemetery'
                      location={props.location}
                      component={HomepageFindGrave}
                    />
                    <Redirect
                      from='/finn-en-grav/:municipality/:cemetery/:slug'
                      to='/deceased/:slug'
                    />
                    <Route
                      path='/graveier'
                      location={props.location}
                      component={LearnMore}
                    />
                    <Route
                      path='/terms_conditions'
                      location={props.location}
                      component={TermsConditions}
                    />
                    <Route
                      path='/abonnement'
                      location={props.location}
                      component={Plans}
                    />
                    <Route path='/auth' component={AuthRouter} />
                    <Route path='/registration' component={AuthRouter} />
                    <Route path='/search' component={SearchPage} />
                    <Redirect from='/deceased' exact to={'/'} />
                    <Route
                      exact
                      path='/deceased/:grave_slug/memory/:memory_id'
                      component={PostDetails}
                    />
                    <Route path='/deceased/:slug' component={NewDeceasedPage} />
                    <Route
                      exact
                      path='/grave/:slug/claim'
                      component={GraveClaimPage}
                    />
                    <Route path='/ny-grav' component={AddNewGrave} />
                    <Route path='/old-settings' component={SettingsPage} />
                    <Route path='/settings' component={NewSettings} />
                    <Redirect from='/member' exact to={'/'} />
                    <Route
                      exact
                      path='/member/:slug'
                      location={props.location}
                      component={UserProfile}
                    />
                    <Route path='/graves' component={Graves} />
                    <Route
                      path='/vipps-payment-confirmation'
                      component={VippsPageAgreementPage}
                    />
                    <Route
                      path='/features-minnealbum'
                      location={props.location}
                      component={FeaturesMemoryAlbum}
                    />
                    <Route
                      path='/features-verdiarkiv'
                      location={props.location}
                      component={FeaturesArchive}
                    />
                    <Route
                      path='/features-minnetavlen'
                      location={props.location}
                      component={FeaturesCommemorative}
                    />
                    <Route
                      path='/features-livslinjen'
                      location={props.location}
                      component={FeaturesLifeLine}
                    />
                    <Route
                      path='/the-end-of-time-center'
                      location={props.location}
                      component={TheEndOfTime}
                    />
                    <Route
                      path='/freeplan'
                      location={props.location}
                      component={FreePlan}
                    />
                    <Route
                      path='/consciousplan'
                      location={props.location}
                      component={ConsciousPlan}
                    />
                    <Route
                      path='/consciousplus'
                      location={props.location}
                      component={ConsciousPlus}
                    />
                    {/* ============================================/ For the next VERSION 2 of NET-GRAVE product =================(not supported)=============================*/}
                    <Route path='/plans' component={PlansRouter} />
                    {/* <Route path='/admin' component={AdminRouter}/> */}
                    {/* =======================================================================================================================================================*/}
                    {/* Hardcoded the site map for now */}
                    <Route
                      path='/sitemap'
                      component={() => {
                        window.location.replace(
                          'https://nettgrav-sitemaps.s3.eu-central-1.amazonaws.com/sitemap.xml'
                        );
                        return null;
                      }}
                    />
                    {/* Proof of Concept */}
                    <Route
                      path='/sitemap1'
                      component={() => {
                        window.location.replace(
                          ' https://nettgrav-sitemaps.s3.eu-central-1.amazonaws.com/sitemaps/sitemap1.xml'
                        );
                        return null;
                      }}
                    />
                    <Route component={NotFoundPage} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </Layout>
          );
        }}
      </Route>
    </Router>
  );
};

export default AppRouter;
