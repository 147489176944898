import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './FirstStep.css';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { useLocation, useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { request } from '../../../service/request';
import PopupModal from '../../Payment/atoms/Modal/Modal';
import isEmpty from 'lodash.isempty';
import UploadDocumentFile from './UploadDocument/UploadDocument';
import TermsAndCondition from './TermsAndCondition/TermsAndCondition';
import SelectDocument from '../../NewDeceased/atoms/GraveClaimPage/SelectDocument/SelectDocument';

const useStyle = makeStyles(() => ({
  clear: {
    display: 'none',
  },
  input: {
    padding: '0px',
  },
}));

const FirstStep = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const [name, setName] = useState();
  const [gender, setGender] = useState('');
  const [fileType, setFileType] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [relation, setRelation] = useState({});
  const [reciprocalRelation, setReciprocalRelation] = useState({});
  const [directionsList, setDirectionsList] = useState([]);
  const plan = useSelector((state) => state.selectedPlan || null);
  const [isChecked, setIsChecked] = useState(false);
  const [user, setUser] = useState({});
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(async () => {
    try {
      const res = await request(`/users/${userSlug}`);
      setUser(res.data.user);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const show = location.state?.show;

    if (show) {
      openModal();
      history.replace({ show: undefined });
    }
  }, [location]);

  useEffect(() => {
    if (props.firstStepData) {
      setName(props.firstStepData.name);
      setGender(props.firstStepData.gender);
      setRelation(props.firstStepData.relation);
      setReciprocalRelation(props.firstStepData.reciprocalRelation);
      setCertificate(props.firstStepData.certificate);
      setIsChecked(props.firstStepData.checked);
      setFileType(props.firstStepData.fileType);
    }
  }, []);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setRelation({});
    } else if (reason === 'select-option') {
      setRelation(value);
    }
    setReciprocalRelation({});
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setReciprocalRelation({});
    } else if (reason === 'select-option') {
      setReciprocalRelation(value);
    }
  };

  const onNameChange = ({ target }) => {
    const inputValue = target.value;
    const trimmedValue = inputValue.replace(/^\s+/, '').replace(/ {2,}$/, ' ');
    setName(trimmedValue);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onGenderChange = (value) => setGender(value);
  const onFileNameChange = (value) => setFileType(value);

  const onCertificateChange = (file) => {
    setCertificate(file);
  };

  const onNextClick = () => {
    if (name && gender && relation.id && reciprocalRelation.id) {
      const data = {
        name: name,
        gender: gender,
        relation: relation,
        reciprocalRelation: reciprocalRelation,
        certificate: certificate,
        checked: isChecked,
        fileType: fileType,
      };
      props.onNextClick(data);
    } else {
      showUserNotification('Alle felt som er markert med må fylles ut');
    }
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      // allow spaces
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      // prevent numbers
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 47)
    ) {
      // prevent special characters
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <div className='first-step'>
      <div className='input-element-list'>
        <div className='title'>Navn på person</div>
        <input
          className='inputs'
          required
          type='text'
          placeholder='Navn på den døde/gravlagte'
          value={name}
          onKeyPress={onKeyPress}
          onChange={onNameChange}
        />
      </div>

      <div className='gender-container'>
        <div className='title'>Avdødes kjønn</div>
        <div className='gender-wrapper'>
          <div
            className={
              gender === 'mann' ? 'select-gender-active' : 'select-gender'
            }
            onClick={() => onGenderChange('mann')}
          >
            <input type='checkbox' checked={gender === 'mann'} />
            <label>Mann</label>
          </div>
          <div
            className={
              gender === 'kvinne' ? 'select-gender-active' : 'select-gender'
            }
            onClick={() => onGenderChange('kvinne')}
          >
            <input type='checkbox' checked={gender === 'kvinne'} />
            <label>Kvinne</label>
          </div>
          <div
            className={
              gender === 'annen' ? 'select-gender-active' : 'select-gender'
            }
            onClick={() => onGenderChange('annen')}
          >
            <input type='checkbox' checked={gender === 'annen'} />
            <label>Annen</label>
          </div>
        </div>
      </div>

      <div className='input-element-list'>
        <div className='title'>Jeg er:</div>
        <div className='choose-relation-selection'>
          <Autocomplete
            id='relation-combo-box'
            classes={{ input: classes.input }}
            options={props.relationList}
            getOptionLabel={(option) => option.name || ''}
            style={{ width: '99%' }}
            renderInput={(params) => (
              <TextField placeholder='Velg relasjon' {...params} />
            )}
            onChange={handleOnSelectRelation}
            value={relation}
          />
        </div>
      </div>

      <div className='input-element-list'>
        <div className='title'>Gravlagte er min:</div>
        <div className='choose-relation-selection'>
          <Autocomplete
            id='relation-combo-box'
            classes={{ input: classes.input }}
            options={filterDirectionList(relation)}
            getOptionLabel={(option) => option.name || ''}
            style={{ width: '99%' }}
            renderInput={(params) => (
              <TextField placeholder='Velg relasjon' {...params} />
            )}
            value={reciprocalRelation}
            onChange={handleOnSelectReciprocalRelation}
          />
        </div>
      </div>
      <SelectDocument
        selectedDocument={fileType}
        handelSelectedDocument={onFileNameChange}
      />
      {fileType && (
        <React.Fragment>
          <UploadDocumentFile
            handelSetDocumentFile={(value) => onCertificateChange(value)}
            selectedFile={certificate}
          />
          <TermsAndCondition
            user={user}
            isChecked={isChecked}
            isCheckedTerms={(value) => setIsChecked(value)}
          />
        </React.Fragment>
      )}
      {/* <input
        className='first-step-file'
        id='contained-button-file'
        type='file'
        accept='.doc, .pdf, .docx, .odt, .xls, .xlsx, .ods, .txt, .jpg, .png, .jpeg'
        onChange={onCertificateChange}
      />
      <label htmlFor='contained-button-file' className='first-step-file-lable'>
        <div className='first-step-file-input'>
          {certificate ? (
            <span>{certificate.name}</span>
          ) : (
            <span>
              Dødsattest <span>*</span>
            </span>
          )}
          <img loading='lazy' src={FileImg} alt='FileImg' />
        </div>
      </label> */}
      <div className='next-button-container'>
        <button className='first-step-button' onClick={() => onNextClick()}>
          Neste
        </button>
      </div>
      <PopupModal open={modalOpen} handleClose={closeModal} plan={plan} />
    </div>
  );
};

export default FirstStep;
