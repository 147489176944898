import React from 'react';
import styles from './BasicInformation.module.css';

const BasicInformation = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  return (
    <>
      {item.length > 0 ? (
        <div className={styles['about-item-content_row-container']}>
          {item.map((item, index) => {
            return (
              <div className={styles['about-item-content_row']} key={index}>
                {item.icon && (
                  <div className={styles['icon']}>
                    <img src={item.icon} alt='' />
                  </div>
                )}
                <div className={styles['about-item-value-container']}>
                  <span className={styles['about-item-key']}>{item.key}: </span>
                  <span className={styles['about-item-value']}>
                    {item.value}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles['empty-grave-info']}>
          <span className={styles['empty-message']}>{emptyMessage}</span>
          {canEdit && (
            <button className={styles['add-btn']} onClick={onEdit}>
              Add {title}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default BasicInformation;
