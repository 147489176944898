import React, { useState, useEffect } from 'react';
import './HomepageFindGraveFirst.css';
import MainImage from '../../../../img/searchpage1.png';
import { Button } from '@material-ui/core';
import { useHistory, useParams, Link } from 'react-router-dom';
import { fetch_request } from '../../../../service/request';

const HomepageFindGraveFirst = (props) => {
    const [searchVal, setSearchVal] = useState("");
    const history = useHistory();
    let { municipality, cemetery } = useParams()
    const [municipalityName, setMunicipalityName] = useState('')
    const [cemeteryName, setCemeteryName] = useState('')

    // Move this to redux
    useEffect(() => {
      if (municipality) {
        fetch_request(`/api/municipalities/${municipality}`)
          .then((response) => response.json())
          .then((data) => {
            setMunicipalityName(data.name);
          })
          .catch((err) => console.log(err));
      }
    }, []);

    // Move this to redux
    useEffect(() => {
      if (cemetery) {
        fetch_request(`/api/graveyards/${cemetery}`)
          .then((response) => response.json())
          .then((data) => {
            setCemeteryName(data.name);
          })
          .catch((err) => console.log(err));
      }
    }, [cemetery]);

    if (municipality && !cemetery) {
      return(
        <div className="header-submain_wrapper">
          <span className="header-submain">
            {<Link to="/finn-en-grav">Finn en grav</Link>} → {municipalityName}
          </span>
        </div>
      );
    }

    if (municipality && cemetery) {
      return(
        <div className="header-submain_wrapper">
          <span className="header-submain">
            {<Link to="/finn-en-grav">Finn en grav</Link>} → {<Link to={`/finn-en-grav/${municipality}`}>{municipalityName}</Link>} → {cemeteryName}
          </span>
        </div>
      );
    }

    return (
        <div className='homepage-findgrave_first'>
            <div className="homepage-findgrave_first-content">
                <div className="homepage-findgrave_first-content_header">
                    <span className='homepage-findgrave_first-content_header-main'>Finn en grav i Norge</span>
                    <span className='homepage-findgrave_first-content_header-submain'>Søk på navn &#8594; Finn en grav</span>
                    <div className="homepage-findgrave_first-content_header-input">
                        <input
                            type='text'
                            value={searchVal}
                            onChange={(e) => setSearchVal(e.target.value)}
                            placeholder='Søk på navn'
                        />
                        <Button
                            className='homepage-findgrave_first-content_header-input_button'
                            onClick={() => history.push(`/search?find-grave=${searchVal}`)}>
                            Finn grav
                        </Button>
                    </div>
                    <span className='homepage-findgrave_first-content_header-submain added_here'>eller <Link to = '/ny-grav'>opprett Nettgrav</Link></span>
                </div>
                <img loading='lazy' src={MainImage} alt='MainImage' className='homepage-findgrave_first-content_bg' />
            </div>
        </div>
    )
}

export default HomepageFindGraveFirst;