import React from 'react';
import styles from '../Subscriptions.module.css';

const PaidSubscription = ({ currentSubscription }) => {
  return (
    <div className={styles['paid-subscription-header']}>
      <div>
        <div className={styles['paid-subscription-name']}>
          {currentSubscription.plan_name} abonnement
        </div>
        <div className={styles['paid-subscription-detail']}>
          For et meningsfullt liv
        </div>
      </div>
      <div>
        <div className={styles['price']}>
          <span>NOK {currentSubscription.subscription_price}</span>
          /mnd
        </div>
        {currentSubscription.user_count && (
          <div className={styles['paid-subscription-detail']}>
            til {currentSubscription.user_count} medlemmer
          </div>
        )}
      </div>
    </div>
  );
};

export default PaidSubscription;
