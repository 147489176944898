import React from 'react';
import styles from './GraveNote.module.css';
import EditSmallIcon from '../../.././../img/EditSmallIconWhite.svg';

const GraveNote = ({ notes, onEdit, canEdit }) => {
  return (
    <div className={styles['notes-container']}>
      <div className={styles['heading-wrapper']}>
        <div className={styles['heading']}>Merknad fra familie</div>
        {canEdit && (
          <div className={styles['action-btn']} onClick={onEdit}>
            <img src={EditSmallIcon} alt='Edit' />
            <button>Redigere</button>
          </div>
        )}
      </div>
      <div className={styles['notes']}>{notes}</div>
    </div>
  );
};

export default GraveNote;
