import React, { useState, useEffect } from 'react';
import styles from './LifeEventPage.module.css';
import LifeEventHeader from './LifeEventHeader/LifeEventHeader';
import LifeEvents from './LifeEvents/LifeEvents';
import LifeEventDetailPage from '../LifeEventDetailPage/LifeEventDetailPage';
import { useSelector } from 'react-redux';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import axios from 'axios';
import { CONSTANTS } from '../../../../service/constants';

const LifeEventPage = () => {
  const showDetails = useSelector((state) => state.grave.openSelectedEvent);
  const year = useSelector((state) => state.grave.selectedEventYear);
  const event = useSelector((state) => state.grave.selectedEvent);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [currentSubscription, setCurrentSubscription] = useState();

  const handelSaveModal = (value) => {
    setOpenSaveModal(value);
  };

  useEffect(() => {
    if (!isAuth) return;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        setCurrentSubscription(res.data);
      })
      .catch((err) => console.log(err));
  }, [userSlug]);

  return (
    <div className={styles['life-container']}>
      {showDetails ? (
        <LifeEventDetailPage year={year} event={event} />
      ) : (
        <React.Fragment>
          <LifeEventHeader
            handelSaveModal={handelSaveModal}
            currentSubscription={currentSubscription}
          />
          <LifeEvents handelSaveModal={handelSaveModal} />
        </React.Fragment>
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          title='Livshendelse lagt til'
          handleClose={() => setOpenSaveModal(false)}
        />
      )}
    </div>
  );
};

export default LifeEventPage;
