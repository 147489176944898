import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './DeleteInvitationModal.module.css';
import SubscriptionMember from './SubscriptionMember';
import X from '../../../img/X.svg';
import DeleteSubscriptionMembers from './DeleteSubscriptionMembers';
import isEmpty from 'lodash.isempty';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const DeleteInvitationsModal = ({
  onOpen,
  onClose,
  currentSubscriptionData,
  updateSubscription,
}) => {
  const classes = useStyles();
  const selectedPlan = useSelector((state) => state.selectedPlan);

  return (
    <Modal
      open={onOpen}
      // onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['modal-header']}>
          <div className={styles['plan-details-header']}>
            {currentSubscriptionData.plan_price > selectedPlan.price ? (
              <React.Fragment>
                <span className={styles['plan-details-header_product_name']}>
                  Nedgrader plan
                </span>
                <span className={styles['plan-details-header_product_desc']}>
                  Deaktiver alle abonnementsmedlemmer og invitasjoner for å fortsette
                  nedgradering av abonnementet ditt
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className={styles['plan-details-header_product_name']}>
                  Oppgrader plan
                </span>
                <span className={styles['plan-details-header_product_desc']}>
                  Fjern alle abonnementsmedlemmer og invitasjoner til å
                  oppgradere planen
                </span>
              </React.Fragment>
            )}
          </div>
          <img loading='lazy' src={X} alt='x' onClick={() => onClose()} />
        </div>
        <DeleteSubscriptionMembers
          members={currentSubscriptionData.subscription_members}
          invitations={currentSubscriptionData.subscription_invitations}
          currentSubscriptionData={currentSubscriptionData}
          updateSubscription={updateSubscription}
        />
        {isEmpty(currentSubscriptionData.subscription_members) &&
          isEmpty(currentSubscriptionData.subscription_invitations) && (
            <div className={styles['empty-message']}>
              Du kan nå lukke og fortsette videre
            </div>
          )}
        <div className={styles['btn-container']}>
          <button onClick={() => onClose()}>Lukk</button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteInvitationsModal;
