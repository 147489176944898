import React from 'react';
import AboutMenuItem from './components/AboutMenuItem';
import './AboutMenuBar.css';

const AboutMenuBar = (props) => {
  const menuItems = [
    {
      id: 1,
      title: 'Gravinformasjon',
    },
    {
      id: 2,
      title: 'Relasjoner',
    },
    {
      id: 3,
      title: 'Arbeidsplass',
    },
    {
      id: 4,
      title: 'Interesser',
    },
    {
      id: 5,
      title: 'Lidenskap',
    },
    {
      id: 6,
      title: 'Gravforbindelser',
    },
  ];
  return (
    <div className='about-menu-container'>
      {menuItems.map((menuItem, index) => (
        <AboutMenuItem
          menuItem={menuItem}
          key={index}
          handelAboutMenuKey={props.handelAboutMenuKey}
          aboutMenuKey={props.aboutMenuKey}
        />
      ))}
    </div>
  );
};

export default AboutMenuBar;
