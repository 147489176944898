import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const GraveTimePicker = ({ time, setDatePickerValue }) => {
  const customDatePicker = {
    '& .MuiInputBase-root': {
      border: 'none !important',
      background: '#F2F5F8',
      padding: '8px 28px',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: '18px',
    },
  };

  const [value, setValue] = useState('');
  useEffect(() => {
    if (time) {
      setValue(dayjs(time, 'HH:mm'));
    }
  }, [time]);

  const handleDateChange = (newValue) => {
    setValue(newValue);
    if (newValue) {
      setDatePickerValue(newValue);
    } else {
      setDatePickerValue('');
    }
  };

  const clearTime = (e) => {
    e.preventDefault();
    setValue('');
    setDatePickerValue('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={value}
        onChange={handleDateChange}
        ampm={false}
        format='HH:mm'
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            sx: customDatePicker,
          },
        }}
      />
      <Button onClick={clearTime} color='#737a7f' type='text'>
        Nullstill tid
      </Button>
    </LocalizationProvider>
  );
};

export default GraveTimePicker;
