import "./QuestionBox.css";
import CommentRow from "./CommentRow";
import React, { useState } from "react";
import UserInfoHeader from "./UserInfoHeader";
import { request } from "../../../service/request";
import showUserNotification from "../../../components/UserNotification/showUserNotification";
import ReactPlayer from "react-player";
import MusicPlayer from "./MusicPlayer";
import QuestionRow from "./QuestionRow";
import { editQuestion, deleteQuestion } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import CommentBox from "./CommentBox";

const QuestionBox = (props) => {
  const question = props.question;
  const author = {
    slug: question.author_slug,
    full_name: question.author_name,
    avatar: question.author_avatar,
  };

  const fileType = question.content && question.content.file_type;
  const content = question.content || [];
  const comments = content && content.comments;
  const [isCommentListExpanded, setIsCommentListExpanded] = useState(false);
  const initialText = question.content ? question.content.description : "";
  const dispatch = useDispatch();

  const allowEdit = () => {
    return question.author_slug === props.loggedInUser.slug;
  };

  const handleDeleteSelection = async () => {
    const response = await request(
      `/user_wall_posts/${question.id}`,
      null,
      "delete"
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(deleteQuestion({ questionId: question.id }));
      props.setStartRefresh(true)
      showUserNotification("Spørsmålet er slettet", "success");
    } else {
      showUserNotification("Unable to delete the question", "error");
    }
  };

  const saveEditedQuestion = async (upDatedquestion) => {
    const formdata = new FormData();
    formdata.append("question", upDatedquestion);
    formdata.append("slug", props.user.slug);
    const response = await request(
      `/user_wall_posts/${question.id}`,
      formdata,
      "put"
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(
        editQuestion({
          questionId: question.id,
          upDatedquestion: upDatedquestion,
        })
      );
      showUserNotification("Spørsmålet er oppdatert", "success");
    } else {
      showUserNotification("Unable to update the question", "error");
    }
  };

  return (
    <div className="question-box-container">
      <UserInfoHeader author={author} allowEdit={allowEdit()} showPrivacy={false} />
      {fileType && fileType === "image" && (
        <div className="image-post-wrapper">
          <img src={question.content.file} alt="" className="image-post" />
        </div>
      )}
      {fileType && fileType === "video" && question.content.external_url && (
        <div className="video-player-wrapper">
          <ReactPlayer
            url={question.content.external_url}
            width="100%"
            height="100%"
            style={{
              margin: "auto",
            }}
            light={true}
          />
        </div>
      )}
      {fileType && fileType === "audio" && (
        <MusicPlayer file={question.content.file} />
      )}

      <QuestionRow
        questionText={initialText}
        allowEdit={allowEdit}
        saveEditedQuestion={saveEditedQuestion}
        handleDeleteSelection={handleDeleteSelection}
        key={Math.random()}
      />

      {isCommentListExpanded &&
        comments &&
        comments.map((item, index) => {
          return (
            <CommentRow
              question={question}
              allowEdit={allowEdit()}
              key={index}
              loggedInUser={props.loggedInUser}
              comment={item}
            />
          );
        })}

      {isCommentListExpanded && (
        <CommentBox
          user={props.user}
          loggedInUser={props.loggedInUser}
          question={question}
        />
      )}
      {!isCommentListExpanded && (
        <div
          className="comments-count-collapsed"
          onClick={() => setIsCommentListExpanded(true)}
        >
          {`${comments.length} Svar`}
        </div>
      )}
      {isCommentListExpanded && (
        <div
          className="comments-count-expanded"
          onClick={() => setIsCommentListExpanded(false)}
        >
          Lukk
        </div>
      )}
    </div>
  );
};

export default QuestionBox;
