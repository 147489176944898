import React, { useState } from 'react';

const SearchBar = (props) => {

  const [searchVal, setSearchVal] = useState('')
  const handleSearchValueChange = (value) => {
    setSearchVal(value)
    props.setSearchInputValue(value)
  }

  return(
    <div className="searchbar-input">
      <input
          type='text'
          value={searchVal}
          onChange={(e) => handleSearchValueChange(e.target.value)}
          placeholder='Søk etter grav'
          disabled={props.searchDisabled}
      />
    </div>
  )
}

export default SearchBar;