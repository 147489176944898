import React, { useState } from 'react';
import './HeaderTabs.css';
import {
  Tab,
  Tabs,
  MenuItem,
  makeStyles,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setScreenRedux } from '../../../redux/actions';
import Popover from '@material-ui/core/Popover';
import MenuBlue from '../../../img/MenuBlue.svg';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  children: {
    whiteSpace: 'normal',
    backgroundColor: '#fff!important',
    color: '#36434B',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: '10px',
  },
  listItemText: {
    fontSize: '1em !important', //Insert your required size
  },
  root: {
    width: '100%',
    minWidth: '170px',
    backgroundColor: theme.palette.background.paper,
  },
}));

const UnAuthorizedHeader = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const mobilePopoverOpen = Boolean(mobileAnchorEl);
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);

  const handleSubMenuExpandClick = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(
      setScreenRedux(newValue === 0 ? 'search' : newValue === 1 ? 'graver' : '')
    );
  };
  const id = open ? 'simple-popover' : undefined;
  const mobileId = mobilePopoverOpen ? 'simple-popover' : undefined;
  const classes = useStyles();
  const history = useHistory();

  const handleAvatarMenuClick = (event) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMobileMenuClick = (event) => {
    if (open) {
      setMobileAnchorEl(null);
    } else {
      setMobileAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    setAnchorEl(null);
    setMobileAnchorEl(null);
    history.push(path);
  };

  return (
    <>
      <div className='unauthorized-full'>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          id='id223223'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
        >
          <Tab label='Hjem' {...a11yProps(40)} to='/' component={Link} />
          <Tab
            label='Om'
            {...a11yProps(50)}
            to='/about-product'
            component={Link}
          />
          <Tab
            label='Funksjoner'
            {...a11yProps(70)}
            to='/funksjoner'
            component={Link}
          />
          <Tab
            label='Hvordan'
            {...a11yProps(60)}
            onClick={(event) => handleAvatarMenuClick(event)}
          />
          <Tab
            label='Planer'
            {...a11yProps(80)}
            to='/abonnement'
            component={Link}
          />
        </Tabs>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            className={classes.children}
            onClick={() => handleMenuItemClick('/find')}
          >
            Finne en grav
          </MenuItem>
          <MenuItem
            className={classes.children}
            onClick={() => handleMenuItemClick('/create')}
          >
            Opprett ny grav
          </MenuItem>
          <MenuItem
            className={classes.children}
            onClick={() => handleMenuItemClick('/ownership')}
          >
            Ta eierskap til graven
          </MenuItem>
        </Popover>
      </div>

      <div className='unauthorized-mobile'>
        <div className='menu' onClick={(e) => handleMobileMenuClick(e)}>
          <img alt='menu' src={MenuBlue} />
        </div>
        <Popover
          id={mobileId}
          open={mobilePopoverOpen}
          anchorEl={mobileAnchorEl}
          onClose={handleMobileMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/* <MenuItem className={classes.children} onClick={() => handleMobileMenuItemClick()}>
            Home
          </MenuItem>
          <MenuItem className={classes.children} onClick={() => handleMobileMenuItemClick()}>
            About
          </MenuItem>
          <MenuItem className={classes.children} onClick={() => handleMobileMenuItemClick()}>
            Features
          </MenuItem>
          <MenuItem className={classes.children} onClick={() => handleMobileMenuItemClick()}>
            How To
          </MenuItem>
          <MenuItem className={classes.children} onClick={() => handleMobileMenuItemClick()}>
            Pricing
          </MenuItem> */}
          <List
            component='nav'
            aria-labelledby='nested-list-subheader'
            className={classes.root}
          >
            <ListItem button onClick={() => handleMenuItemClick('/')}>
              <ListItemText
                primary='Hjem'
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleMenuItemClick('/about-product')}
            >
              <ListItemText
                primary='Om'
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
            <ListItem button onClick={() => handleMenuItemClick('/funksjoner')}>
              <ListItemText
                primary='Funksjoner'
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
            <ListItem button onClick={handleSubMenuExpandClick}>
              <ListItemText
                primary='Hvordan'
                classes={{ primary: classes.listItemText }}
              />
              {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={subMenuOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleMenuItemClick('/find')}
                >
                  <ListItemText
                    primary='Finne en grav'
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleMenuItemClick('/create')}
                >
                  <ListItemText
                    primary='Opprett ny grav'
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleMenuItemClick('/abonnement')}>
              <ListItemText
                primary='Planer'
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>

            <div className='unauthorized-mobile-login'>
              <button
                onClick={() => handleMenuItemClick('/auth/sign-in')}
                className='sign-in-options login_btn unauth-mobile'
              >
                Logg inn
              </button>
              <button
                onClick={() => handleMenuItemClick('/registration')}
                className='sign-in-options register_btn unauth-mobile'
              >
                Registrer
              </button>
            </div>
          </List>
        </Popover>
      </div>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default UnAuthorizedHeader;
