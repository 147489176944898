import React from 'react';
import styles from './PendingSubscriptionModal.module.css';
import X from '../../../../img/X.svg';
import { Modal, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '22px',
    borderRadius: 10,
    outline: 'none',
    '@media (max-width: 500px)': {
      width: '100%',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const PendingSubscriptionModal = ({ onOpen, onClose }) => {
  const classes = useStyles();
  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['close-icon']}>
          <div className={styles['title']}>Advarsel</div>
          <img
            loading='lazy'
            src={X}
            alt='x'
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className={styles['heading']}>
          Mens abonnementet ditt venter, kan du ikke oppdatere eller endre
          planen.
        </div>
      </div>
    </Modal>
  );
};

export default PendingSubscriptionModal;
