import React, { useEffect, useState } from 'react';
import './Membership.css';
import eagle from '../../../img/Eagle.svg';
import AddNewGraveOutline from '../../../img/AddNewGraveOutline.svg';
import DiscoverGrave from '../../../img/DiscoverGrave.svg';
import NotificationOutline from '../../../img/NotificationOutline.svg';
import MemoriesOutline from '../../../img/MemoriesOutline.svg';
import ResearchCenterOutline from '../../../img/ResearchCenterOutline.svg';
import Mask from '../../../img/Mask.svg';
import Bird from '../../../img/Bird.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { request } from '../../../service/request';
import { setReturnPage, setPaymentMethods } from '../../../redux/actions';
import PopupModal from '../../Payment/atoms/Modal/Modal';
import BillingMethod from './BillingMethod';
import ConformModal from './ConformDeleteModal';

const Membership = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user) || {};
  const subscription = user.subscription || {};
  const amount = subscription.amount && `${subscription.amount} kroner`;
  const validUntil =
    subscription.valid_until && new Date(subscription.valid_until);
  const userReadableDate =
    validUntil &&
    `${validUntil.toLocaleString('en-US', {
      month: 'long',
    })} ${validUntil.toLocaleString('en-US', {
      day: '2-digit',
    })}, ${validUntil.getFullYear()}`;
  const [redirectToPlans, setRedirectToPLans] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const plan = useSelector((state) => state.selectedPlan || null);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const paymentMethods = useSelector((state) => state.paymentMethods || null);

  useEffect(() => {
    const show = location.state?.show;

    if (show) {
      setModalOpen(true);
      history.replace({ show: undefined });
    }
  }, [location]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [userSlug]);

  const fetchPaymentMethods = async () => {
    if (!userSlug) return;
    try {
      request(`/users/${userSlug}/payment_methods`).then((data) => {
        dispatch(setPaymentMethods(data.data));
      });
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const goToPlans = (e) => {
    e.preventDefault();
    dispatch(setReturnPage('/old-settings/membership'));
    history.push('/plans');
  };

  return (
    <div className='membership-page'>
      {redirectToPlans && <Redirect to={{ pathname: '/plans' }} />}
      <div className='membership-page_content'>
        <div className='membership-page_content-header'>
          <span className='membership-page_content-header_header'>
            Medlemskap
          </span>
          <div className='membership-page_orange-line' />
          <span className='membership-page_content-header_subheader'>
            Se og administrer ditt nåværende medlemskap
          </span>
        </div>
        <div className='membership-page-details_border'>
          <div className='membership-page-details'>
            <div className='membership-page-details-yearly'>
              <div className='membership-page-details_center_image'>
                <img src={eagle} alt='eagle' />
              </div>
              <div className='membership-page-details_center_image'>
                <img src={Mask} alt='Mask' />
              </div>
              <div className='membership-page-details-yearly_status'>
                <div className='membership-page-details-Conscious'>
                  <div className='membership-page-details-Conscious-details'>
                    <img src={Bird} alt='Bird' />
                    <span className='membership-page-details-Conscious-details_heading'>
                      {user.subscription ? user.subscription.name : ''}
                    </span>
                  </div>
                </div>
                <div className='membership-page-details-cost_label'>
                  <span>Pris:</span>
                  <span>Gyldig til:</span>
                </div>
                <div className='membership-page-details-cost_value'>
                  <span>{amount}</span>
                  <span>{userReadableDate}</span>
                </div>
                <div className='membership-page-details_bottom_heading'>
                  <span>Fokus: Energi, anti-aldring </span>
                </div>
              </div>
            </div>
          </div>
          <div className='membership-page-submenu'>
            <div className='membership-page-submenu_item'>
              <img src={DiscoverGrave} alt='Grave' />
              <span>Oppdag graver</span>
            </div>
            <div className='membership-page-submenu_item'>
              <img src={NotificationOutline} alt='Notification' />
              <span>Varslinger</span>
            </div>
            <div className='membership-page-submenu_item'>
              <img src={MemoriesOutline} alt='Memories' />
              <span>Minner</span>
            </div>
            <div className='membership-page-submenu_item'>
              <img src={AddNewGraveOutline} alt='AddNewGrave' />
              <span>Legg til nye graver</span>
            </div>
            <div className='membership-page-submenu_item'>
              <img src={ResearchCenterOutline} alt='ResearchCenter' />
              <span>Forskningssenter</span>
            </div>
          </div>
          <div className='membership-page-details_update_button'>
            <button onClick={goToPlans}>
              {user.subscription
                ? user.subscription.name === 'Fri'
                  ? 'Oppgrader plan'
                  : 'Rediger plan'
                : 'Rediger plan'}
            </button>
          </div>
        </div>
        <div className='membership-page_content-header'>
          <span className='membership-page_content-header_header'>
            Betalingsmetoder
          </span>
          <div className='membership-page_orange-line' />
          <div className='membership-page_content-header_subheader'>
            Se og administrer betalingsmåten din
          </div>
        </div>
        {paymentMethods &&
          Array.isArray(paymentMethods) &&
          paymentMethods.length == 0 && (
            <div className='membership-page_content-header-no-result'>
              Det er ingen betalingsmetoder lagt til ennå
            </div>
          )}
        {paymentMethods &&
          paymentMethods.map((method) => (
            <BillingMethod
              paymentMethod={method}
              amount={amount}
              key={method.id}
            />
          ))}
        <PopupModal open={modalOpen} handleClose={closeModal} plan={plan} />
      </div>
    </div>
  );
};

export default Membership;
