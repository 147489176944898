import React from 'react';
import UserAvatar from '../UserAvatar';
import styles from './Relations.module.css';
import { useHistory } from 'react-router-dom';

const Relation = ({ user }) => {
  const history = useHistory();
  return (
    <div
      className={styles['relation-container']}
      onClick={() => history.push(`/member/${user.slug}`)}
    >
      <UserAvatar src={user.user_avatar} />
      <div className={styles['user-name']}>{user.user_name}</div>
      <div className={styles['user-relation']}>{user.relation_name}</div>
      {user.grave_owner ? (
        <div className={styles['user-role']}>graveier</div>
      ) : user.admin ? (
        <div className={styles['user-role']}>Admin</div>
      ) : null}
    </div>
  );
};

export default Relation;
