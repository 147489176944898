import React from 'react';
import styles from './Vipps.module.css';
import { setVippsPhoneNumber } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const VippsPhoneNumber = () => {
  const dispatch = useDispatch();
  const vippsPhoneNumber = useSelector(
    (state) => state.paymentMethods.vippsPhoneNumber
  );

  const handlePhoneNumberChange = (event) => {
    const str = event.target.value.replace(/[^\d]/g, '');
    if (str.length > 0) {
      const eightDigitNumber = str.slice(0, 8);
      if (eightDigitNumber) {
        dispatch(setVippsPhoneNumber(eightDigitNumber.replace(/[^\d]/g, '')));
      }
    } else {
      dispatch(setVippsPhoneNumber(''));
    }
  };
  return (
    <div className={styles['billing-details-container']}>
      <span>Telefonnummer</span>
      <div className={styles['auth-welcome_input']}>
        <div className={styles['create-membership_first-input_block-inputs']}>
          <span
            className={
              styles['create-membership_first-input_block-inputs_button']
            }
          >
            (+47){''}
          </span>
          <input
            className={styles['mobile']}
            type='tel'
            placeholder='00 00 00 00'
            value={vippsPhoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <input
            className={styles['desktop']}
            type='tel'
            placeholder='00 00 00 00'
            value={vippsPhoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </div>
      </div>
    </div>
  );
};

export default VippsPhoneNumber;
