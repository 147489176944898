import React from 'react';
import styles from './GraveInterests.module.css';
import isEmpty from 'lodash.isempty';

const GraveInterests = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  return (
    <>
      {!isEmpty(item) && item.length > 0 ? (
        <div className={styles['interests-container']}>
          {item.map((interest) => {
            return (
              <div className={styles['interest']} key={interest.id}>
                <div className={styles['interest-title']}>{interest.name}</div>
                {interest?.description && <p>{interest.description}</p>}
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles['empty-grave-info']}>
          <span className={styles['empty-message']}>{emptyMessage}</span>
          {canEdit && (
            <button className={styles['add-btn']} onClick={onEdit}>
              Add {title}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default GraveInterests;
