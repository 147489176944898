import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import SettingsPageHeader from './atoms/SettingsPageHeader';
import SettingsPersonalInformationEdit from './atoms/SettingsPageInformationEdit';
import SettingsPersonalInformation from './atoms/SettingsPagePersonalIformation';
import AccountInformation from './atoms/AccountInformation';
import './SettingsPage.css';
import { Modal } from '@material-ui/core';
import X from './../../img/X.svg';
import ChangePassword from './atoms/ChangePassword';
import Notifications from './atoms/Notifications';
import Membership from './atoms/Membership';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import { fetch_request } from '../../service/request';
import { setConfirmSignOut } from '../../redux/actions';

const SettingsPage = () => {
  const [modalDelete, setModalDelete] = useState(false);
  const dispatch = useDispatch();
  const [editPersonalInformation, setEditPersonalInformation] = useState(false);
  const user = useSelector((state) => state.user.user || {});

  const editPage = (value) => {
    setEditPersonalInformation(value);
  };

  const openModalDelete = () => {
    setModalDelete(true);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };

  const deleteAccount = () => {
    fetch_request(`/api/users/${user.slug}`, {
      method: 'delete',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        closeModalDelete();
        if (data.success) {
          dispatch(setConfirmSignOut());
          localStorage.clear();
          showUserNotification('Konto er avsluttet', 'success');
        } else {
          showUserNotification(`${data.message}`, 'warning');
        }
      })
      .catch((err) => {
        console.log(err);
        showUserNotification(
          'Noe gikk galt og konto ble ikke slettet',
          'warning'
        );
      });
  };

  const pauseAccount = () => {
    fetch_request(`/api/deactivate_users/${user.slug}`, {
      method: 'post',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        closeModalDelete();
        if (data.success) {
          // dispatch(setConfirmSignOut());
          localStorage.clear();
          showUserNotification('Kontoen er satt på pause', 'success');
        } else {
          showUserNotification(`${data.message}`, 'warning');
        }
      })
      .catch((err) => {
        console.log(err);
        showUserNotification(
          'Noe gikk galt, og kontoen ble ikke satt på pause',
          'warning'
        );
      });
  };

  return (
    <>
      <Link to={`/settings`}>new settings page</Link>
      <div className='settings-page'>
        <div className='settings-page-wrapper'>
          <div className='settings-page-heading'>
            <span className='page-heading-title'>Kontoinnstillinger</span>
          </div>
          <div className='settings-page_content'>
            <div>
              <SettingsPageHeader />
            </div>
            <div className='content_page_border'>
              <Route
                exact
                path='/old-settings'
                render={() =>
                  editPersonalInformation ? (
                    <SettingsPersonalInformationEdit
                      edit={editPage}
                      onOpenModal={openModalDelete}
                    />
                  ) : (
                    <SettingsPersonalInformation edit={editPage} />
                  )
                }
              />
              <Route path='/old-settings/password' component={ChangePassword} />
              <Route
                path='/old-settings/membership'
                render={() => <Membership onOpenModal={openModalDelete} />}
              />
              <Route path='/old-settings/notifications' component={Notifications} />
              <Route
                path='/old-settings/account'
                render={() => (
                  <AccountInformation
                    deleteAccount={deleteAccount}
                    pauseAccount={pauseAccount}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {modalDelete && (
        <Modal
          open={modalDelete}
          onClose={closeModalDelete}
          className='settings-personal-information_delete-modal'
        >
          <div className=''>
            <div className='settings-personal-information_delete-modal_content'>
              <div className='settings-personal-information_delete-modal_content-headers'>
                <span>Skriv inn kortene dine (Debet/Kreditt)</span>
                <img
                  loading='lazy'
                  src={X}
                  alt='close'
                  onClick={closeModalDelete}
                />
              </div>
              <div className='settings-personal-information_delete-modal_content_inputs'>
                <input type='text' placeholder='Navn på kortet' />
                <input type='text' placeholder='Kortnummer' />
                <div className='settings-personal-information_delete-modal_content_inputs_input'>
                  <input type='text' placeholder='Gyldig gjennom' />
                  <input type='text' placeholder='CCV' />
                </div>
              </div>
              <div className='settings-personal-information_delete-modal_content_remember'>
                <input type='checkbox' />
                <span>Husk dette kortet</span>
              </div>
              <div className='settings-personal-information_delete-modal_content-buttons'>
                <button
                  className='settings-personal-information_delete-modal_content-cancel'
                  onClick={closeModalDelete}
                  style={{ border: 'none', cursor: 'pointer' }}
                >
                  Fortsette
                </button>
              </div>
              <div className='settings-personal-information_delete-modal_content_border' />
              <div className='settings-personal-information_delete-modal_content_footer'>
                <span>Nettbank</span>
                <input type='checkbox' />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SettingsPage;
