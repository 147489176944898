import React from 'react';
import './MusicPlayer.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Play from '../../../../../img/TrianglePlay.svg';
import Pause from '../../../../../img/pause.png';
import { Button } from '@material-ui/core';
import Next from '../../../../../img/next.svg';
import Prev from '../../../../../img/prev.svg';
import { useSelector, useDispatch } from 'react-redux';

const MusicPlayer = (props) => {
  const player = React.useRef();
  const [playing, setPlaying] = React.useState(false);
  const source = props.song.music_url
    ? props.song.music_url
    : props.song.file_url;

  const play = () => {
    player.current.audio.current.play();
    setPlaying(true);
  };

  const pause = () => {
    player.current.audio.current.pause();
    setPlaying(false);
    // console.log(player)
  };

  const nextSec = () => {
    player.current.audio.current.currentTime += 5;
  };

  const prevSec = () => {
    if (player.current.audio.current.currentTime > 5) {
      player.current.audio.current.currentTime -= 5;
    }
  };

  return (
    <div className='comment-textarea_music-player'>
      <Button
        className='comment-textarea_music-player_button'
        onClick={playing ? pause : play}
      >
        <img
          loading='lazy'
          src={playing ? Pause : Play}
          alt=''
          className='comment-textarea_music-player_button-image'
        />
      </Button>
      <Button
        onClick={prevSec}
        className='comment-textarea_music-player_button-secs'
      >
        <img loading='lazy' src={Prev} alt='' />
      </Button>
      <Button
        onClick={nextSec}
        className='comment-textarea_music-player_button-secs'
      >
        <img loading='lazy' src={Next} alt='' />
      </Button>
      <AudioPlayer
        ref={player}
        src={source}
        layout='horizontal-reverse'
        className='comment-textarea_music-player_player'
        customAdditionalControls={[]}
      />
    </div>
  );
};

export default MusicPlayer;
