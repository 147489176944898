import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LoggedIn.css';
import Notifications from './Notifications';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import PlusIcon from '../../../img/plusIcon.svg';
import IconButton from './IconButton';

import { connect, useDispatch } from 'react-redux';
import {
  setConfirmSignOut,
  setInformationAboutUser,
  setSubscription,
  setUserNotificationsRedux,
  setSubscriptionInvitationData,
} from '../../../redux/actions';

import ArrowDarkDown from '../../../img/ArrowDarkDown.svg';
import question from '../../../img/HelAndSupport.svg';
import Settings from '../../../img/Settings.svg';
import SignOut from '../../../img/SginOut.svg';
import { setScreenRedux } from '../../../redux/actions';
import Tooltip from '@material-ui/core/Tooltip';
import { fetch_request } from '../../../service/request';
import { saveLastVisitedPage } from '../../../service/NavigationService';

const useStyles = makeStyles((theme) => ({
  white: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
  },
  children: {
    marginBottom: '8px',
    whiteSpace: 'normal',
    width: '250px',
    backgroundColor: '#fff!important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  circleAndQuestion: {
    backgroundImage: `url(${question})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  rootTooltip: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(33, 32, 32, 0.18)',
    borderRadius: '6px',
    maxWidth: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#36434B',
    boxSizing: 'border-box',
    padding: '19px 14px 18px 16px',
  },
  rootArrow: {
    color: '#fff',
  },
  nettgravDefaultBg: {
    color: '#FFF',
    backgroundColor: '#36434B',
  },
  avatarMenu: {
    backgroundColor: '#36434B !important',
    color: '#FFF',
    marginLeft: '25px',
    '@media (max-width: 700px)': {
      margin: '0 7px',
    },
  },
}));

const LoggedIn = ({ history, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuValue, setMenuValue] = React.useState(0);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [openNotification, setOpenNotification] = React.useState(false);
  //set user data
  useEffect(() => {
    const id =
      localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

    if (id) {
      fetch_request(`/api/users/${id}`, {
        method: 'get',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
        .then((response) => response.json())
        .then((data) => {
          props.setInformationAboutUser(data);
          if (data.user && data.user.subscription) {
            dispatch(setSubscription(data.user.subscription));
          }
        });
    }
    return () => setMenuValue(0);
  }, [localStorage.getItem('user-slug'), sessionStorage.getItem('user-slug')]);

  const signOut = () => {
    handleClose(6);
    props.setConfirmSignOut();
    saveLastVisitedPage(null);
    props.setUserNotificationsRedux([]);
    dispatch(setSubscriptionInvitationData({}));
    localStorage.clear();
    history.push('/');
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    setMenuValue(value);
  };

  const handleAvatarClick = () => {
    const slug =
      localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
    history.push(`/member/${slug}`);
    dispatch(setScreenRedux(''));
    setAnchorEl(null);
  };

  const userAvatar = () => {
    if (props.user?.avatar) {
      return (
        <img
          loading='lazy'
          src={props.user.avatar}
          alt='user gender men avatar'
          style={{ width: '100%' }}
        />
      );
    } else return props.user?.full_name?.charAt(0)?.toUpperCase();
  };

  return (
    <div className='loged-in'>
      <Tooltip
        arrow
        placement='bottom'
        title='Livssiden'
        classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
      >
        <Avatar
          onClick={handleAvatarClick}
          style={{ borderRadius: '35%' }}
          className={classes.avatarMenu}
        >
          {userAvatar()}
        </Avatar>
      </Tooltip>

      <Tooltip
        arrow
        placement='bottom'
        title='Opprett ny grav'
        classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
      >
        <Link to='/ny-grav'>
          <IconButton icon={PlusIcon} />
        </Link>
      </Tooltip>

      <div onClick={() => setOpenNotification(true)}>
        <Notifications
          open={openNotification}
          handleCloseNotification={() => setOpenNotification(false)}
        />
      </div>
      <div>
        <IconButton
          icon={ArrowDarkDown}
          handleClick={(event) => setAnchorEl(event.currentTarget)}
          aria-controls='customized-menu'
          aria-haspopup='true'
          variant='contained'
        />
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          elevation={3}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={handleAvatarClick}
            className={classes.children}
            selected={menuValue === 3}
          >
            <Avatar className={classes.white}>{userAvatar()}</Avatar>
            <div className='menu-settings_text'>
              <span className='menu-settings_text-header'>Livssiden</span>
              <span className='menu-settings_text-subheader'>
                Her finner du alle gravene du er tilknyttet og din egen
                livsside.
              </span>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose(3);
              history.push('/settings');
            }}
            className={classes.children}
            selected={menuValue === 3}
          >
            <Avatar className={classes.white}>
              <img loading='lazy' src={Settings} alt='settings' />
            </Avatar>
            <div className='menu-settings_text'>
              <span className='menu-settings_text-header'>Innstillinger</span>
              <span className='menu-settings_text-subheader'>
                Din kontoinfo som passord, medlemskap og
                person.
              </span>
            </div>
          </MenuItem>
          <MenuItem
            onClick={signOut}
            className={classes.children}
            selected={menuValue === 6}
          >
            <Avatar className={classes.white}>
              <img loading='lazy' src={SignOut} alt='sign out' />
            </Avatar>
            <div className='menu-settings_text'>
              <span className='menu-settings_text-header'>Logg ut</span>
              <span className='menu-settings_text-subheader'>
                Takk for at du er til og vi gleder oss til å se deg på nytt.
              </span>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  id: state.auth.userId,
});

const mapDispatchToProps = {
  setConfirmSignOut,
  setInformationAboutUser,
  setUserNotificationsRedux,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn);
