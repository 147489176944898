import React, { useEffect, useState } from 'react';
import styles from './GraveInformation.module.css';
import X from '../../../../img/X.svg';
import dayjs from 'dayjs';
import { Button, makeStyles, Select, MenuItem } from '@material-ui/core';
import OnlyMe from '../../../../img/OnlyMe.svg';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import Everyone from '../../../../img/Everyone.svg';
import Checked from '../../../../img/Checked.svg';
import { fetch_request, request } from '../../../../service/request';
import '../../../AddNewGrave/atoms/DateInput.css';
import GraveDatePicker from './DatePicker/DatePicker';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { goToChosenGrave } from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { useDispatch } from 'react-redux';
import GraveTimePicker from './TimePicker/TimerPicker';
import isEmpty from 'lodash.isempty';

const month = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];

const calculateMonthNumber = (name) => {
  let number = month.findIndex((month) => month === name) + 1;
  return String(number).length === 1 ? `0${number}` : number;
};

const useStyle = makeStyles(() => ({
  rootGender: {
    border: 'none',
    cursor: 'pointer',
    padding: '24px 94px',
    borderRadius: '8px',
    backgroundColor: '#F2F5F8',
    color: '#777',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  rootGenderClicked: {
    cursor: 'pointer',
    border: 'none',
    padding: '24px 94px',
    borderRadius: '8px',
    background: '#404D56',
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  selector: {
    width: '100%',
    background: '#F2F5F8 !important',
    borderRadius: '8px',
    padding: '18px 28px',
    border: '1px solid transparent',
    '&:focus': {
      border: '1px solid',
    },
  },
  rootChecked: {
    minWidth: '22px !important',
    height: '22px',
    backgroundColor: '#FEA65A',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  rootCheckedClicked: {
    minWidth: '22px !important',
    height: '22px',
    backgroundColor: '#FEA65A',
    backgroundImage: `url(${Checked})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  rootVerify: {
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#404D56',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: '400',
    lineHeight: '18px',
    textTransform: 'uppercase',
    padding: '16px 58px',
  },
  rootDiscard: {
    cursor: 'pointer',
    border: 'none',
    padding: '16px 58px',
    borderRadius: '8px',
    border: '1px solid #FEA65A',
    background: '#FFF',
    color: '#FEA65A',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  rootHide: {
    paddingLeft: '0px',
  },
  rootRelations: {
    width: '160px',
    height: '37px',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    '@media (max-width: 750px)': {
      width: '121px',
      padding: '5px',
    },
  },
  rootCancel: {
    background: '#F5F5F5',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
    borderRadius: '10px',
    textTransform: 'none',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#999999',
    width: '97px',
    height: '37px',
    marginRight: '9px',
  },
  rootSave: {
    background: '#FEA65A',
    borderRadius: '10px',
    textTransform: 'none',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#fff',
    width: '97px',
    height: '37px',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  rootSelect: {
    opacity: '0',
    position: 'absolute',
    zIndex: '-2',
  },
}));

const GraveInformation = ({
  graveInformation,
  closeModal,
  isChanged,
  handelIsChanged,
  openDiscardModal,
  handelDiscardModal,
}) => {
  const dispatch = useDispatch();
  const cemeteryBox = graveInformation?.plot_number?.split(/\.|:/);
  const classes = useStyle();
  // const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [timeOfBirth, setTimeOfBirth] = useState(
    graveInformation
      ? graveInformation.time_of_birth
        ? graveInformation.time_of_birth
        : ''
      : ''
  );
  const [timeOfDeath, setTimeOfDeath] = useState(
    graveInformation
      ? graveInformation.time_of_death
        ? graveInformation.time_of_death
        : ''
      : ''
  );

  const [dateOfBirth, setDateOfBirth] = useState(
    graveInformation
      ? graveInformation.date_of_birth
        ? graveInformation.date_of_birth
        : ''
      : ''
  );

  const [dateOfDeath, setDateOfDeath] = useState(
    graveInformation
      ? graveInformation.date_of_death
        ? graveInformation.date_of_death
        : ''
      : ''
  );
  const [name, setName] = useState(
    graveInformation ? (graveInformation.name ? graveInformation.name : '') : ''
  );
  const [canChangeName, setCanChangeName] = useState(false);
  const [gender, setGender] = useState(
    graveInformation.gender ? graveInformation.gender : ''
  );
  const [selectedMonthBirth, setSelectedMonthBirth] = useState(
    graveInformation.date_of_birth
      ? month[parseInt(graveInformation.date_of_birth.substring(5, 7)) - 1]
      : ''
  );
  const [selectedMonthDeath, setSelectedMonthDeath] = useState(
    graveInformation.date_of_death
      ? month[parseInt(graveInformation.date_of_death.substring(5, 7)) - 1]
      : ''
  );
  const [locationOfBirthVisible, setLocationOfBirthVisible] = useState(
    graveInformation
      ? graveInformation.location_of_birth
        ? graveInformation.location_of_birth.length > 0
          ? true
          : false
        : false
      : false
  );
  const [locationOfDeathVisible, setLocationOfDeathVisible] = useState(
    graveInformation
      ? graveInformation.location_of_death
        ? graveInformation.location_of_death.length > 0
          ? true
          : false
        : false
      : false
  );
  const [reasonOfDeathVisible, setReasonOfDeathVisible] = useState(
    graveInformation
      ? graveInformation.other_reason_of_death
        ? graveInformation.other_reason_of_death.length > 0
          ? true
          : false
        : false
      : false
  );
  const [addNameVisible, setAddNameVisible] = useState(false);
  const [locationOfBirth, setLocationOfBirth] = useState(
    graveInformation
      ? graveInformation.location_of_birth
        ? graveInformation.location_of_birth
        : ''
      : ''
  );
  const [locationOfDeath, setLocationOfDeath] = useState(
    graveInformation
      ? graveInformation.location_of_death
        ? graveInformation.location_of_death
        : ''
      : ''
  );

  const [nickName, setNickName] = useState(() => {
    const { grave_additional_names } = graveInformation;
    let names;
    if (grave_additional_names && Array.isArray(grave_additional_names)) {
      names = grave_additional_names.filter(
        (name) => name.name_type == 'Nickname'
      );
    }

    return names[0] ? names[0].name : '';
  });

  const [birthName, setBirthName] = useState(() => {
    const { grave_additional_names } = graveInformation;
    let names;
    if (grave_additional_names && Array.isArray(grave_additional_names)) {
      names = grave_additional_names.filter(
        (name) => name.name_type == 'Birthname'
      );
    }

    return names[0] ? names[0].name : '';
  });
  const [haveNickname, setHaveNickname] = useState(nickName || birthName);
  const [canChangeNickname, setCanChangeNickname] = useState(false);
  const [plotCemetery, setPlotCemetery] = useState(
    cemeteryBox ? cemeteryBox[0] : ''
  );
  const [plotField, setPlotField] = useState(cemeteryBox ? cemeteryBox[1] : '');
  const [plotRow, setPlotRow] = useState(cemeteryBox ? cemeteryBox[2] : '');
  const [plotGrave, setPlotGrave] = useState(cemeteryBox ? cemeteryBox[3] : '');

  const [reasonDeathList, setReasonDeathList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [municipatitiesList, setMunicipalitiesList] = useState([]);
  const [cemetryList, setCemeteryList] = useState([]);
  const [publicPrivacy, setPublicPrivacy] = React.useState(
    graveInformation.privacy
  );

  const [chosenReasonDeathID, setReasonDeathID] = useState();

  useEffect(() => {
    const getFromBEstring = graveInformation
      ? graveInformation.reason_of_death
        ? graveInformation.reason_of_death
        : ''
      : '';
    setReasonDeathID(
      reasonDeathList.filter((reason) => reason.reason === getFromBEstring)[0]
        ?.id
    );
  }, [reasonDeathList]);

  const [chosenCountryID, setChosenCountryID] = useState(
    graveInformation
      ? graveInformation.municipality
        ? graveInformation.municipality.country_id
        : ''
      : ''
  );
  const [chosenMunicipalityID, setChosenMunicipalityID] = useState(
    graveInformation
      ? graveInformation.municipality
        ? graveInformation.municipality.id
        : ''
      : ''
  );
  const [chosenCemetryID, setChosenCemeteryID] = useState(
    graveInformation
      ? graveInformation.graveyard
        ? graveInformation.graveyard.id
        : ''
      : ''
  );
  const slug = graveInformation.slug;

  const handleDateOfBirth = (date) => {
    if (!isEmpty(date)) {
      const parsedDate = dayjs(date);
      if (parsedDate.isValid()) {
        const newDate = parsedDate.format('YYYY-MM-DD');
        setDateOfBirth(newDate);
        handelIsChanged(true);
      }
    } else {
      setDateOfBirth('');
    }
  };

  const handleTimeOfBirth = (time) => {
    if (!isEmpty(time)) {
      const birthTime = time.format('HH:mm');
      setTimeOfBirth(birthTime);
      handelIsChanged(true);
    } else {
      setTimeOfBirth('');
    }
  };

  const handleDateOfDeath = (date) => {
    if (!isEmpty(date)) {
      const parsedDate = dayjs(date);
      if (parsedDate.isValid()) {
        const newDate = parsedDate.format('YYYY-MM-DD');
        setDateOfDeath(newDate);
        handelIsChanged(true);
      }
    } else {
      setDateOfDeath('');
    }
  };

  const handleTimeOfDeath = (time) => {
    if (!isEmpty(time)) {
      const deathTime = time.format('HH:mm');
      setTimeOfDeath(deathTime);
      handelIsChanged(true);
    } else {
      setTimeOfDeath('');
    }
  };

  // const closeDiscardModal = () => {
  //   setOpenDiscardModal(false);
  //   // closeModal();
  // };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    closeModal();
  };

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    fetch_request('/api/reason_of_deaths', {
      ...requestOptions,
      headers: {
        ...requestOptions.header,
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReasonDeathList(data.reasons);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCountriesList(data.countries);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch_request(
      `/api/municipalities?country_id=${chosenCountryID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setMunicipalitiesList(data.municipalities);
      })
      .catch((err) => console.log(err));
  }, [chosenCountryID]);

  useEffect(() => {
    if (chosenMunicipalityID) {
      fetch_request(
        `/api/graveyards?graveyard[municipality_id][]=${chosenMunicipalityID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setCemeteryList(data.graveyards);
        })
        .catch((err) => console.log(err));
    }
  }, [chosenMunicipalityID]);

  const submitData = () => {
    const formData = new FormData();
    formData.append('grave[name]', name);
    formData.append('grave[gender]', gender);
    formData.append('grave[time_of_birth]', timeOfBirth);
    formData.append('grave[time_of_death]', timeOfDeath);
    formData.append('grave[date_of_birth]', dateOfBirth);
    formData.append('grave[location_of_birth]', locationOfBirth);
    formData.append('grave[date_of_death]', dateOfDeath);
    formData.append('grave[location_of_death]', locationOfDeath);
    formData.append('grave[reason_of_death_id]', chosenReasonDeathID);
    formData.append(
      'grave[grave_additional_names_attributes][][name_type]',
      'Nickname'
    );
    formData.append(
      'grave[grave_additional_names_attributes][][name]',
      nickName
    );
    formData.append(
      'grave[grave_additional_names_attributes][][name_type]',
      'Birthname'
    );
    formData.append(
      'grave[grave_additional_names_attributes][][name]',
      birthName
    );

    formData.append('grave[country_id]', chosenCountryID);
    formData.append('grave[municipality_id]', chosenMunicipalityID);
    formData.append('grave[graveyard_id]', chosenCemetryID);
    formData.append(
      'grave[plot_number]',
      `${plotCemetery}:${plotField}:${plotRow}:${plotGrave}`
    );
    if (publicPrivacy !== null)
      formData.append('grave[privacy_attributes][setting]', publicPrivacy);
    request(`/graves/${slug}`, formData, 'put')
      .then((res) => {
        const formData = new FormData();
        setOpenSaveModal(true);

        dispatch(goToChosenGrave(res.data));
      })
      .catch((err) => {
        showUserNotification('noe gikk galt, prøv igjen', 'warning');
      });
  };

  return (
    <>
      <div className={styles['grave-information']}>
        <div className={styles['grave-information_content']}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitData();
            }}
          >
            <div
              className={`${styles['form-control-block']}
                ${styles['grave-information_content-input-name-block']}`}
            >
              <div className={styles['label']}>
                <label htmlFor='name'>Navn</label>
              </div>
              {canChangeName ? (
                <input
                  type='text'
                  name='name'
                  placeholder='Hele navnet'
                  value={name}
                  onChange={(e) => {
                    e.target.value[0] !== ' ' &&
                      setName(
                        e.target.value
                          .replace(new RegExp('[0-9]'), '')
                          .replace(
                            new RegExp(/["'-@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/),
                            ''
                          )
                      );
                    handelIsChanged(true);
                  }}
                />
              ) : null}
              {!canChangeName ? (
                <>
                  <div className={styles['info-block']}>
                    <div className={styles['user-name']}>{name}</div>
                    <div className={styles['warning-note']}>
                      Vennligst merk: Navneendringer er ment for å rette
                      eventuelle feil i navnet til gravlagte.
                    </div>
                    <button onClick={() => setCanChangeName(true)}>
                      Endre navn
                    </button>
                  </div>
                </>
              ) : null}
            </div>
            <div
              className={`${styles['form-control-block']}
             ${styles['grave-information_content-input-name-block']}`}
            >
              <div className={styles['label']}>
                <label htmlFor='name'>Kallenavn</label>
              </div>
              <input
                placeholder='Kallenavn'
                value={nickName}
                onChange={(e) => {
                  setNickName(e.target.value);
                  handelIsChanged(true);
                }}
              />
            </div>
            <div
              className={`${styles['form-control-block']}
             ${styles['grave-information_content-input-name-block']}`}
            >
              <div className={styles['label']}>
                <label htmlFor='name'>Fødselsnavn</label>
              </div>
              <input
                placeholder='Fødenavn'
                value={birthName}
                onChange={(e) => {
                  setBirthName(e.target.value);
                  handelIsChanged(true);
                }}
              />
            </div>
            <div
              className={`${styles['form-control-block']}
            ${styles['grave-information_content-input-gender']}`}
            >
              <div className={styles['label']}>
                <label htmlFor='name'>Kjønn</label>
              </div>
              <div className={styles['gender-buttons']}>
                <button
                  type='button'
                  className={
                    gender === 'mann'
                      ? classes.rootGenderClicked
                      : classes.rootGender
                  }
                  onClick={() => {
                    setGender('mann');
                    handelIsChanged(true);
                  }}
                >
                  Mann
                </button>
                <button
                  type='button'
                  className={
                    gender === 'kvinne'
                      ? classes.rootGenderClicked
                      : classes.rootGender
                  }
                  onClick={() => {
                    setGender('kvinne');
                    handelIsChanged(true);
                  }}
                >
                  Kvinne
                </button>
                <button
                  type='button'
                  className={
                    gender === 'annen'
                      ? classes.rootGenderClicked
                      : classes.rootGender
                  }
                  onClick={() => {
                    setGender('annen');
                    handelIsChanged(true);
                  }}
                >
                  Annen
                </button>
              </div>
            </div>
            <div className={styles['grave-information-date-piker-container']}>
              <div
                className={`${styles['form-control-block']}
                ${styles['grave-information_content-title-birth']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Fødselsdato</label>
                </div>
                <GraveDatePicker
                  date={dateOfBirth}
                  setDatePickerValue={handleDateOfBirth}
                />
              </div>
              <div
                className={`${styles['form-control-block']}
               ${styles['grave-information_content-title-death']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Dødsdato</label>
                </div>
                <GraveDatePicker
                  date={dateOfDeath}
                  setDatePickerValue={handleDateOfDeath}
                />
              </div>
            </div>
            <div className={styles['grave-information-date-piker-container']}>
              <div
                className={`${styles['form-control-block']}
                ${styles['grave-information_content-title-birth']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Tidspunkt for fødsel</label>
                </div>
                <GraveTimePicker
                  time={timeOfBirth}
                  setDatePickerValue={handleTimeOfBirth}
                />
              </div>
              <div
                className={`${styles['form-control-block']}
               ${styles['grave-information_content-title-death']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Dødstidspunktet</label>
                </div>
                <GraveTimePicker
                  time={timeOfDeath}
                  setDatePickerValue={handleTimeOfDeath}
                />
              </div>
            </div>
            <div className={styles['locations']}>
              <div
                style={{ flex: 1 }}
                className={`${styles['form-control-block']}
                 ${styles['grave-information_content-input-name-block']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Fødselssted</label>
                </div>
                <input
                  placeholder='Fødselssted'
                  value={locationOfBirth}
                  onChange={(e) => {
                    setLocationOfBirth(e.target.value);
                    handelIsChanged(true);
                  }}
                />
              </div>
              <div
                style={{ flex: 1 }}
                className={`${styles['form-control-block']}
                 ${styles['grave-information_content-input-name-block']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Dødslokasjon</label>
                </div>
                <input
                  placeholder='Hvor døde gravlagte?'
                  value={locationOfDeath}
                  onChange={(e) => {
                    setLocationOfDeath(e.target.value);
                    handelIsChanged(true);
                  }}
                />
              </div>
            </div>
            <div
              className={`${styles['grave-information_content-title-reason-death']} ${styles['form-control-block']}`}
            >
              <div className={styles['label']}>
                <label htmlFor='name'>Dødsårsak</label>
              </div>
              <div
                className={
                  styles['grave-information_content-input_reason-of-death']
                }
              >
                <select
                  native
                  id='demo-simple-select'
                  value={chosenReasonDeathID}
                  onChange={(e) => {
                    setReasonDeathID(e.target.value);
                    handelIsChanged(true);
                  }}
                  className={styles['selector']}
                  disabled={publicPrivacy === 'private'}
                >
                  <option value='' disabled>
                    Dødsårsak
                  </option>
                  {reasonDeathList.map((reason) => (
                    <option value={reason.id} key={reason.id}>
                      {reason.reason}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles['region']}>
              <div
                className={`${styles['country']} ${styles['form-control-block']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Land</label>
                </div>
                <select
                  native
                  id='demo-simple-select'
                  value={chosenCountryID}
                  onChange={(e) => {
                    setChosenCountryID(e.target.value);
                    handelIsChanged(true);
                  }}
                  className={styles['selector']}
                  disabled={publicPrivacy === 'private'}
                >
                  <option value='' disabled>
                    Land
                  </option>
                  {countriesList.map((country) => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div
                className={`${styles['muncipality']} ${styles['form-control-block']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>kommune</label>
                </div>
                <select
                  native
                  id='demo-simple-select'
                  value={chosenMunicipalityID}
                  onChange={(e) => {
                    setChosenMunicipalityID(e.target.value);
                    handelIsChanged(true);
                  }}
                  className={styles['selector']}
                  disabled={publicPrivacy === 'private' || !chosenCountryID}
                >
                  <option value='' disabled>
                    Kommune
                  </option>
                  {municipatitiesList.map((municipatity) => (
                    <option value={municipatity.id} key={municipatity.id}>
                      {municipatity.name}
                    </option>
                  ))}
                </select>
              </div>

              <div
                className={`${styles['cemetery']} ${styles['form-control-block']}`}
              >
                <div className={styles['label']}>
                  <label htmlFor='name'>Gravplass</label>
                </div>
                <select
                  native
                  id='demo-simple-select'
                  value={chosenCemetryID}
                  onChange={(e) => {
                    setChosenCemeteryID(e.target.value);
                    handelIsChanged(true);
                  }}
                  className={styles['selector']}
                  disabled={
                    publicPrivacy === 'private' ||
                    !chosenCountryID ||
                    !chosenMunicipalityID
                  }
                >
                  <option value='' disabled>
                    Gravlund
                  </option>
                  {cemetryList.map((cemetery) => (
                    <option value={cemetery.id} key={cemetery.id}>
                      {cemetery.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className={`${styles['grave-information_content-input-name-block']} ${styles['form-control-block']}`}
            >
              <div className={styles['label']}>
                <label htmlFor='name'>Gravnummer</label>
              </div>
              <input
                placeholder='Gravnummer'
                value={plotCemetery}
                onChange={(e) => {
                  setPlotCemetery(e.target.value);
                  handelIsChanged(true);
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                marginBottom: '42px',
              }}
            >
              <Select
                value={publicPrivacy}
                displayEmpty
                onChange={(e) => {
                  setPublicPrivacy(e.target.value);
                  handelIsChanged(true);
                }}
                className={styles['profile-photo_body-content_chosen-menu']}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }}
              >
                <MenuItem value={null} hidden={true} disabled={true}>
                  Velg hvem som kan finne graven i søket:{' '}
                </MenuItem>
                <MenuItem value='public'>
                  <img
                    loading='lazy'
                    src={Everyone}
                    alt='Family Silhouette'
                    className={styles['memories-header_right-icon']}
                  />
                  Alle
                </MenuItem>
                <MenuItem value='relatives'>
                  <img
                    loading='lazy'
                    src={FamilySilhouette}
                    alt='Family Silhouette'
                    className={styles['memories-header_right-icon']}
                  />
                  Familie
                </MenuItem>
                <MenuItem value='private'>
                  <img
                    loading='lazy'
                    src={OnlyMe}
                    alt='Family Silhouette'
                    className={styles['memories-header_right-icon']}
                    style={{ marginLeft: '3px' }}
                  />
                  Privat
                </MenuItem>
              </Select>
            </div>
            <div className={styles['grave-btn-container']}>
              <div className={styles['grave-information_content-verify']}>
                <button
                  type='button'
                  className={classes.rootDiscard}
                  onClick={() => {
                    if (isChanged) {
                      handelDiscardModal(true);
                    } else {
                      closeModal();
                    }
                  }}
                >
                  Avbryt
                </button>
                <button
                  className={classes.rootVerify}
                  type='submit'
                  onClick={(e) => {
                    e.preventDefault();
                    submitData();
                  }}
                >
                  Lagre
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={handelDiscardModal}
          onConfirm={closeModal}
          title='Informasjon'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          title='Grunnleggende informasjon om graven er lagret'
          handleClose={closeSaveModal}
        />
      )}
    </>
  );
};

export default GraveInformation;
