import React from 'react';
import './SearchPage.css';
import PagesSeparator from '../../components/PagesSeparator/PagesSeparator';
import SubMenu from '../../components/SubMenu/SubMenu';
import { useLocation} from 'react-router-dom';
import { setSearchInput } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import AuthModal from '../../components/Modals/AuthModal';
import { useHistory } from 'react-router-dom';

const SearchPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuth = useSelector((state) => state.auth.isAuthorized)
  const history = useHistory()
  
  if(location.search){
    const findGrave = new URLSearchParams(location.search).get("find-grave");
    findGrave && dispatch(setSearchInput(findGrave));
  }
  if (!isAuth) {
    return (
      <AuthModal
          isOpen={true}
          handleCloseUpgradePlansModal={() => history.push('/')}
          body="Opprett en konto eller logg inn nå for å finne og besøke gravstedene du leter etter, samt oppdage flere funksjoner."
          title="Registrer / Logg inn"
        />
    )
  }
  
  return (
    <div className='search-page' style ={{marginTop: "50px"}}>
      {/* <PagesSeparator /> */}
      <SubMenu />
    </div>
  )
};

export default SearchPage;