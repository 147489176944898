import React from 'react';
import styles from './PaymentMethod.module.css';

const PaymentMethod = ({ paymentMethod }) => {
  return (
    <div className={styles['payment-details']}>
      <div className={styles['payment-heading']}>Betalingsinformasjon</div>
      <div className={styles['card-payment-wrapper']}>
        <div className={styles['detail']}>
          <div className={styles['title']}>Betalingsmetode:</div>
          <div className={styles['value']}>
            {paymentMethod.card_type} {paymentMethod.payment_type}
          </div>
        </div>
        {paymentMethod.payment_type === 'card' && (
          <div className={styles['detail']}>
            <div className={styles['title']}>Kortnummer:</div>
            <div className={styles['value']}>
              ******
              {paymentMethod.card_number}
            </div>
          </div>
        )}
        {paymentMethod.payment_type === 'vipps' && (
          <div className={styles['detail']}>
            <div className={styles['title']}>Telefonnr:</div>
            <div className={styles['value']}>
              +47{paymentMethod.phone_number}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethod;
