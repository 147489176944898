import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../../img/OrangePointerPrev.svg';
import EmptyDot from '../../../../../img/EmptyDot.svg';
import styles from './EventImageSlider.module.css';
import OrangeDot from '../../../../../img/FilledDot.svg';
import {
  setLifeEvents,
  setSelectedEvent,
  setEventPhotoIndex,
} from '../../../../../redux/actions';
import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Delete from '../../../../../img/DeleteCoverPhoto.svg';
import { request } from '../../../../../service/request';
import ConformModal from '../../../../../containers/NewSettings/atoms/ConformDeleteModal';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';

const EventImageSlider = ({
  photos,
  event,
  currentEventPhotoIndex,
  user,
  setOpenUpgradeModal,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [touchStartX, setTouchStartX] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const allEvents = useSelector((state) => state.grave.lifeEvents);
  const selectedEvent = useSelector((state) => state.grave.selectedEvent);

  const handleClick = (event) => {
    if (user.is_subscribed) {
      setAnchorEl(event.currentTarget);
    } else {
      setOpenUpgradeModal(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentEventPhotoIndex === 0) {
      dispatch(setEventPhotoIndex(photos.length - 1));
    } else {
      dispatch(setEventPhotoIndex(currentEventPhotoIndex - 1));
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentEventPhotoIndex === photos.length - 1) {
      dispatch(setEventPhotoIndex(0));
      return 0;
    } else {
      dispatch(setEventPhotoIndex(currentEventPhotoIndex + 1));
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    const swipeThreshold = 50;

    if (Math.abs(touchDistance) > swipeThreshold) {
      if (touchDistance > 0) {
        prevPhoto();
      } else {
        nextPhoto();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentEventPhotoIndex]);

  const handelChangeImage = (event, index) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    dispatch(setEventPhotoIndex(index));
  };

  const handelStopPagination = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handelCheckBox = async (value) => {
    const data = {
      category: value,
      event_id: selectedEvent.id,
    };
    try {
      const res = await request(
        `/life_event_images/${photos[currentEventPhotoIndex].id}`,
        data,
        'put'
      );
      const images = selectedEvent.life_event_images.map((image) => {
        if (image.id === photos[currentEventPhotoIndex].id) {
          return res.data;
        } else {
          return image;
        }
      });
      dispatch(
        setSelectedEvent({ ...selectedEvent, life_event_images: images })
      );
      const updatedEvents = [...allEvents];
      const index = allEvents.findIndex(
        (event) => event.id === selectedEvent.id
      );
      updatedEvents.splice(index, 1, {
        ...selectedEvent,
        life_event_images: images,
      });
      dispatch(setLifeEvents([...updatedEvents]));
      showUserNotification('Bildet ble satt som visningsbildet', 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEventImage = async () => {
    try {
      await request(
        `/life_event_images/${photos[currentEventPhotoIndex].id}`,
        null,
        'delete'
      );
      if (currentEventPhotoIndex > 0) {
        dispatch(setEventPhotoIndex(currentEventPhotoIndex - 1));
      }
      const images = selectedEvent.life_event_images.filter(
        (image) => image.id !== photos[currentEventPhotoIndex].id
      );
      dispatch(
        setSelectedEvent({ ...selectedEvent, life_event_images: images })
      );
      const updatedEvents = [...allEvents];
      const index = allEvents.findIndex(
        (event) => event.id === selectedEvent.id
      );
      updatedEvents.splice(index, 1, {
        ...selectedEvent,
        life_event_images: images,
      });
      dispatch(setLifeEvents([...updatedEvents]));

      setOpenDeleteModal(false);
      showUserNotification('Bildet er slettet', 'success');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={styles['grave-picture-gallery_photos-photo']}
      style={{
        backgroundImage: `url(${photos[currentEventPhotoIndex].file_url})`,
      }}
    >
      {photos.length > 0 && (
        <img
          className={styles['gallery_photos-photo']}
          loading='lazy'
          src={
            currentEventPhotoIndex === 0
              ? photos[0].file_url
              : photos[currentEventPhotoIndex].file_url
          }
          alt='Display-image'
        />
      )}
      {photos.length > 1 && (
        <React.Fragment>
          <button
            className={`${styles['buttons-prev']} ${styles['show-buttons']}`}
            onClick={(event) => prevPhoto(event)}
          >
            <img loading='lazy' src={PrevPhotoPointer} alt='PrevPhotoPointer' />
          </button>
          <button
            className={`${styles['buttons-next']} ${styles['show-buttons']}`}
            onClick={(event) => nextPhoto(event)}
          >
            <img loading='lazy' src={NextPhotoPointer} alt='PrevPhotoPointer' />
          </button>
        </React.Fragment>
      )}
      {photos.length > 1 && (
        <div
          className={styles['dots-container']}
          onClick={(event) => handelStopPagination(event)}
        >
          {photos.map((photo, index) => (
            <img
              style={{
                height: '10px',
                width: '10px',
              }}
              onClick={(event) => handelChangeImage(event, index)}
              key={index}
              loading='lazy'
              src={currentEventPhotoIndex === index ? OrangeDot : EmptyDot}
              alt='dot'
            />
          ))}
        </div>
      )}
      {event.creator_id === user.id && (
        <div className={styles['action-container']}>
          <MoreHorizIcon
            className={styles['more-icon']}
            onClick={handleClick}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div className='grave-picture-gallery_photos-details_menu-item'>
                <img loading='lazy' src={Delete} alt='Delete' />
                <span>Slett bildet</span>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (photos[currentEventPhotoIndex].category === 'display') {
                  handelCheckBox('timeline');
                } else {
                  handelCheckBox('display');
                }
                handleClose();
              }}
            >
              <div className='set-cover-image'>
                <input
                  type='checkbox'
                  checked={
                    photos[currentEventPhotoIndex].category === 'display'
                  }
                />
                <label>Sett som visningsbilde</label>
              </div>
            </MenuItem>
          </Menu>
        </div>
      )}
      {openDeleteModal && (
        <ConformModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          deletePayment={deleteEventImage}
          title='bilde'
        />
      )}
    </div>
  );
};

export default EventImageSlider;
