import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useDispatch } from 'react-redux';
import './SetRelationOrClaimDialog.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, makeStyles } from '@material-ui/core';
import { fetch_request } from '../../../../service/request';
import { request } from '../../../../service/request';
import { updateCurrentUserInRelationQueue } from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../../service/constants';

const useStyle = makeStyles(() => ({
  rootMembers: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  root: {
    '& .MuiFormLabel-root': {
      top: 0,
      position: 'absolute',
      margin: '13px',
      transform: 'none',
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  rootMembersClicked: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: '1px solid #FEA65A',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootConfirm: {
    width: '171px',
    height: '37px',
    borderRadius: '10px',
    backgroundColor: '#FEA65A',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
    '&:disabled': {
      backgroundColor: '#CBCBCB',
    },
  },
  input: {
    padding: '10px !important',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
      alignSelf: 'center',
    },
  },
  radio: {
    '&$checked': {
      color: '#fea65a',
    },
  },
}));

const RelationTabs = ({ label, onClick, active }) => {
  const buttonClassName = active ? 'active-button' : 'inactive-button';
  return (
    <button className={buttonClassName} onClick={onClick}>
      {label}
    </button>
  );
};

const SetRelationOrClaimDialog = (props) => {
  const dispatch = useDispatch();
  const grave = props.grave;
  const isClaimed = grave.grave_status === CONSTANTS.grave.claimed;
  const classes = useStyle();
  const [relations, setRelations] = useState([]);
  const [chosenRelation, setChosenRelation] = useState({});
  const [reciprocalRelation, setReciprocalRelation] = useState({});
  const [directionsList, setDirectionsList] = useState([]);
  const [activeTab, setActiveTab] = useState('Relative');
  const [friendNote, setFriendNote] = useState('');

  React.useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const getRelationId = (type) =>
    relations.find((item) => item.name.toLowerCase() === type.toLowerCase())
      ?.id;

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setChosenRelation({});
    } else if (reason === 'select-option') {
      setChosenRelation(value);
    }
    setReciprocalRelation({});
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setReciprocalRelation({});
    } else if (reason === 'select-option') {
      setReciprocalRelation(value);
    }
  };

  const handleSetRelation = async () => {
    const formData = new FormData();
    formData.append('grave_user[grave_id]', grave.id);

    if (activeTab === 'friend' || activeTab === 'other') {
      const relationId = getRelationId(activeTab);
      if (relationId) {
        formData.append('grave_user[relation_id]', getRelationId(activeTab));
        formData.append(
          'grave_user[reciprocal_relation_id]',
          getRelationId(activeTab)
        );
      }
    } else {
      formData.append('grave_user[relation_id]', chosenRelation.id);
      formData.append(
        'grave_user[reciprocal_relation_id]',
        reciprocalRelation.id
      );
    }
    if (activeTab === 'friend') {
      formData.append(
        'grave_user[notes_attributes][][description]',
        friendNote
      );
    }

    await request('/grave_relations', formData, 'post')
      .then((res) => res.data)
      .then((data) => {
        request(`/graves/${grave.slug}`)
          .then((response) => response.data)
          .then((data) => {
            dispatch(updateCurrentUserInRelationQueue(data));
            props.handleCloseRelationDialog();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        showUserNotification('Kan ikke oppdatere relasjonen', 'error');
        console.log(error);
      });
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => {
        setRelations(relations);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFriendNoteChange = (event) => {
    setFriendNote(event.target.value);
  };

  const isDisabled = () => {
    return (
      (chosenRelation.id &&
        reciprocalRelation.id &&
        activeTab === 'Relative') ||
      activeTab === 'friend' ||
      activeTab === 'other'
    );
  };

  return (
    <React.Fragment>
      <div className='set-relation-and-claim-modal_display_image_wrapper'>
        <div>
          <React.Fragment>
            <p>Velg relasjon</p>
            <div className='tab-container'>
              <RelationTabs
                label='Slektning'
                onClick={() => handleTabClick('Relative')}
                active={activeTab === 'Relative'}
              />
              <RelationTabs
                label='Venn'
                onClick={() => handleTabClick('friend')}
                active={activeTab === 'friend'}
              />
              <RelationTabs
                label='Annen'
                onClick={() => handleTabClick('other')}
                active={activeTab === 'other'}
              />
            </div>
          </React.Fragment>
          <div className='tab-content'>
            {activeTab === 'Relative' && (
              <div className='tab-content_body'>
                {chosenRelation && !isEmpty(chosenRelation) && (
                  <div className='relation-desc-subheader'>
                    <span>{`Jeg er ${chosenRelation.name} av ${grave.name}`}</span>
                  </div>
                )}
                <p>Velg din relasjon til gravlagte</p>
                <div className='relation-list-autocomplete'>
                  <Autocomplete
                    id='relation-combo-box'
                    options={relations}
                    classes={{ input: classes.input, root: classes.root }}
                    getOptionLabel={(option) => option.name || ''}
                    style={{ width: '99%' }}
                    value={chosenRelation}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Velg relasjon'
                        // className={{ label: classes.formControl }}
                      />
                    )}
                    onChange={handleOnSelectRelation}
                  />
                </div>

                {reciprocalRelation && !isEmpty(reciprocalRelation) && (
                  <div className='relation-desc-subheader'>
                    <span>{`${grave.name} er min ${reciprocalRelation.name}`}</span>
                  </div>
                )}

                <p>Velg gravlagtes relasjon til deg</p>
                <div className='relation-list-autocomplete'>
                  <Autocomplete
                    id='relation-combo-box'
                    options={filterDirectionList(chosenRelation)}
                    classes={{ input: classes.input, root: classes.root }}
                    getOptionLabel={(option) => option.name || ''}
                    style={{ width: '99%' }}
                    disabled={isEmpty(chosenRelation)}
                    value={reciprocalRelation}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Velg relasjon'
                        className={{ label: classes.formControl }}
                      />
                    )}
                    onChange={handleOnSelectReciprocalRelation}
                  />
                </div>
              </div>
            )}
            {activeTab === 'friend' && (
              <div>
                <TextField
                  placeholder={`Eksempel, jeg var venn av ${grave.name} på universitet i Oslo...`}
                  variant='outlined'
                  multiline
                  minRows={4}
                  fullWidth
                  value={friendNote}
                  onChange={handleFriendNoteChange}
                />
              </div>
            )}
            {activeTab === 'other' && (
              <div>
                Andre bekjente av gravlagte som kanskje ikke graveier kjenner
                til
              </div>
            )}
          </div>
        </div>
      </div>
      <p className='message-text'>
        Alle forespørsler blir besvart fortløpende.
      </p>
      <div className='submit-button-wrapper'>
        <button
          className='nettgrav-default-selection-buttons cancel-button '
          onClick={() => props.handleCloseRelationDialog()}
        >
          Avbryt
        </button>
        <button
          disabled={!isDisabled()}
          onClick={handleSetRelation}
          className='nettgrav-default-selection-buttons submit-button m-l-15'
        >
          Send
        </button>
      </div>
    </React.Fragment>
  );
};

export default SetRelationOrClaimDialog;
