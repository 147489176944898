import React, { useState } from 'react';
import './ResetPass.css';
import { Button, ButtonGroup } from '@material-ui/core';
import Logo from '../../img/Logo.svg';
import WhiteLogo from '../../img/Logo.svg';
import AuthFormWrapper from './AuthFormWrapper'
import { Redirect } from 'react-router-dom';
import ResetFirstStep from './atoms/ResetFirstStep';
import FirstStepImageReset from '../../img/RegistrationImage.svg';
import FirstStepImageResetMobile from '../../img/RegistrationImageMobile.svg';
import SecondStepImageReset from '../../img/RegistrationImageSend.svg';
import SecondStepImageResetMobile from '../../img/RegistrationImageSendMobile.svg';
import ResetSecondStep from './atoms/ResetSecondStep';
import ResetThirdStep from './atoms/ResetThirdStep';

const ResetPass = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [firstStep, setFirstStep] = useState(true);
  const [firstStepData, setFirstStepData] = useState();
  const [secondStepData, setSecondStepData] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [authCode, setAuthCode] = useState('')

  const nextResetPage = () => setCurrentStep(prev => prev + 1);
  const prevResetPage = () => setCurrentStep(prev => prev - 1);

  const getDataFromFirstStep = (data) => {
    setFirstStepData(data);
  }

  const setAuthCodeFromSecondStep = (code) => {
    setAuthCode(code)
  }

  const getDataFromSecondStep = (data) => {
    setSecondStepData(data);
  }

  return (<>
      <AuthFormWrapper>
        <div className='auth-reset'>
          {
            redirect && (
              <Redirect to={{ pathname: "/registration" }}/>
            )
          }
          <span className='auth-welcome_title auth-reset'>Tilbakestill passord</span>
          {currentStep === 1 && <ResetFirstStep nextPage={nextResetPage} prevPage={props.onBackClick} getDataFromFirstStepReset={getDataFromFirstStep} />}

          {currentStep === 2 && <img loading='lazy' src={SecondStepImageReset} className='auth-reset_image' alt="auth-reset"/>}
          {currentStep === 2 && <img loading='lazy' src={SecondStepImageResetMobile} className='auth-reset_image-mobile' alt="auth-reset-mobile" />}
          {currentStep === 2 && <ResetSecondStep setAuthCodeFromSecondStep={setAuthCodeFromSecondStep} phoneData={firstStepData} nextPage={nextResetPage} prevPage={prevResetPage}/>}
          {currentStep === 3 && <ResetThirdStep authCode={authCode} getDataFromSecondStep={getDataFromSecondStep} prevPage={prevResetPage} phoneData={firstStepData}/>}
        </div>
        {!firstStep && <div style={{height:"770px"}}></div>}
      </AuthFormWrapper>
    </>
  )
};


export default ResetPass;