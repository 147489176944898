import React from 'react'
import "./PagesSeparator.css";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const PagesSeparator = (props) => {

  return (
    <div className='pages-separator'>
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        <Typography color="inherit">Home</Typography>
        
        <Typography color="inherit">Search</Typography>
      </Breadcrumbs>
    </div>
  )
};

export default PagesSeparator;