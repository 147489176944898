import React, { useState, useEffect } from 'react'
import './RegisterSecondStep.css';
import '../ConfirmThySelf.css';
import { fetch_request } from '../../../service/request';
import {
  Button,
  InputLabel,
} from '@material-ui/core';
import fetch from 'cross-fetch';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../service/constants';

import { useHistory } from 'react-router-dom'

const formatTimer = (timer) => {
  if (String(timer).length === 2) {
    return `${timer}`
  } else if (String(timer).length === 1) {
    return `0${timer}`
  }
}

const RegisterSecondStep = (props) => {
  const [timer, setTimer] = useState(59);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const step = props.authorizationFormData.step;
  const history = useHistory()

  React.useEffect(() => {
    let timerTimeout;
    if (timer > 0) {
      timerTimeout = setTimeout(() => setTimer(prev => prev - 1), 1000)
    };
    return () => {
      if (timerTimeout) {
        clearTimeout(timerTimeout);
      };
    };
  }, [timer]);

  // useEffect(() => {
  //   return () => {
  //     if (history.action === "POP") {
  //       props.onBackClick()
  //     }
  //   };
  // }, [history])

  const handleInputChange = (value, currentField) => {
    const re = /^(\s*|\d+)$/
    if (re.test(value)) {
      if (currentField === 1) {
        setInput1(value)
      } else if (currentField === 2) {
        setInput2(value)
      } else if (currentField === 3) {
        setInput3(value)
      } else if (currentField === 4) {
        setInput4(value)
      } else if (currentField === 5) {
        setInput5(value)
      }

      if (value === "") {
        if (currentField !== 1) {
          document.getElementById((currentField - 1).toString()).focus()
        }
      } else {
        if (currentField !== 5) {
          document.getElementById((currentField + 1).toString()).focus()
        }
      }
       
    }
  }

  const resendOTPCode = () => {
    if (props.authorizationFormData) {
      let formdata = new FormData();
      formdata.append("phone_number", `${props.authorizationFormData.phoneNumber}`);
      formdata.append("country_id", `${props.authorizationFormData.chosenCountry.id}`);
      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          "Accept": '*/*'
        }
      };
      fetch(`${CONSTANTS.baseUrl}/api/generate_auth_token`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setTimer(59)
        })
        .catch(error => console.log('error', error));
    }
  }

  const isButtonEnabled = () => {
    let regex = /^[0-9]/
    return (regex.test(input1) && regex.test(input2) && regex.test(input3) && regex.test(input4) && regex.test(input5))
  }

  const handleOnNextClick = () => {
      var verificationData = new FormData();
      verificationData.append("phone_number", `${props.authorizationFormData.phoneNumber}`)
      verificationData.append("auth_code", `${props.authorizationFormData.code}`);
      var verificationOptions = {
          method: 'POST',
          body: verificationData,
          redirect: 'follow',
      };

      fetch_request('/api/verification', verificationOptions) 
      .then(response => {
          if (response.status === 401 ) {
            showUserNotification('Engangspassordet er ikke gyldig', 'error')
          } else if (response.status === 200 ){
            showUserNotification('Vellykket verifisering', 'success')
            props.onNextClick()
          } else {
            showUserNotification('Noe gikk av. Prøv igjen senere', 'error')
          }
      })
    }

  useEffect(() => {
      props.setAuthFormData({ code : input1.toString() + input2.toString() + input3.toString() + input4.toString() + input5.toString() });
    },[input5])

  return (
    <div className='create-membership_first create-membership_second'>

        {
          step === 1 &&
          <div className='auth-form-register-second'>
            <div className='auth-welcome_input auth-reset auth-thy' style={{ marginBottom: "8px" }}>
              <InputLabel className='auth-welcome_input-label'>Skriv inn OTP koden</InputLabel>
              <div className='auth-welcome_input-thy'>
                <input className='input-thy__inner' type='text' maxLength={1} value={input1} id="1" onChange={(e) => handleInputChange(e.target.value, 1)} />
                <input className='input-thy__inner' type='text' maxLength={1} value={input2} id="2" onChange={(e) => handleInputChange(e.target.value, 2)} />
                <input className='input-thy__inner' type='text' maxLength={1} value={input3} id="3" onChange={(e) => handleInputChange(e.target.value, 3)} />
                <input className='input-thy__inner' type='text' maxLength={1} value={input4} id="4" onChange={(e) => handleInputChange(e.target.value, 4)} />
                <input className='input-thy__inner' type='text' maxLength={1} value={input5} id="5" onChange={(e) => handleInputChange(e.target.value, 5)} />
              </div>
            </div>
            <div className="auth-welcome_input-resend" style={{ marginBottom: "9px" }}>
              <span className="auth-welcome_input-resend_text">
                Har du ikke mottatt engangskode?
                {
                  timer > 0 && ` Du kan sende ny engangskode om 00:${formatTimer(timer)}`
                }
                {
                  timer === 0 && <Button onClick={resendOTPCode} className='auth-welcome_input-resend_text-button_resend'>Send ny</Button>
                }
              </span>
            </div>
            <div className='create-membership_second__buttons'>
              {/* <button className='create-membership_second__button_back' onClick={props.onBackClick}>Tilbake</button> */}
              <button className='auth-welcome_contained-button' onClick={handleOnNextClick} disabled={!isButtonEnabled()}>Bekrefte</button>
            </div>
          </div>
        }
    </div>
  )
};

export default RegisterSecondStep;