import React, { useEffect, useState } from 'react';
import styles from './LifeEventDetailPage.module.css';
import Header from './Header/Header';
import YearButton from './YearButton/YearButton';
import EventDetails from './EventDetails/EventDetails';
import EventImageSlider from './EventImagesSlider/EventImagesSlider';
import ImagesList from './ImagesList/ImagesList';
import AddNewImage from './AddNewImage/AddNewImage';
import EventImageModal from '../LifeEventPage/AddEventImageModal/AddEventImageModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import {
  addLifeEventPhotos,
  setEventPhotoIndex,
} from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import Tabs from './Tabs/Tabs';
import SelectedVideos from './SelectedVideos/SelectedVideos';
import VideoList from './VideoList/VideoList';

const LifeEventDetailPage = ({ year, event }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const currentPhotoIndex = useSelector(
    (state) => state.photoReducer.currentEventPhotoIndex
  );
  const currentEventVideoIndex = useSelector(
    (state) => state.photoReducer.currentEventVideoIndex
  );
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (event.life_event_images.length > 0) {
      setTab(0);
    } else if (event.life_event_videos.length > 0) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [event]);

  const handelSaveModal = (value) => {
    setOpenSaveModal(value);
  };
  return (
    <div className={styles['event-details']}>
      <Header />
      <div className={styles['event-container']}>
        <YearButton year={year} />
        <div className={styles['event-wrapper']}>
          <EventDetails event={event} />
          <React.Fragment>
            {tab === 0 && event.life_event_images.length > 0 && (
              <EventImageSlider
                photos={event.life_event_images}
                event={event}
                currentEventPhotoIndex={currentPhotoIndex}
                user={user}
                setOpenUpgradeModal={setOpenUpgradeModal}
              />
            )}
            {tab === 1 && event.life_event_videos.length > 0 && (
              <SelectedVideos
                videos={event.life_event_videos}
                currentEventVideoIndex={currentEventVideoIndex}
                user={user}
                setOpenUpgradeModal={setOpenUpgradeModal}
                event={event}
              />
            )}
            {event.life_event_images.length > 0 &&
              event.life_event_videos.length > 0 && (
                <Tabs tab={tab} setTab={setTab} />
              )}

            <div className={styles['image-list']}>
              {event.creator_id === user.id &&
                (event.life_event_images.length > 0 ||
                  event.life_event_videos.length > 0) && (
                  <AddNewImage
                    title='Legg til bilde/video'
                    handleOpenModal={() => {
                      if (user.is_subscribed) {
                        setOpenModal(true);
                      } else {
                        setOpenUpgradeModal(true);
                      }
                    }}
                  />
                )}
              {event.life_event_videos.length > 0 &&
                tab === 1 &&
                event.life_event_videos.map((video, index) => {
                  return (
                    <VideoList
                      video={video}
                      videoIndex={index}
                      currentEventVideoIndex={currentEventVideoIndex}
                    />
                  );
                })}
              {event.life_event_images.length > 0 &&
                tab === 0 &&
                event.life_event_images.map((image, index) => {
                  return (
                    <ImagesList
                      photo={image}
                      photoIndex={index}
                      currentEventPhotoIndex={currentPhotoIndex}
                    />
                  );
                })}
            </div>
          </React.Fragment>
        </div>
      </div>
      {openModal && (
        <EventImageModal
          title='Legg til bilder'
          onOpen={openModal}
          onClose={() => {
            setOpenModal(false);
            dispatch(addLifeEventPhotos([]));
          }}
          event={event}
          handelSaveModal={handelSaveModal}
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          title='Bildet er lagt til livshendelsen'
          handleClose={() => setOpenSaveModal(false)}
        />
      )}
      {openUpgradeModal && (
        <UpgradePlanModal
          isOpen={openUpgradeModal}
          handleCloseUpgradePlansModal={() => setOpenUpgradeModal(false)}
          body='Ivaretakelse av livslinjen er tilgjengelig under Familie og Familie + medlemskap.'
          title='Oppdater til bevisst medlem'
        />
      )}
    </div>
  );
};

export default LifeEventDetailPage;
