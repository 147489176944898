import React from 'react';
import { Modal, makeStyles, CircularProgress } from '@material-ui/core';
// import CircularProgress from '@mui/material/CircularProgress';
import './Modal/Modal.css';

const appearance = {
  theme: 'night',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    // width: '700px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '4px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ProcessModal = ({ open, message }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal
      open={open}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className='process-modal'>
          <span>{message}</span>
          {<CircularProgress color='secondary' style={{ color: '#fea65a' }} />}
        </div>
      </div>
    </Modal>
  );
};

export default ProcessModal;
