import './UserConnections.css'
import React, { useState, useEffect } from 'react';
import { CONSTANTS } from "../../../service/constants"
import { useParams, useHistory } from 'react-router-dom';
import { Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux'


const useStyles = makeStyles((theme) => ({

  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: '10px auto'
  },
  iconMenu: {
    cursor: 'pointer !important'
  }
}));
const UserConnections = (props) => {

  const [connectedUsersList, setConnectedUsersList] = useState([]);
  const { slug } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const isAuth = useSelector(state => state.auth.isAuthorized);

  useEffect(() => {
    if (isAuth) {
      fetchList()
      .then((apiResponse) => {
        setConnectedUsersList(apiResponse.user_connections)
      });
    }
  }
  ,[slug])

  const fetchList = async () => {
    const params = {
      slug: slug
    };

    const stringQueryParams = new URLSearchParams(params).toString();

    const response = await fetch(`${CONSTANTS.baseUrl}/api/user_connections?${stringQueryParams}`, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
    });

    if (response.status === 200) {
      const apiResponse = await response.json();
      return apiResponse;
    } else {
      console.log(response.error)
    }
  }

  const handleUserAvatarClicked = (item) => {
    const path = `/member/${item.connected_user_slug}`;
    history.push(path);
  }

  return(
    <div className="user-connections-container">
      <div className="user-connections-header">
        <div className="user-connections-header_title">
          <span>{`${props.user.full_name} sine nærmeste`}</span>
        </div>
      </div>
      {
        connectedUsersList.length === 0 ? 
        <h1>
          Dine nærmeste relasjoner kommer her
        </h1>
        :
        <div className="user-connections_grid">
        {
          connectedUsersList.map((item, index) => {
            return (
              <div className="user-connections_item" key={index} onClick={() => handleUserAvatarClicked(item)}>
                <Avatar 
                  src={item.avatar}
                  className={classes.large}
                />
                <div className="user_info">
                  <span className="user_name">{item.user_name}</span>
                  <span className="user_relation">{item.relation}</span>
                </div>
              </div>
            )
          })
        }
      </div>
      }
    </div>
  )
}

export default UserConnections;