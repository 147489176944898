import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../Subscriptions.module.css';

const FreeSubscription = ({ currentSubscription }) => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className={styles['subscription-name']}>
        {currentSubscription.plan_name}
      </div>
      <div className={styles['subscription-detail']}>Kom i gang gratis.</div>
      <div className={styles['subscription-update']}>
        <div className={styles['price']}>
          <span>NOK {currentSubscription.subscription_price}</span>
          /mnd
        </div>
        <button
          onClick={() => history.push('/plans')}
          className={styles['upGrade-button']}
        >
          Oppgrader plan
        </button>
      </div>
    </React.Fragment>
  );
};

export default FreeSubscription;
