import React from 'react';
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import './UserInfoHeader.css'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Everyone from '../../../img/Everyone.svg';
import OnlyMe from '../../../img/OnlyMe.svg';
import {useHistory} from 'react-router-dom'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "10px auto",
    border: "1px solid #D8D8D8",
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: "10px auto",
    border: "1px solid #D8D8D8",
  },
  iconMenu: {
    cursor: "pointer !important",
  },
}));

const UserInfoHeader = (props) => {
  const classes = useStyles();
  const author = props.author;
  const history = useHistory();

  const handlePrivacyChange = (e) => {
    props.setPrivacy(e.target.value)
  }
  
  return(
    <div className="user-info-container">
        <div className="user-info_avatar-wrapper">
          <Avatar
            src={author.avatar}
            className={classes.small}
            variant="rounded"
            onClick={() => history.push(`/member/${author.slug}`)}
          />
        </div>
        <div className="user-info_name">
          <span>{author.full_name}</span>
        </div>
        {props.showPrivacy && 
          <div className="question-privacy">
            <FormControl className="privacy-question-box">
              <Select
                className='privacy-select'
                value={props.privacy}
                onChange={event => handlePrivacyChange(event)}
              >
                <MenuItem value='public'>
                  <img loading='lazy'
                    src={Everyone}
                    alt="Family Silhouette"
                    className='memories-header_right-icon'
                  />
                  Alle
                </MenuItem>
                <MenuItem value='relatives'>
                  <img loading='lazy'
                    src={OnlyMe}
                    alt="Family Silhouette"
                    className='memories-header_right-icon'
                    style ={{marginRight: "8px", marginLeft: "3px"}}
                  />
                  Privat
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        }
      </div>
  )
}

export default UserInfoHeader;