import React, { useState } from 'react';
import styles from './ReplyCreateForm.module.css';
import { Avatar, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import SendIcon from '@mui/icons-material/Send';

function ReplyCreateForm({ onCreate, comment }) {
  const currUserId = useSelector((state) => state.user.user.id);
  const replyAuthor = useSelector((state) =>
    state.grave.grave.grave_users.find((u) => u.user_id === currUserId)
  );
  const [replyText, setReplyText] = useState('');

  const handleCreateComment = () => {
    if (replyText.trim()) createReply();
    else showUserNotification('Skriv inn svar', 'warning');
  };

  const createReply = () => {
    try {
      onCreate(comment, replyText);
      setReplyText('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles['comment-reply-form']}>
      {replyAuthor?.user_avatar ? (
        <Avatar src={replyAuthor?.user_avatar} />
      ) : (
        <Avatar>{replyAuthor?.user_name?.charAt(0)}</Avatar>
      )}
      <div className={styles['reply-input']}>
        <input
          className={styles['comment-reply-form-input']}
          type='text'
          placeholder='Skriv her'
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
        />
        <button
          onClick={handleCreateComment}
          className={styles['comment-reply-form_input-button']}
        >
          Sende
        </button>
      </div>
    </div>
  );
}

export default ReplyCreateForm;
