import React from 'react';
import styles from './GraveAdministratorModal.module.css';
import { makeStyles, Modal } from '@material-ui/core';
import X from '../../../../../img/X.svg';
import GraveAdministratorForm from '../GraveAdministratorForm/GraveAdministratorForm';

const GraveAdministratorModal = ({ onOpen, onClose, admins }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      height: 'calc(100% - 32px)',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '32px',
      borderRadius: 0,
      outline: 'none',
      '@media (max-width: 800px)': {
        width: '100%',
        height: 'calc(100% - 32px)',
        overflow: 'auto',
      },
    },
    modalBackground: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['administrator-content_header']}>
            <span>Administrator</span>
            <img loading='lazy' src={X} alt='x' onClick={onClose} />
          </div>
          <div className={styles['administrator-content_subheader']}>
            <span>
              Familie og venner kan bidra i å vedlikeholde graven ved å få
              administrative rettigheter. Sørg for at relasjonen dems er satt
              til gravlagte først.
            </span>
          </div>
        </div>
        <GraveAdministratorForm onClose={onClose} admins={admins} />
      </div>
    </Modal>
  );
};

export default GraveAdministratorModal;
