import React, { useState, useEffect } from 'react';
import './GravesUserJoined.css';
//import { request } from '../../../service/request';
import { CONSTANTS } from '../../../service/constants';
import { useParams, useHistory } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useSelector } from 'react-redux';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: '10px auto',
  },
  iconMenu: {
    cursor: 'pointer !important',
  },
}));
const GravesUserJoined = (props) => {
  const [gravesList, setGravesList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [currentGraveList, setCurrentGraveList] = useState([]);
  const { slug } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    if (isAuth && slug) {
      fetchList().then((apiResponse) => {
        if (apiResponse) {
          setGravesList(apiResponse.graves);
          setCurrentGraveList(apiResponse.graves);
        }
      });
    }
  }, [slug]);

  const fetchList = async () => {
    const params = {
      slug: slug,
    };

    const stringQueryParams = new URLSearchParams(params).toString();

    const response = await fetch(
      `${CONSTANTS.baseUrl}/api/graves_joined_by_users?${stringQueryParams}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.status === 200) {
      const apiResponse = await response.json();
      return apiResponse;
    } else {
      console.log(response.error);
    }
  };

  const handleGraveAvatarClicked = (item) => {
    const path = `/deceased/${item.grave_slug}`;
    history.push(path);
  };

  const onFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(() => value);

    if (value === 'all') {
      setCurrentGraveList(gravesList);
    }

    if (value === 'admin') {
      const newGraveList = gravesList.filter((item) => {
        if (item.admin) {
          return true;
        }
        return false;
      });
      setCurrentGraveList(newGraveList);
    }

    if (value === 'Graveier') {
      const newGraveList = gravesList.filter((item) => {
        if (item.grave_owner) {
          return true;
        }
        return false;
      });
      setCurrentGraveList(newGraveList);
    }

    if (value === 'Slektning') {
      const newGraveList = gravesList.filter((item) => {
        if (!item.grave_owner && !item.admin) {
          return true;
        }
        return false;
      });
      setCurrentGraveList(newGraveList);
    }
  };

  return (
    <div className='joined-graves-container'>
      <div className='joined-graves-header'>
        <div className='joined-graves-header_title'>
          <span>Gravene</span>
          <div className='select-roles'>
            <FormControl
              value={selectedFilter}
              className='privacy-select-roles'
            >
              <Select
                className='select-roles-filter_form_control'
                value={selectedFilter}
                onChange={onFilterChange}
                disabled={
                  currentGraveList.length === 0 && gravesList.length === 0
                }
              >
                <MenuItem value='all'>Alle</MenuItem>
                <MenuItem value='Graveier'>Eier</MenuItem>
                <MenuItem value='admin'>Administrator</MenuItem>
                <MenuItem value='Slektning'>Tilknyttet</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {currentGraveList.length === 0 ? (
        <h1>Ingen tilknyttet graver. Søk på navn for å finne gravene.</h1>
      ) : (
        <div className='joined_graves_grid'>
          {currentGraveList.map((item, index) => {
            return (
              <div
                className='joined_graves_item'
                onClick={() => handleGraveAvatarClicked(item)}
                key={index}
              >
                <Avatar src={item.avatar} className={classes.large} />
                <div className='grave_info'>
                  <div className='grave_name'>{item.name}</div>
                  <div className='grave_relation'>{item.relation}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GravesUserJoined;
