import React, { useEffect, useState, useRef } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './AutoCompleteAddress.css';

export const AutocompleteAddress = ({
  onBillingAddressChange,
  billingAddress,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    let option = placePredictions.map((places) => {
      return places.description;
    });
    if (event.key === 'ArrowUp') {
      setActiveSuggestion(
        activeSuggestion > 0 ? activeSuggestion - 1 : option.length - 1
      );
    } else if (event.key === 'ArrowDown') {
      setActiveSuggestion(
        activeSuggestion < option.length - 1 ? activeSuggestion + 1 : 0
      );
    } else if (event.key === 'Enter') {
      const activeSuggestionLabel = option[activeSuggestion];
      onBillingAddressChange(activeSuggestionLabel);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [placePredictions, activeSuggestion, setActiveSuggestion]);

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <div className={'autocomplete'}>
      <div className={'searchInput'}>
        <input
          ref={inputRef}
          className={'input'}
          value={billingAddress}
          placeholder='Enter address'
          onChange={(evt) => {
            getPlacePredictions({ input: evt.target.value });
            onBillingAddressChange(evt.target.value);
            if (billingAddress.trim() !== '') {
              setIsOpen(true);
            } else {
              setIsOpen(false);
            }
          }}
          onBlur={() => handleInputBlur()}
        />
      </div>
      {isOpen && (
        <div className={'suggestionsModel'}>
          <ul className={'list'}>
            {placePredictions.map((places, index) => {
              return (
                <li
                  key={index}
                  className={
                    index === activeSuggestion
                      ? 'autocomplete-active'
                      : 'listItem'
                  }
                  onClick={() => {
                    onBillingAddressChange(places.description);
                    setIsOpen(false);
                  }}
                >
                  {places.description}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutocompleteAddress;
