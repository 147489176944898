import { Link, useHistory, useLocation } from 'react-router-dom';
import featureCheck from '../../../img/featureCheck.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedPlan,
  setSubscriptionTotalPrice,
  setSubscriptionCount,
} from '../../../redux/actions';
import './PlansCard.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useEffect, useState } from 'react';
import UpdatePlanModal from './UpdatePlanModal';
import Increment from './Increment';
import { CONSTANTS } from '../../../service/constants';
import DeleteInvitationsModal from './DeleteInvitationsModal';
import isEmpty from 'lodash.isempty';
import PendingSubscriptionModal from './PendingSubscriptionModal/PendingSubscriptionModal';

const DEFAULT_PAYMENT_METHOD =
  process.env.REACT_APP_ENV === 'production' ? 'card' : 'vipps';

const PlansCard = ({
  plan,
  isCurrentPlan,
  currentSubscription,
  handelUpdateCurrentSubscription,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [subscriptionTotalCount, setSubscriptionTotalCount] = useState(1);
  const [currentSubscriptionData, setCurrentSubscriptionData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [addMore, setAddMore] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [openSubscriptionStatusModal, setOpenSubscriptionStatusModal] =
    useState(false);

  useEffect(() => {
    if (currentSubscription.id) {
      fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setCurrentSubscriptionData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentSubscription.id]);

  const updateSubscription = (field, value) => {
    if (field === 'invitation') {
      setCurrentSubscriptionData({
        ...currentSubscriptionData,
        subscription_invitations: value,
      });
    } else if (field === 'members') {
      setCurrentSubscriptionData({
        ...currentSubscriptionData,
        subscription_members: value,
      });
    }
  };

  const setTotalCount = (value) => {
    setSubscriptionTotalCount(value);
    dispatch(setSubscriptionCount(value));
    const total = plan.price * value;
    dispatch(setSubscriptionTotalPrice(total));
    setTotalPrice(total);
  };

  const handlePlanSelect = () => {
    if (isAuth && plan.key !== 'free') {
      history.push(
        `/plans/payment?payment_method_type=${DEFAULT_PAYMENT_METHOD}`
      );
    } else if (isAuth) {
      history.push(`/plans/payment`);
    } else {
      history.push('/registration');
    }
  };

  return (
    <div className={`plan-details${isCurrentPlan ? ' current' : ''}`}>
      {isCurrentPlan && currentSubscriptionData?.status === 'pending' && (
        <div className='subscription-status'>pending</div>
      )}
      <div className='plan-details-header'>
        <div className='plan-details-wrapper'>
          <span className='plan-details-header_product_name'>{plan.name}</span>
          {isCurrentPlan && (
            <CheckCircleIcon fontSize='large' style={{ color: '#fea65a' }} />
          )}
        </div>
        <span className='plan-details-header_product_desc'>
          {plan.description}
        </span>
      </div>
      <div className='plan-amount'>
        <span
          className={
            plan.key === 'free'
              ? 'plan-amount-currency'
              : 'plan-amount-currency-family'
          }
        >
          NOK {plan.price}
        </span>
        <span className='plan-amount-price'>
          {plan.key === 'free' ? ' per år ' : ' per medlem /år'}
        </span>
      </div>
      {currentSubscription &&
      plan.key !== 'free' &&
      Object.keys(currentSubscription).length > 0 &&
      isCurrentPlan ? (
        <div className='subscription-user-count'>
          Nåværende medlemmer: {currentSubscription.user_count}
        </div>
      ) : null}
      {currentSubscription &&
      plan.key !== 'free' &&
      Object.keys(currentSubscription).length > 0 &&
      isCurrentPlan ? (
        <div className='subscription-user-count'>
          Planpris: {currentSubscription.price} NOK
        </div>
      ) : null}
      {plan.key !== 'free' && !isCurrentPlan && (
        <>
          <div className='family-message'>
            Velg antall medlemskap du ønsker til deg og din familie
          </div>
          <Increment
            setTotalCount={setTotalCount}
            totalCount={subscriptionTotalCount}
          />
        </>
      )}
      {subscriptionTotalCount > 1 && !isCurrentPlan && (
        <>
          <div className='count-number-message'>
            For {subscriptionTotalCount} familiemedlemmer er den årlige
            betalingen:
          </div>
          <div className='total-price-details'>
            <div className='plan-amount'>
              <span className='plan-amount-currency'>NOK {totalPrice}</span>
              <span className='plan-amount-price'> per år</span>
            </div>
            <div className='total-counting'>
              {subscriptionTotalCount} * NOK {plan.price}
            </div>
          </div>
        </>
      )}
      {isCurrentPlan ? (
        <React.Fragment>
          <div className='plans-btn-container'>
            {plan.key !== 'free' ? (
              <button
                class='add-more-btn'
                onClick={() => {
                  if (currentSubscriptionData.status === 'pending') {
                    setOpenSubscriptionStatusModal(true);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                Endre plan
              </button>
            ) : (
              <div class='plans-details-current-plan-btn'>Gjeldende plan</div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className='plan-subscribe-btn'>
          <button
            onClick={() => {
              if (currentSubscriptionData.status === 'pending') {
                setOpenSubscriptionStatusModal(true);
              } else if (
                isEmpty(currentSubscriptionData.subscription_invitations) &&
                isEmpty(currentSubscriptionData.subscription_members) &&
                currentSubscriptionData.status !== 'pending'
              ) {
                dispatch(setSelectedPlan(plan));
                handlePlanSelect();
              } else {
                dispatch(setSelectedPlan(plan));
                setOpenDeleteModal(true);
              }
            }}
          >
            Kom i gang
          </button>
        </div>
      )}
      <div className='plan-features'>
        <span>
          {plan.key === 'free'
            ? 'For alle med konto'
            : plan.key === 'family'
            ? 'Kjærligheten styrkes: For etterlatte, pårørende og andre som ønsker å styrke kjærligheten og bevare minner.'
            : 'Ekstra trykk på gravens etikk'}
        </span>
        <ul className='features-list'>
          {Array.isArray(plan.features) &&
            plan.features.map((feature) => {
              return (
                <li className='feature-list-item' key={feature.id}>
                  <img src={featureCheck} alt='check' />
                  <span>{feature.title}</span>
                </li>
              );
            })}
        </ul>
      </div>
      {openModal && plan && Object.keys(plan).length > 0 && (
        <UpdatePlanModal
          onOpen={openModal}
          onClose={() => setOpenModal(false)}
          plan={plan}
          isCurrentPlan={isCurrentPlan}
          currentSubscription={currentSubscription}
          handlePlanSelect={handlePlanSelect}
          handelUpdateCurrentSubscription={handelUpdateCurrentSubscription}
        />
      )}
      {openDeleteModal && (
        <DeleteInvitationsModal
          onOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          currentSubscriptionData={currentSubscriptionData}
          updateSubscription={updateSubscription}
        />
      )}
      {openSubscriptionStatusModal && (
        <PendingSubscriptionModal
          onOpen={openSubscriptionStatusModal}
          onClose={() => setOpenSubscriptionStatusModal(false)}
        />
      )}
    </div>
  );
};

export default PlansCard;
