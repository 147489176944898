import React, { useState, useEffect } from 'react';
import styles from './GraveMedia.module.css';
import MediaTabs from './MediaTabs/MediaTabs';
import Upload from '../../img/UploadImageIcon.svg';
import MediaPhotos from './MediaPhotos/MediaPhotos';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../service/request';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash.isempty';
import UploadImageModal from '../NewDeceased/atoms/UploadImageModal/UploadImageModal';
import { setListOfTimelinesPhotos } from '../../redux/actions';

const GraveMedia = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const grave = useSelector((state) => state.grave.grave);
  const photos = useSelector((state) => state.photoReducer.listOfPhotos);
  const [tab, setTab] = useState(0);
  const timeLinesPhotos = useSelector(
    (state) => state.photoReducer.timeLinesPhotos
  );
  const [tabItems, setTabItems] = useState([
    {
      id: 1,
      title: 'Bilder',
    },
    {
      id: 2,
      title: 'Videoer',
    },
    {
      id: 3,
      title: 'Kunstverk',
    },
  ]);
  const [tabId, setTabId] = useState(1);
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    setIsLoading(true);
    try {
      const response = await request(
        `/grave_images?grave_image[grave_id]=${grave.id}&page=${page}&per_page=${perPage}`
      );
      if (response.data.grave_images.length === 0) {
        setLoadMore(false);
      } else {
        dispatch(
          setListOfTimelinesPhotos([
            ...timeLinesPhotos,
            ...response.data.grave_images,
          ])
        );
        setPage((prevPage) => prevPage + 1);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (isLoading || !loadMore || tab === 0) return;

    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      fetchImages();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleOpenCropperModal = (title, category) => {
    setTitle(title);
    setCategory(category);
    setOpenCropperModal(true);
  };

  const handelChange = (value) => {
    setTab(value);
  };

  return (
    <div className={styles['media-container']}>
      <div className={styles['media-tabs-container']}>
        {tabItems.map((item, index) => {
          return (
            <MediaTabs
              key={index}
              item={item}
              tabId={tabId}
              setTabId={(id) => setTabId(id)}
            />
          );
        })}
      </div>
      {tabId === 1 && (
        <React.Fragment>
          <div className={styles['images-btn']}>
            <button
              className={tab === 0 ? styles['btn-focus'] : styles['btn']}
              onClick={() => handelChange(0)}
            >
              Alle
            </button>
            <button
              className={tab === 1 ? styles['btn-focus'] : styles['btn']}
              onClick={() => handelChange(1)}
            >
              Tidslinjer
            </button>
          </div>
          {tab === 0 && (
            <React.Fragment>
              {!isEmpty(photos) ? (
                <div>
                  <div className={styles['heading-container']}>
                    <div className={styles['heading']}>Vis bilder</div>
                    {isAuth && grave.can_add_photos && (
                      <div
                        className={styles['upload-cover-image']}
                        onClick={() => {
                          const title = 'Legg til visningsbilder';
                          const category = 'display';
                          handleOpenCropperModal(title, category);
                        }}
                      >
                        <CameraAltIcon
                          style={{ color: '#19262d', fontSize: '16px' }}
                        />
                        <span>Legg til</span>
                      </div>
                    )}
                  </div>
                  <div className={styles['images-container']}>
                    {photos.map((image, index) => {
                      return (
                        <MediaPhotos
                          key={index}
                          image={image}
                          isAuth={isAuth}
                          canAddPhotos={grave.can_add_photos}
                          images={photos}
                          category='display'
                        />
                      );
                    })}
                  </div>
                  {isLoading && (
                    <div className={styles['loading-container']}>
                      <CircularProgress style={{ color: '#fea65a' }} />
                    </div>
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {isAuth && grave.can_add_photos ? (
                    <div className={styles['add-images-container']}>
                      <div className={styles['upload-image']}>
                        <div className={styles['image-text-message']}>
                          Slipp bildet her eller trykk for å bla i filer
                        </div>
                        <div
                          className={styles['upload-image-input']}
                          onClick={() => {
                            const title = 'Legg til visningsbilder';
                            const category = 'display';
                            handleOpenCropperModal(title, category);
                          }}
                        >
                          <img loading='lazy' src={Upload} alt='UploadImage' />
                          <span className={styles['upload-image-text']}>
                            Bildet bør ikke være større enn 20 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles['empty-images-container']}>
                      Ingen bilder funnet
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {tab === 1 && (
            <React.Fragment>
              {!isEmpty(timeLinesPhotos) ? (
                <div>
                  <div className={styles['heading-container']}>
                    <div className={styles['heading']}>Tidslinjer bilder</div>
                    {isAuth && grave.can_add_photos && (
                      <div
                        className={styles['upload-cover-image']}
                        onClick={() => {
                          const title = 'Legg til tidslinjebilder';
                          const category = 'timeline';
                          handleOpenCropperModal(title, category);
                        }}
                      >
                        <CameraAltIcon
                          style={{ color: '#19262d', fontSize: '16px' }}
                        />
                        <span>Legg til</span>
                      </div>
                    )}
                  </div>
                  <div className={styles['images-container']}>
                    {timeLinesPhotos.map((image, index) => {
                      return (
                        <MediaPhotos
                          key={index}
                          image={image}
                          isAuth={isAuth}
                          canAddPhotos={grave.can_add_photos}
                          images={timeLinesPhotos}
                          category='timeline'
                        />
                      );
                    })}
                  </div>
                  {isLoading && (
                    <div className={styles['loading-container']}>
                      <CircularProgress style={{ color: '#fea65a' }} />
                    </div>
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {isAuth && grave.can_add_photos ? (
                    <div className={styles['add-images-container']}>
                      <div className={styles['upload-image']}>
                        <div className={styles['image-text-message']}>
                        Slipp bilder her eller trykk for å bla i filer
                        </div>
                        <div
                          className={styles['upload-image-input']}
                          onClick={() => {
                            const title = 'Legg til tidslinjebilder';
                            const category = 'timeline';
                            handleOpenCropperModal(title, category);
                          }}
                        >
                          <img loading='lazy' src={Upload} alt='UploadImage' />
                          <span className={styles['upload-image-text']}>
                            Bildet bør ikke være større enn 20 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles['empty-images-container']}>
                      Ingen bilder funnet
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {tabId === 2 && (
        <div className={styles['empty-images-container']}>
          Ingen videoer funnet
        </div>
      )}
      {tabId === 3 && (
        <div className={styles['empty-images-container']}>
          Ingen kunstverk funnet
        </div>
      )}
      {openCropperModal && (
        <UploadImageModal
          onOpen={openCropperModal}
          onClose={() => setOpenCropperModal(false)}
          title={title}
          category={category}
        />
      )}
    </div>
  );
};

export default GraveMedia;
