import { TextareaAutosize } from "@material-ui/core";
import EditAndDeleteActions from './EditAndDeleteActions';
import React , {useState, useRef} from 'react'
import './QuestionBox.css'
import { set } from "date-fns";

const QuestionRow = (props) => {
  const [questionText, setQuestionText] = useState(props.questionText)
  const allowEdit = props.allowEdit
  const [isEditEnabled, setIsEditEnabled] = useState(false)  
  const handleEditEnable = () => setIsEditEnabled(true);
  const handleEditDisable = () => setIsEditEnabled(false);
  
  const handleEditText = (e) => {
    setQuestionText(e.target.value)
  }

  const saveEditedQuestion = () => {
    props.saveEditedQuestion(questionText);
    handleEditDisable();
  }

  // IMPORTANT !!!!
  if (questionText !== props.questionText && !isEditEnabled) {
    setQuestionText('')
    setQuestionText(props.questionText)
  }

  return(
    <div className="question-box-row">
        <div className="question-box-row-flex-wrapper">
          <div className="question-box-text-area-wrapper">
            <TextareaAutosize
              value={questionText}
              onChange={handleEditText}
              disabled={!isEditEnabled}
              placeholder='Start spørsmålet ditt med "Hva", "Hvordan", "Hvorfor", etc'
              className={isEditEnabled? "question-text-area_enabled" : "question-text-area_disabled"}
            />
          </div>
          { allowEdit() && !isEditEnabled &&
            <EditAndDeleteActions 
              handleEditEnable={handleEditEnable} 
              handleEditDisable={handleEditDisable}
              handleDeleteSelection={props.handleDeleteSelection}
            />
          }
        </div>
        {
          isEditEnabled && (
            <div className="edit-actions-button">
              <button className="cancel-edit" onClick={() => setIsEditEnabled(false)}>
                Avbryt
              </button>
              <button className="save-edit" onClick={saveEditedQuestion}>
                Lagre
              </button>
            </div>
          )
        }
      </div>
  )
}

export default QuestionRow;