import "./HintMessage.css"
import React from 'react'

function HintMessage({ message }) {
    return (
        <div className="hint-message">
            {message}
        </div>
    )
}

export default HintMessage
