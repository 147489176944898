import React, { useState, useEffect } from 'react';
import './SubscriptionPlanChosen.css';
import { loadStripe } from '@stripe/stripe-js';
import PlansHeading from './PlansHeading';
import PlansCard from './PlansCard';
import AdditionalBenefits from './AdditionalBenefits';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import ComparePlans from './ComparePlans';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const appearance = {
  theme: 'night',
};

const FAQS = [
  {
    id: 1,
    question: 'Hvilke betalingsmetoder aksepterer dere?',
    answer: 'Vi aksepterer kreditt-/debetkort (Visa, Mastercard) og Vipps',
  },
  {
    id: 2,
    question: 'Er det ekstra gebyrer som jeg må være klar over?',
    answer: 'Det er ingen skjulte gebyrer. Her kan du samle alle gravene og gravlagte uten ekstra gebyr',
  },
  {
    id: 3,
    question: 'Hvordan oppdaterer jeg betalingsdetaljene mine?',
    answer:
      'For å oppdatere betalingsdetaljene dine, vennligst gå til kontoinnstillingene dine og naviger deg til abonnement fanen',
  },
  {
    id: 4,
    question: 'Hva skjer etter betalingen er gjennomført?',
    answer:
      'Når betalingen er gjennomført vil du motta en notifikasjon fra Nettgrav, som viser til at ditt medlemskap er aktivert.',
  },
  {
    id: 5,
    question: 'Hva er forskjellen på Fri og familie?',
    answer:
      'Fri abonnement gir fem søk og fem minner. Videre er det en begrensning på størrelsen på mediene man legger til gravene. Med familie har man ubegrenset tilgang til alle funksjoner og tilgang til nye funksjoner som kommer.',
  },
  {
    id: 6,
    question: 'Hvem er familie+ for?',
    answer:
      'Familie+ har samme ubegrenset tilgang som Familie. Forskjellen er at familien får en direkte kontakt her i Nettgrav som gjør alt for deg. Vi setter relasjonene, inviterer inn familien og knytter alle gravlagte sammen etter en samtale med deg.',
  },
];

const SubscriptionPlanChosen = ({
  plans,
  currentSubscriptionPlan,
  currentSubscription,
  updateSubscription,
}) => {
  const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([
    ...FAQS,
  ]);

  return (
    <div className='subscription-chosen_content-main'>
      <div>
        <PlansHeading />
      </div>
      <div className='subscription-chosen-cards'>
        {Array.isArray(plans) &&
          plans.map((plan) => {
            return (
              <PlansCard
                plan={plan}
                key={plan.id}
                isCurrentPlan={plan.id === currentSubscriptionPlan?.id}
                currentSubscription={currentSubscription}
                handelUpdateCurrentSubscription={updateSubscription}
              />
            );
          })}
      </div>
      {/* <div className='compare-plans'>
        <ComparePlans />
      </div> */}
      <div className='additional-benefit'>
        <AdditionalBenefits />
      </div>
      <div className='faq'>
        <div className='faq-heading'>Spørsmål og svar</div>
        {frequentlyAskedQuestions.map((question) => {
          return (
            <FrequentlyAskedQuestions question={question} key={question.id} />
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPlanChosen;
