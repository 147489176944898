import React, { useState, useEffect } from 'react';
import './RegisterSecondStep.css';
import '../ConfirmThySelf.css';
import { setStepRegistration } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../service/request';
import {
  setConfirmSignIn,
  setUserId,
  subscriptionInvitationToken,
} from '../../../redux/actions';
import {
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import fetch from 'cross-fetch';
import { Link, useHistory, useLocation } from 'react-router-dom';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../service/constants';
import isEmpty from 'lodash.isempty';

const RegisterThirdStep = (props) => {
  const {
    code,
    fullName,
    password,
    confirmPassword,
    checkbox,
    gender,
    phoneNumber,
  } = props.authorizationFormData;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(checkbox);
  const step = props.authorizationFormData.step;
  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );
  const userInvitation = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );

  useEffect(() => {
    if (userInvitation && userInvitation.full_name) {
      props.setAuthFormData({
        fullName: userInvitation.full_name,
      });
    }
  }, [userInvitation]);

  const isPasswordValid = () => {
    // Minimum password length: 7-8 characters
    if (password.length < 7) {
      return false;
    }

    // Password complexity: at least 3 out of 4 categories
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const nonAlphanumericRegex = /[^A-Za-z0-9]/;
    let categoriesCount = 0;

    if (uppercaseRegex.test(password)) {
      categoriesCount++;
    }
    if (lowercaseRegex.test(password)) {
      categoriesCount++;
    }
    if (digitRegex.test(password)) {
      categoriesCount++;
    }
    if (nonAlphanumericRegex.test(password)) {
      categoriesCount++;
    }

    return categoriesCount >= 3;
  };

  const passwordsMatch = () => {
    return password === confirmPassword;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    var formdata = new FormData();
    formdata.append('user[password]', `${password}`);
    formdata.append('user[password_confirmation]', `${confirmPassword}`);
    formdata.append('user[full_name]', `${fullName}`);
    formdata.append('user[gender]', `${props.authorizationFormData.gender}`);
    formdata.append(
      'user[phone_number]',
      `${props.authorizationFormData.phoneNumber}`
    );
    formdata.append('user[auth_code]', `${props.authorizationFormData.code}`);
    formdata.append(
      'user[country_id]',
      `${props.authorizationFormData.chosenCountry.id}`
    );

    formdata.append(
      'user[subscription_invitation_token]',
      `${invitationToken}`
    );

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: {
        Accept: '*/*',
      },
    };

    if (password !== confirmPassword)
      showUserNotification('Passwords do not match', 'warning');
    fetch(`${CONSTANTS.baseUrl}/api/users.json`, requestOptions)
      .then((response) => {
        localStorage.setItem(
          'token',
          response.headers.map['x-authentication-token']
        );
        localStorage.setItem('auth', 'true');
        return response.json();
      })
      .then((result) => {
        if (result.user) {
          showUserNotification('Velkommen. Din konto er opprettet.', 'success');
          setTimeout(() => {
            props.setAuthFormData({
              password: '',
              confirmPassword: '',
              fullName: '',
              gender: 'mann',
              phoneNumber: '',
              code: [],
              step: 0,
              chosenCountry: { dial_code: '+47', id: 1, name: 'Norge' },
            });
          }, 1500);

          const formdata = new FormData();
          formdata.append('phone_number', `${phoneNumber}`);
          formdata.append('password', `${password}`);

          if (phoneNumber) {
            request('/sessions', formdata, 'post')
              .then((response) => {
                if (response.data.success) {
                  const userId = response.data.user.id;
                  dispatch(setUserId(userId));
                  localStorage.setItem(
                    'token',
                    response.headers['x-authentication-token']
                  );
                  localStorage.setItem('auth', 'true');
                  localStorage.setItem('rememberme', checked);
                  if (checked) {
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('user-slug', response.data.user.slug);
                  } else {
                    sessionStorage.setItem('id', response.data.user.id);
                    sessionStorage.setItem(
                      'user-slug',
                      response.data.user.slug
                    );
                  }

                  dispatch(setConfirmSignIn());
                }
              })
              .catch((err) => {
                showUserNotification(
                  'Telefonnummer og passord stemmer ikke',
                  'error'
                );
              });
          }
          dispatch(setStepRegistration(0));
          const graveSlugForRedirect =
            localStorage.getItem('slug_for_redirect');
          if (location.key) {
            history.goBack();
          } else if (graveSlugForRedirect) {
            history.push(`/deceased/${graveSlugForRedirect}`);
          } else {
            history.push(`/member/${result.user.slug}`);
          }
        } else showUserNotification(result.message, 'warning');
      })
      .catch((error) => console.log('error', error));
  };

  const handleCheckBoxChange = (event) => {
    setChecked(!checked);
  };

  // useEffect(() => {
  //   return () => {
  //     if (history.action === "POP") {
  //       props.resetStep()
  //     }
  //   };
  // }, [history])

  return (
    <div className='create-membership_first create-membership_second'>
      {step === 2 && (
        <form
          className='auth-form-register'
          autoComplete='off'
          onSubmit={handleFormSubmit}
        >
          <div className='auth-welcome_input create-membership_first__input second-step'>
            <InputLabel className='auth-welcome_input-label'>
              Ditt navn *
            </InputLabel>
            <TextField
              id='filled-basic'
              placeholder='Skriv inn hele navnet'
              variant='filled'
              className='auth-welcome_email-button'
              type='text'
              value={fullName}
              autoComplete='off'
              required
              // onKeyPress="return /[a-z]/i.test(event.key)"
              onChange={(e) =>
                e.target.value[0] !== ' ' &&
                props.setAuthFormData({
                  fullName: e.target.value
                    .replace(new RegExp('[0-9]'), '')
                    .replace(
                      new RegExp(/["'-@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/),
                      ''
                    ),
                })
              }
            />
          </div>

          <div className='auth-welcome_input create-membership_first__input second-step'>
            <InputLabel className='auth-welcome_input-label'>
              Passord *
            </InputLabel>
            <TextField
              id='filled-basic'
              placeholder='Skriv inn passord'
              variant='filled'
              className='auth-welcome_email-button'
              type='password'
              value={password}
              required
              autoComplete='new-password'
              onChange={(e) =>
                props.setAuthFormData({ password: e.target.value })
              }
            />
          </div>

          {password.length > 0 && !isPasswordValid() && (
            <span className='password-error-message'>
              <span className='register-password-error-message-subheading-new-password'>{`Passordet må bestå av minst 7 tegn og inneholde minst 3 av de 4 kategoriene:`}</span>
              <br />
              <br />
              {`⚙︎ Stor bokstav`}
              <br />
              {`⚙︎ Små bokstaver`}
              <br />
              {`⚙︎ Sifre`}
              <br />
              {`⚙︎ Ikke-alfanumeriske tegn`}
              <br />
            </span>
          )}

          <div
            className='auth-welcome_input create-membership_first__input second-step'
            style={{ marginBottom: 0, marginTop: '10px' }}
          >
            <InputLabel className='auth-welcome_input-label'>
              Bekreft passord *
            </InputLabel>
            <TextField
              id='filled-basic'
              placeholder='Skriv inn passord på ny'
              variant='filled'
              className='auth-welcome_email-button'
              type='password'
              required
              value={confirmPassword}
              autoComplete='new-password'
              onChange={(e) =>
                props.setAuthFormData({ confirmPassword: e.target.value })
              }
              disabled={!isPasswordValid()}
            />
          </div>

          {confirmPassword.length > 0 &&
            !passwordsMatch() &&
            isPasswordValid() && (
              <span className='register-password-error-message-subheading'>
                Passordene er ikke like
              </span>
            )}

          {/* <div className='create-membership_second__check-container'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckBoxChange}
                  inputProps={{ 'aria-label': 'checkbox' }}
                  className='checkbox'
                  required
                />
              }
              className='create-membership_second__checkbox'
              label='Jeg er inneforstått med '
            />
            <Link
              to='/terms_conditions'
              className='check-container_terms_conditions'
            >
              <span>vilkår &amp; betingelser</span>
            </Link>
          </div> */}
          <div className='create-membership_second__buttons'>
            {/* <button className='create-membership_second__button_back' onClick={props.onBackClick}>Tilbake</button> */}
            <button
              variant='contained'
              className='auth-welcome_contained-button'
              type='submit'
              disabled={!isPasswordValid() || !passwordsMatch()}
            >
              Registrer
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default RegisterThirdStep;
