import React, {useState} from 'react'
import "./Header.css";
import SearchField from './atoms/SearchField';
import { connect } from "react-redux"
import {setSearchInputType} from "../../redux/actions";
import LoggedIn from './atoms/LoggedIn';
import HeaderTabs from './atoms/HeaderTabs';
import { useHistory } from 'react-router-dom';


const Header = (props) => {
  const history = useHistory()
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const handleSearchFocus = () => {
    setIsSearchFocused(true)
  }

  const handleSearchBlur = () => {
    setIsSearchFocused(false)
  }
  
  return (
    <div className='header-section'>
      <div className="header-section_first-row_mobile">

        <SearchField 
          history = {props.history} 
          setSearchInput={props.setSearchInput}
          searchInput={props.searchInput}
          handleSearchFocus={handleSearchFocus}
          handleSearchBlur={handleSearchBlur}
          isSearchFocused={isSearchFocused}
        />

        <div className='header-section_menu'>
          <HeaderTabs/>
        </div>

        {
          !props.isAuthorized ? (
            <div className='unauthorized-full'>
              <button onClick={() => history.push('/auth/sign-in')} className="sign-in-options login_btn">
                Logg inn
              </button>
              <button onClick={() => history.push('/registration')} className="sign-in-options register_btn">
                Registrer
              </button>
            </div>
          )
          :
          <div className={isSearchFocused ? 'unauthorized-full' : ''}>
            <LoggedIn history={props.history}/>
          </div>
        }
      </div>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    searchInput: state.search.searchInput,
    isAuthorized: state.auth.isAuthorized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchInput: (searchInput) => {dispatch({type : setSearchInputType, searchInput: searchInput})}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);