import React from 'react';
import './DateInput.css';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DateInput = (props) => {

  const handleDateChange = (date) => {
    props.setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        required
        autoOk
        variant="inline"
        label={props.label}
        format="dd.MM.yyyy"
        value={props.selectedDate}
        InputAdornmentProps={{ position: "end" }}
        onChange={date => handleDateChange(date)}
        className='date-picker'
        disableFuture={true}
      />
    </MuiPickersUtilsProvider>
  )
};


export default DateInput;