import React from 'react';
import './HomepageFindGraveFourth.css';
import Image from '../../../../img/searchpage4.png';
import Arrow from '../../../../img/FindGraveArrow.svg';
import ImageMobile from '../../../../img/FindGraveFourthMobile.png';

const HomepageFindGraveFourth = (props) => {
    return(
        <div className='homepage-findgrave_fourth'>
            <div className="homepage-findgrave_fourth-content">
                <div className="homepage-findgrave_fourth-content_image">
                    <img loading='lazy' src={Image} alt='findgrave' className='homepage-findgrave_fourth-content_image-image'/>
                    <img loading='lazy' src={ImageMobile} alt='ImageMobile' className='homepage-findgrave_fourth-content_image-image_mobile'/>
                    <span className="homepage-findgrave_fourth-content_image_span">Graven gir et annerledes perspektiv på livet vårt. På din <span>livsside</span> har du en egen vegg hvor dine kontakter kan sende deg spørsmål.</span>
                </div>
                <div className="homepage-findgrave_fourth-content_empty"/>
                <div className="homepage-findgrave_fourth-content_main">
                    <div className="homepage-findgrave_fourth-content_main-block">
                        <div className="homepage-findgrave_fourth-content_main-block_number">
                            <span>01</span>
                            <img loading='lazy' src={Arrow} alt='arrow'/>
                        </div>
                        <div className="homepage-findgrave_fourth-content_main-block_text">
                            <span className='homepage-findgrave_fourth-content_main-block_text-header'>En plass for samtale - din egen undrings vegg</span>
                            <span className='homepage-findgrave_fourth-content_main-block_text-subheader'>På livssiden din har du tilgang til din vegg for spørsmål og samtaler med familie og nære venner. Her kan dere dele tanker, og følelser på en personlig måte.</span>
                        </div>
                    </div>
                    <div className="homepage-findgrave_fourth-content_main-block">
                        <div className="homepage-findgrave_fourth-content_main-block_number">
                            <span>02</span>
                            <img loading='lazy' src={Arrow} alt='arrow'/>
                        </div>
                        <div className="homepage-findgrave_fourth-content_main-block_text">
                            <span className='homepage-findgrave_fourth-content_main-block_text-header'>Bevar dine kjære minner - koble til graver og relasjoner</span>
                            <span className='homepage-findgrave_fourth-content_main-block_text-subheader'>Ved å knytte deg til en grav, vil den bli lagret på livssiden din for enkel tilgang. Samme gjelder for relasjoner du etablerer med familiemedlemmer og venner.</span>
                        </div>
                    </div>
                    <div className="homepage-findgrave_fourth-content_main-block">
                        <div className="homepage-findgrave_fourth-content_main-block_number">
                            <span>03</span>
                            <img loading='lazy' src={Arrow} alt='arrow'/>
                        </div>
                        <div className="homepage-findgrave_fourth-content_main-block_text">
                            <span className='homepage-findgrave_fourth-content_main-block_text-header'>Hold deg oppdatert med varsler</span>
                            <span className='homepage-findgrave_fourth-content_main-block_text-subheader'>Du vil motta varsler om hendelser som nær forestående merkedager, nye relasjoner knyttet til graver du har tilknytning til, eller nye oppføringer på gravtavlen.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageFindGraveFourth;