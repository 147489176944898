import React from 'react';
import styles from './SelectDocument.module.css';

const SelectDocument = ({ handelSelectedDocument, selectedDocument }) => {
  const certificateTypeMapper = {
    death_certificate: 'Dødsattest',
    birth_certificate: 'Fødselsattest',
    invoice: 'Faktura for stell/feste av grav',
    power_of_attorney: 'Brev fra advokatfullmektig',
    driving_license: 'Avdødes sertifikat',
  };

  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>Last opp dokument</div>
      <div className={styles['sub-heading']}>
        Opplastet dokument validerer eierskapet til graven.
      </div>
      <div className={styles['document-container']}>
        {Object.keys(certificateTypeMapper).map((key, index) => {
          return (
            <div
              key={index}
              className={
                selectedDocument === key
                  ? styles['select-option-selected']
                  : styles['select-option']
              }
              onClick={() => {
                if (selectedDocument === key) {
                  handelSelectedDocument(null);
                } else {
                  handelSelectedDocument(key);
                }
              }}
            >
              <input type='checkbox' checked={selectedDocument === key} />
              <label>{certificateTypeMapper[key]}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectDocument;
