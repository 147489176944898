import React from 'react';
import './AboutMenuItem.css';

const AboutMenuItem = (props) => {
  return (
    <div
      className={
        props.aboutMenuKey === props.menuItem.id
          ? 'menu-item-active'
          : 'menu-item'
      }
      onClick={() => props.handelAboutMenuKey(props.menuItem.id)}
    >
      <span
        className={
          props.aboutMenuKey === props.menuItem.id ? 'active-item' : 'item'
        }
      >
        {props.menuItem.title}
      </span>
    </div>
  );
};

export default AboutMenuItem;
