import React from 'react';
import './Footer.css';
import MobileLogo from '../../img/LoGo_main.svg';
import Logo from '../../img/LoGo_main.svg';

import { Link } from 'react-router-dom';

const Footer = (props) => {
  return (
    <div className='landing-footer'>
      <div className='footer-logo-title-wrapper'>
        <div className='landing-footer_logo'>
          <img
            loading='lazy'
            src={Logo}
            alt='sad'
            className='landing-footer_logo-image'
          />
          <img
            loading='lazy'
            src={MobileLogo}
            alt='logo'
            className='landing-footer_logo-image-mobile'
          />
        </div>
        <span className='landing-footer_logo-header'>Nettgrav © 2024</span>
      </div>

      <div className='landing-footer_links'>
        <Link
          to='/finn-en-grav'
          className={
            props.location.pathname === '/finn-en-grav'
              ? 'landing-footer_chapter-link first-link link-active'
              : 'landing-footer_chapter-link first-link'
          }
        >
          Finn en grav
        </Link>
        <Link
          to='/graven'
          className={
            props.location.pathname === '/graven'
              ? 'landing-footer_chapter-link landing-footer-link-border link-active'
              : 'landing-footer_chapter-link landing-footer-link-border'
          }
        >
          Graven
        </Link>
        <Link
          to='/graveier'
          className={
            props.location.pathname === '/graveier'
              ? 'landing-footer_chapter-link landing-footer-link-border link-active'
              : 'landing-footer_chapter-link landing-footer-link-border'
          }
        >
          Graveier
        </Link>
        <Link
          to='/about'
          className={
            props.location.pathname === '/about'
              ? 'landing-footer_chapter-link landing-footer-link-border link-active'
              : 'landing-footer_chapter-link landing-footer-link-border'
          }
        >
          Om selskapet
        </Link>
        <Link
          to='/funksjoner'
          className={
            props.location.pathname === '/funksjoner'
              ? 'landing-footer_chapter-link landing-footer-link-border link-active'
              : 'landing-footer_chapter-link landing-footer-link-border'
          }
        >
          Funksjoner
        </Link>
        <Link
          to={{ pathname: 'https://gravplassen.no' }}
          target='_blank'
          className='landing-footer_chapter-link landing-footer-link-border'
        >
          Gravplassen.no
        </Link>
      </div>
    </div>
  );
};

export default Footer;
