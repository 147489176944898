import { Avatar } from "@material-ui/core";
import { TextareaAutosize } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./CommentRow.css";
import { useHistory } from "react-router-dom";
import EditAndDeleteActions from "./EditAndDeleteActions";
import { request } from "../../../service/request";
import { deleteUserWallComment, editUserWallComment } from "../../../redux/actions";
import {useDispatch} from 'react-redux'


const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: "1px solid #D8D8D8",
    cursor: "pointer",
    display: "flex",
    alignSelf: "center",
  },
  iconMenu: {
    cursor: "pointer !important",
  },
}));

const CommentRow = (props) => {
  const classes = useStyles();
  const question = props.question;
  const comment = props.comment;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [text, setText] = useState(comment.description || "");

  const allowEdit = () => {
    return comment.author_slug === props.loggedInUser.slug;
  };

  const handleEditEnable = () => setIsEditEnabled(true);
  const handleEditDisable = () => setIsEditEnabled(false);

  const saveEditedComment = async () => {
    await request(
      `/user_wall_post_comments/${comment.id}`,
      buildUpdateRequest(),
      "put"
    )
      .then((res) => {
        dispatch(editUserWallComment({questionId: question.id, commentId: comment.id, commentText: text}))
        setText("");
        handleEditDisable();
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handleDeleteSelection = async () => {
    await request(`/user_wall_post_comments/${comment.id}`, null, "delete")
      .then((res) => {
        dispatch(deleteUserWallComment({questionId: question.id, commentId: comment.id}))
        handleEditDisable();
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const buildUpdateRequest = () => {
    const formdata = new FormData();
    formdata.append("description", text);
    return formdata;
  };

  // IMPORTANT !!!!
  if (text !== props.comment.description && !isEditEnabled) {
    setText('')
    setText(props.comment.description)
  }

  return (
    <>
      <div className="comment-row">
        <Avatar
          src={comment.author_avatar}
          className={classes.small}
          variant="rounded"
          onClick={() => history.push(`/member/${comment.author_slug}`)}
        />
        <div className="question-text-area-wrapper">
          <TextareaAutosize
            value={text}
            onChange={(e) => {
              e.target.value[0] !== " " && setText(e.target.value);
            }}
            disabled={!isEditEnabled}
            placeholder='Start spørsmålet ditt med "Hva", "Hvordan", "Hvorfor", etc'
            className={
              isEditEnabled
                ? "question-text-area_enabled"
                : "question-text-area_disabled"
            }
          />
        </div>
        {allowEdit() && !isEditEnabled && (
          <EditAndDeleteActions
            handleEditEnable={handleEditEnable}
            handleEditDisable={handleEditDisable}
            handleDeleteSelection={handleDeleteSelection}
          />
        )}
      </div>
      {isEditEnabled && (
        <div className="edit-actions-button">
          <button
            className="cancel-edit"
            onClick={() => setIsEditEnabled(false)}
          >
            Cancel
          </button>
          <button className="save-edit" onClick={saveEditedComment}>
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default CommentRow;
