import React, { useState } from 'react'
import Welcome from '../../containers/Auth/Welcome';
import ResetPass from '../../containers/Auth/ResetPass';
import './SignInPage.css';

const SignInPage = (props) => {
    const [step, setStep] = useState('Welcome');

    const onNextStep = () => setStep('Reset');

    const onBackClick = () => setStep('Welcome');

    return (
        <div className='sign-in-page'>
            {step === 'Welcome' &&  <Welcome goToResetPass={onNextStep}/>}
            {step === 'Reset' && <ResetPass onBackClick={onBackClick}/>}
        </div>
    )
};


export default SignInPage;