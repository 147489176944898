import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentGravePhotoIndex } from '../../../../redux/actions';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './ShowAllMediaImages.module.css';
import BackIcon from '../../../../img/BackIcon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: '#fff',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ShowAllMediaImages = ({ onOpen, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentPhoto = useSelector(
    (state) => state.photoReducer.currentGravePhoto
  );
  const graveImageList = useSelector(
    (state) => state.photoReducer.listOfPhotos
  );

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['container']} onClick={() => onClose()}>
          <img src={BackIcon} alt='back' />
          <span className={styles['title']}>Tilbake</span>
        </div>
        <div className={styles['images-grid']}>
          {graveImageList.map((photo, index) => {
            return (
              <LazyLoadImage
                src={photo.file_url}
                alt='grave_image'
                key={index}
                onClick={() => {
                  dispatch(setCurrentGravePhotoIndex(index));
                  onClose();
                }}
                effect='blur'
                className={
                  currentPhoto === index
                    ? styles['selected-image']
                    : styles['image']
                }
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ShowAllMediaImages;
