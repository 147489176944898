import React, { useEffect, useState } from 'react';
import styles from './GraveUserForm.module.css';
import {
  Avatar,
  Select,
  makeStyles,
  Popover,
  TextField,
} from '@material-ui/core';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { request, fetch_request } from '../../../../service/request';
import { useDispatch } from 'react-redux';
import {
  getListOfCountries,
  setGraveInvitationsList,
} from '../../../../redux/actions';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import PhoneNumber from './PhoneNumber/PhoneNumber';
import ConformModal from '../../../Settings/atoms/ConformDeleteModal';
import MoreHorizontal from '../../../../img/MoreHorizontal.svg';
import InvalidNumberModal from './InvalidNumberModal/InvalidNumberModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { set } from 'date-fns';
import isEmpty from 'lodash.isempty';

// import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const useStyle = makeStyles((theme) => ({
  rootIconMenu: {
    color: '#9f9f9f !important',
  },
  rootIconMenuOpen: {
    display: 'none',
  },
  selector: {
    width: '100%',
    background: '#F2F5F8 !important',
    borderRadius: '8px',
    padding: '18px 28px',
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  input: {
    padding: '10px !important',
  },
}));

const GraveUserForm = ({
  relation,
  relationId,
  disable,
  showList,
  grave,
  handelRelations,
  saveRelationsRedux,
  relations,
  handelCloseForm,
  handelRelationId,
  relationList,
  directionsList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [selectedReciprocal, setSelectedReciprocal] = useState('');
  const [selectedRelation, setSelectedRelation] = useState('');
  const [openInValidModal, setOpenInValidModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [leftToRightRelation, setLeftToRightRelation] = useState({});
  const [rightToLeftRelation, setRightToLeftRelation] = useState({});
  const [editRelation, setEditRelation] = useState({
    id: '',
    user_id: '',
    user_name: '',
    slug: '',
    grave_id: '',
    relation_id: '',
    relation_name: '',
    reciprocal_relation_id: '',
    reciprocal_relation_name: '',
    status: '',
    admin: false,
    user_avatar: '',
    gender: '',
  });
  const open = Boolean(anchorEl);

  const handleClick = (value) => {
    setAnchorEl(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isEmpty(relation)) {
      setEditRelation({ ...relation });
      const leftRelation = relationList.find(
        (item) => item.id === relation.relation_id
      );
      if (leftRelation) {
        setLeftToRightRelation(leftRelation);
      }
      const RightRelation = relationList.find(
        (item) => item.id === relation.reciprocal_relation_id
      );
      if (RightRelation) {
        setRightToLeftRelation(RightRelation);
      }
    }
  }, [relation]);

  useEffect(() => {
    if (!relationId) setAnchorEl(null);
  }, [relationId]);

  const handelEditForm = (field, value) => {
    if (field === 'relation_id') {
      const relation = relationList.find((item) => item.id === value);
      setSelectedRelation(relation.name);
    }
    if (field === 'reciprocal_relation_id') {
      const relation = relationList.find((item) => item.id === value);
      setSelectedReciprocal(relation.name);
    }
    setEditRelation((prev) => ({
      ...prev,
      [field]: value,
    }));
    setIsChanged(true);
  };

  const handleLeftToRightRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setLeftToRightRelation({});
      handelEditForm('relation_id', '');
    } else if (reason === 'select-option') {
      setLeftToRightRelation(value);
      handelEditForm('relation_id', value.id);
    }
    setRightToLeftRelation({});
  };

  const handleRightToLeftRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setRightToLeftRelation({});
      handelEditForm('reciprocal_relation_id', '');
    } else if (reason === 'select-option') {
      setRightToLeftRelation(value);
      handelEditForm('reciprocal_relation_id', value.id);
    }
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  const deleteRelation = () => {
    if (editRelation.id) {
      request(`/grave_users/${editRelation.id}`, null, 'delete')
        .then((res) => {
          const newArray = relations.filter(
            (relation) => relation.id !== editRelation.id
          );
          saveRelationsRedux(newArray);
          handelRelations(newArray);
          setOpenDeleteModal(false);
          showUserNotification('Personen er avlinket fra graven', 'success');
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
      setOpenDeleteModal(false);
    }
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(relation).length > 0) {
      setEditRelation({ ...relation });
      handelRelationId(null);
      handleClose();
    } else {
      clearRelation();
      handelCloseForm();
      handelRelationId(null);
      handleClose();
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelCloseForm();
    handelRelationId(null);
    handleClose();
  };

  const updateUser = async () => {
    if (relationId) {
      const inviteRelation = {
        grave_user: {
          admin: editRelation.admin,
          relation_id: editRelation.relation_id,
          reciprocal_relation_id: editRelation.reciprocal_relation_id,
          grave_id: grave.id,
        },
      };
      request(`/grave_users/${relation.id}`, inviteRelation, 'put')
        .then((res) => {
          handelRelations(
            relations.map((w) =>
              w.id === relationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
          saveRelationsRedux(
            relations.map((w) =>
              w.id === relationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
          setOpenSaveModal(true);
          handleClose();
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    }
  };

  const clearRelation = () => {
    setEditRelation({
      id: '',
      user_id: '',
      user_name: '',
      slug: '',
      grave_id: '',
      relation_id: '',
      relation_name: '',
      reciprocal_relation_id: '',
      reciprocal_relation_name: '',
      status: '',
      admin: false,
      user_avatar: '',
      gender: '',
    });
  };

  return (
    <>
      <div>
        {!disable ? (
          <div className={styles['relation-list']}>
            <div className={styles['user-info-container']}>
              <Avatar
                src={editRelation.user_avatar}
                className={classes.large}
              />
              <div className={styles['user-info']}>
                <div className={styles['user-name']}>
                  {editRelation.user_name}
                </div>
                <div className={styles['user-relation']}>
                  {editRelation.relation_name}
                </div>
                {editRelation.grave_owner ? (
                  <div className={styles['user-role']}>graveier</div>
                ) : editRelation.admin ? (
                  <div className={styles['user-role']}>Admin</div>
                ) : null}
              </div>
            </div>
            <div>
              <span
                className={styles['more-icon-container']}
                onClick={(e) => {
                  handleClick(e.currentTarget);
                }}
              >
                <img
                  className={styles['more-icon']}
                  src={MoreHorizontal}
                  alt='moreIcon'
                />
              </span>
              <Popover
                id='long-menu'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: '160px',
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={styles['user-action']}>
                  <div
                    className={styles['action-btn']}
                    onClick={() => {
                      handelRelationId(editRelation.id);
                      handelCloseForm();
                    }}
                  >
                    <img src={EditIcon} alt='Edit' />
                    <button type='button'>Redigere</button>
                  </div>
                  <div
                    className={styles['action-btn']}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setAnchorEl(null);
                    }}
                  >
                    <img src={DeleteIcon} alt='Delete' />
                    <button type='button'>Slett</button>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles['company-name-container']}>
              <div className={styles['input-element']}>
                <div className={styles['title']}>Navn</div>
                <input
                  disabled={true}
                  className={styles['inputs']}
                  type='text'
                  required={true}
                  placeholder='Navn'
                  value={editRelation?.user_name ? editRelation.user_name : ''}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const trimmedValue = inputValue
                      .replace(/^\s+/, '')
                      .replace(/ {2,}$/, ' ');
                    handelEditForm('user_name', trimmedValue);
                  }}
                />
              </div>
              <div className={styles['input-element']}>
                <div className={styles['title']}>
                  Ønsker du at den inviterte skal være administrator?
                  Administratorer kan endre på graven, og godta innkommende
                  invitasjoner
                </div>
                <select
                  disabled={!disable}
                  native
                  value={editRelation?.admin ? editRelation.admin : ''}
                  onChange={(e) => handelEditForm('admin', e.target.value)}
                  className={styles['selector']}
                >
                  <option value='' disabled>
                    Velg rolle
                  </option>
                  <option value={true}>admin</option>
                  <option value={false}>ikke admin</option>
                </select>
              </div>
            </div>
            <div className={styles['company-name-container']}>
              <div className={styles['input-element']}>
                <div className={styles['title']}>
                  Gravlagtes relasjon til den du inviterer
                </div>
                <div className={styles['choose-relation']}>
                  <Autocomplete
                    id='relation-combo-box'
                    options={relationList}
                    classes={{ input: classes.input }}
                    getOptionLabel={(option) => option.name}
                    style={{ width: '99%' }}
                    value={leftToRightRelation}
                    disabled={!disable}
                    renderInput={(params) => (
                      <TextField placeholder='Velg relasjon' {...params} />
                    )}
                    onChange={handleLeftToRightRelation}
                  />
                </div>
              </div>
              <div className={styles['input-element']}>
                <div className={styles['title']}>
                  Den du inviterer sin relasjon til gravlagte
                </div>
                <div className={styles['choose-relation']}>
                  <Autocomplete
                    id='relation-combo-box'
                    options={filterDirectionList(leftToRightRelation)}
                    classes={{ input: classes.input }}
                    getOptionLabel={(option) => option.name || ''}
                    value={rightToLeftRelation}
                    style={{ width: '99%' }}
                    disabled={isEmpty(leftToRightRelation) || !disable}
                    renderInput={(params) => (
                      <TextField placeholder='Velg relasjon' {...params} />
                    )}
                    onChange={handleRightToLeftRelation}
                  />
                </div>
              </div>
            </div>
            {selectedRelation && selectedReciprocal && (
              <div className={styles['relation-message']}>
                {`${grave.name} er ${selectedRelation} og gravlagte er ${selectedReciprocal} til ${grave.name}`}
              </div>
            )}
          </div>
        )}

        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                type='button'
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={styles['rootVerify']}
                type='submit'
                onClick={updateUser}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='Slektning'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          title='Slektning oppdatert'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='Slektning'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteRelation}
        />
      )}
      {openInValidModal && (
        <InvalidNumberModal
          open={openInValidModal}
          handleClose={() => setOpenInValidModal(false)}
        />
      )}
    </>
  );
};

export default GraveUserForm;
