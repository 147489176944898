import { ListItem, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './SettingsPageHeader.css';
import noGender from '../../../img/SexType.svg';
import MenPhoto from '../../../img/NonePhotoMen.svg';
import WomenPhoto from '../../../img/NonePhotoWomen.svg';
import PersonalInformation from '../../../img/PersonalInformation.svg';
import Password from '../../../img/Password.svg';
import Membership from '../../../img/Membership.svg';
import Processing from '../../../img/Processing.svg';
import Notifications from '../../../img/Notifications.svg';
import Camera from '../../../img/Camera.svg';
import 'cropperjs/dist/cropper.css';
import UserProfileHeader from '../../../pages/UserProfile/components/UserProfileHeader';
import UserProfileImage from '../../../components/UserProfileImage';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '20px 30px',
    cursor: 'pointer',
    borderBottom: '1px solid  #C4C4C4',
    '&:focus': {
      backgroundColor: '#FEA65A',
    },
    '@media (max-width: 750px)': {
      padding: '15px 25px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  listItemSelected: {
    backgroundColor: '#FEA65A!important',
  },
}));

const SettingsPageHeader = () => {
  const monthArray = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  const [menuItems, setMenuItems] = useState([
    {
      name: 'Personinfo',
      icon: (
        <img loading='lazy' src={PersonalInformation} alt='personal info' />
      ),
      url: `/old-settings`,
    },
    {
      name: 'Passord',
      icon: <img loading='lazy' src={Password} alt='password' />,
      url: `/old-settings/password`,
    },
    {
      name: 'Medlemskap',
      icon: <img loading='lazy' src={Membership} alt='membership' />,
      url: `/old-settings/membership`,
    },
    {
      name: 'Varslinger',
      icon: <img loading='lazy' src={Notifications} alt='Notifications' />,
      url: `/old-settings/notifications`,
    },
    {
      name: 'Kontoinformasjon',
      icon: <img loading='lazy' src={Processing} alt='account Information' />,
      url: `/old-settings/account`,
    },
  ]);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);

  const handleClick = (url) => {
    history.push(url);
  };

  const genderAvatar_forBackground = () => {
    if (user.avatar) return user.avatar;
    return user.gender?.toLowerCase() === 'mann'
      ? MenPhoto
      : user.gender?.toLowerCase() === 'kvinne'
      ? WomenPhoto
      : noGender;
  };

  return (
    <div className='settings-page-nav'>
      <div className='settings-page_header-info'>
        <div className='settings-page_header-info-userImage'>
          <UserProfileImage user={user} />
        </div>
        <span className='settings-page_header-info_name'>
          {!isEmpty(user) ? user.full_name : ''}
        </span>
        <span className='settings-page_header-info_date-of-birth'>
          {!isEmpty(user)
            ? `${new Date(user.date_of_birth).getDate()} ${
                monthArray[new Date(user.date_of_birth).getMonth()]
              }, ${new Date(user.date_of_birth).getFullYear()}`
            : ''}
        </span>
      </div>
      <div className='settings-page_header-navigation'>
        <div className='settings-page_header-navigation_first'>
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              selected={item.url === location.pathname}
              onClick={() => {
                handleClick(item.url);
              }}
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
            >
              <span>{item.icon}</span>
              <span className='settings-page_header-navigation_name'>
                {item.name}
              </span>
            </ListItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsPageHeader;
