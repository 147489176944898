import React, { useState } from 'react';
import GraveInformation from '../EditGraveInformation/GraveInformation';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './BasicInformationModal.module.css';
import X from '../../../../img/X.svg';

const BasicInformationModal = ({ onOpen, onClose, grave }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      height: 'calc(100% - 32px)',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '32px',
      borderRadius: 0,
      outline: 'none',
      '@media (max-width: 800px)': {
        width: '100%',
        height: 'calc(100% - 32px)',
        overflow: 'auto',
      },
    },
    modalBackground: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));
  const classes = useStyles();
  const [isChanged, setIsChanged] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  const handelDiscardModal = (value) => {
    setOpenDiscardModal(value);
    // closeModal();
  };
  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['basic-information-content_header']}>
            <span>Grunnleggende informasjon</span>
            <img
              loading='lazy'
              src={X}
              alt='x'
              onClick={() => {
                if (isChanged) {
                  handelDiscardModal(true);
                } else {
                  onClose();
                }
              }}
            />
          </div>
          <div className={styles['basic-information-content_subheader']}>
            <span>
              Legg til informasjon som kjønn, fødsels- og dødsdato, steder,
              lidenskaper, arbeid og interesser.
            </span>
          </div>
        </div>
        <GraveInformation
          closeModal={onClose}
          graveInformation={grave}
          isChanged={isChanged}
          handelIsChanged={(value) => setIsChanged(value)}
          openDiscardModal={openDiscardModal}
          handelDiscardModal={handelDiscardModal}
        />
      </div>
    </Modal>
  );
};

export default BasicInformationModal;
