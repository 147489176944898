import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, makeStyles, Dialog } from '@material-ui/core';
import { fetch_request } from '../../../../../service/request';
import SetUserConnectionModal from '../../UserConnectionModal/SetUserConnectionModal';
import styles from './Member.module.css';
import BirthIcon from '../../../../../img/Birth.svg';

const useStyles = makeStyles(() => ({
  large: {
    width: 93,
    height: 93,
    borderRadius: 10,
  },
}));

const Member = ({
  member,
  relationList,
  updateSubscriptionMembers,
  subscriptionMembers,
  familyOwner,
}) => {
  const user = useSelector((state) => state.user.user || {});
  const classes = useStyles();
  const [openSetUserConnectionModal, setOpenUserConnectionModal] =
    useState(false);
  const [memberRelation, setMemberRelation] = useState(null);

  const handleNewConnection = () => {
    setOpenUserConnectionModal(true);
  };

  useEffect(() => {
    if (member?.connection) {
      const relation = relationList.find(
        (relation) => relation.id === member.connection.relation_id
      );
      if (relation) {
        setMemberRelation(relation.name);
      }
    }
  }, [member]);

  const handleCloseUserConnectionModal = () =>
    setOpenUserConnectionModal(false);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  return (
    <>
      <div className={styles['member-card']}>
        <div className={styles['member-container']}>
          <div className={styles['user-avatar']}>
            <Avatar src={member.avatar} className={classes.large} />
          </div>
          <div className={styles['user-container']}>
            <div className={styles['user-name']}>{member.full_name}</div>
            {member?.date_of_birth && (
              <div className={styles['date-of-birth-container']}>
                <span>
                  <img src={BirthIcon} alt='date-of-birth' />
                  <span className={styles['date-of-birth']}>
                    {member?.date_of_birth
                      ? formatDate(member.date_of_birth)
                      : ''}
                  </span>
                </span>
              </div>
            )}

            <React.Fragment>
              {memberRelation ? (
                <div className={styles['relation-container']}>
                  <span className={styles['relation']}>{memberRelation}</span>
                  <span className={styles['current-user-relation']}>
                    {memberRelation} av {familyOwner.owner_name}
                  </span>
                </div>
              ) : member.user_id === user.id ? (
                <div className={styles['current-user']}>You</div>
              ) : (
                familyOwner.user_id === user.id &&
                !memberRelation && (
                  <button
                    className={styles['connect-btn']}
                    onClick={handleNewConnection}
                  >
                    koble relasjon
                  </button>
                )
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
      <Dialog
        open={openSetUserConnectionModal}
        onClose={handleCloseUserConnectionModal}
        maxWidth={'90%'}
      >
        <SetUserConnectionModal
          slug={member.slug}
          relationsList={relationList}
          closeModal={handleCloseUserConnectionModal}
          user={member}
          subscriptionMembers={subscriptionMembers}
          updateSubscriptionMembers={updateSubscriptionMembers}
        />
      </Dialog>
    </>
  );
};

export default Member;
