import React, { useState, useEffect } from 'react';
import { fetch_request } from '../../../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import styles from './LifeEvents.module.css';
import {
  setLifeEvents,
  getListOfCountries,
} from '../../../../../redux/actions';
import isEmpty from 'lodash.isempty';
import LifeEvent from '../LifeEvent/LifeEvent';
import spinner from '../../../../../service/Loading_animation.gif';
import Loader from '../../../../../components/Loader/Loader';

const LifeEvents = ({ handelSaveModal }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [eventsByYear, setEventsByYears] = useState({});
  const [countries, setCountries] = useState([]);
  const events = useSelector((state) => state.grave.lifeEvents);
  const grave = useSelector((state) => state.grave.grave);

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  useEffect(() => {
    if (!grave.id) return;
    setLoading(true);
    fetch_request(`/api/life_events?grave_id=${grave.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setLifeEvents(data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [dispatch, grave.id]);

  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const eventsByYearAndMonth = {};

    if (events.length > 0) {
      events.forEach((event) => {
        const eventYear = event.event_year;
        const eventMonth = new Date(event.event_date).getMonth();

        if (!eventsByYearAndMonth[eventYear]) {
          eventsByYearAndMonth[eventYear] = {};
        }
        if (!eventsByYearAndMonth[eventYear][eventMonth]) {
          eventsByYearAndMonth[eventYear][eventMonth] = [];
        }
        eventsByYearAndMonth[eventYear][eventMonth].push(event);
      });
      setEventsByYears(eventsByYearAndMonth);
    }
  }, [events]);

  if (loading) return <Loader />;

  return (
    <div>
      <React.Fragment>
        {events.length > 0 && grave.is_relative && !loading ? (
          <React.Fragment>
            {Object.entries(eventsByYear).map(([year, eventsInYear], index) => (
              <LifeEvent
                key={index}
                year={year}
                eventsInYear={eventsInYear}
                countries={countries}
                index={index}
                handelSaveModal={handelSaveModal}
              />
            ))}
          </React.Fragment>
        ) : (
          <div className={styles['empty-event-container']}>
            <span>
              Ingen livs-hendelser funnet. Klikk på legg til knappen for å
              starte livslinjen.
            </span>
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default LifeEvents;
