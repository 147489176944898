import React, { useState } from 'react';
import './MusicMobile.css';
import AddMusicLinkMobile from '../../../../../img/AddMusicLinkMobile.svg';
import Pointer from '../../../../../img/Pointer.svg';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentOnToggle } from '../../../../../redux/actions';
import MusicPLay from '../../../../../img/MusicButton.svg';

const useStyle = makeStyles(() => ({
    rootCancel: {
        width: '180px',
        height: '55px',
        color: '#C9CACB',
        background: '#fff',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            background: '#fff',
        }
    },
    rootSave: {
        width: '180px',
        height: '55px',
        color: '#fff',
        background: '#FEA65A',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        borderRadius: '10px',
        '&:hover': {
            background: '#fff',
        }
    },
    rootAdd: {
        width: '66px',
        height: '42px',
        color: '#fff',
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        backgroundColor: '#FEA65A',
        marginLeft: '-10px',
        '&:hover': {
            backgroundColor: '#FEA65A',
        }
    }
}))

const MusicMobile = props => {
    const classes = useStyle();
    const [link, setLink] = useState('');
    const [musicArray, setMusicArray] = useState([]);
    const dispatch = useDispatch();
    const currentComment = useSelector(state => state.grave.commentOnToggle);

    const handleAddMusic = () => {
        if (link) {
            setMusicArray(prev => [{title: 'Song Lorem', author: 'Lorem Lorem', duration: '10:00', id: Date.now().toString()}, ...prev]);
            setLink('');
        }
    }

    const saveMusicComment = () => {
        dispatch(addCommentOnToggle({...currentComment, title: '', music: musicArray}))
        props.closeModal();
    }

    return(
        <div className='music-modal_mobile'>
            <div className="music-modal_mobile-close">
                <img loading='lazy' src={Pointer} alt='pointer' onClick={props.closeModal}/>
                <span onClick={props.closeModal}>Go to add new post</span>
            </div>
            <div className="music-modal_mobile-content">
                <div className="music-modal_mobile-content_header">
                    <img loading='lazy' src={AddMusicLinkMobile} alt='AddMusicLinkMobile'/>
                </div>
                <div className="music-modal_mobile-content_input">
                    <span>Link to music</span>
                    <div>
                        <input type='text' placeholder='https://' value={link} onChange={e => setLink(e.target.value)}/>
                        <Button className={classes.rootAdd} onClick={handleAddMusic}>Add</Button>
                    </div>
                </div>
                <div className="music-modal_mobile-content_subheader">
                    <span className='add-by-link_header-subtitle'>You can add music from <span>Spotify</span>, <span>Apple music</span>, <span>SoundCloud</span> and more.</span>
                </div>
                <div className="music-modal_mobile-content_song-list">
                    {
                        musicArray.map(song => {
                            return(
                                <div className='music-modal_mobile-content_song' key={song.id}>
                                    <img loading='lazy' src={MusicPLay} alt='MusicPLay'/>
                                    <span className='add-by-link_header-music_text'><span>{song.title} -</span> {song.author}</span>
                                    <span className='add-by-link_header-music_duration'>{song.duration}</span>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    musicArray.length > 0 &&
                    <div className='music-modal_mobile-content_buttons'>
                        <Button className={classes.rootCancel} onClick={props.closeModal}>Cancel</Button>
                        <Button className={classes.rootSave} onClick={saveMusicComment}>Save</Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default MusicMobile;