import React from 'react';
import './Card.css';
import WhiteLocation from '../../../../../img/WhiteLocation.svg';

const Card = (props) => {
  const name = props.name;
  const countryName = props.countryName;
  return (
    <div className='card'>
      <div className='card-content'>
        <span className='card-content_header'>{name}</span>
        <div className='card-content_subheader_wrapper'>
          <img src={WhiteLocation} alt='location' />
          <span className='card-content_subheader'>{countryName}</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
