import React from 'react';
import './HomepageFindGraveFifth.css';
import Image from '../../../../img/saerchpage5.png';
import ImageBlur from '../../../../img/searchpage5-dull.png';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setSearchInput } from "../../../../redux/actions";

const HomepageFindGraveFifth = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setSearchInput(''));
        history.push("/search");
    }
    return(
        <div className='homepage-findgrave_fifth'>
            <div className="homepage-findgrave_fifth-content">
                <div className="homepage-findgrave_fifth-content_header">
                    <span className='homepage-findgrave_fifth-content_header-main'>Nettgrav <span>Bevisst</span></span>
                    <span className='homepage-findgrave_fifth-content_header-submain'>Gi familie og venner en grav å besøke, og bevar historien om de som har gått bort.</span>
                    <Button onClick={handleClick} className='homepage-findgrave_fifth-content_header-button'>Finn en grav</Button>
                </div>
                <div className="homepage-findgrave_fifth-content_empty"/>
                <div className="homepage-findgrave_fifth-content_header-images">
                    <div className="homepage-findgrave_fifth-content_header-images_grey"/>
                    <div className="homepage-findgrave_fifth-content_header-images_orange"/>
                    <img loading='lazy' src={ImageBlur} alt='ImageBlur' className='homepage-findgrave_fifth-content_header-images_blur'/>
                    <img loading='lazy' src={Image} alt='findgrave' className='homepage-findgrave_fifth-content_header-images_image'/>
                </div>
            </div>
        </div>
    )
}

export default HomepageFindGraveFifth;