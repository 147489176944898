import React, { useEffect, useState } from 'react';
import '../ResetPass.css';
import './ResetFirstStep.css'
import { Button, Fade, makeStyles, Popper } from '@material-ui/core';
import axios from 'axios';
import CountriesArrowClosed from '../../../img/CountriesPopoverClosed.svg';
import CountriesArrowOpen from '../../../img/CountriesPopoverOpen.svg';
import CountriesPopover from './CountriesPopover';
import { useDispatch } from 'react-redux';
import { getListOfCountries } from '../../../redux/actions';
import { request } from '../../../service/request';
import { CONSTANTS } from '../../../service/constants';
import showUserNotification from '../../../components/UserNotification/showUserNotification';

const useStyles = makeStyles((theme) => ({
    paper: {
      border: 'none',
      minWidth: '350px',
      maxWidth: '350px',
      width: '100%',
      marginTop: '6px',
      borderRadius: '10px',
      maxHeight: '271px', 
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
    },
}));

const ResetFirstStep = (props) => {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [chosenCountry, setChosenCountry] = useState({dial_code: '+47', id: 1, name: "Norge"});
    const [anchorEl, setEnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;
    const dispatch = useDispatch();

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleOpenPopover = (e) => {
        setEnchorEl(anchorEl ? null : e.target.parentElement.parentElement);
    };

    const handleChoseCountryFromList = (country) => {
        setChosenCountry(country);
        setEnchorEl(null);
    };

    // useEffect(() => {
    //     request('/countries')
    //     .then(response => response.json())
    //     .then(data => dispatch(getListOfCountries(data)))
    //     .catch(err => console.log(err))
    // }, [dispatch])

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const data = {
            'phone_number': phoneNumber,
            'country_id': chosenCountry.id
        }
        const config = {
            'redirect': 'follow',
            "headers": {
                "Accept": '*/*'
              }
        }
        axios.post(`${CONSTANTS.baseUrl}/api/generate_auth_token_for_forgot_passwords`, data, config)
        .then(response => {
            props.getDataFromFirstStepReset({phoneNumber: phoneNumber, country: chosenCountry.id});
            props.nextPage();
            console.log(response)
        })
        .catch(err => {
            err.message === "Request failed with status code 500" && showUserNotification("For mange forsøk. Vent litt og prøv på ny.", "warning")
        })
    }; 
    return(
        <div className='auth-welcome'>
            <div className='auth-reset_first-step'>
            <Popper id={id} open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <div className={classes.paper}>
                    <CountriesPopover handleChoseCountryFromList={handleChoseCountryFromList} country={chosenCountry.id}/>
                    </div>
                </Fade>
                )}
            </Popper>
            <span className="auth-reset_subtitle">Engangskode sendes til din mobiltelefon</span>

            <form className='auth-form-reset' autoComplete="off" onSubmit={handleFormSubmit}>
            <div className="create-membership_first-input_block">
                <span>Mobilnummer</span>
                <div className="create-membership_first-input_block-inputs">
                    <Button onClick={handleOpenPopover} className='create-membership_first-input_block-inputs_button'>{chosenCountry.dial_code}<img loading='lazy' src={anchorEl ? CountriesArrowOpen : CountriesArrowClosed} className='countries-arrow_popover' alt=''/></Button>
                    <input type='tel' placeholder='00 00 00 00' value={phoneNumber} onChange={handlePhoneNumberChange}/>
                </div>
            </div>
                <div className='auth-reset_button-group auth-reset_buttons'>
                <button variant="contained" className='auth-welcome_contained-button_back' type='button' onClick={props.prevPage}>Tilbake</button>
                <button variant="contained" className='auth-welcome_contained-button' type="submit">Send</button>
                </div>
            </form>
            </div>
        </div>
    )
}

export default ResetFirstStep;