import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './CancelSubscriptionModal.module.css';
import X from '../../../../img/X.svg';
const appearance = {
  theme: 'night',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '32px',
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    width: 600,
    backgroundColor: '#fff',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const CancelSubscriptionModal = ({
  open,
  handleClose,
  cancelSubscription,
  planeName,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['heading-container']}>
          <div className={styles['heading']}>Avbryt abonnementer</div>
          <img
            style={{ cursor: 'pointer' }}
            loading='lazy'
            src={X}
            alt='x'
            onClick={handleClose}
          />
        </div>
        <div className={styles['message']}>
          Du er i ferd med å kansellere abonnementene dine på Nettgrave
          {planeName} plan. Merk at ved å kansellere abonnementet ditt, vil du
          ikke ha tilgang til alle funksjonene knyttet til denne planen. Du vil
          kun kunne samhandle med Nettgravv med gratisplanens funksjon.
        </div>
        <div className={styles['footer-container']}>
          <div className={styles['footer']}>
            <button
              className={styles['rootDiscard']}
              onClick={() => handleClose()}
            >
              IKKE NÅ
            </button>
            <button
              className={styles['rootVerify']}
              onClick={() => cancelSubscription()}
            >
              JA AVBRYT
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
