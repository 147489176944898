import React from 'react';
import styles from './GraveInterestsModal.module.css';
import { Modal, makeStyles } from '@material-ui/core';
import X from '../.././../../img/X.svg';
import GraveInterests from '../GraveInterests/GraveInterests';

const GraveInterestsModal = ({ onOpen, onClose, grave }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      height: 'calc(100% - 32px)',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '32px',
      borderRadius: 0,
      outline: 'none',
      '@media (max-width: 800px)': {
        width: '100%',
        height: 'calc(100% - 32px)',
        borderRadius: 0,
        overflow: 'auto',
      },
    },
    modalBackground: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['interests-content_header']}>
            <span>Interesser</span>
            <img loading='lazy' src={X} alt='x' onClick={onClose} />
          </div>
          <div className={styles['interests-content_subheader']}>
            <span>Hva var de avdødes interesser</span>
          </div>
        </div>
        <GraveInterests grave={grave} onClose={onClose} />
      </div>
    </Modal>
  );
};

export default GraveInterestsModal;
