import React, { useState, useEffect } from 'react';
import '../App.css';
import {Helmet} from "react-helmet";

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import {fetch_request} from '../service/request';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  rootCancel: {
    marginRight: '58px',
    borderRadius: '10px',
    backgroundColor: '#d8d8d8',
    width: '299px',
    height: '51px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d8d8d8',
    },
    '@media (max-width: 750px)': {
      marginRight: '0px',
      marginTop: '16px'
    }
  },
  //responsive_margin_due_fixed_header
  body: {
    minHeight: "calc(100vh - 160px)",
    marginTop: '90px',
    '@media (max-width: 532px)':{
      marginTop: '65px'
    },
    '& > div > div:nth-child(2)': {
      display: "none"
    },
  }
}))

const Layout = (props) => {
  const classes = useStyles();

  const handleClick = async () => {
    const flow = props.history.location.pathname.split('/')
    flow.length === 3
    && flow[1] === 'deceased'
    && flow[2] !== 'null'
    && flow[2] !== null
    && flow[2] !== 'undefined'
    && flow[2] !== undefined
    && localStorage.setItem('slug_for_redirect', flow[2])

    props.history.push('/auth')
  };

  const defaultDescription = `Are you looking to find or create a meaningful grave? Nettgrav.no is a private and personal network that gives modern families the opportunity to continue the love they have for deceased with pictures, films, words and music. Find or create a virtual grave here - nettgrav.no.`;
  const [title, setTitle] = useState("Nettgrav.no");
  const [description, setDescription] = useState(defaultDescription);

  useEffect(() => {
    fetch_request(`/admin_panel/search_engine_optimisations?url=${props.history.location.pathname}`,{headers: {"Authorization": "Bearer " + localStorage.getItem("token")}})
    .then(res=> res.json())
    .then(data => {
      if(data.content !== null && data.content !== undefined) {
        setTitle(data.content?.title);
        setDescription(data.content?.description);
      } else {
        setTitle("Nettgrav.no");
        setDescription(defaultDescription);
      }
    })
    .catch(err => console.log(err))
  },[props.history.location.pathname])

  return (
    <div className='App'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header onClick={handleClick} history={props.history}/>
      <div className = {classes.body}>
        {props.children}
      </div>
      <Footer location = {props.history.location}/>
    </div>
  )
};

export default Layout;