import React from 'react';
import './DeleteModal.css';
import DeleteImage from '../../../../img/DeleteImage.svg';
import { Button, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
    rootRemove: {
        width: '50%',
        border: 'none',
        backgroundColor: '#fff',
        borderRadius: '0px',
        borderBottomLeftRadius: '10px',
        height: '50px',
        color: '#eb5757',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '@media (max-width: 750px)': {
            width: '170px',
            borderRadius: '10px'
        }
    },
    rootCancel: {
        width: '50%',
        border: 'none',
        backgroundColor: '#FEA65A',
        borderRadius: '0px',
        borderBottomRightRadius: '10px',
        height: '50px',
        color: '#fff',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#FEA65A',
        },
        '@media (max-width: 750px)': {
            width: '170px',
            borderRadius: '10px'
        }
    }
}))

const DeleteModal = (props) => {
    const classes = useStyle();

    const deletePhoto = () => {
        props.deleteCoverPhoto();
        props.closeModal();
    }

    return(
        <div className='delete-virtue'>
            <div className="delete-virtue_content">
                <div className="delete-virtue_content-header">
                    <span>Slette bildet?</span>
                </div>
                <div className="delete-virtue_content-main">
                    <img loading='lazy' src={DeleteImage} alt='delete'/>
                    <span>Bekreft at du ønsker å slette bildet</span>
                </div>
            </div>
            <div className="delete-virtue_buttons">
                <Button variant="outlined" className={classes.rootRemove} onClick={deletePhoto}>Slett</Button>
                <Button variant='contained' className={classes.rootCancel} onClick={props.closeModal}>Avbryt</Button>
            </div>
        </div>
    )
}

export default DeleteModal;