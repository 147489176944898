import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  button: {
    backgroundColor: '#404D56',
    color: '#fff',
    borderRadius: 6,
    padding: '8px 16px',
    width: 'max-content',
    '&:hover': {
      backgroundColor: '#36434B',
    },
  },
}));

const PrimaryButton = ({ onClick, children, style }) => {
  const classes = useStyle();

  return (
    <Button className={classes.button} onClick={onClick} style={style}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
