
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import { fetch_request } from '../../../../../service/request';
const Header = (props) => {
	const cemeteriesCount = props.cemeteriesCount
  const [municipalityName, setMunicipalityName] = useState('')
	const municipalitySlug = props.municipalitySlug;

  useEffect(() => {
		if (municipalitySlug) {
			fetch_request(`/api/municipalities/${municipalitySlug}`)
				.then((response) => response.json())
				.then((data) => {
					setMunicipalityName(data.name);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	return (
		<div className='municipality-header'>
			<div className='municipality-header_left'>
				<div className='municipality-header_title'>
					<span>Gravlunder</span>
				</div>
				<div className='municipality-header_info'>
					<span className='search-graves_none-text'>
            Det er { cemeteriesCount === 0 ? 'no cemeteries in' : 'totalt'} { cemeteriesCount === 0 ? '': <span>{cemeteriesCount}</span>} gravlunder i {<span>{municipalityName}</span>}. { cemeteriesCount === 0 ? '' : 'Du kan søke etter gravlund for å enklere finne graven og knytte din relasjon til gravlagte'}
					</span>
				</div>
			</div>
			<div className='municipality-header_right'>
				<SearchBar setSearchInputValue={props.setSearchInputValue} searchDisabled={props.searchDisabled} />
			</div>
		</div>
	);
};

export default Header;
