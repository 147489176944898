import React, { useEffect, useState } from 'react';
import './Filters.css';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';
import {fetch_request} from '../../../service/request';

const useStyle = makeStyles(() => ({
  clear: {
    // display: 'none'
  }
}))

const Filters = (props) => {
  const classes = useStyle();
  const [countries, setCountries] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [cemeteries, setCemeteries] = useState([]);

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*'
    }
  }
  const countriesProps = {
    options: countries,
    getOptionLabel: (option) => option.name,
  }
  const municipalityProps = {
    options: municipalities,
    getOptionLabel: (option) => option.name,
  }

  const cemeteryProps = {
    options: cemeteries,
    getOptionLabel: (option) => option.name,
  }
  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
        .then(response => response.json())
        .then(data => {
            setCountries(data.countries);
        })
        .catch(err => console.log(err))
    }, [])

  useEffect(() => {
    props.setChosenCemetery(null);
    props.setChosenMunicipality(null);

    if(props.chosenCountry) {
      fetch_request(`/api/municipalities?country_id=${props.chosenCountry.id}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setMunicipalities(data.municipalities);
      })
      .catch(err => console.log(err))
    } else {
      setMunicipalities([]);
      setCemeteries([])
    }
  }, [props.chosenCountry])

  useEffect(() => {
    props.setChosenCemetery(null);
    if(props.chosenMunicipality) {
      fetch_request(`/api/graveyards?graveyard[municipality_id][]=${props.chosenMunicipality.id}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setCemeteries(data.graveyards);
      })
      .catch(err => console.log(err))
    } else setCemeteries([])
  }, [props.chosenMunicipality])

  return (
    <div className='search-filters'>
      <div className='form-margin'>
        <InputLabel className='input-lable'>Land</InputLabel>
        <FormControl variant="filled" className='form-control'>
          <Autocomplete
            {...countriesProps}
            id="auto-highlight"
            autoHighlight
            value = {props.chosenCountry}
            classes={{clearIndicator: classes.clear}}
            className='search-filters_select'
            onChange={(e, value)=> props.setChosenCountry(value)}
            renderInput={(params) => <TextField onChange={(e) => !e.target.value && props.setChosenCountry(null)} className='search-filters_select-input' {...params} margin="normal" />}
          />
        </FormControl>
      </div>
      <div className='form-margin'>
        <InputLabel className='input-lable'>Kommune</InputLabel>
        <FormControl variant="filled" className='form-control'>
          <Autocomplete
            {...municipalityProps}
            id="auto-highlight"
            autoHighlight
            classes={{clearIndicator: classes.clear}}
            value = {props.chosenMunicipality}
            className='search-filters_select'
            onChange={(e, value)=> props.setChosenMunicipality(value)}
            renderInput={(params) => <TextField onChange={(e) => !e.target.value && props.setChosenMunicipality(null)} className='search-filters_select-input municipality-22333' {...params} margin="normal" />}
          />
        </FormControl>
        
      </div>
      <div className='form-manege'>
        <InputLabel className='input-lable'>Gravlund</InputLabel>
        <FormControl variant="filled" className='form-control'>
          <Autocomplete
            {...cemeteryProps}
            id="auto-highlight"
            autoHighlight
            classes={{clearIndicator: classes.clear}}
            value = {props.chosenCemetery}
            onChange={(e, value) => props.setChosenCemetery(value)}
            className='search-filters_select'
            renderInput={(params) => <TextField onChange={(e) => !e.target.value && props.setChosenCemetery(null)} className='search-filters_select-input cemetery-22333' {...params} margin="normal" />}
          />
        </FormControl>
      </div>

    </div>
  )
};

export default Filters;