import React, { useEffect, useState } from 'react';
import styles from './EmbedImagesForm.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { addCommentOnToggle } from '../../../../../redux/actions';
import isEmpty from 'lodash.isempty';
import DeleteIcon from '../../../../../img/DeleteIcon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import EmbeddedImages from '../EmbeddedImages/EmbeddedImages';

const EmbedImagesForm = ({ onClose, openWarningModal }) => {
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);
  const [imageUrl, setImageUrl] = useState('');

  const bigAmountOfPhotos = () =>
    showUserNotification('Velg ferre en 10 bilder og prøv igjen', 'warning');

  const handleChange = (event) => {
    setImageUrl(event.target.value);
  };

  const validateUrl = (url) => {
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const handleAddLink = async (event) => {
    event.preventDefault();
    const isValidUrl = validateUrl(imageUrl);
    if (!isValidUrl) {
      showUserNotification('Please Enter a valid URL', 'error');
      return;
    }

    try {
      const response = await axios.head(imageUrl);

      if (response.status === 200) {
        handleOnLinkChange(imageUrl);
      } else {
        showUserNotification('URL does not point to a valid image', 'error');
      }
    } catch (error) {
      showUserNotification('Please Enter a valid URL', 'error');
    }
  };

  const handleOnLinkChange = (photoUrl) => {
    if (!isEmpty(currentComment.embeddedPhotos)) {
      if (currentComment.embeddedPhotos.length <= 10) {
        dispatch(
          addCommentOnToggle({
            ...currentComment,
            embeddedPhotos: [
              {
                image: photoUrl,
                id: Date.now().toString(),
                file: photoUrl,
                title: '',
              },
              ...currentComment.embeddedPhotos,
            ],
          })
        );
      } else {
        bigAmountOfPhotos();
      }
    } else {
      dispatch(
        addCommentOnToggle({
          ...currentComment,
          embeddedPhotos: [
            {
              image: photoUrl,
              id: Date.now().toString(),
              file: photoUrl,
              title: '',
            },
          ],
        })
      );
    }
    setImageUrl('');
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      handleAddLink(e);
    }
  };

  return (
    <div>
      <div className={styles['input-element']}>
        <div className={styles['title']}>Skriv inn lenken her</div>
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            placeholder='https://'
            value={imageUrl}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          {!isEmpty(imageUrl) && (
            <div className={styles['action-button']}>
              <button onClick={handleAddLink}>Add</button>
              <button onClick={() => setImageUrl('')}>Clear</button>
            </div>
          )}
        </div>
      </div>
      {!isEmpty(currentComment.embeddedPhotos) &&
        currentComment.embeddedPhotos.map((photo, index) => {
          return <EmbeddedImages embeddedPhoto={photo} index={index} />;
        })}
      <div className={styles['grave-btn-container']}>
        <div className={styles['grave-information_content-verify']}>
          <button
            type='button'
            className={styles['rootDiscard']}
            onClick={() => openWarningModal()}
          >
            Avbryt
          </button>
          <button
            disabled={
              !(
                currentComment.embeddedPhotos &&
                currentComment.embeddedPhotos.length > 0
              )
            }
            className={
              currentComment.embeddedPhotos &&
              currentComment.embeddedPhotos.length > 0
                ? styles['rootVerify']
                : styles['disabledButton']
            }
            type='submit'
            onClick={() => onClose()}
          >
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmbedImagesForm;
