import React, { useEffect, useState } from 'react';
import './PlansHeading.css';

const PlansHeading = () => {
  return (
    <div className='plans-section'>
      <div className='subscription-plans_card-content_header'>
        <span>Styrker kjærligheten og ivaretar mennesket</span>
      </div>
      <div className='subscription-plans_card-content_header-desc'>
        <span>
        Velg mellom Bevisst og Bevisst+ medlemskap for å komme i gang med funksjoner som gir mer mening og nærhet i livet.
        </span>
      </div>
    </div>
  );
};

export default PlansHeading;
