import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import closeIcon from '../../../../img/CloseSmallIcon.svg';
import GreenTrueIcon from '../../../../img/GreenTrueIcon.svg';
import './SavedPopupModal.css';

const appearance = {
  theme: 'night',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '500px',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const SavedPopupModal = ({ open, handleClose, title }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <button className='close-btn' onClick={() => handleClose()}>
          <img src={closeIcon} alt='close' />
        </button>
        <div className='true-icon'>
          <img src={GreenTrueIcon} alt='Tick' />
          <div className='saved-title'>Gjort!</div>
          <div className='saved-message'>{title}</div>
        </div>
      </div>
    </Modal>
  );
};

export default SavedPopupModal;
