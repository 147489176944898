import React from 'react';
import styles from './PlansDescription.module.css';
import { Link } from 'react-router-dom';

const PlansDescription = ({ isFreePlan, amount }) => {
  return (
    <div>
      <div className={styles['automatic-renew']}>
        {!isFreePlan ? (
          <>
            <span className={styles['automatic-renew-heading']}>
              Automatisk fornyelse
            </span>

            <span className={styles['automatic-renew-desc']}>
              Abonnementet ditt fornyes automatisk hver måned som én betaling på
              NOK {amount.toFixed(2)}. Du kan kansellere abonnementet ditt når
              som helst fra delen Mitt Medlemskap i profilen din.
            </span>
          </>
        ) : null}
      </div>
      <div className={styles['terms-condition']}>
        <span>
          Ved å klikke "Abonnér" godtar du
          <Link
            to='/terms_conditions'
            className={styles['terms-condition-link']}
          >
            Vilkår og betingelser.
          </Link>
        </span>
      </div>
    </div>
  );
};

export default PlansDescription;
